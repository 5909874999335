import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarDescuento, getInfoDescuento } from "../Funciones/Descuentos";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import HelperError from "../../../../includes/HelperError";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { TipoDescuento } from "../../../../../Lib/Funciones Generales/Constantes";
import dayjs from "dayjs";

const MySwal = withReactContent(Swal);

const Descuento = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [fecha, setFecha] = useState(GetDate());

  const [Values, setValues] = useState({
    id: id ?? "0",
    cantidad: "",
    descripcion: "",
    tipo_de_descuento: "1",
    fecha_de_caducidad: GetDate(),
    descuento_max: "",
    caducidad: "",
  });
  const [Errores, setErrores] = useState({
    cantidad: false,
    descripcion: false,
    tipo_de_descuento: false,
    fecha_de_caducidad: false,
    descuento_max: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    if (Name == "cantidad") {
      Value = Value.toDecimal();
    }

    if (Name == "descuento_max") {
      Value = Value.toDecimal();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoDescuento(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            const dmy = resp.fecha_de_caducidad.split("/");
            setFecha(
              dayjs(
                dmy?.length == 3
                  ? `${dmy[2]}-${dmy[1]}-${dmy[0]}`
                  : dayjs().add(7, "day").format("YYYY-MM-DD")
              )
            );
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarDescuento = () => {
    setIsGuardando(true);
    guardarDescuento(Values, Errores, setErrores)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  useEffect(() => {
    if (fecha != "") {
      setValues({ ...Values, fecha_de_caducidad: ConvertDate(fecha) });
      setErrores({ ...Errores, fecha_de_caducidad: false });
    }
  }, [fecha]);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const ErrorFecha =
    Errores.fecha_de_caducidad ||
    Values.fecha_de_caducidad == "" ||
    Values.fecha_de_caducidad == null
      ? true
      : false;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Descuentos
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar descuento" : "Nuevo descuento"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={6} className="py-3">
                <TextField
                  fullWidth
                  select
                  label="Tipo de descuento"
                  name="tipo_de_descuento"
                  helperText={
                    Errores.tipo_de_descuento
                      ? "Tipo de descuento no valido"
                      : ""
                  }
                  value={Values.tipo_de_descuento}
                  error={Errores.tipo_de_descuento}
                  onChange={handlInputChange}
                >
                  {TipoDescuento.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>

              <Col sm={12} md={6} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Cantidad
                  </InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        {Values.tipo_de_descuento == "1" ? "%" : "$"}
                      </InputAdornment>
                    }
                    name="cantidad"
                    helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                    value={Values.cantidad}
                    error={Errores.cantidad}
                    label="Cantidad"
                    placeholder="Cantidad"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>

              <Col sm={12} md={6} className="py-3">
                <FormControl fullWidth>
                  <TextField
                    name="descripcion"
                    label="Descripcion"
                    variant="outlined"
                    value={Values.descripcion}
                    error={Errores.descripcion}
                    helperText={
                      Errores.descripcion ? "Descripcion no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>

              <Col sm={12} md={6} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Descuento máximo
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="descuento_max"
                    helperText={
                      Errores.descuento_max ? "Descuento máximo no valido" : ""
                    }
                    value={Values.descuento_max}
                    error={Errores.descuento_max}
                    label="Descuento máximo"
                    placeholder="Descuento máximo"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>

              <Col xs={12} md={6} className="py-3">
                <BasicDatePicker
                  fullWidth
                  format="DD/MM/YYYY"
                  label="Fecha de caducidad"
                  fecha={fecha}
                  setFecha={setFecha}
                />
                {
                  <HelperError
                    Error={ErrorFecha}
                    Mensaje="Error en la fecha de caducidad"
                  />
                }
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            {Values.caducidad === "1" ? (
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarDescuento()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarDescuento()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
            )}
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Descuento;
