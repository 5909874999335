import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoProveedor } from "../Funciones/Proveedores";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Estado from "../../../../../Lib/Direcciones/Componentes/Estado";
import Municipio from "../../../../../Lib/Direcciones/Componentes/Municipio";

const MySwal = withReactContent(Swal);

const Proveedor = () => {
  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const maxLength = 1000;

  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre_comercial: "",
    correo: "",
    telefono: "",
    rfc: "",
    razon_social: "",
    domicilio: "",
    id_estado: "0",
    id_municipio: "0",
    contacto: "",
  });
  const [Errores, setErrores] = useState({
    nombre_comercia: false,
    correo: false,
    telefono: false,
    rfc: false,
    razon_social: false,
    domicilo: false,
    id_estado: false,
    id_municipio: false,
    contacto: false,
  });

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "id_estado") {
      NewValue = { ...NewValue, id_municipio: 0 };
    } else if (Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (id != undefined) {
      getInfoProveedor(id, setValues)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues({ ...resp, init: true });
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarProveedor = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const caracteresRestantes = (tam, max) => {
    const total = max - Number(tam);

    if (Number(tam) == 0) {
      return "";
    } else {
      return `(${total})`;
    }
  };
  console.log(Values.init);
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Proveedores
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar proveedor" : "Nuevo proveedor"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="nombre_comercial"
                    label="Nombre comercial"
                    variant="outlined"
                    inputProps={{ maxLength: 200 }}
                    helperText={
                      Errores.nombre_comercial
                        ? "Nombre comercial no valido"
                        : ""
                    }
                    value={Values.nombre_comercial}
                    error={Errores.nombre_comercial}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="correo"
                    label="Correo electrónico"
                    variant="outlined"
                    helperText={Errores.correo ? "Correo incorrecto" : ""}
                    value={Values.correo}
                    error={Errores.correo}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    helperText={Errores.telefono ? "Teléfono inválido" : ""}
                    value={Values.telefono}
                    error={Errores.telefono}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="rfc"
                    label="RFC"
                    variant="outlined"
                    helperText={Errores.rfc ? "RFC invalido" : ""}
                    value={Values.rfc}
                    error={Errores.rfc}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="razon_social"
                    label="Razon Social"
                    variant="outlined"
                    value={Values.razon_social}
                    error={Errores.razon_social}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    label="Domicilio"
                    variant="outlined"
                    inputProps={{ maxLength: 200 }}
                    name="domicilio"
                    helperText={Errores.domicilio ? "Domicilio no valido" : ""}
                    value={Values.domicilio}
                    error={Errores.domicilio}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <Estado
                    Value={Values.id_estado}
                    Error={Errores.id_estado}
                    handlInputChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <Municipio
                    Estado={Values.id_estado}
                    Value={Values.id_municipio}
                    Error={Errores.id_municipio}
                    init={Values.init}
                    handlInputChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    multiline
                    fullWidth
                    name="contacto"
                    variant="outlined"
                    label={`Contacto ${caracteresRestantes(
                      Values.contacto.length,
                      1000
                    )}`}
                    inputProps={{ maxLength: 1000 }}
                    value={Values.contacto}
                    error={Errores.contacto}
                    onChange={handlInputChange}
                  />
                </Col>
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarProveedor()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Proveedor;
