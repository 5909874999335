import React, { useState, useEffect } from "react";
import { Alert, Avatar, Snackbar, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
    TableHead,
    Tooltip,
    Grid,
    MenuItem,
    Menu,
    Typography,
} from "@mui/material";
import AccEditar from "../../../../assets/AccEditar.svg";
import AccEliminar from "../../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import Loading from "../../../../includes/Loading";
import { Eliminar, Desactivar, EliminarTamano } from "../Funciones/Productos";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Switch from "../../../../includes/Switch";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import MoreVert from "@mui/icons-material/MoreVert";

const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function Tabla({ Lista, IsLoading, setLista, handleChange, id_producto }) {
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(15);

    const [open, setOpen] = useState(false);
    // const [mensaje, setMensaje] = useState("");
    // const [severity, setSeverity] = useState("error");
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [idRow, setIdRow] = useState();
    const [stateRow, setStateRow] = useState();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const EliminarRegistro = (id, index) => {
        new Swal({
            title: "¿Estás seguro de eliminar este tamaño?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {

            if (isConfirm.isConfirmed) {
                if (id != undefined) {

                    if (id == 0) {
                        eliminarTamanoDeTablaIndex(index);
                        return;
                    }

                    EliminarTamano(id_producto, id)
                        .then((resp) => {
                            new Swal({
                                title: "Exito!",
                                text: "Eliminado correctamente",
                                icon: "success",
                            }).then(function () {
                                eliminarTamanoDeTabla(id);
                            });
                        })
                        .catch((resp) => {
                            alert("error al eliminar");
                        });
                }
            }
        });
    };

    // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
    const eliminarTamanoDeTabla = (id) => {
        const filteredLibraries = Lista.filter((item) => {
            return item.ID !== id;
        });
        setLista(filteredLibraries);
    };

    const eliminarTamanoDeTablaIndex = (index) => {
        const filteredLibraries = Lista.filter((item, i) => {
            return i != index;
        });
        setLista(filteredLibraries);
    };

    //   const onChange = (checked, id) => {
    //     setIdRow(id);
    //     setStateRow(checked);
    //     ActivarOrDesactivar(id, checked);
    //   };
    //   const ActivarOrDesactivar = (id, checked) => {
    //     if (id != undefined) {
    //       Desactivar(id, checked)
    //         .then((resp) => {
    //           const filteredLibraries = Lista.filter((item) => {
    //             if (item.ID == id) {
    //               item.ACTIVO = checked;
    //             }
    //             return item;
    //           });
    //           setLista(filteredLibraries);
    //           setSeverity("success");
    //           setMensaje("Cambios guardados correctamente");
    //           setOpen(true);
    //         })
    //         .catch((resp) => {
    //           setSeverity("error");
    //           setMensaje("Error al guardar los cambios");
    //           setOpen(true);
    //         });
    //     }
    //   };

    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
        ({ theme }) => ({
            "& .MuiSnackbar-root": {
                top: theme.spacing(15),
            },
        })
    );
    return (
        <>
            <Menu
                open={openMenu}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                TransitionComponent={Fade}
            >
                {/* <MenuItem>
            <Switch
              checked={stateRow}
              onChange={onChange}
              id={idRow}
              tituloActivo="Desactivar producto "
              tituloInactivo="Activar producto"
              on
            />
            {stateRow ? "Desactivar" : "Activar"}
          </MenuItem> */}
                {/* <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"./edit/" + idRow}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem> */}
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        EliminarRegistro(idRow, Lista.tamanos.findIndex(row => row.ID == idRow));
                    }}
                >
                    <IconButton>
                        <img src={AccEliminar} />
                    </IconButton>
                    Eliminar
                </MenuItem>
            </Menu>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell> */}
                        <TableCell className="font-Avenir font-AvenirBold" align="left">
                            IDENTIFICADOR
                        </TableCell>
                        <TableCell className="font-Avenir font-AvenirBold" align="left">
                            TAMAÑO
                        </TableCell>
                        {/* <TableCell className="font-Avenir font-AvenirBold" align="left">
                                CÓDIGO
                            </TableCell> */}

                        <TableCell className="font-Avenir font-AvenirBold" align="right">
                            ACCIONES
                        </TableCell>
                    </TableRow>
                </TableHead>
                {IsLoading ? (
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row" colSpan={3}>
                                <Loading />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {
                            Array.isArray(Lista) && (
                                Lista.map((row, index) => (
                                    <TableRow key={row.ID}>
                                        {/* <TableCell
                                            component="th"
                                            scope="row"
                                            className="d-none d-sm-table-cell"
                                        >
                                            {row.FECHA}
                                        </TableCell> */}

                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className="d-none d-sm-table-cell"
                                        >
                                            {(index + 1)}
                                        </TableCell>

                                        <TableCell align="right" component="td" scope="row" className="">
                                            <TextField
                                                fullWidth
                                                name="NOMBRE"
                                                label="Nombre"
                                                variant="outlined"
                                                value={row.NOMBRE}
                                                onChange={({ target }) => handleChange(index, "NOMBRE", target.value,)}
                                            />
                                        </TableCell>



                                        <TableCell align="right">
                                            <Grid key={row.ID} className="d-none d-sm-block">
                                                {/* <Switch
                                                    checked={row.ACTIVO}
                                                    onChange={onChange}
                                                    id={row.ID}
                                                    tituloActivo="Desactivar Color "
                                                    tituloInactivo="Activar Color"
                                                />
                                                <Link
                                                    to={"./edit/" + row.ID}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <Tooltip title="Editar" placement="top">
                                                        <IconButton>
                                                            <img src={AccEditar} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link> */}

                                                <Tooltip title="Eliminar" placement="top">
                                                    <IconButton onClick={() => EliminarRegistro(row.ID, index)}>
                                                        <img src={AccEliminar} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid>
                                                <DrawerHeader
                                                    style={{ backgroundColor: "#FFFFFF" }}
                                                    className="d-xsm-none d-sm-block d-sm-none"
                                                    onClick={() => {
                                                        setIdRow(row.ID);
                                                        setStateRow(row.ACTIVO);
                                                    }}
                                                >
                                                    <IconButton onClick={handleClick}>
                                                        <MoreVert />
                                                    </IconButton>
                                                </DrawerHeader>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )
                        }
                    </TableBody>
                )}
            </Table>
        </>
    );
}
