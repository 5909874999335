import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";

export const guardarCuponera = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.tipoDescuento || Values.tipoDescuento == "") {
    Errores.tipoDescuento = true;
    setErrores(Errores);
    error = true;
  }

  if (
    !Values.valor ||
    Values.valor == "" ||
    Values.valor == "0" ||
    Values.valor == 0
  ) {
    Errores.valor = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.descuento_max || Values.descuento_max == "") {
    Errores.descuento_max = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.vigencia || Values.vigencia == "") {
    Errores.vigencia = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.tipo) {
    if (!Values.codigo || Values.codigo == "") {
      Errores.codigo = true;
      setErrores(Errores);
      error = true;
    }
    if (
      !Values.maxUsos ||
      Values.maxUsos == "" ||
      Values.maxUsos == "0" ||
      Values.maxUsos == 0
    ) {
      Errores.maxUsos = true;
      setErrores(Errores);
      error = true;
    }
  } else {
    if (
      !Values.numeroCupones ||
      Values.numeroCupones == "" ||
      Values.numeroCupones == "0" ||
      Values.numeroCupones == 0
    ) {
      Errores.numeroCupones = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    let data = [];
    const body = {
      id: Number(Values.id),
      tipO_DESCUENTO: Number(Values.tipoDescuento),
      cupoN_CODIGO: Values.tipo,
      valor: Number(Values.valor),
      vigencia: Values.vigencia,
      numerO_CUPONES: Number(Values.numeroCupones),
      codigo: Values.codigo,
      maximO_USOS: Number(Values.maxUsos),
      descuentO_MAX: Number(Values.descuento_max),
    };
    //console.log(body);
    const url = "cupones/Insertar";

    const res = await postdData(url, body);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    cat: 0,
  };

  const url = `cupones/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getCuponesByCuponera = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    ID: id,
  };

  const url = `cupones/ConsultarCUPO?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `cupones/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `cupones/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoByCodigo = async (cod) => {
  let data = [];
  let queryParamsObj = {
    codigo: cod,
  };

  if (cod == "" || cod == null || cod == undefined || !cod) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Ingresa un codigo", codigo: "901" });
    });
  }

  const url = `cupones/ConsultaByCodigo?${stringify(queryParamsObj)}`;
  const res = await getData(url);

  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;

      resolve(data);
    } else {
      reject(res);
    }
  });
};
