import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepCupones from "./Componentes/RepCupones";
import Cupon from "./Componentes/Cupon";

const CuponesRuta = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepCupones />} />
        <Route path="/add" element={<Cupon />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default CuponesRuta;