import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getOpcionesSucursal } from "../Funciones/Sucursales";

const SelectSucursales = ({
  Value = 0,
  Error,
  handlInputChange,
  Name = "id_sucursal",
  Label = "Sucursal",
  className = "",
  size = "medium",
  Values = {},
  currencies = [],
  MostrarSucu,
}) => {
  const opcionInicial = { ID: 0, NOMBRE: "Sucursal" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Sucursal, setSucursal] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  // alert(Value)

  const cargarOps = () => {
    getOpcionesSucursal()
      .then((resp) => {
        let lists = JSON.parse(resp.data);
        let list = MostrarSucu
          ? JSON.parse(resp.data)
          : [{ ID: 0, NOMBRE: "Sin sucursales" }, ...lists];

        if (list.length !== 0) {
          setSucursal({ cargado: true, lista: list });
        } else {
          setSucursal({
            cargado: true,
            lista: [{ ID: 0, NOMBRE: "Sin sucursales" }],
          });
        }
      })
      .catch((resp) => {
        setSucursal({
          cargado: true,
          lista: [{ ID: 0, NOMBRE: "Sin sucursales" }],
        });
      });
  };

  useEffect(() => {
    cargarOps();
  }, []);

  const hasMounted = useRef(false); // Para saber si ya se montó

  useEffect(() => {
    if (!hasMounted.current) {
      // Si es la primera vez, actualizamos el ref y no ejecutamos la lógica
      hasMounted.current = true;
    } else {
      // Aquí tu lógica cuando no es la primera vez que se monta el componente
      //  console.log(Sucursal);
      if (Value !== Values.idS) {
       //  setSucursal(prev => ({ ...prev, cargado: true })); 
        console.log(Sucursal); 

        setTimeout(() => {
         // setSucursal(prev => ({ ...prev, cargado: false })); 
        }, 1500);
      }
    }
  }, [Values.tipo_envio]);

    
  const getOpcion = () => {
    const opcion = Sucursal.lista.find((x) => x.ID === Number(Value));
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Sucursal.cargado ? (
    <Autocomplete
      size={size}
      fullWidth
      className={className}
     defaultValue={getOpcion}
       // value={Value} 
      isOptionEqualToValue={(option, value) => {
        return option.ID === value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({
            target: {
              name: Name,
              value: value.ID,
              dir: String(value.DIRECCION),
            },
          });
        } else {
          handlInputChange({ target: { name: Name, value: "", dir: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Sucursal.lista}
      getOptionLabel={(option) => {
        return option.NOMBRE;
      }}
      renderInput={(params) => {
        return (
          <TextField
            size={size}
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un Sucursal" : ""}
            label={Label}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <span>{option.NOMBRE}</span>
          </li>
        );
      }}
    />
  ) : (
    <TextField
      size={size}
      fullWidth
      disabled={true}
      value={"Sucursal"}
      className={className}
    />
  );
};

export default SelectSucursales;
