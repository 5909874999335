import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepTraspasarMercacia from "./Componentes/RepTraspasarMercacia";
import TraspasarMercancia from "./Componentes/TraspasarMercancia.";

const routerTraspasoMercancia = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepTraspasarMercacia />} />
        <Route path="/add" element={<TraspasarMercancia />} />
        <Route path="/edit/:id" element={<TraspasarMercancia />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerTraspasoMercancia;
