import React, { createContext, useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import ValidadorWebMovile from "../../Lib/Funciones Generales/ValidadorWebMovile";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import Error404 from "../../Includes/Error404";
import Dashboard from "../Components/Inicio/Dashboard";
import AccountMenu from "./MenuPerfil";
import CambioDeEmpresa from "./CambioDeEmpresa";
import MenuNotificaciones from "./Notificaciones";
import AccEditarImagen from "../assets/AvatarMenu.svg";
import { getInfoPerfil } from "../Components/Catalogos/Perfil/Funciones/Perfiles";
import Perfil from "../Components/Catalogos/Perfil/Perfil";
import Clientes from "../Components/Catalogos/Clientes/Cliente";
import Categorias from "../Components/Catalogos/Categorias/Categorias";
import Sucursales from "../Components/Catalogos/Sucursales/Sucursales";
import ConfigCorreos from "../Components/Configuraciones/Correos/Correos";
import Administradores from "../Components/Catalogos/Administradores/Administradores";
import Almacenes from "../Components/Catalogos/Almacenes/Almacenes";
import Series from "../Components/Catalogos/Series/Series";
import Productos from "../Components/Catalogos/Productos/Productos";
import Proveedor from "../Components/Catalogos/Proveedores/Proveedores";
import Cupon from "../Components/Catalogos/Cupones/Cupones";
import Descuento from "../Components/Catalogos/Descuentos/Descuentos";
import OrdenCompra from "../Components/Catalogos/OrdenCompra/Ordencompra";
import Requision from "../Components/Catalogos/RequisicionMercancia/Requisicion";
import Zonas from "../Components/Catalogos/Zonas/Zonas";
import Repartidores from "../Components/Catalogos/Repartidores/Repartidores";
import GruposAcceso from "../Components/Configuraciones/gruposacceso/GruposAcceso";
import Facturas from "../Components/Facturacion/Facturas/Facturas";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import Empresas from "../Components/Configuraciones/Empresas/Empresas";
import ExistenciaMercancia from "../Components/Catalogos/ExistenciaMercancia/ExistenciaMercancia";
import Mermas from "../Components/Catalogos/Mermas/Mermas";
import HorariosEnvios from "../Components/Catalogos/HorarioEnvio/HorarioEnvio";
import Puestos from "../Components/Catalogos/Puestos/Puestos";
import ProductosDescuentos from "../Components/Catalogos/ProductosDescuentos/ProductosDescuentos";

import PuntoDeVenta from "../Components/PuntoDeVenta/PuntoDeVenta";
import MonitorPedidos from "../Components/Pedidos/Pedidos";
import ErrorAccess from "./ErrorAccess";
import { usePermisos } from "../../Context/PermissionsContext";
import ReporteVentas from "../Components/Catalogos/ReporteVentas/ReporteVentas";
import ReporteVentasProd from "../Components/Catalogos/ReporteVentasProducto/ReporteVentasProd";
import CorteCaja from "../Components/Catalogos/CorteCaja/CorteCaja";

import IngresoMercancia from "../Components/Catalogos/IngresoMercancia/IngresoMercancia";
import TraspasoMercancia from "../Components/Catalogos/TraspasoMercancia/TraspasoMercancia";
import AjusteMercancia from "../Components/Catalogos/AjusteMercancia/AjusteMercancia";
import ReStock from "../Components/Catalogos/ReStock/ReStock";
import Bitacora from "../Components/Catalogos/Bitacora/Bitacora";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#101727",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#101727",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const IconContext = createContext();

export default function Menu() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [url, setUrl] = useState("");
  // const [permisos, setPermisos] = useState([]);
  // const [permisosMenu, setPermisosMenu] = React.useState([]);

  const { userPermissions, handleSetUserPermissions, IsLoading, setIsLoading } =
    usePermisos();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isTabletOrMobile = useMediaQuery("(max-width: 1224px)");

  useEffect(() => {
    setOpen(ValidadorWebMovile());
  }, []);
  useEffect(() => {
    setOpen(!isTabletOrMobile);
  }, [isTabletOrMobile]);

  const [Values, setValues] = useState({
    img: AccEditarImagen,
  });

  useEffect(() => {
    setIsLoading(true);
    getInfoPerfil(AccEditarImagen, true)
      .then((resp) => {
        // console.log("Respuesta", resp);
        //console.log("info perfil", resp);
        setValues(resp.Values);
        handleSetUserPermissions(resp.permisos);
        //console.log(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  //console.log("PER", userPermissions);

  return (
    <>
      {IsLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <IconContext.Provider value={{ url, setUrl, open, setOpen }}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            color="transparent"
            style={{ boxShadow: "none", zIndex: 10 }}
          >
            <Toolbar style={open ? { paddingLeft: "0px" } : {}}>
              <div className="d-flex justify-content-between w-100 ">
                <div>
                  {open ? (
                    <DrawerHeader style={{ backgroundColor: "#FFFFFF" }}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          <MoreVert />
                        ) : (
                          <MoreVert />
                        )}
                      </IconButton>
                    </DrawerHeader>
                  ) : (
                    <IconButton onClick={handleDrawerOpen}>
                      <MenuIcon style={{ color: "white", zIndex: 10 }} />
                    </IconButton>
                  )}
                </div>
                <div
                  className="d-flex justify-content-end w-100 align-content-center"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="p-1 d-flex align-content-center d-md-none d-md-block">
                    <MenuNotificaciones
                      Values={Values}
                      IsLoading={IsLoading}
                      className="align-self-center"
                    />
                  </div>

                  <div className="p-1 d-flex align-content-center  d-none d-md-flex d-md-block">
                    <div className="align-self-center  d-none d-md-flex d-md-block">
                      <CambioDeEmpresa
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                        permisosMenu={userPermissions}
                      />
                    </div>
                    <div className="align-self-center  d-none d-md-flex d-md-block">
                      <MenuNotificaciones
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                    <div className="align-self-center  d-none d-md-flex d-md-block">
                      <AccountMenu
                        Values={Values}
                        IsLoading={IsLoading}
                        className="align-self-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            style={{
              backgroundColor: "#101727",
              height: "100%",
              color: "white",
              zIndex: 2,
            }}
          >
            <Sidebar
              openMenu={open}
              permisosMenu={userPermissions}
              IsLoading={IsLoading}
            />
          </Drawer>
          <Divider light />
          <Box
            component="main"
            sx={{ flexGrow: 1 }}
            className="p-xsm-0 p-sm-3 "
            style={{ backgroundColor: "#F9FAFC", minHeight: "100vh" }}
          >
            <div className="mb-5"></div>
            <Routes>
              {!IsLoading && (
                <>
                  <Route
                    path="inicio/*"
                    element={<Dashboard Values={Values} />}
                  />
                  <Route path="/" element={<Dashboard Values={Values} />} />
                  <Route path="perfil/*" element={<Perfil />} />

                  <Route
                    path="facturas/*"
                    element={
                      userPermissions.includes("Facturas") == true ? (
                        <Facturas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="clientes/*"
                    element={
                      userPermissions.includes("Clientes") == true ? (
                        <Clientes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="categorias/*"
                    element={
                      userPermissions.includes("Categorias") == true ? (
                        <Categorias />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="zonas/*"
                    element={
                      userPermissions.includes("Zonas") == true ? (
                        <Zonas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="sucursales/*"
                    element={
                      userPermissions.includes("Sucursales") == true ? (
                        <Sucursales />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="administradores/*"
                    element={
                      userPermissions.includes("Administradores") == true ? (
                        <Administradores />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="configCorreos/*"
                    element={
                      userPermissions.includes("Correos") == true ? (
                        <ConfigCorreos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="horariosEnvios/*"
                    element={
                      userPermissions.includes("HorariosEnvios") == true ? (
                        <HorariosEnvios />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="almacenes/*"
                    element={
                      userPermissions.includes("Almacenes") == true ? (
                        <Almacenes />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="series/*"
                    element={
                      userPermissions.includes("Series") == true ? (
                        <Series />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="proveedores/*"
                    element={
                      userPermissions.includes("Proveedores") == true ? (
                        <Proveedor />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="cupones/*"
                    element={
                      userPermissions.includes("Cupones") == true ? (
                        <Cupon />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="descuentos/*"
                    element={
                      userPermissions.includes("Descuentos") == true ? (
                        <Descuento />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="productos/*"
                    element={
                      userPermissions.includes("Productos") == true ? (
                        <Productos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="repartidores/*"
                    element={
                      userPermissions.includes("Repartidores") == true ? (
                        <Repartidores />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="requisicionmercancia/*"
                    element={
                      userPermissions.includes("RequisicionMercancia") ==
                        true ? (
                        <Requision />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="ordencompra/*"
                    element={
                      userPermissions.includes("OrdenDeCompra") == true ? (
                        <OrdenCompra />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="monitor_pedidos/*"
                    element={
                      userPermissions.includes("Pedidos") == true ? (
                        <MonitorPedidos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  {/* <Route path="monitor_pedidos/*" element={<MonitorPedidos />} /> */}
                  <Route
                    path="puestos/*"
                    element={
                      userPermissions.includes("Puestos") == true ? (
                        <Puestos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="mermas/*"
                    element={
                      userPermissions.includes("Mermas") == true ? (
                        <Mermas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="exitenciamercancia/*"
                    element={
                      userPermissions.includes("ExitenciaMercancia") == true ? (
                        <ExistenciaMercancia />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="ingresomercancia/*"
                    element={
                      userPermissions.includes("IngresoMercancia") == true ? (
                        <IngresoMercancia />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="traspasomercancia/*"
                    element={
                      userPermissions.includes("TraspasoMercancia") == true ? (
                        <TraspasoMercancia />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="ajustemercancia/*"
                    element={
                      userPermissions.includes("AjusteMercancia") == true ? (
                        <AjusteMercancia />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="gruposacceso/*"
                    element={
                      userPermissions.includes("GruposDeacceso") == true ? (
                        <GruposAcceso />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="punto_de_venta/*"
                    element={
                      userPermissions.includes("PuntoDeVenta") == true ? (
                        <PuntoDeVenta open={open} setOpen={setOpen} />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route
                    path="/configEmpresas*"
                    element={
                      userPermissions.includes("Empresas") == true ? (
                        <Empresas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />
                  <Route path="/configEmpresas/*" element={<Empresas />} />
                  <Route
                    path="/reportepedidos"
                    element={
                      userPermissions.includes("ReportePedidos") == true ? (
                        <ReporteVentas />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/reporteventas"
                    element={
                      userPermissions.includes("ReporteVentas") == true ? (
                        <ReporteVentasProd />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  ></Route>
                  <Route
                    path="/productos_descuento/*"
                    element={
                      userPermissions.includes("ProductosDescuento") == true ? (
                        <ProductosDescuentos />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route
                    path="cortes/*"
                    element={
                      userPermissions.includes("CorteCaja") == true ? (
                        <CorteCaja open={open} setOpen={setOpen} />
                      ) : (
                        <ErrorAccess />
                      )
                    }
                  />

                  <Route path="*" element={<Error404 />} />
                </>
              )}
              <Route path="restock/*" element={<ReStock />} />
              <Route
                path="bitacora/*"
                element={
                  userPermissions.includes("BitacoraInventarios") == true ? (
                    <Bitacora />
                  ) : (
                    <ErrorAccess />
                  )
                }
              />
            </Routes>
          </Box>
        </Box>
      </IconContext.Provider>
    </>
  );
}
