 import {  Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Grupo from "./Componentes/Grupo";
import RepGrupos from "./Componentes/RepGrupos";
 
 const GruposAcceso =()=> {
  return (
    <> 
     <Routes>     
        <Route index element={<RepGrupos/>} /> 
        <Route path="/add" element={<Grupo/>} /> 
        <Route path="/edit/:id" element={<Grupo />} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default GruposAcceso