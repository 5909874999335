export function GetDate(dias = 0, FORMAT = 0) {
  var fecha = new Date();
  fecha.setDate(fecha.getDate() + dias);
  const year = fecha.getFullYear();
  const month = fecha.getMonth() + 1;
  const day = fecha.getDate();
  switch (FORMAT) {
    case 1: // (DD/MM/YYYY)
      return (
        (day < 10 ? "0" + day : day) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        year
      );

    default: // (YYYY-MM-DD)
      return (
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day)
      );
  }
}

export function ConvertTime(fecha) {
  if (typeof fecha == "object" && fecha) {
    let fechaFormateada = "";
    const hh = fecha["$H"];
    const mm = fecha["$m"];
    fechaFormateada =
      (hh < 10 ? "0" + hh : hh) + ":" + (mm < 10 ? "0" + mm : mm) + ":00";
    return fechaFormateada;
  } else {
    return fecha;
  }
}

export function ConvertDate(fecha) {
  if (typeof fecha == "object" && fecha) {
    let fechaFormateada = "";
    let yyyy = fecha["$y"];
    let mm = fecha["$M"] + 1;
    let dd = fecha["$D"];
    fechaFormateada =
      yyyy + "-" + (mm < 10 ? "0" + mm : mm) + "-" + (dd < 10 ? "0" + dd : dd);
    return fechaFormateada;
  } else {
    return fecha;
  }
}

export const isValidDate = (d) => {
  let valido = d instanceof Date && !isNaN(d);
  if (!valido) {
    if (typeof d == "string") {
      valido = d.includes("-");
    }
  }
  return valido;
};

export const ValidateTimes = (inicio = "", fin = "") => {
  const FechaFake = "1999-01-01";
  let resp = {
    err: false,
    msj: "",
    in: "0", //0 = no hay errores --- 1 = error en inicio --- 2 = error en fin --- 3 = error en ambos
  };
  //console.log(inicio, fin);
  if (inicio.includes("NaN")) {
    resp.err = true;
    resp.msj = "Formato invalido";
    resp.in = "1";
    return resp;
  }

  if (fin.includes("NaN")) {
    resp.err = true;
    resp.msj = "Formato invalido";
    resp.in = "2";
    return resp;
  }

  if (inicio == fin) {
    resp.err = true;
    resp.msj = "La hora es igual";
    resp.in = "3";
    return resp;
  }

  if (new Date(FechaFake + " " + inicio) > new Date(FechaFake + " " + fin)) {
    resp.err = true;
    resp.msj = "El inicio es mayor al fin";
    resp.in = "1";
    return resp;
  }

  return resp;
};
