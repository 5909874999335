import { stringify } from "query-string";
import { getData, postdData } from "../../../Context/backend";

export const getRegimenfiscal = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarRegimenFiscal?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getCFDI = async (query = "") => {
  let data = [];
  let queryParamsObj = {
      "query": query
  }
  const url = `sat/ConsultarUsoCFDI?${stringify(queryParamsObj)}`
  const res = await getData(url);
  return new Promise((resolve, reject) => {
      if (!res.error) {
          data = res.data.respuesta;
          resolve(data);
      } else {
          reject(res);
      }
  });
}


export const getInfoRegimenFiscal = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/ConsultarRegimenFiscal?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        clave: String(data.CLAVE),
        descripcion: String(data.DESCRIPCION),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};

export const getInfoProductoServicio = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/getInfoProductoServicio?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoUnidadMedida = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `sat/getInfoUnidadMedida?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getProductosServicios = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarProdServicio?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getUnidadesMedidas = async (query) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sat/ConsultarUnidadMedida?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log("dataSat", res.data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getPlanner = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `planners/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoPlanner = async (clave) => {
  let data = [];
  let queryParamsObj = {
    clave: clave,
  };
  const url = `planners/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data)[0];
      let Values = {
        id: String(data.ID),
        nombre: String(data.NOMBRE),
        apellido_paterno: String(data.APELLIDO_PATERNO),
        apellido_materno: String(data.APELLIDO_MATERNO),
      };
      resolve(Values);
    } else {
      reject(res);
    }
  });
};
