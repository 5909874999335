import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../../includes/Loading";
import { avatarLetters } from "../../../../Lib/Funciones Generales/Generales";
import { getDescuentosVigentes } from "../../Catalogos/Descuentos/Funciones/Descuentos";
import iconodescuento from "../../../assets/iconodescuento.svg";
import CodigoDescuento from "./CodigoDescuento";

export default function Descuentos({
  setDescuento = () => { },
  descuento,
  mensaje,
  setMensaje = () => { },
  setOpenAlert = () => { },
  handleInputChange = () => { },
  codigo = "",
  habilitarClick = true
}) {
  const [descuentos, setDescuentos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDescuentosVigentes()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        if (list.length != 0) {
          setDescuentos(list);
        } else {
          setDescuentos(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setDescuentos([]);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          {isLoading ? (
            <Loading />
          ) : (
            <div style={{ filter: !habilitarClick ? "grayscale(100%)" : "" }} className="w-100  d-flex justify-content-start flex-wrap flex-row">
              {descuentos.map((row) => (
                <div
                  className=""
                  onClick={() => {
                    if (!habilitarClick) return;
                    setDescuento({
                      id: row.ID,
                      descuento: row.CANTIDAD,
                      tipo: row.TIPO_DE_DESCUENTO,
                      max: row.DESCUENTO_MAX,
                      categoria: "DESCUENTO",
                    });
                    //console.log(row);
                  }}
                >
                  <div
                    style={{ maxWidth: "130px", maxHeight: "130px" }}
                    className={
                      " card-producto card p-3 m-3 ms-0 mt-1 mb-3 shadow-sm d-flex justify-content-center  flex-column " +
                      (descuento?.id == row.ID && codigo == "" && habilitarClick
                        ? "card-producto-active"
                        : "")
                    }
                  >
                    <center>
                      <img
                        src={iconodescuento}
                        className="img-fluid"
                        style={{ maxWidth: "30px" }}
                      />
                    </center>
                    <h3
                      style={{ fontSize: "20px" }}
                      className="font-AvenirBold parrafo2lineastruncate text-center my-1 "
                    >
                      {row.TIPO_DE_DESCUENTO == 1
                        ? row.CANTIDAD + " %"
                        : "$ " + row.CANTIDAD}
                    </h3>
                    <p
                      style={{ fontSize: "10px" }}
                      className="font-AvenirBold  parrafo2lineastruncate text-center my-1"
                    >
                      DESCUENTO
                    </p>
                    {row.TIPO_DE_DESCUENTO == 1 ? (
                      <p
                        style={{ fontSize: "11px" }}
                        className="font-Avenir  parrafo2lineastruncate text-center my-1"
                      >
                        Max. -$ {row.DESCUENTO_MAX}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <CodigoDescuento
            habilitarClick={habilitarClick}
            descuentos={descuento}
            setDescuento={setDescuento}
            mensaje={mensaje}
            setMensaje={setMensaje}
            setOpenAlert={setOpenAlert}
            handleInputChange={handleInputChange}
            codigo={codigo}
          />
        </Col>
      </Row>
    </>
  );
}
