import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const AsignarFlorista = ({
  setShowDialogAsignarFlorista,
  setPedidoSeleccionado,
  id,
}) => {
  return (
    <>
      <Tooltip title="Asignar florista" placement="top" className="me-auto">
        <IconButton
        onClick={(event) => {
          event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDialogAsignarFlorista(true);
          }}
        >
          <AssignmentIndIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AsignarFlorista;
