import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";

export default function TabsDedicatoria({
  Values = {},
  handleInputChange = () => {},
}) {
  const caracteresRestantes = (tam, max) => {
    const total = max - Number(tam);

    if (Number(tam) == 0) {
      return "";
    } else {
      return `(${total})`;
    }
  };
  return (
    <> 
      <Col xs={12} className="p-3">
        <TextField
          fullWidth
          label={`Envia ${caracteresRestantes(Values.envia.length, 500)}`}
          value={Values.envia}
          onChange={handleInputChange}
          name="envia"
        />
      </Col>
      <Col xs={12} className="p-3">
        <TextField
          fullWidth
          label={`Recibe ${caracteresRestantes(Values.recibe.length, 500)}`}
          value={Values.recibe}
          onChange={handleInputChange}
          name="recibe"
        />
      </Col>
      <Col xs={12} className="p-3">
        <TextField
          fullWidth
          multiline
          rows={7}
          value={Values.dedicatoria}
          label={`Dedicatoria ${caracteresRestantes(
            Values.dedicatoria.length,
            250
          )}`}
          inputProps={{ maxLength: 250 }}
          name="dedicatoria"
          onChange={handleInputChange}
        />
      </Col>
    </>
  );
}
