import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepAdministradores from "./Componentes/RepAdministradores";
import Administradores from "./Componentes/Administradores";
const routerAdministradores = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepAdministradores />} />
        <Route path="/add" element={<Administradores/>} />
        <Route path="/edit/:id" element={<Administradores/>} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerAdministradores;
