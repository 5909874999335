import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { formatMoneda } from "../../../../../../Lib/Funciones Generales/Generales";

export default function TabConceptos({
  data = { conceptos: [] },
  ivaTotal = 0,
  totalProds = 0,
  subTotal = 0,
}) {
  return (
    <>
      <Row>
        <Col xs={12} className="p-3">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>CODIGO</TableCell>
                  <TableCell align="right">DESCRIPCIÓN</TableCell>
                  <TableCell align="right">CLAVE SAT</TableCell>
                  <TableCell align="right">UNIDAD DE MEDIDA SAT</TableCell>
                  <TableCell align="right">UNIDAD DE MEDIDA INTERNA</TableCell>
                  <TableCell align="right">PRECIO UNITARIO</TableCell>
                  <TableCell align="right">IVA</TableCell>
                  <TableCell align="right">CANTIDAD</TableCell>
                  <TableCell align="right">IMPORTE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.conceptos.map((row) => (
                  <TableRow key={row.ID}>
                    <TableCell>{row.CODIGO}</TableCell>
                    <TableCell>{row.PRODUCTO}</TableCell>
                    <TableCell align="right">{row.CLAVE_PRODUCTO}</TableCell>
                    <TableCell align="right">{row.CLAVE_MEDIDA}</TableCell>
                    <TableCell align="right">{row.UNIDAD_MEDIDA}</TableCell>
                    <TableCell align="right">
                      {formatMoneda(parseFloat(row.PRECIO_SIN_IVA.toFixed(2)))}
                    </TableCell>
                    <TableCell align="right">
                      {formatMoneda(
                        parseFloat(row.CANTIDAD) *
                          parseFloat(row.TOTAL_IVA.toFixed(2))
                      )}
                    </TableCell>
                    <TableCell align="right">{row.CANTIDAD}</TableCell>
                    <TableCell align="right">
                      {formatMoneda(
                        parseFloat(row.CANTIDAD) *
                          (parseFloat(row.PRECIO_SIN_IVA) +
                            parseFloat(row.TOTAL_IVA))
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  <TableCell rowSpan={4} />
                  {/*
                  <TableCell colSpan={1}>DESCUENTO</TableCell>
                  <TableCell align="right">
                    {data.conceptos.length > 0
                      ? data.conceptos[0].DESCUENTO
                      : "0"}
                    </TableCell>*/}
                </TableRow>
                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    SUBTOTAL
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(subTotal.toFixed(2)))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    IVA
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(ivaTotal.toFixed(2)))}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    colSpan={1}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    style={{ fontSize: "18px" }}
                    align="right"
                  >
                    {formatMoneda(parseFloat(totalProds))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
}
