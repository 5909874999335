//#region Importaciones
import { Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import TablaDetalleComposicion from "../TablaDetalleComposicion";
//#endregion

const MySwal = withReactContent(Swal);

export default function TabComposicion({
  handlInputChange = () => {},
  Values = {},
  Errores = {},
  productosCarrito = [],
  setProductosCarrito = () => {},
  IsLoading,
  openModal = () => {},
}) {
  return (
    <>
      {Values.isMateriaPrima ? (
        <>
          <Typography className="text-danger text-center" fontSize={30}>
            La materia prima no puede tener composición
          </Typography>
        </>
      ) : (
        <>
          <div>
            <Button
              disabled={false}
              style={{ float: "right" }}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4"
              onClick={() => openModal()}
            >
              Agregar productos
            </Button>
          </div>

          <TablaDetalleComposicion
            IsLoading={IsLoading}
            productosCarrito={productosCarrito}
            setProductosCarrito={setProductosCarrito}
          />
        </>
      )}
    </>
  );
}
