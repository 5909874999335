import React, { useEffect } from "react";
import Error from "../assets/error.png";
const ErrorAccess = () => {
  useEffect(() => {
    document.title = `404`;
  }, []);
  return (
    <div className="w-100 d-flex justify-content-center mt-5 pt-5">
      <div>
        <div>
          <center>
            <img
              src={Error}
              className="img-fluid mt-5"
              style={{ maxWidth: "100px" }}
            />
          </center>
        </div>
        <h4 className="font-AvenirMedium mt-5 pt-5">
          Lo sentimos, no cuentas con acceso a este modulo
        </h4>
      </div>
    </div>
  );
};

export default ErrorAccess;
