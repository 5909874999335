import { Badge, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";

import Modal from "../../../../../Includes/Modal";
import Mapa from "../Funciones/Mapa";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  LoadScript,
} from "@react-google-maps/api";
import { useEffect } from "react";
import { getPrecioZona } from "../Funciones/Zonas";

const containerStyle = {
  width: "100%",
  height: "300px",
};

const ModalZonasEnvio = ({ titulo, fnCerrar, PrecioEnvio, setPrecioEnvio }) => {
  const centerMap = { lat: 20.661724, lng: -103.349921 };

  const [ListaPuntos, setListaPuntos] = useState([centerMap]);
  const [Values, setValues] = useState({
    direccion: "",
  });
  const [Errores, setErrores] = useState({
    direccion: false,
  });

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });
    setErrores({
      ...Errores,
      [target.name]: false,
    });
  };

  const zoomMap = 13;
  const clickMapa = (lat, lng) => {
    actualizarMarker(0, lat, lng);
  };
  const moverMarker = (marker, index) => {
    const { lat, lng } = marker.latLng;
    actualizarMarker(index, lat(), lng());
  };
  const obtenerPointByAddress = (lat, lng) => {
    if (lat != 0 && lng != 0) {
      actualizarMarker(0, lat, lng);
    }
  };

  const {
    onLoad,
    onUnmount,
    onMapClick,
    actualizarMarker,
    eliminarMarker,
    buscarAddress,
    isLoaded,
    Zoom,
    ZoomInit,
    Map,
  } = Mapa({
    clickMapa,
    obtenerPointByAddress,
    centerMap,
    zoomMap,
    ListaPuntos,
    setListaPuntos,
  });

  useEffect(() => {
    const { lat, lng } = ListaPuntos[0];
    if (lat != centerMap.lat && lng != centerMap.lng) {
      getPrecioZona(lat, lng)
        .then((precio) => {
          //console.log(precio);
          // if (precio.length > 0) {
          //   if (precio[0].PRECIO_DE_ENVIO == 0) {
          //     setPrecioEnvio("Servicio no disponible");
          //   } else {
          //     setPrecioEnvio(precio[0].PRECIO_DE_ENVIO);
          //   }
          // } else {
          //   setPrecioEnvio("Servicio no disponible");
          // }
          setPrecioEnvio(precio[0].PRECIO_DE_ENVIO);
          /*if (precio == 0) {
            setPrecioEnvio("Servicio no disponible");
          } else {
            setPrecioEnvio(precio);
          }*/
        })
        .catch((resp) => {
          setPrecioEnvio("Servicio no disponible");
        });
    }
  }, [ListaPuntos]);

  useEffect(() => {
    setPrecioEnvio(0);
  }, []);

  return (
    <Modal
      Titulo={titulo}
      mostrar={true}
      fnCerrar={fnCerrar}
      maxWidth="md"
      textoGuardar=""
      textoCancelar=""
    >
      <Row className="mx-0 p-4 px-3 w-100 h-100">
        <Row className="mx-0 d-flex justify-content-between">
          <Row style={{ width: "calc(100% - 100px)" }}>
            <TextField
              fullWidth
              name="direccion"
              label="Buscar dirección de envío"
              variant="outlined"
              value={Values.direccion}
              error={Errores.direccion}
              helperText={Errores.direccion ? "Dirección no valida" : ""}
              onChange={handlInputChange}
            />
          </Row>
          <Button
            onClick={() => buscarAddress(Values.direccion)}
            className="btn btn-Crexendo px-4 "
            variant="contained"
            style={{ width: "115px", fontSize: "16px" }}
          >
            <SearchIcon style={{ color: "#FFF", marginRight: "5px" }} /> Buscar
          </Button>
        </Row>

        <Row className="mx-0 d-flex align-align-items-center mt-5">
          <strong style={{ width: "150px" }} className="my-auto">
            {" "}
            Precio de envio:{" "}
          </strong>
          <div
            style={{ width: "150px" }}
            className={
              typeof PrecioEnvio == "number" ? "text-success" : "text-danger"
            }
          >
            <strong>
              {typeof PrecioEnvio == "number"
                ? PrecioEnvio.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  }) + " MXN"
                : PrecioEnvio}
            </strong>
          </div>
        </Row>

        <Row className="mx-0 d-flex justify-content-between mt-4">
          {isLoaded && (
            <GoogleMap
              center={ListaPuntos[0]}
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={onMapClick}
              zoom={Zoom}
            >
              {ZoomInit &&
                Map &&
                ListaPuntos.map((punto, index) => (
                  <Marker
                    onDragEnd={(marker) => moverMarker(marker, index)}
                    onDblClick={() => eliminarMarker(index)}
                    draggable={true}
                    position={{
                      lat: punto.lat,
                      lng: punto.lng,
                    }}
                  />
                ))}
            </GoogleMap>
          )}
        </Row>
      </Row>
    </Modal>
  );
};

export default ModalZonasEnvio;
