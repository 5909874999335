
const HelperError = ({ Error = false, Mensaje = "", className="" }) => {
    if (!Error) {
        return
    }
    return (
        <p className={"text-danger my-1 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root" + " " + className}>
            {Mensaje}
        </p>
    )
}

export default HelperError