import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../../../Context/backend";
import RFCValidator from "../../../../../Lib/Funciones Generales/ValidarRFC";

export const getLista = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    cat: 0,
  };
  const url = `productosDescuento/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const guardar = async (
  Values = {},
  Errores = {},
  setErrores = () => {}
) => {
  let error = false;

  if (
    !Values.id_descuento ||
    Values.id_descuento == 0 ||
    Values.id_descuento == "" ||
    Values.id_descuento == "0"
  ) {
    error = true;
    Errores.id_descuento = true;
    setErrores(Errores);
  }

  if (!Values.observaciones || Values.observaciones == "") {
    error = true;

    Errores.observaciones = true;
    setErrores(Errores);
  }

  if (error === false && Values.productos.length <= 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Ingresa al menos un producto" });
    });
  }

  if (!error) {
    let data = [];
    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "productosDescuento/Insertar"
        : "productosDescuento/Editar";
    const body = Values;
    //console.log(body);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `productosDescuento/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `productosDescuento/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const getInfo = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `productosDescuento/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const gen = JSON.parse(res.data.respuesta.data);
      const rel = JSON.parse(res.data.respuesta.rel);
      if (gen.length == 0 || rel.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información",
        });
      } else {
        //console.log(res.data.respuesta);
        //data = JSON.parse(JSON.stringify(res.data.respuesta));
        let VALUES = {
          general: {
            id: JSON.parse(res.data.respuesta.data)[0].ID,
            id_descuento: JSON.parse(res.data.respuesta.data)[0].ID_DESCUENTO,
            observaciones: JSON.parse(res.data.respuesta.data)[0].OBSERVACIONES,
            descuento: JSON.parse(res.data.respuesta.data)[0].CANTIDAD,
            tipo: JSON.parse(res.data.respuesta.data)[0].TIPO_DE_DESCUENTO,
          },
          relacion: JSON.parse(res.data.respuesta.rel),
        };
        //console.log(data);

        resolve(VALUES);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
