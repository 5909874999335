import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getMunicipios } from "../Funciones/Direcciones";

const Municipio = ({
  Value,
  Error,
  Estado,
  handlInputChange,
  Name = "id_municipio",
}) => {
  const [ValorFiltrado, setFiltro] = useState();
  const [Municipio, setMunicipio] = useState({ cargado: false, lista: [] });

  useEffect(() => {
    if (Estado != 0) {
      setMunicipio({ ...Municipio, cargado: false });
      getMunicipios(Estado)
        .then((resp) => {
          let list = JSON.parse(resp.data);
          if (list.length != 0) {
            setMunicipio({ cargado: true, lista: list });
          } else {
            setMunicipio({
              cargado: true,
              lista: [{ ID: 0, DESCRIPCION: "Sin municipios disponibles" }],
            });
          }
        })
        .catch((resp) => {
          setMunicipio({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Sin municipios disponibles" }],
          });
        });
    } else {
      setMunicipio({ cargado: true, lista: [] });
    }
  }, [Estado]);

  const getOpcion = () => {
    const opcion = Municipio.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Municipio.cargado && Municipio.lista.length != 0 ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        window.MUNICIPIO = option.DESCRIPCION;
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          //console.log(value);
          handlInputChange({
            target: { name: Name, value: value.ID, desc: value.DESCRIPCION },
          });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Municipio.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un municipio" : ""}
            label="Municipio"
          />
        );
      }}
    />
  ) : (
    <TextField
      fullWidth
      disabled={Error ? false : true}
      error={Error}
      helperText={Error ? "Selecciona un municipio" : ""}
      value={"Municipio"}
    />
  );
};

export default Municipio;
