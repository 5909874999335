import { Button, TextField } from "@mui/material"
import { Col, Row } from "react-bootstrap"
import { MuiColorInput } from 'mui-color-input'
import TablaColores from '../TablaColores'
import TablaTamanos from '../TablaTamanos'
import { PRODUCTO_ESTILO } from "../../../../../../Lib/Funciones Generales/Constantes"
// import { rgbToHex } from "@material-ui/core"




const CrearProductoEstilo = (TIPO, NOMBRE = "", CODIGO = "") => {
    return { ID: 0, CODIGO, NOMBRE, TIPO }
}


const TabEstilos = ({ id_producto, Values, SetValues, IsLoading }) => {

    const handleChangeColor = (index, name, value) => {
        Values.colores[index] = {
            ...Values.colores[index],
            [name]: value
        }
        SetValues({ ...Values })
    }


    const handleChangeTamano = (index, name, value) => {
        Values.tamanos[index] = {
            ...Values.tamanos[index],
            [name]: value
        }
        SetValues({ ...Values })
    }

    const handleAddColor = () => {
        Values.colores = [...Values.colores, CrearProductoEstilo(PRODUCTO_ESTILO.COLOR, "", "#000000")]
        SetValues({ ...Values })
    }

    const handleAddTamano = () => {
        Values.tamanos = [...Values.tamanos, CrearProductoEstilo(PRODUCTO_ESTILO.TAMANO)]
        SetValues({ ...Values })
    }


    const setListaColores = (lista) => {
        SetValues({ ...Values, colores: lista })
    }

    const setListaTamanos = (lista) => {
        SetValues({ ...Values, tamanos: lista })
    }


    return (
        <>
            <Col xs={12}>
                <Row className="p-3">
                    <div className="w-100 d-flex justify-content-between ">
                        <div>
                            <h6 className="font-AvenirMedium m-0 p-0">
                                Lista de colores
                            </h6>
                            <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
                                Ingresa la variedad de colores del producto
                            </h6>
                        </div>
                    </div>
                    <Col xs={12}>
                        <TablaColores
                            IsLoading={IsLoading}
                            Lista={Values.colores}
                            setLista={setListaColores}
                            handleChange={handleChangeColor}
                            id_producto={id_producto}
                        />
                    </Col>
                    <div className="w-100 d-flex justify-content-end mt-2">
                        <Button
                            onClick={handleAddColor}
                            className="btn btn-cancelar font-AvenirMedium px-4 "
                            variant="outlined"
                        >
                            Agregar nuevo
                        </Button>
                    </div>

                    <div className="w-100 d-flex justify-content-between mt-5">
                        <div>
                            <h6 className="font-AvenirMedium m-0 p-0">
                                Lista de tamaños
                            </h6>
                            <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
                                Ingresa la variedad de tamaños del producto
                            </h6>
                        </div>
                    </div>
                    <Col xs={12}>
                        <TablaTamanos
                            IsLoading={IsLoading}
                            Lista={Values.tamanos}
                            setLista={setListaTamanos}
                            handleChange={handleChangeTamano}
                            id_producto={id_producto}
                        />
                    </Col>
                    <div className="w-100 d-flex justify-content-end mt-2">
                        <Button
                            onClick={handleAddTamano}
                            className="btn btn-cancelar font-AvenirMedium px-4 "
                            variant="outlined"
                        >
                            Agregar nuevo
                        </Button>
                    </div>
                </Row>
            </Col>

        </>
    )
}

export default TabEstilos