import {
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { formatMoneda } from "../../../../../Lib/Funciones Generales/Generales";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import EliminarRojo from "../../../../assets/EliminarRojo.svg";
import Switch from "../../../../includes/Switch";

const Conceptos = ({
  productosCarrito = [],
  setProductosCarrito,
  pagado,
  setPagado,
}) => {
  let subTotal = 0;
  let ivaTotal = 0;
  let totalProds = 0;

  productosCarrito.map((row) => {
    ivaTotal += parseFloat(row.CANTIDAD) * parseFloat(row.TOTAL_IVA);
    totalProds +=
      parseFloat(row.CANTIDAD) *
      (parseFloat(row.PRECIO_SIN_IVA) + parseFloat(row.TOTAL_IVA));
  });
  console.log(productosCarrito);
  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD = Number(items[itemIndex].CANTIDAD) + 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD = (
        Number(items[itemIndex].CANTIDAD) - 1
      ).toFixed(2);
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
      // items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setProductosCarrito(items);
  };

  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value =
      target.value == "" ||
      target.value == undefined ||
      target.value == null ||
      target.value == null
        ? ""
        : target.value;
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == ID)
      : -1;
    if (itemIndex !== -1) {
      //let cantidad = parseFloat(Value);
      //items[itemIndex].CANTIDAD = cantidad.toString();
      let cantidad = Value.toDecimal();
      items[itemIndex].CANTIDAD = !/^\d*\.?\d{0,2}$/.test(cantidad)
        ? parseFloat(cantidad).toFixed(2)
        : cantidad;
    } else {
      alert("producto no encontrado");
    }
    setProductosCarrito(items);
  };

  return (
    <>
      <div className="p-3">
        <Table sx={{ width: "100%" }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontSize: "10px" }}
                className=" fw-bold font-Avenir font-AvenirBold"
              >
                CODIGO
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                DESCRIPCIÓN
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                CLAVES
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                PRECIO UNITARIO
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="left"
              >
                IVA
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="center"
              >
                CANTIDAD
              </TableCell>
              <TableCell
                style={{ fontSize: "10px" }}
                className="fw-bold font-Avenir font-AvenirBold"
                align="right"
              >
                IMPORTE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productosCarrito.map((row) => {
              return (
                <TableRow key={row.ID}>
                  <TableCell>{row.CODIGO}</TableCell>
                  <TableCell>{row.DESC_CORTA}</TableCell>
                  <TableCell align="left">
                    {row.CLAVE_CODIGO_PROD_SAT} <small>(Cód. SAT)</small>
                    <br />
                    {row.CLAVE_UNIDAD_MEDIDA_SAT} <small>(Cód. Unidad)</small>
                    <br />
                    {row.UNIDAD_MEDIDA} <small>(Unidad)</small>
                  </TableCell>
                  <TableCell align="left">
                    {formatMoneda(parseFloat(row.PRECIO_SIN_IVA.toFixed(2)))}
                  </TableCell>
                  <TableCell align="left">
                    {formatMoneda(
                      (
                        parseFloat(row.CANTIDAD) * parseFloat(row.TOTAL_IVA)
                      ).toFixed(2)
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <IconButton className="mx-2" onClick={() => addItem(row)}>
                        <img src={Mas} style={{ width: "15px" }} />
                      </IconButton>
                      <div
                        className="d-flex justify-content-center flex-column"
                        style={{ maxWidth: "150px" }}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          id={row.ID}
                          label="Cantidad"
                          variant="outlined"
                          name={row.ID}
                          value={isNaN(row.CANTIDAD) ? "" : row.CANTIDAD}
                          //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                          onChange={handlInputChange}
                        />
                      </div>
                      <IconButton
                        aria-label="delete"
                        className="mx-2"
                        onClick={() => disminuirItem(row)}
                      >
                        <img src={Menos} style={{ width: "15px" }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        className="   mx-1"
                        onClick={() => deleteItem(row)}
                      >
                        <img src={EliminarRojo} style={{ width: "15px" }} />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell
                    align="right"
                    className="fw-bold font-Avenir font-AvenirBold"
                  >
                    {formatMoneda(
                      parseFloat(row.CANTIDAD) *
                        (parseFloat(row.PRECIO_SIN_IVA) +
                          parseFloat(row.TOTAL_IVA))
                    )}
                  </TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell rowSpan={4} />
              <TableCell rowSpan={4} />
              <TableCell rowSpan={4} />
              <TableCell rowSpan={4} />
              <TableCell rowSpan={4} />
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: "16px" }} colSpan={1}>
                SUBTOTAL
              </TableCell>
              <TableCell style={{ fontSize: "16px" }} align="right">
                {formatMoneda(parseFloat(totalProds - ivaTotal))}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontSize: "16px" }} colSpan={1}>
                IVA
              </TableCell>
              <TableCell style={{ fontSize: "16px" }} align="right">
                {formatMoneda(parseFloat(ivaTotal.toFixed(2)))}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                className="fw-bold"
                style={{ fontSize: "18px" }}
                colSpan={1}
              >
                TOTAL
              </TableCell>
              <TableCell
                className="fw-bold"
                style={{ fontSize: "18px" }}
                align="right"
              >
                {formatMoneda(parseFloat(totalProds))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="w-100 text-end">
          <FormGroup>
            <div className=" w-100 d-flex justify-content-end">
              <p
                className="my-0 algin-self-center font-Avenir mt-2 text-center"
                style={{ fontSize: "13px" }}
              >
                {pagado ? "Factura pagada" : "Factura NO pagada"}
              </p>

              <Switch
                className="algin-self-center"
                checked={pagado}
                name="pagado"
                onChange={(checked) => {
                  setPagado(checked);
                }}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default Conceptos;
