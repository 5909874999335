import {IconButton, Tooltip } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
const AsignarRepartidor = ({
  setShowDialogAsignarRepartidor,
  setPedidoSeleccionado,
  id,
}) => {
  return (
    <>
      <Tooltip title="Asignar repartidor" placement="top" className="me-2">
        <IconButton
          onClick={(event) => {
            setPedidoSeleccionado(id);
            setShowDialogAsignarRepartidor(true);
          }}
        >
          <LocalShippingIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AsignarRepartidor;
