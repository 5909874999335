import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getDescuentosVigentes } from "../Funciones/Descuentos";

const SelectVigentes = ({
  Value,
  Error,
  handlInputChange = () => {},
  Name = "id_descuento",
  Opciones = [],
}) => {
  const opcionInicial = { ID: 0, DESCRIPCCION: "Categoría" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Categorias, setCategorias] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  useEffect(() => {
    getDescuentosVigentes()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        if (list.length != 0) {
          setCategorias({ cargado: true, lista: [...Opciones, ...list] });
        } else {
          setCategorias({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCCION: "Sin descuentos disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setCategorias({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCCION: "Sin descuentos disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = Categorias.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Categorias.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({
            target: {
              name: Name,
              value: value.ID,
              tipo: value.TIPO_DE_DESCUENTO,
              cantidad: value.CANTIDAD,
            },
          });
        } else {
          handlInputChange({
            target: { name: Name, value: "", tipo: "", cantidad: "" },
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Categorias.lista}
      //tipo 1: porcentaje
      //tipo 2: cantidad neta
      getOptionLabel={(option) => {
        return `${option.DESCRIPCION} (${
          option.TIPO_DE_DESCUENTO == 1 ? `%` : `$`
        }${option.CANTIDAD})`;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un descuento" : ""}
            label="Descuentos"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Descuentos"} />
  );
};

export default SelectVigentes;
