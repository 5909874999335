import React from "react";
import { Button } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import BackTab from "./BackTab";
import NetxTab from "./NextTab";

export default function ButtonTabs({
  tab = 0,
  setTab = () => {},
  tabsTotales = 2,
  btnClass = "btn btn-exp-Excel",
  style = {},
}) {
  return (
    <>
      {tab === 0 && tabsTotales > 1 && (
        <div className="d-flex justify-content-end w-100">
          <NetxTab
            className="ms-auto"
            tab={tab}
            setTab={setTab}
            btnClass={btnClass}
            style={style}
          />
        </div>
      )}

      {tab > 0 && tab < tabsTotales - 1 && (
        <div className="d-flex justify-content-between w-100">
          <>
            <BackTab
              tab={tab}
              setTab={setTab}
              btnClass={btnClass}
              style={style}
            />
            <NetxTab
              tab={tab}
              setTab={setTab}
              btnClass={btnClass}
              style={style}
            />
          </>
        </div>
      )}

      {tab === tabsTotales - 1 && tabsTotales > 1 && (
        <div className="d-flex justify-content-start w-100">
          <BackTab
            tab={tab}
            setTab={setTab}
            btnClass={btnClass}
            style={style}
          />
        </div>
      )}
    </>
  );
}
