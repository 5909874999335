import React from "react";
import SelectAlmacenes from "../../Almacenes/Componentes/SelectAlmacenes";
import { TextField, FormControl, MenuItem } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Monedas } from "../../../../../Lib/Funciones Generales/Constantes";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import FormasDePago from "../../../../../Lib/SAT/Componentes/FormasDePago";
import { caracteresRestantes } from "../../../../../Lib/Funciones Generales/Generales";

export default function TabDetalles({
  Values = {},
  Errores = {},
  handlInputChange = () => {},
  Fecha = {},
  setFecha = () => {},
  idAlmacenPrincipal,
  setIdAlmacenPrinpal = () => {},
}) {
  return (
    <>
      <Col sm={12} md={6} className="p-3">
        <FormControl fullWidth>
          <SelectSucursales
            Name="id_sucursal"
            Value={Values.id_sucursal}
            Error={Errores.id_sucursal}
            handlInputChange={handlInputChange}
          />
        </FormControl>
      </Col>

      <Col sm={12} md={6} className="p-3">
        <FormControl fullWidth>
          <SelectAlmacenes
            name="id_almacen"
            Value={Values.id_almacen}
            Error={Errores.id_almacen}
            handlInputChange={handlInputChange}
            Sucursal={Values.id_sucursal}
            handleAlmacenPrincipal={(e) => {
              setIdAlmacenPrinpal(e);
              //console.log(e);
            }}
            principal={idAlmacenPrincipal}
          />
        </FormControl>
      </Col>

      <Col sm={12} md={6} className="p-3">
        <BasicDatePicker
          size="medium"
          label={"Fecha de compra"}
          fecha={Fecha}
          setFecha={setFecha}
        />
      </Col>

      <Col sm={12} md={6} className="p-3">
        <TextField
          label="Serie"
          name="serie"
          fullWidth
          value={Values.serie}
          error={Errores.serie}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={6} className="p-3">
        <TextField
          fullWidth
          label="Folio de factura"
          name="folio"
          value={Values.folio}
          error={Errores.folio}
          onChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={6} className="p-3">
        <FormasDePago
          showCodigos
          Value={Values.forma_pago}
          Error={Errores.forma_pago}
          handleInputChange={handlInputChange}
        />
      </Col>
      <Col sm={12} md={12} className="p-3">
        <TextField
          fullWidth
          multiline
          rows={5}
          value={Values.observaciones}
          label={`Observaciones ${caracteresRestantes(
            Values.observaciones?.length,
            500
          )}`}
          inputProps={{ maxLength: 500 }}
          name="observaciones"
          onChange={handlInputChange}
        />
      </Col>
    </>
  );
}
