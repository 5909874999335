import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Estado from "../../../../../Lib/Direcciones/Componentes/Estado";
import Municipio from "../../../../../Lib/Direcciones/Componentes/Municipio";
import TablaProds from "./TablaProds";
import Switch from "../../../../includes/Switch";
import ModalSelectProd from "../../Productos/Componentes/Dialogos/ModalSelectProd";
import { getDescuentosVigentes } from "../../Descuentos/Funciones/Descuentos";
import SelectVigentes from "../../Descuentos/Componentes/SelectVigentes";
import { getInfo, guardar } from "../Funciones/ProductosDescuentos";

const MySwal = withReactContent(Swal);

export default function ProductosDescuento() {
  //#region Variables de estado
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModalProds, setOpenModalProds] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);
  const [descuentos, setDescuentos] = useState();

  const [Values, setValues] = useState({
    id: id ?? "0",
    id_descuento: 0,
    observaciones: "",
    productos: [],
    descuento: 0,
    tipo: 0,
  });
  const [Errores, setErrores] = useState({
    id_descuento: false,
    observaciones: false,
    productos: false,
  });

  //#endregion

  //#region Handles

  const handleInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;

    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "id_descuento") {
      NewValue.tipo = target.tipo;
      NewValue.descuento = target.cantidad;
      //console.log(target);
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModalProds(false);
  };

  const handleProductoSeleccionado = (producto) => {
    //console.log("desde handle", producto);
    const items = [...Lista];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      //productoExistente.CANTIDAD += 1;
    } else {
      producto = {
        ID: parseInt(producto.ID),
        PRODUCTO: producto.DESC_CORTA,
        PRECIO: parseFloat(producto.PRECIO),
        PRECIO_PUBLICO: parseFloat(producto.PRECIO_PUBLICO),
      };
      items.push(producto);
    }
    setLista(items);
    //console.log(Lista);
  };

  //#endregion

  //#region Funciones

  const GuardarProductosConDescuento = () => {
    //console.log(Values);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        //console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        //console.log(data);
        if (data.data.codigo == "902") {
          const arr = JSON.parse(data.data.respuesta);
          //console.log(arr);
          const newArr = Lista.filter((item) => {
            const found = arr.find((element) => element == item.ID);
            if (found != undefined) {
              console.log(found);
              item.REPETIDO = true;
            }

            return item;
          });
          setLista(newArr);
        }
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    //console.log("renderizando...");
    if (id != undefined) {
      getInfo(id)
        .then((data) => {
          //console.log(data);
          if (data?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            //setValues(resp);
            //console.log(data);
            setValues(data.general);
            setLista(data.relacion);
            //setDescuentos(data);
            setIsLoading(false);
          }
        })
        .catch((data) => {
          console.log(data);
          setIsLoading(false);
        });
      /*getDescuentosVigentes()
        .then((resp) => {
          let list = JSON.parse(resp.data);
          //console.log(list);
          if (list.length != 0) {
            setDescuentos(list);
          } else {
            setDescuentos(list);
          }
          setIsLoading(false);
        })
        .catch((resp) => {
          setDescuentos([]);
          setIsLoading(false);
        });*/
    } else {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    setValues({ ...Values, productos: Lista });
  }, [Lista]);
  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      {openModalProds ? (
        <ModalSelectProd
          handleProductoSeleccionado={handleProductoSeleccionado}
          proveedorId={0}
          onClose={handleCloseModal}
          isProductosDescuento
        />
      ) : (
        <></>
      )}
      <Button
        onClick={() => navigate(-1)}
        className="btn font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Productos con descuento
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id
                ? "Editar productos con descuento"
                : "Nuevos productos con descuento"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Col className="p-3">
                <Row>
                  <Col sm={12} md={6} className="p-3">
                    <SelectVigentes
                      handlInputChange={handleInputChange}
                      Value={Values.id_descuento}
                      Error={Errores.id_descuento}
                      Name="id_descuento"
                    />
                  </Col>
                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      multiline
                      label="Observaciones"
                      value={Values.observaciones}
                      name="observaciones"
                      error={Errores.observaciones}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <div>
                    Total: {Lista.length}
                    <Button
                      disabled={false}
                      style={{ float: "right" }}
                      className="btn btn-Crexendo font-AvenirMedium  py-2 px-4"
                      onClick={() => {
                        setOpenModalProds(true);
                      }}
                    >
                      Agregar productos
                    </Button>
                  </div>
                  <Col xs={12} className="p-3">
                    {Lista.length == 0 ? (
                      <></>
                    ) : (
                      <TablaProds
                        Lista={Lista}
                        setLista={setLista}
                        descuento={Values.descuento}
                        tipo={Values.tipo}
                      />
                    )}
                  </Col>
                  {/*<code>
                    <pre>{JSON.stringify(descuentos, null, 4)}</pre>
                    </code>*/}
                </Row>
              </Col>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarProductosConDescuento()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
