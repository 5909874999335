import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";

//Peticiones POST
export const Guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.descripcion || Values.descripcion == "") {
    let Errores2 = Errores;
    Errores2.descripcion = true;
    setErrores(Errores2);
    error = true;
  }

  Values.id_sucursal = String(Values.id_sucursal);

  if (!error) {
    const body = {
      id: Values.id,
      id_sucursal: Values.id_sucursal,
      nombre: Values.nombre,
      descripcion: Values.descripcion,
      is_principal: Values.is_principal,
      is_ecommerce: Values.is_ecommerce,
    };
    //console.log(body);
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "almacenes/Insertar"
        : "almacenes/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `almacenes/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `almacenes/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const CambiarAlmacen = async (ID_ALMACEN, ID_SUCURSAL) => {
  if (ID_ALMACEN != undefined && ID_SUCURSAL != undefined) {
    let data = [];
    let queryParamsObj = {
      ID_SUCURSAL: ID_SUCURSAL,
      ID_ALMACEN: ID_ALMACEN,
    };
    const url = `almacenes/CambiarPrincipal?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

//Peticiones GET
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `almacenes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesAlmacenes = async (
  ID_SUCURSAL,
  obtenerTodos = true
) => {
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: ID_SUCURSAL,
    OBTENER_TODOS: obtenerTodos,
  };
  const url = `almacenes/getOpciones?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoAlmacen = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `almacenes/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta almacen",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          fecha: String(data.FECHA),
          id_sucursal: String(data.ID_SUCURSAL),
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCION),
          is_principal: Boolean(data.IS_PRINCIPAL),
          is_ecommerce: Boolean(data.IS_ECOMMERCE),
          activo: Boolean(data.ACTIVO),
        };
        resolve(Values);
        //console.log(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
