import React from "react";
import { useEffect, useState } from "react"; 
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; 
import Tabla from "./Tabla"; 
import SelectOrdenamientoProductos from "../../../../includes/SelectOrdenamientoProductos";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import { getLista } from "../Funciones/TraspasarMercancia";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";

 
export default function RepTraspasarMercacia() {
  const [ValueSelect, setValueSelect] = useState("123");
  // const [ValueSelect2, setValueSelect2] = useState("0");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [ValueFechaInicio, setValueFechaInicio] = useState(GetDate(-7));
  const [ValueFechaFin, setValueFechaFin] = useState(GetDate());

  const fechaCompletaInicio = new Date(ValueFechaInicio);
  const offsetMinutosInicio = fechaCompletaInicio.getTimezoneOffset();
  fechaCompletaInicio.setMinutes(
    fechaCompletaInicio.getMinutes() + offsetMinutosInicio
  );
  const diaInicio = fechaCompletaInicio.getDate();
  const mesInicio = fechaCompletaInicio.getMonth() + 1;
  const anioInicio = fechaCompletaInicio.getFullYear();
  const fechaInicio = `${mesInicio < 10 ? "0" : ""}${mesInicio}/${
    diaInicio < 10 ? "0" : ""
  }${diaInicio}/${anioInicio}`;
  //console.log(fechaInicio);

  const fechaCompletaFin = new Date(ValueFechaFin);
  const offsetMinutosFin = fechaCompletaFin.getTimezoneOffset();
  fechaCompletaFin.setMinutes(fechaCompletaFin.getMinutes() + offsetMinutosFin);
  const diaFin = fechaCompletaFin.getDate();
  const mesFin = fechaCompletaFin.getMonth() + 1;
  const anioFin = fechaCompletaFin.getFullYear();
  const fechaFin = `${mesFin < 10 ? "0" : ""}${mesFin}/${
    diaFin < 10 ? "0" : ""
  }${diaFin}/${anioFin}`;

  useEffect(() => {
    getListaITraspaso();
  }, [ValueBusqueda, ValueSelect]);

  const getListaITraspaso = () => {
    setIsLoading(true);
    //console.log(0, 0, ValueSelect, ValueBusqueda, fechaInicio, fechaFin);
    getLista(0, 0, ValueSelect, ValueBusqueda, fechaInicio, fechaFin)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log("lista de base de datos: ", list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Traspasar Mercancia`;
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Traspasar Mercancia</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}> 
            <Link to="add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
              >
                Crear nueva
              </Button>
            </Link>
          </Stack>
        </div>
      </div>
      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12}>
            <div class="d-flex flex-row align-items-center mb-3 flex-wrap">
              <div class="p-2" style={{ width: "200px" }}>
                <BasicDatePicker
                  label={"Inicio"}
                  fecha={ValueFechaInicio}
                  setFecha={setValueFechaInicio}
                  format="DD/MM/YYYY"
                  size="small"
                />
              </div>
              <div class="p-2" style={{ width: "200px" }}>
                <BasicDatePicker
                  label={"Fin"}
                  fecha={ValueFechaFin}
                  setFecha={setValueFechaFin}
                  format="DD/MM/YYYY"
                  size="small"
                />
              </div>
              <div class="p-2" style={{ width: "200px" }}>
                <SelectOrdenamientoProductos
                  ValueSelect={ValueSelect}
                  setValueSelect={setValueSelect}
                  size="small"
                />
              </div>
              <div class="p-2">
                <Button
                  className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
                  variant="contained"
                  onClick={getListaITraspaso}
                >
                  Filtrar
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tabla
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              updateTable={getListaITraspaso}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
