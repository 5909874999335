import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpcionesAlmacenes } from "../Funciones/Almacenes";

const SelectAlmacenes = ({
  Value,
  Error,
  handlInputChange,
  name = "id_almacen",
  Sucursal,
  Label = "Almacen",
  className = "",
  size = "medium",
  handleAlmacenPrincipal = () => {},
  principal = 0,
}) => {
  const opcionInicial = { ID: 0, DESCRIPCION: "Almacen" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Almacen, setAlmacen] = useState({
    cargado: false,
    lista: [opcionInicial],
  });
  const [findPrincipal, setFidPrincipal] = useState(
    principal == 0 ? false : true
  );

  useEffect(() => {
    setAlmacen({ ...Almacen, cargado: false });
    getOpcionesAlmacenes(Sucursal)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        // console.log(list);
        if (list.length != 0) {
          setAlmacen({ cargado: true, lista: list });
        } else {
          setAlmacen({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Selecciona una almacén" }],
          });
        }
      })
      .catch((resp) => {
        setAlmacen({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCION: "Sin almacenes disponibles" }],
        });
      });
  }, [Sucursal]);

  const getOpcion = () => {
    const opcion = Almacen.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Almacen.cargado ? (
    <Autocomplete
      size={size}
      className={className}
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: name, value: String(value.ID) } });
          if (value.IS_PRINCIPAL) {
            setFidPrincipal(true);
            handleAlmacenPrincipal(value.ID);
          } else {
            setFidPrincipal(false);
            handleAlmacenPrincipal(0);
          }
        } else {
          handlInputChange({ target: { name: name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Almacen.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION; // + "|" + option.IS_PRINCIPAL;
      }}
      renderInput={(params) => {
        ///console.log(params);
        //onst data = params.inputProps.value.split("|");
        //console.log(data);
        //const is_principal = data[1];
        //const val = data[0];
        //params.inputProps.value = val;
        return (
          <TextField
            size={size}
            {...params}
            error={Error}
            helperText={
              Error
                ? "Selecciona un almacen"
                : findPrincipal
                ? "Almacen principal"
                : ""
            }
            label={Label}
            //value={val}
          />
        );
      }}
    />
  ) : (
    <TextField
      size={size}
      fullWidth
      disabled={true}
      value={"Almacen"}
      className={className}
    />
  );
};

export default SelectAlmacenes;
