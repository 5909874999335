import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Producto from "../Producto";
import "../../../../../../Lib/Funciones Generales/Prototipos";

let i = 0;
const ModalCrearProductos = ({
  onClose = () => {},
  titulo = "Seleccionar productos",
  productosDisponibles,
  setProductosDisponibles,
  getListaProductos,
}) => {
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true}>
        <IconButton
          aria-label="Close"
          className="m-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
          </DialogTitle>
        }
        <DialogContent style={{ minHeight: "70vh" }}>
          <Producto
            Modal={true}
            productosDisponibles={productosDisponibles}
            setProductosDisponibles={setProductosDisponibles}
            getListaProductos={getListaProductos}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalCrearProductos;
