import React from "react";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function NextTab({
  tab = 0,
  setTab = () => {},
  btnClass = "btn btn-exp-Excel",
  style = {},
}) {
  return (
    <>
      <Button
        onClick={() => {
          const nextTab = tab + 1;
          setTab(nextTab);
        }}
        style={style}
        className={`${btnClass} font-AvenirMedium py-2 px-4 ms-n2`}
        variant="text"
      >
        Siguiente
        <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
      </Button>
    </>
  );
}
