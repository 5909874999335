import { stringify } from "query-string";
import validator from "validator";
import AccEditarImagen from "./../../../../assets/EditarImagen.svg";

import { getData, postdData, postUrl } from "../../../../../Context/backend";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getCategorias = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `categorias/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getSucursalesByProd = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_CATEGORIA: ID,
  };
  const url = `categorias/getSucurslesRelacion?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarCategoria = async (
  Values,
  Errores,
  setErrores,
  sucursales
) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  // if (!Values.descripcion || Values.descripcion == "") {
  //   Errores.descripcion = true;
  //   setErrores(Errores);
  //   error = true;
  // }

  const relacion = sucursales
    .filter((objeto) => objeto.ACTIVO === 1)
    .map((objeto) => objeto.ID);

  if (!error) {
    let data = [];
    if (Values.img?.includes("base64")) {
      Values.img = Values.img?.split("base64,")[1];
    } else {
      Values.img = "";
    }
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "categorias/Insertar"
        : "categorias/Editar";

    const body = {
      id: Values.id,
      nombre: Values.nombre,
      descripcion: Values.descripcion,
      img: Values.img,
      is_ecommerce: Values.is_ecommerce,
      ordenamiento: Values.ordenamiento,
      relacion: relacion,
      id_descuento: Values.id_descuento == "" ? 0 : Values.id_descuento,
    };
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarCategoria = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `categorias/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoCategoria = async (id) => {
  //console.log("Entro a la peticion getInfCategoria");
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `categorias/Consultar?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta categoria",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Value = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          descripcion: String(data.DESCRIPCCION),
          ordenamiento: String(data.ORDENAMIENTO),
          is_ecommerce: Boolean(data.IS_ECOMMERCE),
          img: String(data.IMG ? data.IMG : AccEditarImagen),
          id_descuento: String(data.ID_DESCUENTO),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  // console.log("Activar desactivar");
  // console.log(id, checked);
  //return "ok";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `categorias/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "", suc = 0, ecommerce = false) => {
  let data = [];
  let queryParamsObj = {
    query: query,
    IS_ECOMMERCE: ecommerce ? 1 : 0,
    idSucursal: suc,
  };
  const url = `categorias/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
