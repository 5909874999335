import React from "react";
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "../../../includes/Loading";
import { avatarLetters } from "../../../../Lib/Funciones Generales/Generales";
import { Button, Col, Row } from "react-bootstrap";
import { stringify } from "query-string";

import { getListaByCategoria } from "../../Catalogos/Productos/Funciones/Productos";
import { formatItemCarrito } from "../Funciones/PuntoDeVente";
export default function Productos({
  categoria,
  valueBusqueda,
  limpairBusqueda = () => { },
  productosCarrito,
  setProductosCarrito = () => { },
  setCategoria = () => { },
  IVA,
  comisionBancaria,
  sucursalSelector,
  ventaLibre,
  setDescuento,
}) {
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getListaByCategoria(
      categoria,
      valueBusqueda,
      sucursalSelector,
      sucursalSelector == 0 ? 1 : 0
    )
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setProductos(list.map(formatItemCarrito));
        } else {
          setProductos(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setProductos([]);
        setIsLoading(false);
      });
  }, [valueBusqueda]);

  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };
  useEffect(() => {
    console.log({ productos });
  }, [productos])
  const Valor_IVA = 16; // IVA del producto
  const IVA_FRACCION = Valor_IVA / 100 + 1;
  const RestaIVA = 0;
  // const valorCalculado = IVA ? total : RestaIVA.toFixed(2);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col xs={12}>
          <Button
            onClick={() => {
              setCategoria(0);
              limpairBusqueda("");
            }}
            className="btn  font-AvenirBold my-1 text-star ps-0 pe-4  "
            variant="text"
          >
            <ArrowBackIcon className="me-3" />
            Regresar
          </Button>
        </Col>
        <Col xs={12}>
          {productos.length == 0 ? (
            <>
              <h2 className="my-4 font-font-AvenirBold text-center">
                Sin resultados
              </h2>
            </>
          ) : (
            <div className="w-100 d-flex   flex-wrap">
              {productos.map((row, index) => {
                return (
                  <div
                    className=""
                    key={String(index)}
                    onClick={() => {
                      let precio = row.PRECIO;
                      let descontado = 0
                      if (!ventaLibre && !comisionBancaria) {
                        precio = row.PRECIO_ORIGINAL
                        descontado = (row.PRECIO_SIN_DESCUENTO ?? precio) - precio;

                      }
                      if (!ventaLibre && comisionBancaria) {
                        precio = row.PRECIO_PUBLICO
                        descontado = (row.PRECIO_SIN_DESCUENTO_PUBLICO ?? precio) - precio;
                      }
                      if (ventaLibre && comisionBancaria) {
                        precio = row.PRECIO_PUBLICO
                      }
                      if (row.DESCUENTOS_CATEGORIA_PUNTO.TIPO_DESCUENTO != 0)
                        setDescuento({
                          id: 0,
                          descuento: 0,
                          tipo: 0,
                          max: 0,
                          categoria: "",
                        });


                      let item = {
                        ID: row.ID,
                        DESCRIPCCION: row.DESC_CORTA,
                        PRECIO: precio,
                        PRECIO_ORIGINAL: row.PRECIO_ORIGINAL,
                        PRECIO_PUBLICO: row.PRECIO_PUBLICO,
                        DESCUENTOS_CATEGORIA: row.DESCUENTOS_CATEGORIA,
                        DESCUENTOS_CATEGORIA_PUNTO: row.DESCUENTOS_CATEGORIA_PUNTO,
                        PRECIO_SIN_DESCUENTO_PUBLICO: row.PRECIO_SIN_DESCUENTO_PUBLICO,
                        PRECIO_SIN_DESCUENTO: row.PRECIO_SIN_DESCUENTO,
                        CANTIDAD: 1,
                        RENTABLE: row.IS_RENTABLE,
                        HORAS_RENTA: 24,
                        COMPOSICION: [],
                        DESCONTADO: descontado.toFixed(2)
                      }
                      addItem(item);
                      //console.log(row);
                    }}
                  >
                    <div
                      style={{}}
                      className="card-producto card p-3 m-3 shadow-sm d-flex justify-content-center  flex-column "
                    >
                      <center>
                        <Avatar sx={{ bgcolor: "#fd8c27" }}>
                          {avatarLetters(row.DESC_CORTA)}
                        </Avatar>
                      </center>
                      <p className="font-Avenir fw-bold parrafo2lineastruncate text-center my-1">
                        {row.DESC_CORTA}
                      </p>
                      {/* {<p className="font-font-AvenirMedium fw-normal fs-6 text-truncate text-center my-2">
                        ${
                          comisionBancaria ?
                            IVA ? row.PRECIO_SIN_DESCUENTO_PUBLICO : (row.PRECIO_SIN_DESCUENTO_PUBLICO / 1.16).toFixed(2) :
                            IVA ? row.PRECIO_SIN_DESCUENTO : (row.PRECIO_SIN_DESCUENTO / 1.16).toFixed(2)
                        }
                        MXN
                      </p>} */}
                      <p className="font-font-AvenirMedium fw-bold text-truncate text-center my-2">
                        ${
                          comisionBancaria ?
                            IVA ? row.PRECIO_PUBLICO : (row.PRECIO_PUBLICO / 1.16).toFixed(2) :
                            IVA ? row.PRECIO : (row.PRECIO / 1.16).toFixed(2)
                        }
                        MXN
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
