import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Card, Col } from "react-bootstrap";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MopedIcon from "@mui/icons-material/Moped";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { getColoresEstatus } from "../Funciones/Pedidos";
import AsignarFlorista from "./Acciones/AsignarFlorista";
import View from "./Acciones/ViewDetalle";
import CambiarEstatus from "./Acciones/CambiarEstatus";
import Print from "./Acciones/Print";
import AsignarRepartidor from "./Acciones/AsignarRepartidor";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { usePermisos } from "../../../../Context/PermissionsContext";

const CardPedido = ({
  row,
  index,
  setPedidoSeleccionado,
  setShowDialogAsignarFlorista,
  setShowDilogDetalle,
  setShowDiloglEstatutus,
  setShowDiloglImprimir,
  setShowDialogAsignarRepartidor,
  setShowDialogoModalEditar,
}) => {
  const {
    FOLIO,
    FECHA_DE_ENTREGA,
    ENTREGA_INICIO,
    ENTREGA_FIN,
    SUCURSAL,
    PAGADO,
    ID_ESTATUS,
    TIPO_ENVIO,
    ENCARGADO,
    REPARTIDOR,
    ZONA_LARGA,
    CLIENTE, 
  } = row;

  const { colorSstatus, backgrorundStatus, backgrorundStatusStyle } =
    getColoresEstatus(ID_ESTATUS);
  const { userPermissions } = usePermisos();
 
  //console.log("PEDIDO: ", row);
  return (
    <>
      <Col sm={12} md={4} className="mb-4">
        <Card
          onClick={() => {
            //setPedidoSeleccionado(row.id);
            //setShowDilogDetalle(true);
          }}
          className={"px-3 pt-3 shadow h-100 "}
          id="pedidos"
          style={{ cursor: "pointer", borderRadius: "20px" }}
        >
          {/* <Tooltip title="Seleccionar pedido" placement="top" style={{ position: 'absolute' }} className="p-0">
            <IconButton onClick={() => SeleccionaPedido(row.ID)}>
              {
                seleccionado ? (
                  <CheckBoxIcon style={{ fontSize: "2rem" }} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ fontSize: "2rem" }} />
                )
              }
            </IconButton>
          </Tooltip> */}

          <h3 className="text-center mb-0">
            <strong> Pedido: {FOLIO} </strong>
          </h3>
          <center>
            <spam>
              <small>{row.USUARIO}</small>
            </spam>
          </center>

          <p className="d-flex w-100 align-items-center text-center m-0">
            <strong> Cliente:</strong>
            <p className={"text-center m-0 ms-auto text-truncate"}>{CLIENTE}</p>
          </p>

          <p className="d-flex flex-wrap align-items-center text-center m-0">
            <strong> Entrega:</strong>
            <p className="text-center m-0 ms-auto">{FECHA_DE_ENTREGA}</p>
          </p>
          <p className="d-flex flex-wrap align-items-center text-center m-0">
            <strong> hora:</strong>
            <p className="text-center m-0 ms-auto">
              {ENTREGA_INICIO + " - " + ENTREGA_FIN}
            </p>
          </p>

          {ZONA_LARGA != "" && ZONA_LARGA && (
            <p className="d-flex w-100 align-items-center text-center m-0">
              <strong> Zona:</strong>
              <p className={"text-center m-0 ms-auto text-truncate"}>
                {ZONA_LARGA}
              </p>
            </p>
          )}

          {TIPO_ENVIO == 2 ? (
            <p className="d-flex flex-wrap align-items-center text-center m-0">
              <strong> Sucursal: </strong>
              <p className="text-center m-0 ms-auto">{SUCURSAL}</p>
            </p>
          ) : (
            <p className="d-flex   align-items-center text-center m-0">
              <strong> Entrega: </strong>
              <p className="text-center m-0 ms-auto text-truncate">
                A domicilio
              </p>
            </p>
          )}

          <div className="d-flex flex-wrap justify-content-between  w-100 mb-3">
            <p className="d-flex  w-100 align-items-center text-center m-0">
              <strong> Florista:</strong>
              <p
                className={
                  "text-center m-0 ms-auto text-truncate" +
                  (ENCARGADO.trim() == "No asignado" ? " text-danger" : " ")
                }
              >
                {ENCARGADO}
              </p>
            </p>
            {TIPO_ENVIO == 1 && (
              <p className="d-flex w-100 align-items-center text-center m-0">
                <strong> Repartidor:</strong>
                <p
                  className={
                    "text-center m-0 ms-auto text-truncate" +
                    (REPARTIDOR.trim() == "No asignado" ? " text-danger" : " ")
                  }
                >
                  {REPARTIDOR}
                </p>
              </p>
            )}
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <div
              className="d-flex align-items-center justify-content-center w-100 me-2"
              style={{
                backgroundColor: PAGADO == 1 ? "#E0FFD2" : "#FFF1D2",
                height: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "15px",
              }}
            >
              {PAGADO == 1 ? (
                <p
                  style={{ fontSize: "0.5rem" }}
                  className="d-flex align-items-center"
                >
                  <CheckCircleOutlineIcon color="green" className="me-2" />{" "}
                  PAGADO
                </p>
              ) : (
                <p
                  style={{ fontSize: "0.5rem" }}
                  className="d-flex align-items-center"
                >
                  <WarningAmberIcon color="orange" className="me-2" /> NO PAGADO
                </p>
              )}
            </div>

            {
              <div
                className={
                  "d-flex align-items-center justify-content-center w-100 ms-2 " +
                  backgrorundStatus
                }
                style={{
                  ...backgrorundStatusStyle,
                  height: "30px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "15px",
                }}
              >
                {ID_ESTATUS == 1 ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <LocalShippingOutlinedIcon className="me-2" /> RECIBIDO
                  </p>
                ) : ID_ESTATUS == 2 ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <AccessTimeIcon className="me-2" /> EN PROCESO
                  </p>
                ) : ID_ESTATUS == 3 ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> TERMINADO
                  </p>
                ) : ID_ESTATUS == 4 ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> EN RUTA
                  </p>
                ) : ID_ESTATUS == 5 ? (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <CheckCircleOutlineIcon className="me-2" /> ENTREGADO
                  </p>
                ) : (
                  <p
                    style={{ color: colorSstatus, fontSize: "0.5rem" }}
                    className="d-flex align-items-center"
                  >
                    <HighlightOffIcon className="me-2" /> CANCELADO
                  </p>
                )}
              </div>
            }
          </div>

          <div className="d-flex flex-wrap w-100 mt-3 mb-1">
            <div className="d-flex justify-content-between w-100 p-0">
              <View
                setShowDilogDetalle={setShowDilogDetalle}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
              <Print
                setShowDiloglImprimir={setShowDiloglImprimir}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
              <CambiarEstatus
                setShowDiloglEstatutus={setShowDiloglEstatutus}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
              {userPermissions.includes("Editar Pedidos") && (
                <>
                  {ID_ESTATUS == 1 &&
                  <Tooltip
                    title="Editar pedido"
                    placement="top"
                    className="me-2"
                  >
                    <IconButton onClick={()=>{
                        setShowDialogoModalEditar(true);
                        setPedidoSeleccionado(row.ID);}}>
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
}
                </>
              )  }
              {TIPO_ENVIO == 1 && (
                <>
                  <AsignarRepartidor
                    setShowDialogAsignarRepartidor={
                      setShowDialogAsignarRepartidor
                    }
                    setPedidoSeleccionado={setPedidoSeleccionado}
                    id={row.ID}
                  />
                </>
              )}
              <AsignarFlorista
                setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
            </div>
          </div>
        </Card>
      </Col>
    </>
  );
};

export default CardPedido;
