import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import AccEditar from "../../../../assets/AccEditar.svg";
import AccEliminar from "../../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import Loading from "../../../../includes/Loading";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { eliminarCategoria, Desactivar } from "../Funciones/Categorias";
import Switch from "../../../../includes/Switch";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { MoreVert } from "@mui/icons-material";
import { firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
import { avatarLetters } from "../../../../../Lib/Funciones Generales/Generales";

const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function TableProductos({
  Lista,
  IsLoading,
  setLista,
  ValueBusqueda,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [open, setOpen] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  /*Lista = [
    {
      ID: "1",
      FECHA: "12/12/12",
      NOMBRE: "name",
      ACTIVO: 0,
    },
    {
      ID: "2",
      FECHA: "12/12/12",
      NOMBRE: "name",
      ACTIVO: 0,
    },
    {
      ID: "3",
      FECHA: "12/12/12",
      NOMBRE: "name",
      ACTIVO: 0,
    },
    {
      ID: "4",
      FECHA: "12/12/12",
      NOMBRE: "name",
      ACTIVO: 0,
    },
    {
      ID: "5",
      FECHA: "12/12/12",
      NOMBRE: "name",
      ACTIVO: 0,
    },
  ];*/

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("categorias", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar este categoría?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          eliminarCategoria(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarCategoriaDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };
  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarCategoriaDeTabla = (id) => {
    const ListaFiltrado = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(ListaFiltrado);
  };
  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };
  const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          const ListaFiltrado = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(ListaFiltrado);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    //console.log({ Lista, ValueBusqueda });
    if (ValueBusqueda != "") {
      setPage(0);
    }
    // console.log({Lista});
  }, [Lista]);
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem>
            <Switch
              checked={stateRow}
              onChange={onChange}
              id={idRow}
              tituloActivo="Desactivar producto "
              tituloInactivo="Activar producto"
              on
            />
            {stateRow ? "Desactivar" : "Activar"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"./edit/" + idRow}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              EliminarRegistro(idRow);
            }}
          >
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
            Eliminar
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                IMAGEN
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                NOMBRE
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESCUENTO
              </TableCell>
              <TableCell className="d-none d-sm-table-cell font-Avenir font-AvenirBold font-Avenir font-AvenirBold" align="left">
                VIGENCIA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                ESTATUS
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={12}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.IMG ? (
                      <Avatar sx={{ width: 45, height: 45 }} src={row.IMG} />
                    ) : (
                      <Avatar sx={{ bgcolor: "#FD8C27" }}>
                        {avatarLetters(firstLeterMayus(row.NOMBRE))}
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Grid className="d-none d-sm-block">{row.NOMBRE}</Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="d-xsm-none d-sm-block d-sm-none"
                      style={{
                        background: "",
                        maxWidth: 300,
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            maxWidth: 150,
                            margin: "auto",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {row.NOMBRE}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {row.TIPO_DE_DESCUENTO == -1 ? "-" : row.TIPO_DE_DESCUENTO === 1
                      ? row.CANTIDAD + "%"
                      : "$ " + row.CANTIDAD}

                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA_DE_CADUCIDAD ?? "-"}
                  </TableCell>
                  <TableCell>
                    {row.VIGENTE == -1 ? "-" : <span
                      className={
                        row.VIGENTE == 1
                          ? "badge rounded-pill bg-success"
                          : "badge rounded-pill bg-danger"
                      }
                    >
                      {row.VIGENTE == 1 ? "Vigente" : "Vencido"}
                    </span>}
                  </TableCell>

                  <TableCell align="right">
                    <Grid className="d-none d-sm-block">
                      <Switch
                        checked={row.ACTIVO}
                        onChange={onChange}
                        id={row.ID}
                        tituloActivo="Desactivar categoría"
                        tituloInactivo="Activar categoría"
                        on
                        defaultChecked={row.ACTIVO}
                      />

                      <Link
                        to={"./edit/" + row.ID}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Editar" placement="top">
                          <IconButton>
                            <img src={AccEditar} />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Tooltip title="Eliminar" placement="top">
                        <IconButton onClick={() => EliminarRegistro(row.ID)}>
                          <img src={AccEliminar} />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
