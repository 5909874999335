import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Alert,
} from "@mui/material";
import { getListaProdProve, getSizeCaducidades } from "../../Funciones/Productos";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../../assets/Menos.svg";
import Mas from "../../../../../assets/Mas.svg";
import Buscador from "../../../../../includes/Buscador";
import { Col, Row } from "react-bootstrap";
import ModalCrearProductos from "./ModalCrearProductos";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CrearFechaCaducidad, EliminarFechaCaducidad } from "../../Funciones/ProductosCaducidad";
import { BasicDatePicker } from "../../../../../includes/DatePicker"
import { ConvertDate } from "../../../../../../Lib/Funciones Generales/ObtenerFecha"
import AccEliminar from "../../../../../assets/AccEliminar.svg";

function ModalSelectProd({
  proveedorId = 0,
  onClose = () => { },
  handleProductoSeleccionado = () => { },
  onlyInventariables = false,
  titulo = "Seleccionar productos",
  id_almacen = 0,
  id_sucursal = 0,
  isProductosDescuento = false,
  existencias = false,
  requiredTotal = true,
  onlyMateriaPrima = false,
  titulo_ = "Costo",
  //onlyProductosTerminados = false,
  MostrarBoton,
  productosCarrito,
  setProductosCarrito,
  IsShowProductoPerecedero = false
}) {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const disminuirItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
    }
    setProductosDisponibles(items);
  };
  const addItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosDisponibles(items);
  };
  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == ID)
      : -1;
    if (itemIndex !== -1) {
      //let cantidad = parseInt(Value);
      //items[itemIndex].CANTIDAD = cantidad.toString();
      let cantidad = Value.toDecimal();
      items[itemIndex].CANTIDAD = !/^\d*\.?\d{0,2}$/.test(cantidad)
        ? parseFloat(cantidad).toFixed(2)
        : cantidad;
    } else {
      alert("producto no encontrado");
    }
    setProductosDisponibles(items);
  };

  const getListaProductos = () => {
    getListaProdProve(
      proveedorId,
      //onlyProductosTerminados,
      onlyInventariables,
      onlyMateriaPrima,
      existencias,
      id_almacen,
      id_sucursal,
      ValueBusqueda
    )
      .then((resp) => {
        ///console.log(resp);
        if (resp.length > 0) {
          resp = resp.map((item) => {
            existencias
              ? requiredTotal
                ? (item.CANTIDAD = item.STOCK_ACTUAL)
                : (item.CANTIDAD = 1)
              : (item.CANTIDAD = 1);


            // Modificaciones para agregar la caducidad de un producto perecedero
            item.Caducidades = [CrearFechaCaducidad(item.CANTIDAD)]
            item.OpenCaducidades = false
            item.ErrorCaducidades = false
            item.MensajeCaducidades = ''


            //
            return item;
          }).sort((x, y) => {
            return y.IS_PERECEDERO - x.IS_PERECEDERO
          });
        }
        setProductosDisponibles(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getListaProductos();
  }, [ValueBusqueda, ValueSelect]);

  const quitarProducto = (producto) => {
    const nuevosProductos = productosDisponibles.filter(
      (p) => p.ID !== producto.ID
    );
    setProductosDisponibles(nuevosProductos);
  };

  //console.log("Lista Productos", productosDisponibles);


  const handleAddProducto = ({ Productos, IndexProducto, sizeCaducidades }) => {
    if (Productos.IS_PERECEDERO && IsShowProductoPerecedero) {
      if (Productos.CANTIDAD != sizeCaducidades) {
        Productos.OpenCaducidades = true
        setProductosDisponibles([...productosDisponibles])
      } else {
        handleProductoSeleccionado(Productos, Productos.CANTIDAD);
        quitarProducto(Productos);
      }
    } else {
      handleProductoSeleccionado(Productos, Productos.CANTIDAD);
      quitarProducto(Productos);
    }
  }

  // const handleAddProducto = ({ Productos, openCaducidades, setOpenCaducidades }) => {
  //   handleProductoSeleccionado(Productos, Productos.CANTIDAD);
  //   quitarProducto(Productos);
  // }

  const handleInputChangePerecedero = (target, IndexProducto, IndexCaducidad) => {
    let Value = target.value
    let Name = target.name
    if (Name == 'cantidad_ingresada') {
      Value = Value.toDecimal()
    }
    productosDisponibles[IndexProducto].ErrorCaducidades = false
    productosDisponibles[IndexProducto].MensajeCaducidades = ''
    productosDisponibles[IndexProducto].Caducidades[IndexCaducidad][Name] = Value
    productosDisponibles[IndexProducto].Caducidades[IndexCaducidad].errores[Name] = false
    setProductosDisponibles([...productosDisponibles])
  }





  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true}>
        <IconButton
          aria-label="Close"
          className="m-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
          </DialogTitle>
        }
        <DialogContent style={{ minHeight: "70vh" }}>
          <div className="px-1 py-2">
            <Row>
              <Col xs={12} md={MostrarBoton ? 10 : 12}>
                {" "}
                <Buscador
                  placeholder="Buscar producto"
                  ValueBusqueda={ValueBusqueda}
                  setValueBusqueda={setValueBusqueda}
                />
              </Col>{" "}
              {MostrarBoton ? (
                <>
                  <Col xs={12} md={2}>
                    {" "}
                    <Button
                      style={{ float: "right" }}
                      className="btn btn-Crexendo font-AvenirMedium  py-1 px-4"
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    >
                      Agregar productos
                    </Button>
                    {showModal && (
                      <ModalCrearProductos
                        onClose={() => {
                          setShowModal(!showModal);
                        }}
                        titulo="Crear productos para el ajuste"
                        productosDisponibles={productosDisponibles}
                        setProductosDisponibles={setProductosDisponibles}
                        getListaProductos={getListaProductos}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                {IsShowProductoPerecedero && (
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="left"
                  >
                  </TableCell>
                )}

                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  Descripcion
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  {/*proveedorId == 0 &&
                  onlyInventariables == false &&
                  id_almacen == 0 &&
                  id_sucursal == 0 &&
                  isProductosDescuento == false &&
                  existencias == false
                    ? "Precio"
                    : "Costo"*/}
                  {titulo_}
                </TableCell>

                {onlyInventariables && (
                  <>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      Stock Actual.
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      Stock Max.
                    </TableCell>
                  </>
                )}
                {!isProductosDescuento && (
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  ></TableCell>
                )}
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="right"
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                Array.isArray(productosDisponibles) && (
                  productosDisponibles.map((Productos, IndexProducto) => {

                    const sizeCaducidades = Array.isArray(Productos.Caducidades)
                      ? getSizeCaducidades(Productos.Caducidades)
                      : 0;


                    return (
                      <>
                        <TableRow>
                          {IsShowProductoPerecedero && (
                            <TableCell
                              onClick={() => {
                                productosDisponibles[IndexProducto].OpenCaducidades = !productosDisponibles[IndexProducto].OpenCaducidades
                                setProductosDisponibles([...productosDisponibles])
                              }}
                              component="th"
                              scope="row"
                              className="d-none d-sm-table-cell"
                              style={{ cursor: 'pointer' }}
                            >
                              {
                                (
                                  Productos.IS_PERECEDERO
                                  // ||
                                  // (
                                  //   Productos.CANTIDAD != sizeCaducidades &&
                                  //   Productos.IS_PERECEDERO
                                  // )
                                ) && (
                                  productosDisponibles[IndexProducto].OpenCaducidades
                                    ? <KeyboardArrowUpIcon />
                                    : <KeyboardArrowDownIcon />
                                )
                              }
                            </TableCell>
                          )}
                          <TableCell
                            component="th"
                            scope="row"
                            className="d-none d-sm-table-cell"
                          >
                            {firstLeterMayus(Productos.DESC_CORTA)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="d-none d-sm-table-cell"
                          >
                            {proveedorId == 0 &&
                              onlyInventariables == false &&
                              id_almacen == 0 &&
                              id_sucursal == 0 &&
                              isProductosDescuento == false &&
                              existencias == false
                              ? formatMoneda(Productos.PRECIO)
                              : formatMoneda(Productos.PRECIO_COMPRA)}
                          </TableCell>
                          {onlyInventariables && (
                            <>
                              <TableCell
                                component="th"
                                scope="row"
                                className="d-none d-sm-table-cell"
                              >
                                {Productos.STOCK_ACTUAL}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                className="d-none d-sm-table-cell"
                              >
                                {Productos.STOCK_MAX}
                              </TableCell>
                            </>
                          )}
                          {!isProductosDescuento && (
                            <TableCell
                              component="th"
                              scope="row"
                              className="d-none d-sm-table-cell"
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  className="mx-2"
                                  onClick={() => addItem(Productos)}
                                >
                                  <img src={Mas} style={{ width: "15px" }} />
                                </IconButton>
                                <div
                                  className="d-flex justify-content-center flex-column"
                                  style={{ maxWidth: "150px" }}
                                >
                                  <TextField
                                    style={{ maxWidth: "85px" }}
                                    size="small"
                                    fullWidth
                                    id={Productos.ID}
                                    label="Cantidad"
                                    variant="outlined"
                                    name={Productos.ID}
                                    value={
                                      isNaN(Productos.CANTIDAD)
                                        ? ""
                                        : Productos.CANTIDAD
                                    }
                                    //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                                    onChange={handlInputChange}
                                  />
                                </div>
                                <IconButton
                                  aria-label="delete"
                                  className="mx-2"
                                  onClick={() => disminuirItem(Productos)}
                                >
                                  <img src={Menos} style={{ width: "15px" }} />
                                </IconButton>
                              </div>
                            </TableCell>
                          )}

                          <TableCell align="right">
                            <Button
                              variant="contained"
                              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                              onClick={() => handleAddProducto({ Productos, IndexProducto, sizeCaducidades })}
                            >
                              Agregar
                            </Button>
                          </TableCell>
                        </TableRow>

                        {
                          (
                            Productos.OpenCaducidades
                            // ||
                            // (
                            //   Productos.CANTIDAD != sizeCaducidades &&
                            //   Productos.IS_PERECEDERO
                            // )
                          ) && (
                            <>

                              {/* Area dinamica de las caducidades */}
                              <TableRow >
                                <TableCell ></TableCell>
                                <TableCell
                                  colSpan={4 + (onlyInventariables ? 1 : 0) + (!isProductosDescuento ? 1 : 0)}
                                >
                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <h6 className="font-AvenirBold m-0">Fechas de caducidad del producto</h6>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      className="btn btn-cancelar font-AvenirMedium py-2 px-4 "
                                      onClick={() => {
                                        Productos.Caducidades.push(CrearFechaCaducidad(1))
                                        setProductosDisponibles([...productosDisponibles])
                                      }}
                                    >
                                      {/* <AddIcon />  */}
                                      Agregar
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>

                              {
                                Array.isArray(Productos.Caducidades) && (
                                  Productos.Caducidades.map((Caducidad, IndexCaducidad) => (

                                    <TableRow>
                                      <TableCell></TableCell>
                                      {
                                        onlyInventariables && (
                                          <TableCell colSpan={2}></TableCell>
                                        )
                                      }
                                      {
                                        !isProductosDescuento && (
                                          <TableCell></TableCell>
                                        )
                                      }
                                      <TableCell>
                                        <div className="w-100 my-2">
                                          <TextField
                                            style={{ maxWidth: 100 }}
                                            fullWidth
                                            size="small"
                                            name="cantidad_ingresada"
                                            label="Cantidad"
                                            variant="outlined"
                                            value={Productos.Caducidades[IndexCaducidad].cantidad_ingresada}
                                            error={Productos.Caducidades[IndexCaducidad].errores.cantidad_ingresada}
                                            helperText={Productos.Caducidades[IndexCaducidad].errores.cantidad_ingresada ? "Cantidad no valido" : ""}
                                            onChange={({ target }) => handleInputChangePerecedero(target, IndexProducto, IndexCaducidad)}
                                          />
                                        </div>
                                      </TableCell>
                                      <TableCell>
                                        <div className="w-100 my-2">
                                          <BasicDatePicker
                                            fullWidth
                                            style={{ maxWidth: 170 }}
                                            format="DD/MM/YYYY"
                                            label="Fecha de caducidad"
                                            size="small"
                                            fecha={Productos.Caducidades[IndexCaducidad].fecha_caducidad}
                                            setFecha={(value) => {
                                              value = ConvertDate(value);
                                              handleInputChangePerecedero({ name: 'fecha_caducidad', value }, IndexProducto, IndexCaducidad)
                                            }}
                                          />
                                        </div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Tooltip title="Eliminar" placement="top">
                                          <IconButton onClick={() => EliminarFechaCaducidad(IndexProducto, IndexCaducidad, productosDisponibles, setProductosDisponibles)}>
                                            <img src={AccEliminar} />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                )
                              }


                              <TableRow >
                                <TableCell
                                  style={{ border: 0 }}
                                  className="py-2"
                                  colSpan={5 + (onlyInventariables ? 1 : 0) + (!isProductosDescuento ? 1 : 0)}
                                >
                                  {
                                    (
                                      Productos.CANTIDAD != sizeCaducidades &&
                                      Productos.IS_PERECEDERO
                                    ) && (
                                      <Alert severity="error" sx={{ width: "100%" }}>
                                        El desglose no coincide con la cantidad del producto
                                      </Alert>
                                    )
                                  }
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        }

                      </>
                    )
                  })
                )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ModalSelectProd;
