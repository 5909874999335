import React from "react";
import { getLista } from "../Funciones/Direcciones";
import { useEffect } from "react";
import { useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Stack,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Col, Row } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loading from "../../../Manager/includes/Loading";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import AccEditar from "../../../Manager/assets/AccEditar.svg";
import AccEliminar from "../../../Manager/assets/AccEliminar.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ModalDireccion from "./ModalDireccion";

const MySwal = withReactContent(Swal);

export default function ModalSelectDireccionCliente({
  SetshowModalDireccion = () => {},
  setOpenNuevaDireccion = () => {},
  id,
  id_cliente,
  handlInputChange = () => {},
  id_or_name = "direccion",
}) {
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Lista, setLista] = useState([]);
  const [selectDireccion, setSelectDireccion] = useState(false);
  const [idDireccion, setIdDireccion] = useState("");
  const [LatLong, setLatLong] = useState({ lat: 0, long: 0 });

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const seleccionar = () => {
    //console.log("seleccionar");
    if (selectDireccion != false || idDireccion != 0) {
      handlInputChange({
        target: {
          value: selectDireccion,
          name: id_or_name,
          id: idDireccion,
          lat: LatLong.lat,
          long: LatLong.long,
        },
      });
      SetshowModalDireccion(false);
    } else {
      setMensaje("Selecciona una direccion para confirmar");
      setOpen(true);
    }
  };

  const handleClick = (index, val) => {
    //console.log(index);
    const newLista = Lista.filter((item, ind) => {
      //console.log(item);
      if (index == ind) {
        item.SELECT = true;
        setIdDireccion(item.ID);
        setSelectDireccion(
          "Calle " +
            item.CALLE +
            " " +
            item.NUMERO_EXT +
            " - " +
            item.COLONIA +
            ", " +
            item.MUNICIPIO +
            ", " +
            item.ESTADO +
            "/" +
            item.NOMBRE_CONTACTO +
            "/" +
            item.TELEFONO
        );
        setLatLong({ lat: item.LAT, long: item.LONG });
        return item;
      } else {
        item.SELECT = false;
        return item;
      }
    });

    setLista(newLista);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //console.log("modal", id_cliente);
    let it = 0;
    let dir = "";
    let latlong = { lat: 0, long: 0 };
    setIsLoading(true);
    getLista(id_cliente)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        list = list.map((row) => {
          row.SELECT = false;
          if (row.ID == id) {
            row.SELECT = true;
            it = row.ID;

            dir =
              "Calle " +
              row.CALLE +
              " " +
              row.NUMERO_EXT +
              " - " +
              row.COLONIA +
              ", " +
              row.MUNICIPIO +
              ", " +
              row.ESTADO;
            latlong.lat = row.LAT;
            latlong.long = row.LONG;
          }

          const newRow = row;
          return newRow;
        });
        //console.log(list);
        if (it != 0) {
          setSelectDireccion(dir);
          setIdDireccion(it);
          //console.log(latlong);
          setLatLong(latlong);
        }
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Dialog fullWidth maxWidth="sm" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalDireccion(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Seleccionar dirección</h6>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <Row>
              {Lista.map((row, i) => (
                <div
                  className="pb-0 px-3 px-md-4 pt-3  mt-3"
                  style={{ backgroundColor: "#F7F7F7" }}
                  onClick={() => {
                    //console.log("si");
                    handleClick(i, row.SELECT);
                  }}
                >
                  <Col>
                    <Row>
                      <Col
                        xs={2}
                        className="d-flex justify-content-center align-items-center align-content-center"
                      >
                        {row.SELECT ? (
                          <RadioButtonCheckedIcon />
                        ) : (
                          <RadioButtonUncheckedIcon />
                        )}
                      </Col>
                      <Col xs={10} className="">
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography fontWeight="bold">
                            {row.NOMBRE_CONTACTO}
                          </Typography>
                          <Typography>{"CP " + row.CP}</Typography>
                          <Typography>
                            {"Calle " +
                              row.CALLE +
                              " " +
                              row.NUMERO_EXT +
                              " - " +
                              row.COLONIA +
                              ", " +
                              row.ESTADO +
                              ", " +
                              row.MUNICIPIO}
                          </Typography>
                          <Typography>
                            {"Telefono - " + row.TELEFONO}
                          </Typography>
                        </Grid>
                      </Col>
                    </Row>
                  </Col>
                  {/*
                  <div className="row">
                    <div className="col-12">
                      <h6 className="font-OxygenBold">{row.NOMBRE_CONTACTO}</h6>
                      <RadioButtonUncheckedIcon />
                    </div>
                    <p className="font-OxygenLight my-1">
                      {row.CALLE + " " + row.NUMERO_EXT}
                    </p>
                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Calle</p>
                        <p className="font-OxygenLight my-1">{row.CALLE}</p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1"> Número exterior</p>
                        <p className="font-OxygenLight my-1">
                          {row.NUMERO_EXT}
                        </p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Número interior</p>
                        <p className="font-OxygenLight my-1">
                          {row.NUMERO_INT}
                        </p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Colonia</p>
                        <p className="font-OxygenLight my-1">{row.COLONIA}</p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Código postal</p>
                        <p className="font-OxygenLight my-1">{row.CP}</p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Estado</p>
                        <p className="font-OxygenLight my-1">{row.ESTADO}</p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Municipio</p>
                        <p className="font-OxygenLight my-1">{row.MUNICIPIO}</p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">
                          Tipo de domicilio
                        </p>
                        <p className="font-OxygenLight my-1">
                          {row.TIPODOMICILIO}
                        </p>
                      </small>
                    </div>

                    <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Entre calles</p>
                        <p className="font-OxygenLight my-1">{row.CALLE1}</p>
                        <p className="font-OxygenLight my-1">{row.CALLE2}</p>
                      </small>
                    </div>

                    <div className="col-12 col-lg-3  mt-3 mt-md-1">
                      <small>
                        <p className="font-OxygenBold my-1">Indicaciones</p>
                        <p className="font-OxygenLight my-1">
                          {row.INDICACIONES}
                        </p>
                      </small>
                    </div>
                  </div>
                    */}
                </div>
              ))}
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <Col sm={12} className="p-3">
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => seleccionar()}
              className="btn btn-Crexendo shadow-none w-100 font-OxygenMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 font-OxygenMedium "}
              >
                {IsGuardando ? "Guardando..." : "Confirmar dirección"}
              </span>
            </LoadingButton>
            <br />
            <br />
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => {
                //alert("funcion no disponible");
                setOpenNuevaDireccion(true);
                SetshowModalDireccion(false);
              }}
              className="btn btn-Crexendo shadow-none w-100 font-OxygenMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 font-OxygenMedium "}
              >
                {IsGuardando ? "Guardando..." : "Agregar dirección"}
              </span>
            </LoadingButton>
          </Col>
        </DialogActions>
      </Dialog>
    </>
  );
}
