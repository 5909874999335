import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "../../../../assets/ExportarDanger.svg";
import ExportarSucess from "../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableCliente from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import Buscador from "../../../../includes/Buscador";
import { getLista } from "../Funciones/Clientes";
import SelectFuente from "./SelectFuente";
import BACKEND_URL, { KEY } from "../../../../../Context/backend";

const Clientes = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [fuente, setFuente] = useState("");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const getAll = true;

  const getListaCliente = (order, busqueda, fuente) => {
    getLista(0, 0, order, busqueda, fuente)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListaCliente(ValueSelect, ValueBusqueda, fuente);
  }, [ValueBusqueda, ValueSelect, fuente]);

  const generarReportePDF = () => {
    window.open(`../pdf/clientes/${ValueSelect}/${fuente}`, "_blank");
  };

  const handleChangesFuente = (event) => {
    console.log(event.target.value);
    if (event.target.value != 0) {
      setFuente(event.target.value);
    } else {
      setFuente("Todos");
    }
  };

  useEffect(() => {
    document.title = `Clientes`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Clientes</h4>
        </div>
        <div className="p-2">
          <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
            <a
              href={
                BACKEND_URL +
                `usuarios/ReporteClientesExcel?KEY=${KEY}&Order=${ValueSelect}&fuente=${fuente}`
              }
              target={"_blank"}
            >
              <Button
                className="btn btn-exp-Excel font-AvenirMedium"
                variant="text"
              >
                <img className="me-2" src={ExportarSucess} />
                Exportar Excel
              </Button>
            </a>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
              onClick={() => generarReportePDF(ValueSelect, fuente)}
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button>
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium px-4 "
                variant="contained"
              >
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={6}>
            <Buscador
              placeholder="Buscar clientes por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectFuente
              value={fuente}
              name="fuente"
              label="Fuente"
              onChange={handleChangesFuente}
              getAll={getAll}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectOrdenamiento
              currencies={[
                {
                  value: "1",
                  label: "Más recientes",
                },
                {
                  value: "2",
                  label: "Más antiguos",
                },
                {
                  value: "3",
                  label: "Alfabéticamente descendente",
                },
                {
                  value: "4",
                  label: "Alfabéticamente ascendente",
                },
                {
                  value: "5",
                  label: "Tipo de registro",
                },
              ]}
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableCliente
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TableCliente>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Clientes;
