import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import Cancelar from "../../../../assets/Cancelar.svg";
import XML from "../../../../assets/XML.svg";
import Ver from "../../../../assets/Ver.svg";
import Pdf from "../../../../assets/Pdf.svg";
import Enviar from "../../../../assets/Enviar.svg";

import { Link } from "react-router-dom";
import Loading from "../../../../includes/Loading";
// import withReactContent from "sweetalert2-react-content";
// import Swal from "sweetalert2";
// import { eliminarFactura } from "../Funciones/Facturas";
// import Switch from "../../../../includes/Switch";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { MoreVert } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import SendIcon from "@mui/icons-material/Send";
import { descargarXML } from "../Funciones/Facturas";
import BACKEND_URL from "../../../../../Context/backend";

// const MySwal = withReactContent(Swal);

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
  setShowModalEnviarFactura,
  showModalEnviarFactura,
}) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function TableProductos({
  Lista,
  IsLoading,
  setLista,
  ValueBusqueda,
  setShowModalEnviarFactura,
  setShowModalCancelarFactura,
  setFacturaSeleccionada,
  setCorreoEnvio,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [open, setOpen] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState([]);
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("Facturas", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  useEffect(() => {
    //console.log({ Lista, ValueBusqueda });
    if (ValueBusqueda != "") {
      setPage(0);
    }
  }, [Lista]);
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem>
            <Link
              to={"/pdf/factura/" + idRow.ID}
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Tooltip title="Ver factura" placement="top">
                <IconButton>
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
              Ver factura
            </Link>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowModalCancelarFactura(true);
              setFacturaSeleccionada(idRow);
            }}
          >
            <IconButton>
              <img src={Cancelar} width={20} />
            </IconButton>
            Cancelar
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowModalEnviarFactura(true);
              setFacturaSeleccionada(idRow);
              setCorreoEnvio(idRow.CORREO_CLIENTE);
            }}
          >
            <IconButton>
              <img src={Enviar} width={20} />
            </IconButton>
            Enviar
          </MenuItem>

          <MenuItem
            onClick={() => {
              descargarXML(idRow.ID, idRow.UUID ? idRow.UUID : "", idRow.XML);
            }}
          >
            <IconButton>
              <img src={XML} width={20} />
            </IconButton>
            Desc. XML
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                FACTURA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                CLIENTE
              </TableCell>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                ESTATUS
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                TIPO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PAGADO
              </TableCell>
              <TableCell className=" font-Avenir font-AvenirBold" align="left">
                TOTAL
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="right"
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={7}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row" className="">
                    {row.SERIE}-{row.FOLIO}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Grid className="d-none d-sm-block">{row.CLIENTE}</Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="d-xsm-none d-sm-block d-sm-none"
                      style={{
                        background: "",
                        maxWidth: 300,
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            maxWidth: 150,
                            margin: "auto",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {row.CLIENTE}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.CANCELADO == "0" ? (
                      <Chip size="small" label="Vigente" color="success"></Chip>
                    ) : (
                      <Chip size="small" label="Cancelado" color="error"></Chip>
                    )}
                    <br />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.AUTO_FACTURA == "1" ? (
                      <Chip
                        size="small"
                        label="Autofactura"
                        color="success"
                      ></Chip>
                    ) : (
                      <Chip size="small" label="Web" color="primary"></Chip>
                    )}
                    <br />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.PAGADO == 1 ? (
                      <Chip size="small" label="SI" color="success"></Chip>
                    ) : (
                      <Chip size="small" label="NO" color="error"></Chip>
                    )}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Grid className="d-none d-sm-block">
                      {row.TOTAL.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className="d-xsm-none d-sm-block d-sm-none"
                      style={{
                        background: "",
                        maxWidth: 300,
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{
                            maxWidth: 150,
                            margin: "auto",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                          noWrap={true}
                        >
                          {row.TOTAL.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell align="right">
                    <Grid className="d-none d-sm-block">
                      {row.CANCELADO == "0" && (
                        <Tooltip
                          title="Cancelar factura"
                          placement="top"
                          onClick={() => {
                            setShowModalCancelarFactura(true);
                            setFacturaSeleccionada(row);
                          }}
                        >
                          <IconButton>
                            <img src={Cancelar} width={20} />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip
                        title="Enviar factura"
                        placement="top"
                        onClick={() => {
                          setShowModalEnviarFactura(true);
                          setFacturaSeleccionada(row);
                          setCorreoEnvio(row.CORREO_CLIENTE);
                        }}
                      >
                        <IconButton>
                          <img src={Enviar} width={20} />
                        </IconButton>
                      </Tooltip>

                      <Link
                        to={"/pdf/factura/" + row.ID}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Ver factura" placement="top">
                          <IconButton>
                            <img src={Pdf} width={20} />
                          </IconButton>
                        </Tooltip>
                      </Link>

                      {/*<Tooltip
                        title="Descargar xml"
                        placement="top"
                        onClick={() => {
                          descargarXML(
                            row.ID,
                            row.UUID ? row.UUID : "",
                            row.XML
                          );
                        }}
                      >
                        <IconButton>
                          <img src={XML} width={20} />
                        </IconButton>
                      </Tooltip>*/}
                      <a
                        href={
                          BACKEND_URL +
                          `pedidos/XMLFactura?ID_FACTURA=${row.ID}`
                        }
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Descargar xml" placement="top">
                          <IconButton>
                            <img src={XML} width={20} />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </Grid>

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
