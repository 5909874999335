import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import ExportarDanger from "./../../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../../assets/ExportarSucess.svg";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
import SelectAlmacenes from "../../Almacenes/Componentes/SelectAlmacenes";
import TableSucuAlma from "./Tabla";
import BACKEND_URL, { KEY } from "../../../../../Context/backend";
import Select from "../../../../../Includes/Select";
import { getListaSucuReSotck } from "../Funciones/ReStock";

const RepReStock = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [SucursalSelecciona, setSucursalSelecciona] = useState("0");
  const [AlmacenSelecciona, setAlmacenSelecciona] = useState("0");
  const [tipo, setTipo] = useState(0);
  const [conExistencia, setConExistencia] = useState(0);

  const getListaMercaSucuAlma = () => {
    setIsLoading(true);
    getListaSucuReSotck(SucursalSelecciona, AlmacenSelecciona, tipo)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListaMercaSucuAlma();
  }, [ValueBusqueda, ValueSelect]);

  const handleChangesSucu = (event) => {
    if (event.target.value != 0) {
      setSucursalSelecciona(event.target.value);
    } else {
      setSucursalSelecciona("0");
    }
  };

  const handleChangesAlma = (event) => {
    if (event.target.value != 0) {
      setAlmacenSelecciona(event.target.value);
    } else {
      setAlmacenSelecciona("0");
    }
  };

  const generarReportePDF = () => {
    window.open(
      `../pdf/pdfrestock/${SucursalSelecciona}/${AlmacenSelecciona}/${tipo}/${3}`,
      "_blank"
    );
  };
  useEffect(() => {
    document.title = `Re-Stock`;
  }, []);
  const IS_REPORTE = 1;
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Re-Stock</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <a
              target={"_blank"}
              href={
                BACKEND_URL +
                `ordencompra/ReporteMercaExcel?ID_SUCURSAL=${SucursalSelecciona}&ID_ALMACEN=${AlmacenSelecciona}&KEY=${KEY}&tipo=${tipo}&existencia=${conExistencia}&IS_REPORTE=${IS_REPORTE}`
              }
            >
              <Button
                className="btn btn-exp-Excel font-AvenirMedium"
                variant="text"
              >
                <img className="me-2" src={ExportarSucess} />
                Exportar Excel
              </Button>
            </a>
            <Button
              onClick={() =>
                generarReportePDF(
                  SucursalSelecciona,
                  AlmacenSelecciona,
                  tipo,
                  conExistencia
                )
              }
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button>
          </Stack>
        </div>
      </div>
      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} className="p-2 pb-0">
            <h6>Selecciona los filtros</h6>
          </Col>
          <Col xs={12} className="p-0">
            <div className="d-flex flex-wrap justify-content-star">
              <div className="p-2" style={{ width: "250px" }}>
                <SelectSucursales
                  size="small"
                  Value={SucursalSelecciona}
                  handlInputChange={handleChangesSucu}
                />
              </div>
              <div className="p-2" style={{ width: "250px" }}>
                <SelectAlmacenes
                  size="small"
                  Value={AlmacenSelecciona}
                  handlInputChange={handleChangesAlma}
                  Sucursal={SucursalSelecciona}
                />
              </div>

              <div className="p-2" style={{ width: "250px" }}>
                <Select
                  titulo="Tipo de prodcuto"
                  size="small"
                  ValueSelect={tipo}
                  setValueSelect={setTipo}
                  opciones={[
                    { value: 0, label: "Todos" },
                    { value: 1, label: "Materia prima" },
                    { value: 2, label: "Producto termiando" },
                    { value: 3, label: "Producto perecedero" },
                  ]}
                />
              </div>
              {/* <div className="p-2" style={{ width: "250px" }}>
                <Select
                  titulo="Existencia"
                  size="small"
                  ValueSelect={conExistencia}
                  setValueSelect={setConExistencia}
                  opciones={[
                    { value: 0, label: "Todos" },
                    { value: 1, label: "Sin existencia" },
                    { value: 2, label: "Con existencia" },
                    { value: 3, label: "Con stok mínimo" },
                  ]}
                />
              </div> */}

              <div className="p-2">
                <Button
                  className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
                  variant="contained"
                  onClick={getListaMercaSucuAlma}
                >
                  Filtrar
                </Button>
              </div>
              <div className="me-3"></div>
            </div>
          </Col>

          <Col sm={12}>
            <TableSucuAlma
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TableSucuAlma>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default RepReStock;
