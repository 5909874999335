import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { getUnidadesMedidas, getInfoUnidadMedida } from "../Funciones/SAT";

const UnidadMedidaSat = ({
  Value,
  Error,
  handlInputChange,
  Name = "clave_unidad_medida_sat",
  MinLen = 1,
}) => {
  const [UnidadMedida, setUnidadMedida] = useState({
    cargado: false,
    lista: [{ CLAVE: 0, DESCRIPCION: "Unidad Medadida del SAT" }],
  });

  // Consultar al momento de buscar por query  con sentencia like
  const consultar = (valor) => {
    getUnidadesMedidas(valor)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setUnidadMedida({ cargado: true, lista: list });
        } else {
          setUnidadMedida({
            cargado: true,
            lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setUnidadMedida({
          cargado: true,
          lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }],
        });
      });
  };

  //Consultar solo una vez en caso de edición por query con sentencia where clave =
  useEffect(() => {
    getInfoUnidadMedida(Value)
      .then((resp) => {
        clearInterval(window.intervalo);
        let list = JSON.parse(resp.data);
        //console.log("list", list);
        if (list.length != 0) {
          const info = list[0];
          setUnidadMedida({
            cargado: true,
            lista: [
              {
                CLAVE: info.CLAVE,
                DESCRIPCION: info.DESCRIPCION,
              },
            ],
          });
        } else {
          setUnidadMedida({
            cargado: true,
            lista: [{ CLAVE: 0, DESCRIPCION: "Ingresa clave para buscar" }],
          });
        }
      })
      .catch((resp) => {
        clearInterval(window.intervalo);
        setUnidadMedida({
          cargado: true,
          lista: [{ CLAVE: 0, DESCRIPCION: "Sin claves disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = UnidadMedida.lista.find((x) => x.CLAVE == Value);
    if (opcion) {
      if (opcion.CLAVE != 0) {
        return opcion;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return UnidadMedida.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.CLAVE == value.CLAVE;
      }}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value.CLAVE } });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        const titulo =
          newInputValue.trim().length == 0
            ? "Sin claves disponibles"
            : newInputValue + " - Buscando claves del SAT...";
        setUnidadMedida({
          ...UnidadMedida,
          lista: [{ CLAVE: 0, DESCRIPCION: titulo }],
        });
        if (newInputValue.trim().length >= MinLen) {
          clearInterval(window.intervalo);
          window.intervalo = setInterval(function () {
            consultar(newInputValue);
            clearInterval(window.intervalo);
          }, 1000);
        }
      }}
      options={UnidadMedida.lista}
      getOptionLabel={(option) => {
        return (
          (option.CLAVE != 0 ? option.CLAVE + " - " : "") + option.DESCRIPCION
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona Unidad de Medida del SAT" : ""}
            label="Unidad de Medida del SAT"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder="Buscar..."
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Unidad de Medida del SAT"} />
  );
};

export default UnidadMedidaSat;
