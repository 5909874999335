import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { IconButton, Stack } from "@mui/material";

import { Close } from "@mui/icons-material";
import { Fragment } from "react";

const Modal = ({
    children,
    sizeClose = 0,
    mostrar,
    fnCerrar,
    Titulo = "Titulo",
    maxWidth = "xl",
    textoGuardar = "Guardar",
    textoCancelar = "Cancelar"
}) => {

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={mostrar}
                onClose={fnCerrar}
            >
                {
                    Titulo != "Titulo" && (
                        <div className="d-flex justify-content-between">
                            <DialogTitle fullWidth>
                                {Titulo}
                            </DialogTitle>
                            {
                                sizeClose == 0 ? (
                                    <IconButton onClick={fnCerrar}>
                                        <Close className="me-3 my-auto" />
                                    </IconButton>

                                ) : (
                                    <IconButton onClick={fnCerrar}>
                                        <Close className="my-auto" style={{ width: 60, height: 60 }} />
                                    </IconButton>
                                )
                            }

                        </div>
                    )
                }

                <DialogContent className="p-0">
                    {
                        children
                    }
                </DialogContent>
                {
                    (textoGuardar != "" || textoCancelar != "") && (
                        <DialogActions>
                            <Stack
                                className="p-3"
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                {
                                    textoGuardar != "" && (
                                        <LoadingButton
                                            loading={false}
                                            loadingPosition="start"
                                            disabled={false}
                                            onClick={fnCerrar}
                                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                                            variant="contained"
                                        >
                                            <span className="px-2">{textoGuardar}</span>
                                        </LoadingButton>
                                    )
                                }
                                {
                                    textoCancelar != "" && (
                                        <Button
                                            onClick={fnCerrar}
                                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                                            variant="outlined"
                                        >
                                            {textoCancelar}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </DialogActions>
                    )
                }

            </Dialog>
        </Fragment>
    );
};

export default Modal;
