import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Categorias from "./Categorias";
import Productos from "./Productos";
import Carrito from "./Carrito";
import Descuentos from "./Descuentos";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import {
  Alert,
  styled,
  Snackbar,
  Tab,
  Tabs,
  Button,
  Stack,
} from "@mui/material";
import { guardar } from "../Funciones/PuntoDeVente";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalSelectDireccionCliente from "../../../../Lib/Direcciones/Componentes/ModalSelectDireccionCliente";
import ModalDireccion from "../../../../Lib/Direcciones/Componentes/ModalDireccion";
import { getDireccionPrincipal } from "../../../../Lib/Direcciones/Funciones/Direcciones";
import { getPrecioZona } from "../../Catalogos/Zonas/Funciones/Zonas";
import TabsDatosEntrega from "./Tabs/TabsDatosEntrega";
import TabsDedicatoria from "./Tabs/TabsDedicatoria";
import TabsPago from "./Tabs/TabsPago";
import { useContext } from "react";
import { IconContext } from "../../../includes/Menu";
import ModalPagoVenta from "./ModalPagoVenta";
import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import { setLocalStorage, getLocalStorage } from "../../../../Context/Storage";
import BackTab from "./Tabs/BackTab";
import NetxTab from "./Tabs/NetxTab";
import ButtonsTabs from "./Tabs/ButtonsTabs";

const MySwal = withReactContent(Swal);

export default function PruebaContenedores() {
  const context = useContext(IconContext);

  //#region Variables de estado
  const { id } = useParams();
  const [categoria, setCategoria] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [valueBusqueda, setValueBusqueda] = React.useState("");
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
  const [showModalMetodosPago, setShowModalMetodosPago] = useState(false);
  const [openNuevaDireccion, setOpenNuevaDireccion] = useState(false);
  const [precioEnvio, setPreioEnvio] = useState(0);
  const [tab, setTab] = React.useState(0);
  const [descuento, setDescuento] = useState({
    id: 0,
    descuento: 0,
    tipo: 0,
    max: 0,
  });
  const [expanded, setExpanded] = React.useState("panel1");
  const [Fecha, setFecha] = useState(GetDate());
  const [HoraInicio, setHoraInicio] = useState("");
  const [HoraFin, setHoraFin] = useState("");
  const [isRentable, setIsRentable] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Values, setValues] = useState({
    id: id ?? "0",
    id_cliente: "0",
    id_sucursal: "0",
    id_direccion: 0,
    fecha_entrega: ConvertDate(GetDate()),
    observaciones: "",
    direccion: "",
    tipo_envio: 1,
    descuento: 0,
    tipo_descuento: 0,
    descuento_max: 0,
    subtotal: 0,
    total: 0,
    pagado: false,
    productos: [],
    codigo: "",
    id_zona: 0,
    ubicacion: "0 0",
    lat: 0,
    long: 0,
    horario_entrega: {
      inicio: "12:34:00",
      fin: "15:10:00",
    },
    metodos_pago: {
      tarjeta: 0,
      transferencia: 0,
      efectivo: 0,
      monedero: 0,
    },
    costo_envio: 0,
    metodo_pago: "",
    dedicatoria: "",
    envia: "",
    recibe: "",
  });
  const [Errores, setErrores] = useState({
    id_cliente: false,
    id_sucursal: false,
    fecha_entrega: false,
    observaciones: false,
    observaciones: false,
    direccion: false,
    tipo_envio: false,
  });
  //#endregion

  //#region handles
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleInputChange = ({ target }) => {
    //console.log(target);
    let Value = target.value;
    const Name = target.name;

    if (Name == "id_sucursal") {
      Values.direccion = target.dir;
    }
    if (Name == "tipo_envio" && Value != Values.tipo_envio) {
      Values.id_sucursal = "0";
      Values.direccion = "";
      Values.id_direccion = 0;
    }
    if (Name == "direccion") {
      Values.id_direccion = target.id;
      Values.lat = target.lat;
      Values.long = target.long;
    }
    //Values[Name] = Value;
    //setValues(Values);
    setValues({ ...Values, [Name]: Value });
    //console.log(Values);
    setErrores({ ...Errores, [Name]: false });
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //#endregion

  //#region funciones
  const openModalValidations = () => {
    if (Values.id_cliente == "" || Values.id_cliente == "0") {
      Errores.id_cliente = true;
      setErrores(Errores);
      setMensaje("Selecciona un cliente para ver direcciones");
      setOpenAlert(true);
    } else {
      SetshowModalDireccion(true);
    }
  };

  const Guardar = () => {
    setIsGuardando(true);
    //setValues({ ...Values, productos: productosCarrito });
    //console.log(Values);
    guardar(
      Values,
      Errores,
      setErrores,
      productosCarrito,
      isRentable,
      descuento,
      precioEnvio
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setProductosCarrito([]);
            setValues({
              id: id ?? "0",
              id_cliente: "0",
              id_sucursal: "0",
              id_direccion: 0,
              fecha_entrega: ConvertDate(GetDate()),
              observaciones: "",
              direccion: "",
              tipo_envio: 1,
              descuento: 0,
              tipo_descuento: 0,
              descuento_max: 0,
              subtotal: 0,
              total: 0,
              pagado: false,
              productos: [],
              codigo: "",
              id_zona: 0,
              ubicacion: "0 0",
              lat: 0,
              long: 0,
              horario_entrega: {
                inicio: "12:34:00",
                fin: "15:10:00",
              },
              metodos_pago: {
                tarjeta: 0,
                transferencia: 0,
                efectivo: 0,
                monedero: 0,
              },
              costo_envio: 0,
              metodo_pago: " ",
              dedicatoria: "",
              envia: "",
              recibe: "",
            });
            setDescuento({ id: 0, descuento: 0, tipo: 0, max: 0 });
            setFecha(GetDate());
            setIsRentable(false);
            setCategoria(0);
            setExpanded("panel1");
            setPreioEnvio(0);
            //console.log(Values);
          });
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        //console.log(data);
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setIsGuardando(false);
      });
  };
  //#endregion

  //#region Inicio de effects
  useEffect(() => {
    setValues({ ...Values, fecha_entrega: ConvertDate(Fecha) });
    Errores.fecha_entrega = false;
    setErrores(Errores);
  }, [Fecha]);

  useEffect(() => {
    Values.horario_entrega.inicio = HoraInicio;
    setValues(Values);
  }, [HoraInicio]);

  useEffect(() => {
    Values.horario_entrega.fin = HoraFin;
    setValues(Values);
  }, [HoraFin]);

  useEffect(() => {
    //console.log("checar si hay uno de renta");
    let sumaPrecios = 0;
    productosCarrito.map((item) => {
      //console.log(item);
      sumaPrecios = parseFloat(item.PRECIO) * item.CANTIDAD;
      if (item.RENTABLE == true) {
        setIsRentable(true);
        //console.log(item);
      }
    });
    setValues({ ...Values, total: sumaPrecios });
  }, [productosCarrito]);

  useEffect(() => {
    if (
      Values.id_direccion != 0 &&
      Values.id_direccion != "" &&
      Values.id_direccion != "0"
    ) {
      getPrecioZona(Values.lat, Values.long)
        .then((data) => {
          //console.log(data[0]);
          if (data.length > 0) {
            setPreioEnvio(data[0].PRECIO_DE_ENVIO);
            setValues({
              ...Values,
              ubicacion: `${Values.long} ${Values.lat}`,
              id_zona: data[0].ID,
            });
          }
        })
        .catch((data) => {
          console.log();
        });
      //console.log("LAT: ", Values.lat);
      //console.log("LONG: ", Values.long);
    }
  }, [Values.id_direccion, Values.direccion]);

  useEffect(() => {
    if (
      Values.tipo_envio == 1 &&
      Values.id_cliente != "" &&
      Values.id_cliente != "0"
    ) {
      getDireccionPrincipal(Values.id_cliente)
        .then((data) => {
          //console.log(data);
          if (data.length == 0) {
            setValues({ ...Values, direccion: "", id_direccion: 0 });
          } else {
            setValues({
              ...Values,
              direccion:
                "Calle " +
                data.CALLE +
                " " +
                data.NUMERO_EXT +
                " - " +
                data.COLONIA +
                ", " +
                data.NOMBRE_MUNICIPIO +
                ", " +
                data.NOMBRE_ESTADO,
              id_direccion: data.ID,
              lat: data.LAT,
              long: data.LONG,
            });

            if (Errores.direccion) setErrores({ ...Errores, direccion: false });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      setValues({
        ...Values,
        direccion: "",
        //id_sucursal: 0,
        //tipo_envio: 0
      });
    }
  }, [Values.id_cliente]);

  useEffect(() => {
    //console.log(context);
    context.setOpen(false);
  }, []);
  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <div
        style={{
          height: "calc(100vh - 80px)",
          width: "100%",
          //background: "red",
          //padding: "5px",
        }}
      >
        <StyledSnackbar
          direction="right"
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {mensaje}
          </Alert>
        </StyledSnackbar>
        {showModalDireccion ? (
          <ModalSelectDireccionCliente
            SetshowModalDireccion={SetshowModalDireccion}
            setOpenNuevaDireccion={setOpenNuevaDireccion}
            //getDireciones={getDireciones}
            id_cliente={Values.id_cliente}
            id={Values.id_direccion}
            handlInputChange={handleInputChange}
          />
        ) : (
          ""
        )}
        {openNuevaDireccion ? (
          <ModalDireccion
            SetshowModalDireccion={setOpenNuevaDireccion}
            SetshowModalDirecciones={SetshowModalDireccion}
            handlInputChanges={handleInputChange}
            id_cliente={Values.id_cliente}
          />
        ) : (
          ""
        )}
        {showModalMetodosPago ? (
          <ModalPagoVenta
            setShowModalPago={setShowModalMetodosPago}
            Values={Values}
            setValues={setValues}
            Errores={Errores}
            handlInputChange={handleInputChange}
            totalCarrito={Values.total + precioEnvio}
          />
        ) : (
          ""
        )}
        <Row className="h-100">
          <Col
            xs={7}
            className="d-flex flex-column justify-content-between"
            style={
              {
                //background: "pink",
                //padding: "5px"
              }
            }
          >
            <div
              className="flex-grow-1"
              style={
                {
                  //background: "gray",
                  //overflowY: "scroll",
                  //overflow: "auto",
                }
              }
            >
              {categoria == 0 && query == "" ? (
                <Categorias setCategoria={setCategoria} categoria={categoria} />
              ) : (
                <Productos
                  setCategoria={setCategoria}
                  categoria={categoria}
                  productosCarrito={productosCarrito}
                  setProductosCarrito={setProductosCarrito}
                />
              )}
            </div>
            <div
              style={{
                //background: "yellow",
                //overflowX: "scroll",
                //overflow: "auto",
                paddingBottom: "15px",
              }}
            >
              <Descuentos
                productosCarrito={productosCarrito}
                productos={productosCarrito}
                setProductosCarrito={setProductosCarrito}
                setDescuento={setDescuento}
                descuento={descuento}
                mensaje={mensaje}
                setMensaje={setMensaje}
                setOpenAlert={setOpenAlert}
                handleInputChange={handleInputChange}
              />
            </div>
          </Col>
          <Col
            xs={5}
            className="d-flex flex-column justify-content-between"
            style={
              {
                //background: "green"
              }
            }
          >
            <div
              className="flex-grow-1"
              style={
                {
                  //background: "orange",
                }
              }
            >
              <Row>
                <Col xs={12}>
                  <Tabs
                    TabIndicatorProps={{
                      bgcolor: "#3abe88",
                      style: {
                        backgroundColor: "#3abe88",
                      },
                    }}
                    value={tab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="#212529"
                    indicatorColor=""
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Carrito" className="font-AvenirBold" />
                    <Tab label="Datos de Entrega" className="font-AvenirBold" />
                    <Tab label="Dedicatorias" className="font-AvenirBold" />
                    <Tab label="Pago" className="font-AvenirBold" />
                  </Tabs>
                </Col>
                {tab == 0 ? (
                  <Col xs={12}>
                    <Carrito
                      //isVista
                      productosCarrito={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                      descuento={descuento}
                      setDescuento={setDescuento}
                      cambio={0}
                      setIsRentable={setIsRentable}
                      isRentable={isRentable}
                      precioEnvio={precioEnvio}
                      tipoEnvio={Values.tipo_envio}
                    />
                  </Col>
                ) : tab == 1 ? (
                  <TabsDatosEntrega
                    IsGuardando={IsGuardando}
                    Guardar={Guardar}
                    id={id}
                    Values={Values}
                    handleInputChange={handleInputChange}
                    openModalValidations={openModalValidations}
                    expanded={expanded}
                    handleChange={handleChange}
                    Fecha={Fecha}
                    setFecha={setFecha}
                    Errores={Errores}
                    setShowModalMetodosPago={setShowModalMetodosPago}
                    HoraInicio={HoraInicio}
                    HoraFin={HoraFin}
                    setHoraInicio={setHoraInicio}
                    setHoraFin={setHoraFin}
                  />
                ) : tab == 2 ? (
                  <TabsDedicatoria
                    Values={Values}
                    handleInputChange={handleInputChange}
                  />
                ) : (
                  <TabsPago />
                )}
              </Row>
            </div>
            <div
              style={
                {
                  //background: "blue",
                }
              }
            >
              <Row>
                <Stack
                  className="p-3"
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                  style={{}}
                >
                  <ButtonsTabs tab={tab} setTab={setTab} />
                </Stack>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
