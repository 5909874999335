import { Dialog, DialogActions, DialogContent, IconButton, TextField } from '@mui/material'
import { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { ConvertTime, GetDate } from '../../../../../Lib/Funciones Generales/ObtenerFecha'
import CloseIcon from '@mui/icons-material/Close';
import HelperError from '../../../../../Includes/HelperError';
import { BasicTimePicker } from '../../../../../Includes/TimePicker';
import { LoadingButton } from '@mui/lab';
import { guardarHorarioEnvio, validarHorario } from '../Funciones/HorarioEnvio';
import '../../../../../Lib/Funciones Generales/Prototipos.js'

const fecha_actual = GetDate();

const ModalHorarioEnvio = ({
    Horarios,
    horario,
    isSave,
    isInsertar,
    handleCerrarModal,
    handleActualizar,
    setOpen,
    setMensaje
}) => {

    const [IsGuardando, setIsGuardando] = useState(false);
    const [ValuesHorario, setValuesHorario] = useState(horario)
    const [error, setError] = useState({
        INICIO: { mostrar: false, mensaje: "" },
        FIN: { mostrar: false, mensaje: "" },
        MAX_PEDIDOS: { mostrar: false, mensaje: "" },
    })

    const handleChange = ({ name, value }) => {
        if (name == "INICIO" || name == "FIN") {
            setValuesHorario({
                ...ValuesHorario,
                [name]: ConvertTime(value)
            });
            setError({ ...error, INICIO: { mostrar: false, mensaje: "" }, FIN: { mostrar: false, mensaje: "" } })
        }
        else {
            setValuesHorario({
                ...ValuesHorario,
                [name]: value.toNumber()
            });
            setError({ ...error, [name]: { mostrar: false, mensaje: "" } })
        }
    }

    const GuardarHorario = async () => {

        console.log("SI GUARDAR: ", isSave)
        if (isSave) {

            setIsGuardando(true);
            guardarHorarioEnvio(ValuesHorario, error, setError, fecha_actual)
                .then((data) => {
                    if (data.codigo == "200") {

                        let mensaje = "";
                        let ID = ValuesHorario.ID;
                        if (isInsertar) {
                            const respuesta = data.mensaje.split("|")
                            if (Array.isArray(respuesta)) {
                                if (respuesta.length > 1) {
                                    mensaje = respuesta[0]
                                    ID = parseInt(respuesta[1]);
                                }
                            }
                        } else {
                            mensaje = data.mensaje
                        }

                        setIsGuardando(false);
                        setMensaje({ texto: mensaje, color: 'success' });
                        setOpen(true);
                        handleActualizar({ ...ValuesHorario, ID }, fecha_actual)

                    } else {
                        setIsGuardando(false);
                        setMensaje({ texto: data.mensaje, color: 'error' });
                        setOpen(true);
                    }
                })
                .catch((data) => {
                    setIsGuardando(false);
                    setMensaje({ texto: data.mensaje, color: 'error' });
                    setOpen(true);
                });
        } else {
            const validacion = await validarHorario(ValuesHorario, error, fecha_actual, Horarios[ValuesHorario.DIA].lista, true)
            if (validacion) {
                console.log("VALIDACION: ", validacion)
                setMensaje({ texto: validacion.mensaje, color: 'error' });
                setOpen(true);
                return
            }
            handleActualizar(ValuesHorario, fecha_actual)
        }
    }

    return (
        <>
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={"sm"}
            >

                <div className="position-absolute" style={{ "right": 0 }} >
                    <IconButton onClick={handleCerrarModal} >
                        <CloseIcon />
                    </IconButton>
                </div>

                <DialogContent>
                    <h6 className="font-AvenirBold">
                        {ValuesHorario.ID == 0 ? "Agregar horario de envío" : "Editar horario de envío"}
                    </h6>

                    <Row className='p-3'>
                        <Col sm={12} md={6} className='p-2'>
                            <BasicTimePicker
                                size='large'
                                label="Inicio de la jornada"
                                hora={ValuesHorario.INICIO}
                                setTime={(hora) => {
                                    handleChange({ name: "INICIO", value: hora })
                                }}
                            />
                            {
                                <HelperError
                                    Error={error.INICIO.mostrar}
                                    Mensaje={error.INICIO.mensaje}
                                />
                            }
                        </Col>
                        <Col sm={12} md={6} className='p-2'>
                            <BasicTimePicker
                                size='large'
                                label="Fin de la jornada"
                                hora={ValuesHorario.FIN}
                                setTime={(hora) => {
                                    handleChange({ name: "FIN", value: hora })
                                }}
                            />
                            {
                                <HelperError
                                    Error={error.FIN.mostrar}
                                    Mensaje={error.FIN.mensaje}
                                />
                            }
                        </Col>
                        <Col sm={12} className='p-2'>
                            <TextField
                                fullWidth
                                name="MAX_PEDIDOS"
                                variant="outlined"
                                placeholder='Maximo de pedidos'
                                value={ValuesHorario.MAX_PEDIDOS}
                                onChange={({ target }) => {
                                    handleChange({ name: "MAX_PEDIDOS", value: target.value })
                                }}
                                error={error.MAX_PEDIDOS.mostrar}
                                helperText={error.MAX_PEDIDOS.mostrar ? error.MAX_PEDIDOS.mensaje : ""}
                            />
                        </Col>
                    </Row>


                    <DialogActions>
                        <Button
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                            variant="outlined"
                            onClick={handleCerrarModal}
                        >
                            Cancelar
                        </Button>

                        <LoadingButton
                            loading={IsGuardando}
                            loadingPosition="start"
                            onClick={() => GuardarHorario()}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                                {IsGuardando ? "Guardando..." : "Guardar"}
                            </span>
                        </LoadingButton>

                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ModalHorarioEnvio