import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BasicDatePicker } from "../../../../includes/DatePicker";


export default function ModalProcesar({
  onClose = () => { },
  titulo = "",
  id = 0,
  getProductos = async () => { },
  save = () => { },
  Boton = "",
  IsShowProductoPerecedero = false
}) {
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [desactivado, setDesactivado] = useState(true);
  useEffect(() => {
    getProductos(id)
      .then((data) => {
        console.log("Data de modal [" + id + "]: ", data);
        if (data.productos && data.productos.length > 0) {
          setProductosDisponibles(data.productos);
        } else {
          const d = JSON.parse(data.data);
          setProductosDisponibles(d);
        }
        setDesactivado(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // console.log(productosDisponibles);
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">{titulo}</h6>
        </DialogTitle>

        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {
                  IsShowProductoPerecedero && (
                    <TableCell className="font-Avenir font-AvenirBold" align="left"></TableCell>
                  )
                }
                <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="left"
                >
                  DESCRIPCIÓN
                </TableCell>
                {
                  IsShowProductoPerecedero && (
                    <TableCell className="font-Avenir font-AvenirBold" align="left">
                      PRODUCTO PERECEDERO
                    </TableCell>
                  )
                }
                <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="left"
                >
                  PRECIO
                </TableCell>

                <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="left"
                >
                  CANTIDAD
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productosDisponibles
                && productosDisponibles.map((Productos, IndexProducto) => (
                  <>
                    <TableRow key={Productos.ID}>
                      {IsShowProductoPerecedero && (
                        <TableCell
                          onClick={() => {
                            productosDisponibles[IndexProducto].OpenCaducidades = !productosDisponibles[IndexProducto].OpenCaducidades
                            setProductosDisponibles([...productosDisponibles])
                          }}
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell"
                          style={{ cursor: 'pointer' }}
                        >
                          {
                            (
                              Productos.IS_PERECEDERO
                              // ||
                              // (
                              //   Productos.CANTIDAD != sizeCaducidades &&
                              //   Productos.IS_PERECEDERO
                              // )
                            ) && (
                              productosDisponibles[IndexProducto].OpenCaducidades
                                ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />
                            )
                          }
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row" className="">
                        {firstLeterMayus(Productos.DESCRIPCION_PRODUCTO)}
                      </TableCell>
                      {IsShowProductoPerecedero && (
                        <TableCell component="th" scope="row" className="">
                          {Productos.IS_PERECEDERO ? 'Si' : 'No'}
                        </TableCell>
                      )}

                      <TableCell component="th" scope="row" className="">
                        {formatMoneda(Productos.PRECIO)}
                      </TableCell>
                      <TableCell component="th" scope="row" className="">
                        {Productos.CANTIDAD}
                      </TableCell>
                    </TableRow>


                    {
                      (
                        Productos.OpenCaducidades
                        // ||
                        // (
                        //   Productos.CANTIDAD != sizeCaducidades &&
                        //   Productos.IS_PERECEDERO
                        // )
                      ) && (
                        <>

                          {/* Area dinamica de las caducidades */}
                          <TableRow >
                            <TableCell ></TableCell>
                            <TableCell
                              colSpan={5}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <h6 className="font-AvenirBold m-0">Fechas de caducidad del producto</h6>
                              </div>
                            </TableCell>
                          </TableRow>

                          {
                            Array.isArray(Productos.Caducidades) && (
                              Productos.Caducidades.map((Caducidad, IndexCaducidad) => (


                                <TableRow>
                                  <TableCell colSpan={3}></TableCell>

                                  <TableCell>
                                    <div className="w-100 my-2">
                                      <TextField
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                        style={{ maxWidth: 100 }}
                                        fullWidth
                                        size="small"
                                        name="cantidad_ingresada"
                                        label="Cantidad"
                                        variant="outlined"
                                        value={Productos.Caducidades[IndexCaducidad].cantidad_ingresada}
                                        error={Productos.Caducidades[IndexCaducidad].errores.cantidad_ingresada}
                                        helperText={Productos.Caducidades[IndexCaducidad].errores.cantidad_ingresada ? "Cantidad no valido" : ""}
                                        onChange={({ target }) => {
                                          // handleInputChangePerecedero(target, IndexProducto, IndexCaducidad)
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="w-100 my-2">
                                      <BasicDatePicker
                                        fullWidth
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                        style={{ maxWidth: 170 }}
                                        format="DD/MM/YYYY"
                                        label="Fecha de caducidad"
                                        size="small"
                                        fecha={Productos.Caducidades[IndexCaducidad].fecha_caducidad}
                                        setFecha={(value) => {
                                          // value = ConvertDate(value);
                                          // handleInputChangePerecedero({ name: 'fecha_caducidad', value }, IndexProducto, IndexCaducidad)
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            )
                          }

                        </>
                      )
                    }

                  </>
                ))
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions className="px-3 pb-3">
          <Button
            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
            variant="contained"
            disabled={desactivado}
            onClick={() => {
              save(id);
              //onClose();
            }}
          >
            {Boton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
