import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoRepartidor } from "../Funciones/Repartidor";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
const MySwal = withReactContent(Swal);

const Repartidor = () => {
  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [Puestos, setPuestos] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [fecha, setFecha] = useState(GetDate());
  const [Values, setValues] = useState({
    id: id ?? "0",
    id_sucursal: 0,
    codigo_de_empleado: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    fecha_de_ingreso: "",
    placas: "",
    domicilio: ""

  });
  const [Errores, setErrores] = useState({
    nombre: false,
    id_sucursal: false,
    codigo_de_empleado: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
    fecha_de_ingreso: false,
    domicilio: false
  });

  useEffect(() => {
    setValues({ ...Values, fecha_de_ingreso: ConvertDate(fecha) });
  }, [fecha]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Name == "telefono") {
      Value = Value.toNumber();
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoRepartidor(id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  // useEffect(()=>{
  //   ActivarOrDesactivar(setActivo)
  //    },[activo])

  const GuardarRepartidor = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Repartidores
      </Button>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar repartidor" : "Nuevo repartidor"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">

              
              <Col sm={12} md={4} className="p-3">
                <SelectSucursales
                  size="large"
                  name="id_sucursal"
                  Value={Values.id_sucursal}
                  Error={Errores.id_sucursal}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="codigo_de_empleado"
                  label="Código de empleado"
                  variant="outlined"
                  value={Values.codigo_de_empleado}
                  error={Errores.codigo_de_empleado}
                  helperText={Errores.codigo_de_empleado ? "Código de empleado no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_paterno"
                  label="Apellido paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  helperText={
                    Errores.apellido_paterno ? "Apellido paterno no valido" : ""
                  }
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_materno"
                  label="Apellido materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  error={Errores.apellido_materno}
                  helperText={
                    Errores.apellido_materno ? "Apellido materno no valido" : ""
                  }
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="domicilio"
                  label="Domicilio"
                  variant="outlined"
                  helperText={Errores.domicilio ? "Domicilio no valido" : ""}
                  value={Values.domicilio}
                  error={Errores.domicilio}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  helperText={Errores.correo ? "Correo incorrecto" : ""}
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Teléfono"
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  helperText={Errores.telefono ? "Teléfono inválido" : ""}
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="placas"
                  label="Placas"
                  variant="outlined"
                  helperText={Errores.placas ? "Placas no validas" : ""}
                  value={Values.placas}
                  error={Errores.placas}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={4} className="p-3">
                <BasicDatePicker
                  label="Fecha de ingreso"
                  name="fecha_de_ingreso"
                  value={Values.fecha_de_ingreso}
                  error={Errores.fecha_de_ingreso}
                  fecha={Values.fecha_de_ingreso}
                  setFecha={setFecha}
                  fechaMin="2021-01-01"
                  handlInputChange={handlInputChange}
                />
              </Col>


              <Col sm={12}></Col>
              <Col sm={12} md={4} className="p-3">
                {Values.id == 0 ? (
                  <>
                    <Alert severity="info">
                      <AlertTitle>Mensaje</AlertTitle>
                      Se enviará por correo una contraseña temporal
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarRepartidor()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Repartidor;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
