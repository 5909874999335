import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

import {
  ImageListItemBar,
  Container,
  Card,
  Tooltip,
  ImageListItem,
  ImageList,
  IconButton,
  Grid,
  Input,
  Alert,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import Loading from "../../../../includes/Loading";
import AccEditarImagen from "../../../../assets/EditarImagen.svg";
import IconoAgregar from "../../../../assets/IconoAgregar.svg";
import { ActualizarImgPrincipal } from "../Funciones/Productos";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import { EliminarImagen } from "../../../../../Lib/Imagen/Funciones/CargarImagen";

const MySwal = withReactContent(Swal);

export default function StandardImageList(props) {
  const [IsLoading, setIsLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleClose = () => {
    setOpen(false);
  };

  const validarTamanio = (tam) => {
    //console.log(tam);
    if (tam >= props.maximoImagenes) {
      MySwal.fire({
        title: "Advertencia",
        html: "Has llegado al limite de cargas",
        icon: "warning",
        confirmButtoColor: "#3ABE88",
        showConfirmButton: false,
        timer: 1700,
      });
      return false;
    } else {
      return true;
    }
  };
  const eliminarIMG = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar esta imagen?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        const url = props.imagenes[id].IMG.split("?v=");
        //console.log(url);
        EliminarImagen(props.imagenes[id].ID, IMG.TIPO_PRODUCTOS, url[0], true, props.id, props.imagenes[id].PRINCIPAL)
          .then((resp) => {            

            if(props.imagenes[id].PRINCIPAL)
            {
              if(Array.isArray(props.imagenes))
              {
                if(props.imagenes.length != 0)
                {
                  const newImages = props.imagenes.map(item => {
                    if(resp.respuesta == item.IMG){
                      item.PRINCIPAL = true;
                    }
                    return item;
                  })
                  props.setImagenes([...newImages])
                }
              }
            }
            setSeverity("success");
            setMensaje("Cambios guardados correctamente");
            setOpen(true);
            eliminarImagenlDeTabla(id);
          })
          .catch((e) => {
            console.log(e);
            setSeverity("error");
            setMensaje("Error al guardar los cambios. " + e?.mensaje);
            setOpen(true);
          });
      }
    });
  };

  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarImagenlDeTabla = (id) => {
    const filteredLibraries = props.imagenes.filter((item, index) => {
      return index !== id;
    });

    props.setImagenes(filteredLibraries);
  };

  const onchange = (id, checked, id_entidad, url) => {


    const filteredLibraries = props.imagenes.filter((item, index) => {
      if (item.PRINCIPAL == true) {
        item.PRINCIPAL = false;
      }
      if (item.ID == id) {
        if (checked == false && item.PRINCIPAL == false) {
          item.PRINCIPAL = true;
        } else {
          item.PRINCIPAL = checked;
        }
      }
      return item;
    });


    if(!props.id || props.id == 0){
      props.setImagenes(filteredLibraries);
      return;
    }

    ActualizarImgPrincipal(id_entidad, url)
      .then((resp) => {
        //console.log(resp);
        setSeverity("success");
        setMensaje("Cambios guardados correctamente");
        setOpen(true);
        props.setImagenes(filteredLibraries);
      })
      .catch((resp) => {
        //console.log(resp);
        setSeverity("error");
        setMensaje("Error al guardar los cambios");
        setOpen(true);
      });

  };
  useEffect(() => {
    //console.log(props.imagenes);
  });

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return IsLoading ? (
    <Loading />
  ) : (
    <Grid
      style={
        {
          //background: "red",
          //color: "red",
        }
      }
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className="w-100 d-inline-block"
    >
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <ImageList
        sx={{
          mb: 8,
          gridTemplateColumns:
            "repeat(auto-fill,minmax(280px, 9fr)) !important",
        }}
        gap={12}
      >
        <Grid
          container
          key={"open"}
          color="#D5D5D5"
          //arriba abajo derecha izquierda
          ///ImageList>
          style={{
            borderStyle: "dashed",
            borderWidth: "4px",
          }}
          sx={
            props.imagenes.length > 0
              ? { height: "100% !important" }
              : { height: "250px !important" }
          }
          direction="row"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            if (validarTamanio(props.imagenes.length)) {
              //console.log(props.imagenes);
              props.setOpenModal(true);
            }
          }}
        >
          <ImageListItem
            sx={{
              height: "90px !important",
              width: "90px !important",
            }}
            style={{ color: "black" }}
          >
            <img src={IconoAgregar} className="img-fluid" />
          </ImageListItem>
        </Grid>
        {props.imagenes.map((item, index) => (
          <Card key={item?.ID || index}>
            <ImageListItem sx={{ height: "250px !important" }}>
              <img src={`${item.IMG}`} alt={item.ID} loading="lazy" />
              <ImageListItemBar
                title={""}
                position={"top"}
                actionIcon={
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >




                    
                        <Tooltip title="Imagen principal" sx={{ mr: "5px" }}>
                          {item.PRINCIPAL ? (
                            <IconButton
                              //sx={{ color: "rgba(255, 255, 255, 1)" }}
                              aria-label={`info about ${item.ID}`}
                              className="bg-white shadow mt-1"
                              onClick={() => {
                                onchange(
                                  item.ID,
                                  !item.PRINCIPAL,
                                  item.ID_PRODUCTO,
                                  item.IMG
                                );
                              }}
                            >
                              <CheckBoxIcon className="text-danger" />
                            </IconButton>
                          ) : (
                            <IconButton
                              //sx={{ color: "rgba(255, 255, 255, 1)" }}
                              aria-label={`info about ${item.ID}`}
                              className="bg-white shadow mt-1"
                              onClick={() => {
                                onchange(
                                  item.ID,
                                  !item.PRINCIPAL,
                                  item.ID_PRODUCTO,
                                  item.IMG
                                );
                              }}
                            >
                              <CheckBoxOutlineBlankIcon className="text-danger" />
                            </IconButton>
                          )}
                        </Tooltip>
                      
                    







                    <Tooltip title="Eliminar" sx={{ mr: "5px" }}>
                      <IconButton
                        //sx={{ color: "rgba(255, 255, 255, 1)" }}
                        aria-label={`info about ${item.ID}`}
                        onClick={() => {
                          //console.log(props);
                          props.id == undefined ||
                            props.id == "" ||
                            props.id == 0
                            ? eliminarImagenlDeTabla(index)
                            : eliminarIMG(index);
                        }}
                        className="bg-white shadow mt-1"
                      >
                        <DeleteIcon className="text-danger" />
                      </IconButton>
                    </Tooltip>




                  </Grid>
                }
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
              />
            </ImageListItem>
          </Card>
        ))}
      </ImageList>
    </Grid>
  );
}
