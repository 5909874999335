import { useState } from "react";
import { Card } from "@mui/material";
import { Row } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import RepHorarioEnvio from "./Componentes/RepHorarioEnvio";
import ExportarDanger from "../../../assets/ExportarDanger.svg";
import Button from "@mui/material/Button";
import ExportarSucess from "../../../assets/ExportarSucess.svg";
import BACKEND_URL, { KEY } from "../../../../Context/backend";


const RouterHorarioEnvio = () => {
  const [Horarios, setHorarios] = useState({
    1: { rango: "", lista: [] },
    2: { rango: "", lista: [] },
    3: { rango: "", lista: [] },
    4: { rango: "", lista: [] },
    5: { rango: "", lista: [] },
    6: { rango: "", lista: [] },
    7: { rango: "", lista: [] },
  });

  const generarReportePDF = () => {
    window.open(`../pdf/horarios`, "_blank");
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Horarios de envío</h4>
        </div>
        <div className="p-2 mt-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <a
              href={
                BACKEND_URL + `horarios/ReporteHorariosExcel?KEY=${KEY}`
              }
              target={"_blank"}
            >
              <Button
                className="btn btn-exp-Excel font-AvenirMedium"
                variant="text"
              >
                <img className="me-2" src={ExportarSucess} />
                Exportar Excel
              </Button>
            </a>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
              onClick={() => generarReportePDF()}
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <RepHorarioEnvio setHorarios={setHorarios} Horarios={Horarios} />
        </Row>
      </Card>
    </>
  );
};

export default RouterHorarioEnvio;
