import React, { useEffect, useState } from "react";
import Loading from "../../../../../Includes/Loading";
import { getData } from "../../../../../Context/backend";
import { useParams } from "react-router-dom";
import { stringify } from "query-string";

const PDFCategorias = (ORDER) => {
  const [base64Data, setBase64PDF] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { ordernamiento, fuente } = useParams();

  useEffect(() => {
    document.title = `Reporte de categorias `;
    ORDER = ordernamiento;
    let queryParamsObj = {
      Order: ORDER
    };

    const url = `categorias/ReporteCategorias?${stringify(queryParamsObj)}`;

    getData(url)
      .then((res) => {
        if (!res.error) {
          console.log(res.data.respuesta.base64);
          const base64 = res.data.respuesta.base64;
          setBase64PDF(base64);
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <h6 className="text-center mt-5">Cargando reporte, por favor espere</h6>
        <div className="p-5 mt-3">
          <Loading />
        </div>
      </>
    );
  }

  if (!base64Data) {
    return (
      <div>
        No se pudo obtener el archivo PDF, revisa tu conexión a internet e
        intenta de nuevo
      </div>
    );
  }
  return (
    <object
      data={`data:application/pdf;base64,${base64Data}`}
      style={{ height: "100vh" }}
      type="application/pdf"
      width="100%"
    ></object>
  );
};
export default PDFCategorias;
