import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { getInfoUnidadMedida } from "../Funciones/Productos";
// import { getUnidadesMedidas, getInfoUnidadMedida } from "../Funciones/SAT";

const SelectUnidadMedidaInterna = ({
  Value,
  Error,
  handlInputChange,
  Name = "unidad_medida",
}) => {
  const [ValorFiltrado, setFiltro] = useState();
  const [UnidadMedidaInterna, setUnidadMedidaInterna] = useState({
    cargado: false,
    lista: [{ CLAVE: 0, DESCRIPCION: "Unidad de medida interna" }],
  });

  useEffect(() => {
    getInfoUnidadMedida()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setUnidadMedidaInterna({ cargado: true, lista: list });
        } else {
          setUnidadMedidaInterna({
            cargado: true,
            lista: [
              {
                CLAVE: 0,
                DESCRIPCION: "Sin unidades de medida interna disponibles",
              },
            ],
          });
        }
      })
      .catch((resp) => {
        setUnidadMedidaInterna({
          cargado: true,
          lista: [
            {
              CLAVE: 0,
              DESCRIPCION: "Sin unidades de medida interna disponibles",
            },
          ],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = UnidadMedidaInterna.lista.find((x) => x.CLAVE == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return UnidadMedidaInterna.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.CLAVE == value.CLAVE;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: value.CLAVE } });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={UnidadMedidaInterna.lista}
      getOptionLabel={(option) => {
        return (
          (option.CLAVE != 0 ? option.CLAVE + " - " : "") + option.DESCRIPCION
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona una unidad de medida interna" : ""}
            label="Unidad de medida interna"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Unidad de medida interna"} />
  );
};

export default SelectUnidadMedidaInterna;
