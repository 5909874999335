import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { TableHead } from "@mui/material";
import Loading from "../../../../../Includes/Loading";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import EliminarRojo from "../../../../assets/EliminarRojo.svg";

export default function TablaProds({
  Lista = [],
  setLista = () => {},
  IsVista = false,
  descuento,
  tipo,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState();

  const deleteItem = (newItem) => {
    const items = [...Lista];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setLista(items);
  };

  const calcularPrecioConDescuento = (PRECIO_PUBLICO = 0) => {
    let precioFinal = 0;
    switch (tipo) {
      case 1: //%
        const decimal = descuento / 100;
        const desc = PRECIO_PUBLICO * decimal;
        precioFinal = PRECIO_PUBLICO - desc;
        return precioFinal;
      case 2: //$
        precioFinal = PRECIO_PUBLICO - descuento;
        if (precioFinal < 0) {
          precioFinal = 0;
        }
        return precioFinal;
      default:
        return 0;
    }
  };

  return (
    <>
      <TableContainer component={!IsVista ? Paper : ""}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PRODUCTO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                DESCUENTO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PRECIO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PRECIO PUBLICO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                PRECIO CON DESCUENTO
              </TableCell>
              {!IsVista ? (
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="center"
                >
                  ACCIONES
                </TableCell>
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : Lista
            ).map((row) => (
              <TableRow
                key={row.ID}
                style={{ background: row.REPETIDO ? "rgba(255,0,0,0.2)" : "" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="d-none d-sm-table-cell"
                  align="center"
                >
                  {row.PRODUCTO}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className="d-none d-sm-table-cell"
                  align="center"
                >
                  {(tipo == 1 ? "%" : "$") + " " + descuento}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className="d-none d-sm-table-cell"
                  align="center"
                >
                  {formatMoneda(parseFloat(row.PRECIO))}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className="d-none d-sm-table-cell"
                  align="center"
                >
                  {formatMoneda(parseFloat(row.PRECIO_PUBLICO))}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className="d-none d-sm-table-cell"
                  align="center"
                >
                  {formatMoneda(
                    parseFloat(calcularPrecioConDescuento(row.PRECIO_PUBLICO))
                  )}
                </TableCell>
                {!IsVista ? (
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    <IconButton
                      aria-label="delete"
                      className="   mx-1"
                      onClick={() => deleteItem(row)}
                    >
                      <img src={EliminarRojo} style={{ width: "15px" }} />
                    </IconButton>
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
