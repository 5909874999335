import { TextField } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import UsoCFDI from "../../../../../../Lib/SAT/Componentes/UsoCFDI";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Tune } from "@mui/icons-material";
import FormasDePago from "../../../../../../Lib/SAT/Componentes/FormasDePago";
import SelectSeries from "../../../../Catalogos/Series/Componentes/SelectSeries";

export default function TabDatosCFDI({
  handleInputChange = () => {},
  ValuesSend = {},
  Errores = {},
  id_pedido = 0,
}) {
  const caracteresRestantes = (tam, max) => {
    const total = max - Number(tam);

    if (Number(tam) == 0) {
      return "";
    } else {
      return `(${total})`;
    }
  };

  return (
    <>
      <Row className="">
        <Col xs={4} className="p-3">
          <FormControl fullWidth>
            <InputLabel id="demo-select-small-label">
              Fecha de emisión
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={ValuesSend.fecha_emision}
              error={Errores.fecha_emision}
              label="Fecha de emisión"
              onChange={handleInputChange}
              fullWidth
              name="fecha_emision"
              defaultValue={"0"}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"0"}> Actual </MenuItem>
              <MenuItem value={"-1"}> 1 dia antes</MenuItem>
              <MenuItem value={"-2"}>2 días antes</MenuItem>
              <MenuItem value={"-3"}>3 días antes</MenuItem>
            </Select>
          </FormControl>
        </Col>
        <Col xs={4} className="p-3">
          <SelectSeries
            id_pedido={id_pedido}
            name="serie"
            Value={ValuesSend.serie}
            Error={Errores.serie}
            handlInputChange={handleInputChange}
          />
        </Col>
        <Col xs={4} className="p-3">
          <TextField
            fullWidth
            value={"MXN"}
            InputProps={{
              readOnly: true,
            }}
            label="Moneda"
          />
        </Col>
        <Col xs={4} className="p-3">
          <TextField
            fullWidth
            value={"I - Ingreso"}
            InputProps={{
              readOnly: true,
            }}
            label="Tipo de comprobante"
          />
        </Col>
        <Col xs={4} className="p-3">
          <FormasDePago
            Value={ValuesSend.forma_pago}
            Error={Errores.forma_pago}
            handleInputChange={handleInputChange}
          />
        </Col>
        <Col xs={4} className="p-3">
          <FormControl fullWidth>
            <InputLabel id="demo-select-small-label">Método de pago</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={ValuesSend.metodo_pago}
              error={Errores.metodo_pago}
              label=" Método de pago"
              onChange={handleInputChange}
              fullWidth
              name="metodo_pago"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"PUE"}>PUE- pago en una sola excibiion</MenuItem>
              <MenuItem value={"PPD "}> PPD - Pago en parcialidades</MenuItem>
            </Select>
          </FormControl>
        </Col>
        <Col xs={4} className="p-3">
          <UsoCFDI
            Value={ValuesSend.uso_cfdi}
            handlInputChange={handleInputChange}
            Error={Errores.uso_cfdi}
          />
        </Col>
        <Col xs={12} className="p-3">
          <TextField
            fullWidth
            multiline
            rows={5}
            value={ValuesSend.observaciones}
            label={`Observaciones ${caracteresRestantes(
              ValuesSend.observaciones.length,
              500
            )}`}
            inputProps={{ maxLength: 500 }}
            name="observaciones"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
    </>
  );
}
