import * as React from "react";
import { MenuItem, TextField } from "@mui/material";

export default function SelectStatusPedido({
  ValueSelect,
  size = "",
  handleChanges,
  currencies = [
    {
      value: "1",
      label: "Recibido",
    },
    {
      value: "2",
      label: "En proceso",
    },
    {
      value: "3",
      label: "Terminado",
    },
    {
      value: "0",
      label: "Cancelado",
    },
    {
      value: "1230",
      label: "Todos",
    },
  ],
}) {
  return (
    <TextField
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handleChanges}
      label="Estatus"
      value={ValueSelect}
      className="mt-0 mt-sm-0 mt-md-0"
      size={size}
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
