import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  Tabs,
  Box,
  Tab,
  Typography,
  FormGroup,
  Divider,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import "../../../../../Lib/Funciones Generales/Prototipos";
import ModalCargarMultImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarMultImagen";
import logo from "../../../../../assets/LogoOscuro.svg";
import StandardImageList from "./ImageList";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import Switch from "../../../../includes/Switch";
import TabGenerales from "./Tab/TabGenerales";
import TabSat from "./Tab/TabSat";
import {
  Guardar,
  getInfoProducto,
  getSucursalesByProd,
} from "../Funciones/Productos";
import { getComisiones } from "../../../Configuraciones/Empresas/Funciones/Empresas";
import TabComposicion from "./Tab/TabComposicion";
import ModalSelectProd from "./Dialogos/ModalSelectProd";
import ButtonTabs from "../../AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
import TabEstilos from "./Tab/TabEstilos";

const MySwal = withReactContent(Swal);
let i = 0;

const Producto = ({ Modal, getListaProductos }) => {
  //Eventos del formulario
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tamanioActual, setTamanioActual] = useState(0);
  const [maximoImagenes, setMaximoImagenes] = useState(5);
  const [comisionTotal, setComisionTotal] = useState(0);
  const [comisiones, setComisiones] = useState({
    COMISION_FIJA: 0,
    PORCENTAJE_COMISION: 0,
  });


  const [Values, SetValues] = useState({
    //generales
    id: id ?? "0",
    codigo: "",
    id_categoria: 0,
    desc_corta: "",
    desc_larga: "",
    precio: "",
    p_compra: "",
    id_proveedor: "",
    id_sucursal: 0,
    id_almacen: 0,
    //materia prima
    isMateriaPrima: false,
    //inventariable
    isInventariable: false,
    stock_max: "",
    stock_min: "",
    //rentable
    isRentable: false,
    //perecedero
    isPerecedero: false,
    //es ecommerce
    is_ecommerce: false,
    //p_dia: "",
    p_horaExtra: "",
    p_penalizacion: "",
    //imagenes
    galeriaImg: [],
    //sat
    isFacturable: true,
    unidad_medida: "",
    clave_unidad_medida_sat: "",
    clave_codigo_prod_sat: "",
    moneda: "MXN",
    iva: "16",
    //precio con comisiones
    p_c_comisiones: 0,
    comision: "",
    //composicion de producto terminado
    composicion: [],
    //is_productoTerminado
    is_productoTerminado: false,

    colores: [],
    tamanos: [],
  });

  const [Errores, setErrores] = useState({
    //generales
    codigo: false,
    id_categoria: false,
    desc_corta: false,
    desc_larga: false,
    precio: false,
    p_compra: false,
    id_proveedor: false,
    id_almacen: false,
    //inventariable
    stock_max: false,
    stock_min: false,
    //rentable
    //p_dia: false,
    p_horaExtra: false,
    p_penalizacion: false,
    //sat
    unidad_medida: false,
    clave_unidad_medida_sat: false,
    clave_codigo_prod_sat: false,
    moneda: false,
    iva: false,
    is_productoTerminado: false,
  });

  const [productosCarrito, setProductosCarrito] = useState([]);
  const [openModalSelectProd, setOpenModalSelectProd] = useState(false);

  //const [Horarios, SetHorarios] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [imagenes, setImagenes] = useState([]);

  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);

  //const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getTotalImgs = () => {
    return Array.isArray(Values.galeriaImg) ? Values.galeriaImg.length : 0;
    //return tamanioActual;
  };

  //#region HANDLES
  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    console.log("Name", Name, "Value", Value);
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    if (
      Name == "precio" ||
      Name == "p_compra" ||
      Name == "p_dia" ||
      Name == "p_horaExtra" ||
      Name == "p_penalizacion"
    ) {
      NewValue = { ...NewValue, [Name]: Value.toDecimal() };
    }
    if (Name == "stock_max" || Name == "stock_min") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    SetValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD =
        Number(productoExistente.CANTIDAD) + Number(cantidad);
    } else {
      producto = {
        ID_PRODUCTO: parseInt(producto.ID),
        DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
        PRECIO: parseFloat(producto.PRECIO_COMPRA),
        CANTIDAD: cantidad,
      };
      items.push(producto);
    }
    setProductosCarrito(items);
  };
  //#endregion

  //#region FUNCIONES

  // setProductosDisponibles(Values);

  const GuardarEvento = () => {
    //console.log(imagenes);

    /////////////////////////
    setIsGuardando(true);

    Guardar(Values, Errores, setErrores, productosCarrito, sucursales)
      .then((data) => {
        //console.log(data);
        if (data?.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            if (Modal) {
              Modal = false;
              getListaProductos();
            } else {
              navigate(-1);
            }
          });
        } else {
          setMensaje(data?.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const fnDevolverImagen = (img) => {
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    let newData = imagenes.filter((item) => {
      return item;
    });

    img.filter((item) => {
      i = i + 1;
      const status = newData.length > 0 ? false : true;
      if (newData.length + 1 <= maximoImagenes) {
        newData.push({
          //ID: i,
          ID: item.hasOwnProperty("IMG") ? item.ID : i,
          IMG: item.hasOwnProperty("IMG") ? item.IMG : item,
          ID_PRODUCTO: i,
          //ID_SUCURSAL: id,
          PRINCIPAL: status,
        });
      }
    });

    setImagenes(newData);
    setTamanioActual(newData.length);
    SetValues({ ...Values, galeriaImg: imagenes });
  };

  const comisionPrecioPublico = (d = "") => {
    const precio = Number(Values.precio != "" ? Values.precio : 0);
    const porcentaje = comisiones.PORCENTAJE_COMISION;
    const montoAdicional = comisiones.COMISION_FIJA;
    const impuesto = 16;
    const porcentajeDecimal = porcentaje / 100;
    const descuento = precio * porcentajeDecimal;
    const descuentoTotal = descuento + montoAdicional;
    const impuestoDecimal = impuesto / 100;
    const impuestoTotal = descuentoTotal * impuestoDecimal;
    const comisionFinal = descuentoTotal + impuestoTotal;
    const precioFinal = precio + comisionFinal;

    SetValues({
      ...Values,
      p_c_comisiones: precioFinal,
    });
    setComisionTotal(comisionFinal);
  };

  //#endregion

  //#region EFFECTS
  useEffect(() => {
    comisionPrecioPublico("effectde values.precio y comisiones");
  }, [Values.precio, comisiones]);

  useEffect(() => {
    document.title = `Productos`;
    getComisiones()
      .then((resp) => {
        const precios = JSON.parse(resp.data);
        setComisiones({
          COMISION_FIJA: precios[0].COMISION_FIJA,
          PORCENTAJE_COMISION: precios[0].PORCENTAJE_COMISION,
        });
      })
      .catch((resp) => {
        console.log(resp);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoProducto(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            try {
              const filteredLibraries = resp.data.galeriaImg.filter((item) => {
                if (item.IMG == resp.data.img) {
                  item.PRINCIPAL = true;
                } else {
                  item.PRINCIPAL = false;
                }
                return item;
              });
              setImagenes(filteredLibraries);
            } catch (err) {
              console.log(err);
            }


            const filterProds = resp.data.composicion.map((item) => {
              return {
                ID_PRODUCTO: item.ID_MATERIA_PRIMA,
                DESCRIPCION_PRODUCTO: item.DESCRIPCION_PRODUCTO,
                PRECIO: 0,
                FECHA: item.FECHA,
                CANTIDAD: item.CANTIDAD,
              };
            });


            //console.log(filterProds);
            setProductosCarrito(filterProds);
            SetValues(resp.data);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }

    getSucursalesByProd(id == undefined ? 0 : id)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          setSucursales([]);
        } else {
          setSucursales(JSON.parse(resp.data));
        }
      })
      .catch((resp) => {
        setSucursales([]);
      });
  }, []);

  useEffect(() => {
    SetValues({
      ...Values,
      galeriaImg: imagenes,
    });
  }, [imagenes]);
  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {Modal ? (
        <></>
      ) : (
        <>
          {" "}
          <Button
            onClick={() => navigate(-1)}
            className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
            variant="text"
          >
            <ArrowBackIcon className="me-3" />
            Productos
          </Button>
        </>
      )}

      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Galeria de imagenes" className="font-AvenirBold" />
          <Tab label="Facturacion e impuestos" className="font-AvenirBold" />
          <Tab label="Composición" className="font-AvenirBold" />
          <Tab label="Sucursales" className="font-AvenirBold" />
          <Tab label="Adicionales" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar producto" : "Nuevo producto"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <TabGenerales
                    handlInputChange={handlInputChange}
                    Values={Values}
                    Errores={Errores}
                    comisionTotal={comisionTotal}
                  />
                ) : tab == 1 ? (
                  <>
                    <Col
                      sm={12}
                      className="d-flex flex-wrap align-items-center justify-content-center"
                    >
                      <StandardImageList
                        id={Values.id}
                        logo={logo}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        imagenes={imagenes}
                        setImagenes={setImagenes}
                        maximoImagenes={maximoImagenes}
                      />

                      {openModal && (
                        <ModalCargarMultImagen
                          id={Values.id}
                          tipo={IMG.TIPO_PRODUCTOS}
                          Titulo="Cargar imagenes del producto"
                          mostrar={openModal}
                          fnCerrar={handleCloseModal}
                          fnDevolverImagen={fnDevolverImagen}
                          imgDefault={true}
                          getTotalImgs={getTotalImgs}
                          maximoImagenes={maximoImagenes}
                          ConfigRecortar={{
                            requerida: true,
                            aspectoAlto: 4,
                            aspectoAncho: 3,
                          }}
                        />
                      )}
                    </Col>
                  </>
                ) : tab == 2 ? (
                  <TabSat
                    handlInputChange={handlInputChange}
                    Values={Values}
                    Errores={Errores}
                  />
                ) : tab == 3 ? (
                  <TabComposicion
                    handlInputChange={handlInputChange}
                    Values={Values}
                    Errores={Errores}
                    productosCarrito={productosCarrito}
                    setProductosCarrito={setProductosCarrito}
                    IsLoading={IsLoading}
                    openModal={() => {
                      setOpenModalSelectProd(true);
                    }}
                  />
                ) : tab == 4 && (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-7 ">
                        <h6
                          lassName="font-Avenir mt-0 p-0"
                          style={{ fontSize: "13px" }}
                        >
                          Al activar las sucursales, éstas podran vender este
                          prodcuto desde el punto de venta
                        </h6>
                      </div>

                      <div className="col-12 col-md-5 text-end ">
                        <Typography className="w-100 ">
                          {"Seleccionar todos"}
                          <Switch
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={0}
                            checked={isAll}
                            onChange={(checked) => {
                              const resultado = sucursales.map((objeto) => {
                                return { ...objeto, ACTIVO: checked ? 1 : 0 };
                              });
                              setSucursales([...resultado]);
                              setIsAll(checked);
                            }}
                          />
                        </Typography>
                      </div>
                      <div className="col-12 mt-2 border-bottom"></div>

                      <div className="col-12 col-md-6 col-lg-4">
                        {sucursales.map((prod, index) => {
                          if (index % 3 === 0) {
                            return (
                              <div className="d-flex justify-content-between border-bottom">
                                <Typography>{prod.DESCRIPCION}</Typography>
                                <Switch
                                  checked={prod.ACTIVO == 1}
                                  tituloActivo="Desactivar"
                                  tituloInactivo="Activar"
                                  id={prod.ID}
                                  onChange={(checked) => {
                                    const indice = sucursales.findIndex(
                                      (objeto) => objeto.ID === prod.ID
                                    );
                                    if (indice !== -1) {
                                      sucursales[indice].ACTIVO = checked
                                        ? 1
                                        : 0;
                                      setSucursales([...sucursales]);
                                    }
                                  }}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        {sucursales.map((prod, index) => {
                          if (index % 3 === 1) {
                            return (
                              <div className="d-flex justify-content-between border-bottom">
                                <Typography>{prod.DESCRIPCION}</Typography>
                                <Switch
                                  checked={prod.ACTIVO == 1}
                                  tituloActivo="Desactivar"
                                  tituloInactivo="Activar"
                                  id={prod.ID}
                                  onChange={(checked) => {
                                    const indice = sucursales.findIndex(
                                      (objeto) => objeto.ID === prod.ID
                                    );
                                    if (indice !== -1) {
                                      sucursales[indice].ACTIVO = checked
                                        ? 1
                                        : 0;
                                      setSucursales([...sucursales]);
                                    }
                                  }}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        {sucursales.map((prod, index) => {
                          if (index % 3 === 2) {
                            return (
                              <div className="d-flex justify-content-between border-bottom">
                                <Typography>{prod.DESCRIPCION}</Typography>
                                <Switch
                                  checked={prod.ACTIVO == 1}
                                  tituloActivo="Desactivar"
                                  tituloInactivo="Activar"
                                  id={prod.ID}
                                  onChange={(checked) => {
                                    const indice = sucursales.findIndex(
                                      (objeto) => objeto.ID === prod.ID
                                    );
                                    if (indice !== -1) {
                                      sucursales[indice].ACTIVO = checked
                                        ? 1
                                        : 0;
                                      setSucursales([...sucursales]);
                                    }
                                  }}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </>
                )}

                {
                  tab === 5 && (
                    <TabEstilos
                      IsLoading={IsLoading}
                      id_producto={Values.id}
                      SetValues={SetValues}
                      Values={Values}
                    />
                  )
                }
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              // onClick={() => (Modal ? GuardarEnCarrito() : GuardarEvento())}
              onClick={() => GuardarEvento()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            {Modal ? (
              <></>
            ) : (
              <>
                {" "}
                <Button
                  onClick={() => navigate(-1)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </>
            )}

            <ButtonTabs tab={tab} setTab={setTab} tabsTotales={5} />
          </Stack>
        </CardContent>
        <Button />
      </Card>
      {openModalSelectProd && (
        <ModalSelectProd
          onClose={() => {
            setOpenModalSelectProd(false);
          }}
          handleProductoSeleccionado={handleProductoSeleccionado}
          requiredTotal={false}
          onlyMateriaPrima
        />
      )}
    </>
  );
};

export default Producto;
