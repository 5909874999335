//#region Importaciones
import React, { useState, useEffect } from "react";
import { Alert, Grid, Menu, MenuItem, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import AccEditar from "./../../../../assets/AccEditar.svg";
import AccEliminar from "./../../../../assets/AccEliminar.svg";
import { Link } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MoreVert from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import Switch from "../../../../../Includes/Switch";
import Loading from "../../../../../Includes/Loading";
import { firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
import { Eliminar, Desactivar } from "../Funciones/ProductosDescuentos";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModalDetalleDescuentosProds from "./ModalDetalleDescuentosProds";

//#endregion

//#region Paginacion
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
//#endregion

const MySwal = withReactContent(Swal);

export default function Tabla({ Lista, IsLoading, setLista, ValueBusqueda }) {
  //#region Variables de estado
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [showModalDetalles, setShowModalDetalles] = useState(false);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();
  const [activoRow, setActivoRow] = useState();
  const [vigencia, setVigencia] = useState("");

  //#endregion

  //#region Handles
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //#endregion

  //#region Funciones
  const EliminarRegistro = (id) => {
    new Swal({
      title: "¿Estás seguro de eliminar este proveedor?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          //eliminarProveedorDeTabla(id);
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarProveedorDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };
  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarProveedorDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };

  const onChange = (checked, id) => {
    setIdRow(id);
    setStateRow(checked);
    ActivarOrDesactivar(id, checked);
  };
  const ActivarOrDesactivar = (id, checked) => {
    /*const ListaFiltrado = Lista.filter((item) => {
      if (item.ID == id) {
        item.ACTIVO = checked;
      }
      return item;
    });
    setLista(ListaFiltrado);
    setSeverity("success");
    setMensaje("Cambios guardados correctamente");
    setOpen(true);*/
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          const ListaFiltrado = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(ListaFiltrado);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    //console.log({ Lista, ValueBusqueda });
    if (ValueBusqueda != "") {
      setPage(0);
    }
  }, [Lista]);
  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      {
        //#region Mensaje
      }
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {
        //#endregion
      }
      {showModalDetalles ? (
        <ModalDetalleDescuentosProds
          id={idRow}
          setShowModal={setShowModalDetalles}
          vigencia={vigencia}
        />
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        {
          //#region Menu Responsivo pantalla
        }
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          {activoRow == 1 ? (
            <>
              <MenuItem>
                {"Ver Detalles"}
                <IconButton
                  onClick={() => {
                    setShowModalDetalles(!showModalDetalles);
                  }}
                >
                  <VisibilityOutlinedIcon
                    style={{ background: "", color: "black" }}
                  />
                </IconButton>
              </MenuItem>
              <MenuItem>
                {stateRow ? "Desactivar" : "Activar"}
                <Switch
                  checked={stateRow}
                  onChange={onChange}
                  id={idRow}
                  tituloActivo="Desactivar producto "
                  tituloInactivo="Activar producto"
                  on
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                }}
                component={Link}
                to={"./edit/" + idRow}
              >
                Editar
                <IconButton>
                  <img src={AccEditar} />
                </IconButton>
              </MenuItem>
            </>
          ) : (
            <></>
          )}

          <MenuItem
            onClick={() => {
              handleMenuClose();
              EliminarRegistro(idRow);
            }}
          >
            Eliminar
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
          </MenuItem>
        </Menu>
        {
          //#endregion
        }

        <Table aria-label="custom pagination table">
          {
            //#region Encabezado de tabla
          }
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="center"
              >
                DESCUENTO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="center">
                DESCRIPCIÓN
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="center">
                No.PRODUCTOS
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="center">
                VIGENCIA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="center">
                ESTATUS
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>
          {
            //#endregion
          }

          {
            //#region Cuerpo de tabla
          }
          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={7}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="left"
                  >
                    {row.FECHA}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {(row.TIPO_DE_DESCUENTO == 1 ? "% " : "$ ") + row.CANTIDAD}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className=""
                    align="center"
                  >
                    {row.OBSERVACIONES}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className=""
                    align="center"
                  >
                    {row.PRODUCTOS}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                    align="center"
                  >
                    {row.FECHA_DE_CADUCIDAD}
                  </TableCell>
                  <TableCell align="center">
                    <span
                      className={
                        row.VIGENTE == 1
                          ? "badge rounded-pill bg-success"
                          : "badge rounded-pill bg-danger"
                      }
                    >
                      {row.VIGENTE == 1 ? "Vigente" : "Vencido"}
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      {row.VIGENTE == 1 ? (
                        <>
                          <Switch
                            checked={row.ACTIVO}
                            onChange={onChange}
                            id={row.ID}
                            tituloActivo="Desactivar proveedor "
                            tituloInactivo="Activar proveedor"
                            on
                          />
                          <Tooltip title="Ver detalles" placement="top">
                            <IconButton
                              onClick={() => {
                                setIdRow(row.ID);
                                setVigencia(row.FECHA_DE_CADUCIDAD);
                                setShowModalDetalles(!showModalDetalles);
                              }}
                            >
                              <VisibilityOutlinedIcon
                                style={{ background: "", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Link
                            to={"./edit/" + row.ID}
                            style={{ textDecoration: "none" }}
                          >
                            <Tooltip title="Editar" placement="top">
                              <IconButton>
                                <img src={AccEditar} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                      <Tooltip title="Eliminar" placement="top">
                        <IconButton onClick={() => EliminarRegistro(row.ID)}>
                          <img src={AccEliminar} />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                          setVigencia(row.FECHA_DE_CADUCIDAD);
                          setActivoRow(row.VIGENTE);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          )}
          {
            //#endregion
          }

          {
            //#region Pie de tabla (Paginacion)
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
          {
            //#endregion
          }
        </Table>
      </TableContainer>
    </>
  );
}
