import React, { useState, useEffect, useRef } from "react";
import TablePedidos from "./Tabla";
import {
  CambiarTerminado,
  asignarRepartidorPedido,
  cambiarEstatusPedido,
  getColoresEstatus,
  getLista,
  getOpcionesRepartidor,
  tomarPedido,
} from "../Funciones/Pedidos";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { BasicDatePicker } from "../../../includes/DatePicker";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import { Alert, Button, Snackbar, Tooltip } from "@mui/material";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import Cuadricula from "./Cuadricula";
import AsignarFlorista from "./Dialogos/AsignarFlorista";
import ModalDetalleCompra from "./Dialogos/ModalDetalleCompra";
import ModalEstatusCompra from "./Dialogos/ModalEstatusCompra";
import ModalRepartidor from "./Dialogos/ModalRepartidor";

import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalTicket from "./Dialogos/ModalTicket";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ModalComprobante from "./Dialogos/ModalComprobante";
import audioFile from "../../../assets/notificacion.mp3";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getMachineId } from "../../../../Lib/Funciones Generales/Generales";
import BACKEND_URL from "../../../../Context/backend";
import ModalFormaPago from "./Dialogos/ModalFormasPago";
import ModalCargarImagen from "../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import { IMG } from "../../../../Lib/Funciones Generales/Constantes";
import ModalComprobanteCargar from "./Dialogos/ModalComprobanteCargar";
import ModalComprobanteLista from "./Dialogos/ModalComprobanteLista";
import ModalVerComprobante from "./Dialogos/ModalComprobante";
import ModalFacturacion from "./Dialogos/ModalFacturacion";
import ModalEditarPedido from "./Dialogos/ModalEditarPedido";
import { ModalPedidoTerminado } from "./ModalPedidoTerminado";
import { SearchOffOutlined, SearchOutlined } from "@mui/icons-material";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ESTATUS = [1, 2, 3, 4, 5, 0, -1];
const Empleados = () => {
  //#region Variables para usar el socket y las notificaciones

  let audio = new Audio(audioFile);
  const [connection, setConnection] = useState(null);
  //#endregion

  //#region State Vars
  const [ValueSelect, setValueSelect] = useState("1");
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [order, setOrder] = useState(1);
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [estado, setEstado] = React.useState(0);
  const [estadoVistaLista, setEstadoVistaLista] = React.useState(1);
  const [pedidoSeleccionado, setPedidoSeleccionado] = useState(0);
  const [showDilogDetalle, setShowDilogDetalle] = useState(false);
  const [showDiloglEstatutus, setShowDiloglEstatutus] = useState(false);
  const [showDiloglImprimir, setShowDiloglImprimir] = useState(false);
  const [showDialogAsignarFlorista, setShowDialogAsignarFlorista] =
    useState(false);
  const [showDialogAsignarRepartidor, setShowDialogAsignarRepartidor] =
    useState(false);
  const [showDialogComprobante, setShowDialogComprobante] = useState(false);
  const [showDialogFormaPago, setShowDialogFormaPago] = useState(false);
  const [showDialogCargarComprobante, setShowDialogCargarComprobante] =
    useState(false);
  const [comprobanteSeleccionado, setComprobanteSeleccionado] = useState(-1);
  const [showDialogVerComprobante, setShowDialogVerComprobante] =
    useState(false);
  const [showDialoModalEditar, setShowDialogoModalEditar] = useState(false);
  const [rangoFecha, setRangoFecha] = useState({
    inicio: GetDate(0),
    fin: GetDate(5),
  });

  const [showDialogFacturacion, setShowDialogFacturacion] = useState(false);
  const [showModalTerminado, setShowModal] = useState(false)
  const [folioBusqueda, setFolioBusqueda] = useState("")

  //#endregion

  //#region Handles
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setEstado(newValue);
  };

  const handleChangeFecha = (tipo, fecha) => {
    fecha = ConvertDate(fecha);
    setRangoFecha({ ...rangoFecha, [tipo]: fecha });
  };
  //#endregion

  //#region Funciones
  const AlertaPedido = (pedido) => {
    try {
      audio.play();
    } catch (error) {
      console.log("SONIDO: ", error);
    }

    MySwal.fire({
      title: "¡Nuevo pedido!",
      html: "Se ha creado un nuevo pedido No. " + pedido.FOLIO_VENTA,
      icon: "info",
      confirmButtoColor: "#3ABE88",
      showConfirmButton: false,
      showCancelButton: false,
      position: "top-center",
      timer: 5000,
      timerProgressBar: true,
    }).then((result) => {
      setShowDiloglEstatutus(false);
      setPedidoSeleccionado(0);
      // getListaPedidos()
    });

    getListaPedidos();
  };


  const CambiarEstatusPedido = (id_estatus) => {
    new Swal({
      title: "¿Estás seguro de cambiar el estatus de este pedido?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        cambiarEstatusPedido(pedidoSeleccionado, id_estatus)
          .then((data) => {
            if (data.codigo == "200") {
              getListaPedidos();
              MySwal.fire({
                title: "Correcto",
                html: data.respuesta.register,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                setShowDiloglEstatutus(false);
                setPedidoSeleccionado(0);
                // getListaPedidos()
              });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            // alert(data.mensaje);
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  };

  const CambiarTerminadoPedido = (img) => {
    new Swal({
      title: "¿Estás seguro de cambiar el estatus de este pedido?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        CambiarTerminado(pedidoSeleccionado, img)
          .then((data) => {
            if (data.codigo == "200") {
              getListaPedidos();
              MySwal.fire({
                title: "Correcto",
                html: data.respuesta.register,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                setShowDiloglEstatutus(false);
                setPedidoSeleccionado(0);
                // getListaPedidos()
              });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            // alert(data.mensaje);
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  }

  const getListaPedidos = () => {
    if (estado == 6 && !folioBusqueda) {
      setLista([]);
      return;
    }
    setIsLoading(true);
    getLista(
      0,
      0,
      order,
      ValueBusqueda,
      rangoFecha.inicio,
      rangoFecha.fin,
      estado == 6 ? folioBusqueda : undefined,
      ESTATUS[estado]
    )
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  //#endregion

  //#region Effects
  useEffect(() => {
    if (rangoFecha.inicio <= rangoFecha.fin) {
      getListaPedidos();
    }
  }, [rangoFecha, order]);

  useEffect(() => {
    document.title = `Monitor de pedidos`;
  }, []);

  useEffect(() => {
    if (!IsLoading) {
      getListaPedidos();
    }
  }, [estado, folioBusqueda]);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(BACKEND_URL.replace("/api", "") + "notificaciones")
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    setConnection(connection);

    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on("Online", () => {
            console.log("EN LINEA");
          });
          connection.on("Offline", () => {
            console.log("FUERA DE LINEA");
          });
          connection.on("RecibirNotificacionVenta", (pedido) => {
            pedido = JSON.parse(pedido);
            AlertaPedido(pedido);
          });
          connection.invoke("ConectarAdmin", getMachineId());
        })
        .catch((e) => console.log("ERROR DE CONEXION: ", e));
    }
    return () => {
      if (connection) {
        connection.invoke("Desconectar", getMachineId());
        // connection.stop();
      }
    };
  }, []);

  //#endregion

  const handleClose = () => {
    setShowModal(false);

  }
  const handleImage = (img) => {
    return new Promise((resolve,reject)=>{
      CambiarTerminado(pedidoSeleccionado, img)
      .then((data) => {
        if (data.codigo == "200") {
          getListaPedidos();
          MySwal.fire({
            title: "Correcto",
            html: data.respuesta.register,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setShowDiloglEstatutus(false);
            setShowModal(false);
            setPedidoSeleccionado(0);
            // getListaPedidos()
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
        }
        resolve();
      })
      .catch((data) => {
        // alert(data.mensaje);
        reject(data);
        setMensaje(data.mensaje);
        setOpen(true);
      });
    })
  }

  const handleTerminado = () => {
    setShowModal(true)
  }
  return (
    <>

      <div className={"d-flex flex-wrap justify-content-between p-0 pt-0 "+ (estado==6?" mb-1":" mb-4")}>
        <div className="d-flex mt-auto" style={{ maxWidth: 330 }}>
          <div className="px-2">
            <BasicDatePicker
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de inicio"
              fecha={rangoFecha.inicio}
              setFecha={(fecha) => {
                handleChangeFecha("inicio", fecha);
              }}
            />
          </div>
          <div className="px-2">
            <BasicDatePicker
              fullWidth
              size="small"
              format="DD/MM/YYYY"
              label="Fecha de fin"
              fecha={rangoFecha.fin}
              setFecha={(fecha) => {
                handleChangeFecha("fin", fecha);
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center flex-grow-1">
          <div className="d-flex align-items-center">
            <Tabs
              value={estado}
              onChange={handleChange}
              aria-label="Estados de los pedidos"
              textColor="primary"
              indicatorColor="primary"
            >
              <Tooltip title="Mostrar solo recibidos" placement="top">
                <Tab
                  icon={
                    <MarkunreadMailboxOutlinedIcon style={{ fontSize: "17px" }} />
                  }
                  aria-label="Recibido"
                  label={<span style={{ fontSize: "10px" }}>Recibidos</span>}
                />
              </Tooltip>

              <Tooltip
                title="Mostrar solo los pedidos en proceso"
                placement="top"
                label="En proceso"
              >
                <Tab
                  icon={<AccessTimeIcon style={{ fontSize: "17px" }} />}
                  label={<span style={{ fontSize: "10px" }}>En proceso</span>}
                />
              </Tooltip>

              <Tooltip title="Mostrar solo terminados" placement="top">
                <Tab
                  icon={
                    <CheckCircleOutlineOutlinedIcon
                      style={{ fontSize: "17px" }}
                    />
                  }
                  aria-label="Terminado"
                  label={<span style={{ fontSize: "10px" }}>Terminados</span>}
                />
              </Tooltip>

              <Tooltip title="Mostrar solo pedios en ruta" placement="top">
                <Tab
                  icon={
                    <DeliveryDiningOutlinedIcon style={{ fontSize: "17px" }} />
                  }
                  label={<span style={{ fontSize: "10px" }}>En ruta</span>}
                />
              </Tooltip>

              <Tooltip title="Mostrar solo pedidos entregados" placement="top">
                <Tab
                  icon={<NoCrashOutlinedIcon style={{ fontSize: "17px" }} />}
                  aria-label="Entregados"
                  label={<span style={{ fontSize: "10px" }}>Entregados</span>}
                />
              </Tooltip>

              <Tooltip title="Mostrar solo cancelados" placement="top">
                <Tab
                  icon={<HighlightOffIcon style={{ fontSize: "17px" }} />}
                  aria-label="Cancelado"
                  label={<span style={{ fontSize: "10px" }}>Cancelados</span>}
                />
              </Tooltip>
              <Tooltip title="Buscar pedidos por folio" placement="top">
                <Tab
                  icon={<SearchOutlined style={{ fontSize: "17px" }} />}
                  aria-label="Buscar"
                  label={<span style={{ fontSize: "10px" }}>Buscar</span>}
                />
              </Tooltip>
              {/* <code>
          <pre>{JSON.stringify(estado, null, 2)}</pre>
        </code> */}
            </Tabs>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-column">
          <div className="d-flex">
            <div
              className="d-flex align-self-center me-2"
              onClick={() => {
                setOrder(order == 1 ? 2 : 1);
              }}
              style={{ cursor: "pointer" }}
            >
              <ShortTextIcon /> {order == 1 ? "Por fecha" : "Por folio"}
            </div>
            <ToggleButtonGroup>
              <ToggleButton onClick={() => setEstadoVistaLista(0)}>
                <ViewListIcon
                  style={estadoVistaLista == 0 ? { color: "#2A85DF" } : {}}
                />
              </ToggleButton>
              <ToggleButton onClick={() => setEstadoVistaLista(1)}>
                <ViewModuleIcon
                  style={estadoVistaLista == 1 ? { color: "#2A85DF" } : {}}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>

      {/*  Tipo de vista */}

      {estadoVistaLista == 1 && (
        <Cuadricula
          getListaPedidos={getListaPedidos}
          Lista={Lista ?? []}
          IsLoading={IsLoading}
          setLista={setLista}
          ValueBusqueda={ValueBusqueda}
          setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDilogDetalle={setShowDilogDetalle}
          setShowDiloglEstatutus={setShowDiloglEstatutus}
          setShowDiloglImprimir={setShowDiloglImprimir}
          setShowDialogAsignarRepartidor={setShowDialogAsignarRepartidor}
          setShowDialogoModalEditar={setShowDialogoModalEditar}
          estado={estado}
          setFolio={setFolioBusqueda}
          folio={folioBusqueda}
        />
      )}

      {estadoVistaLista == 0 && (
        <TablePedidos
          getListaPedidos={getListaPedidos}
          Lista={Lista ?? []}
          IsLoading={IsLoading}
          setLista={setLista}
          ValueBusqueda={ValueBusqueda}
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDilogDetalle={setShowDilogDetalle}
          setShowDiloglEstatutus={setShowDiloglEstatutus}
          setShowDiloglImprimir={setShowDiloglImprimir}
          setShowDialogAsignarRepartidor={setShowDialogAsignarRepartidor}
          setShowDialogComprobante={setShowDialogComprobante}
          setShowDialogFacturacion={setShowDialogFacturacion}
          setShowDialogoModalEditar={setShowDialogoModalEditar}
          estado={estado}
          setFolio={setFolioBusqueda}
          folio={folioBusqueda}
        />
      )}

      {
        //#region Dialogos
      }

      {showDialogFacturacion && (
        <ModalFacturacion
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogFacturacion={setShowDialogFacturacion}
          getListaPedidos={getListaPedidos}
        />
      )}

      {showDialogComprobante && (
        <ModalComprobante
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogComprobante={setShowDialogComprobante}
        />
      )}

      {showDilogDetalle && (
        <ModalDetalleCompra
          setComprobanteSeleccionado={setComprobanteSeleccionado}
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogFormaPago={setShowDialogFormaPago}
          setShowDialogComprobante={setShowDialogComprobante}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDilogDetalle={setShowDilogDetalle}
          setShowDiloglImprimir={setShowDiloglImprimir}
          setShowDialogCargarComprobante={setShowDialogCargarComprobante}
        />
      )}

      {showDiloglEstatutus && (
        <ModalEstatusCompra
          setShowDiloglEstatutus={setShowDiloglEstatutus}
          CambiarEstatusPedido={CambiarEstatusPedido}
          pedidoSeleccionado={pedidoSeleccionado}
          setPedidoSeleccionado={setPedidoSeleccionado}
          CambiarTerminado={CambiarTerminadoPedido}
          handleTerminado={handleTerminado}
        />
      )}
      {showDiloglEstatutus && showModalTerminado && (
        <ModalPedidoTerminado
          handleLoad={handleImage}
          id={pedidoSeleccionado}
          handleClose={handleClose}
          showModal={showModalTerminado}
        />
      )}

      {showDiloglImprimir && (
        <ModalTicket
          pedidoSeleccionado={pedidoSeleccionado}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDiloglImprimir={setShowDiloglImprimir}
        />
      )}

      {showDialogAsignarRepartidor && (
        <ModalRepartidor
          pedidoSeleccionado={pedidoSeleccionado}
          getListaPedidos={getListaPedidos}
          setShowDialogAsignarRepartidor={setShowDialogAsignarRepartidor}
        />
      )}

      {showDialogAsignarFlorista && (
        <AsignarFlorista
          pedidoSeleccionado={pedidoSeleccionado}
          getListaPedidos={getListaPedidos}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
        />
      )}

      {showDialogFormaPago && (
        <ModalFormaPago
          pedidoSeleccionado={pedidoSeleccionado}
          getListaPedidos={getListaPedidos}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDialogFormaPago={setShowDialogFormaPago}
          setShowDiloglImprimir={setShowDiloglImprimir}
        />
      )}

      {showDialogComprobante && (
        <ModalComprobanteLista
          setShowDialogVerComprobante={setShowDialogVerComprobante}
          pedidoSeleccionado={pedidoSeleccionado}
          setShowDialogComprobante={setShowDialogComprobante}
          setComprobanteSeleccionado={setComprobanteSeleccionado}
          setShowDialogCargarComprobante={setShowDialogCargarComprobante}
        />
      )}

      {showDialogCargarComprobante && comprobanteSeleccionado != -1 && (
        <ModalComprobanteCargar
          pedidoSeleccionado={pedidoSeleccionado}
          comprobanteSeleccionado={comprobanteSeleccionado}
          setComprobanteSeleccionado={setComprobanteSeleccionado}
          setShowDialogCargarComprobante={setShowDialogCargarComprobante}
        />
      )}

      {showDialogVerComprobante && comprobanteSeleccionado != -1 && (
        <ModalVerComprobante
          comprobanteSeleccionado={comprobanteSeleccionado}
          setShowDialogVerComprobante={setShowDialogVerComprobante}
        />
      )}

      {showDialoModalEditar && (
        <ModalEditarPedido
          setShowDialogoModalEditar={setShowDialogoModalEditar}
          CambiarEstatusPedido={CambiarEstatusPedido}
          pedidoSeleccionado={pedidoSeleccionado}
          setPedidoSeleccionado={setPedidoSeleccionado}
          setShowDiloglImprimir={setShowDiloglImprimir}
        />
      )}

      {
        //#endregion
      }

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>
    </>
  );
};

export default Empleados;
