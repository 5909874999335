import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  DialogActions
} from "@mui/material";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { Col, Row } from "react-bootstrap";



export default function ModalProcesar({
  onClose = () => {},
  titulo = "Productos para autorizar",
  id = 0,
  getProductos = async () => {},
  save = () => {},
}) {
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [desactivado, setDesactivado] = useState(true);
  const [Observaciones, setObservacion] = useState('');
  const [valor, setValor] = useState('');
  const [ValueFechaEntrega, setValueFechaEntrega] = useState(GetDate());

  const handleChange = (event) => {
    //console.log("ID: "+event.target.value)
    setObservacion(event.target.value)


  };

  const fechaCompletaEntrega = new Date(ValueFechaEntrega);
  const offsetMinutosEntrega= fechaCompletaEntrega.getTimezoneOffset();
  fechaCompletaEntrega.setMinutes(
    fechaCompletaEntrega.getMinutes() + offsetMinutosEntrega
  );
  const diaInicio = fechaCompletaEntrega.getDate();
  const mesInicio = fechaCompletaEntrega.getMonth() + 1;
  const anioInicio = fechaCompletaEntrega.getFullYear();
  const fechaEntrega = `${mesInicio < 10 ? "0" : ""}${mesInicio}/${
    diaInicio < 10 ? "0" : ""
  }${diaInicio}/${anioInicio}`;

  


  //console.log("ID: "+Observaciones);
  useEffect(() => {
    getProductos(id)
      .then((data) => {
        console.log("Data de modal [" + id + "]: ", data);
        //const d = JSON.parse(data.data);
        setProductosDisponibles(data.productos);
        setDesactivado(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">{titulo}</h6>
        </DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Descripcion
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Precio
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Cantidad
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productosDisponibles
                  ? productosDisponibles.map((Productos) => (
                      <>
                        <TableRow key={Productos.ID}>
                          <TableCell component="th" scope="row" className="">
                            {firstLeterMayus(Productos.DESCRIPCION_PRODUCTO)}
                          </TableCell>
                          <TableCell component="th" scope="row" className="">
                            {formatMoneda(Productos.PRECIO)}
                          </TableCell>
                          <TableCell component="th" scope="row" className="">
                            {Productos.CANTIDAD}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>



        
        </DialogContent>

        <div>

                    
        <Row className="mx-2">

             <Col sm={12} md={4} className="p-3">
                <BasicDatePicker
                  label={"Entrega"}
                  fecha={ValueFechaEntrega}
                  setFecha={setValueFechaEntrega}
                  format="DD/MM/YYYY"
                  size="small"
                />
           
           </Col>

              <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        multiline
                        name="observaciones"
                        label="Observaciones"
                        value={Observaciones}
                        variant="outlined"
                        inputProps={{ maxLength: 500 }}
                        onChange={handleChange}
                    
                      />
               </Col>

  
        </Row>

        </div>
                    



        <DialogActions>
          <Button
            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 mx-2 my-2"
            variant="contained"
            disabled={desactivado}
            onClick={() => {
              save(id, Observaciones, fechaEntrega);
              //onClose();
            }}
          >
            Autorizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
