import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CheckIcon from "@mui/icons-material/Check";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  CambiarEmpresa,
  getOpcionesEmpresas,
} from "../Components/Configuraciones/Empresas/Funciones/Empresas";
import {
  getLocalStorage,
  setLocalStorage,
  setLocalStorageJWT,
} from "../../Context/Storage";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
const MySwal = withReactContent(Swal);

const CambioDeEmpresa = ({ Values, IsLoading, permisosMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [empresas, getEmpresas] = useState([]);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [nombreEmpresa, setNombreEmpresa] = useState("Cargando...");
  const [logo, setLogo] = useState("");
  const [empresasCargadas, setEmpresasCargadas] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getOpcionesEmpresas()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          /*const newArr = empresas.filter((row) => {
            if (
              (row.ID === 1 && !Values.empresa_1) ||
              (row.ID === 2 && !Values.empresa_2)
            ) {
              return false;
            }
            return true;
          });*/
          setEmpresasCargadas(true);
          getEmpresas(list);
          //getEmpresas(newArr);
        } else {
          getEmpresas([]);
        }
      })
      .catch((resp) => {
        getEmpresas([]);
      });
  }, []);

  const clicCambioEmpresa = (key) => {
    setIsGuardando(true);
    CambiarEmpresa(key)
      .then((data) => {
        if (data.codigo == "200") {
          setLocalStorage("key", data.respuesta.key);

          //console.log(getLocalStorage("JWT"));
          //console.log(data.respuesta.token);
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          setLocalStorage("tipo", data.respuesta.tipo);
          setLocalStorage("nombre_empresa", data.respuesta.nombre);

          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: "El cambio de empresa se realizó con éxito",
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1200,
          }).then((result) => {
            window.location.reload();
          });
        } else {
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setIsGuardando(false);
        MySwal.fire({
          title: "Error",
          html: "Ha ocurrido un error al relizar el cambio de empresa",
          icon: "error",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: false,
          timer: 1200,
        }).then((result) => {});
      });
  };

  useEffect(() => {
    //console.log(empresas);
    if (empresasCargadas) {
      if (Values.empresa_1 && Values.empresa_2) {
        //console.log("entro al if");
        try {
          let Ar = empresas.find((emp) => emp.KEY == getLocalStorage("key"));
          if (empresas.length > 0) {
            setNombreEmpresa(Ar.NOMBRE);
            setLogo(Ar.IMG);
          }
        } catch (error) {}
      } else {
        //console.log("entro al else");
        const newArr = empresas.filter((row) => {
          if (
            (row.ID === 1 && !Values.empresa_1) ||
            (row.ID === 2 && !Values.empresa_2)
          ) {
            return false;
          }
          return true;
        });

        if (newArr.length > 0) {
          setNombreEmpresa(newArr[0].NOMBRE);
          setLogo(newArr[0].IMG);
        }
        //console.log(newArr);
      }
    }
    console.log("...");
  }, [empresas, IsLoading]);

  return (
    <React.Fragment>
      <Tooltip title="Configuración de la empresa">
        <Button className="btn-empresa me-2" onClick={handleClick}>
          <div className="d-flex justify-content-star align-content-center align-items-center">
            <div>
              {logo == "" ? (
                <Avatar
                  style={{ backgroundColor: "#caa396" }}
                  sx={{ width: 25, height: 25 }}
                >
                  <StorefrontIcon className="p-1" />
                </Avatar>
              ) : (
                <Avatar
                  src={logo}
                  className="border"
                  style={{ backgroundColor: "white" }}
                  sx={{ width: 25, height: 25 }}
                ></Avatar>
              )}
            </div>

            <div style={{ MaxWidth: "120px" }}>
              <h6
                className="font-Avenir my-0 ms-1 text-truncate"
                style={{ fontSize: "13px" }}
              >
                {nombreEmpresa}
              </h6>
            </div>
          </div>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Values.empresa_1 && Values.empresa_2 ? open : false}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="text-center my-3 mx-3 ">
          <h6 className="font-AvenirBold" style={{ fontSize: "13px" }}>
            Selecciona una empresa
          </h6>
        </div>
        <Divider className="mb-2" light />
        <Link style={{ textDecoration: "none", color: "black" }}>
          {empresas.map((row, i) => {
            return (
              <MenuItem onClick={() => clicCambioEmpresa(row.KEY)}>
                <ListItemIcon>
                  {row.IMG == "" ? (
                    <Avatar className="border">
                      <StorefrontIcon className="p-1" />
                    </Avatar>
                  ) : (
                    <Avatar className="border" src={row.IMG}></Avatar>
                  )}
                </ListItemIcon>
                <ListItemText> {row.NOMBRE}</ListItemText>
                {IsGuardando ? (
                  <HourglassEmptyIcon
                    className="ms-3 text-success"
                    style={{ width: "15px" }}
                  />
                ) : row.KEY == getLocalStorage("key") ? (
                  <CheckIcon
                    className="ms-3 text-success"
                    style={{ width: "15px" }}
                  />
                ) : (
                  <></>
                )}
              </MenuItem>
            );
          })}
        </Link>
      </Menu>
    </React.Fragment>
  );
};

export default CambioDeEmpresa;
