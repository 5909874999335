import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  console.log(queryParamsObj);
  const url = `administradores/managers/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesFlorista = async () => {
  let data = [];
  let queryParamsObj = {};
  const url = `administradores/managers/ListarAdminsFloristas?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoAdministrador = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `administradores/managers/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          apellido_paterno: String(data.APELLIDO_PATERNO),
          apellido_materno: String(data.APELLIDO_MATERNO),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          id_grupo: String(data.ID_GRUPO_ACCESO),
          id_puesto: String(data.ID_PUESTO),
          empresas_opcion: {
            emp1: data.EMPRESA_1,
            emp2: data.EMPRESA_2,
          },
          img: String(data.IMG) == "" ? imgDefault : String(data.IMG),
          supervisor: data.SUPERVISOR == 0 ? false : true
        };
        console.log("Usuario Sitema", Values);
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;

    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    let Errores2 = Errores;

    Errores2.apellido_paterno = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    let Errores2 = Errores;

    Errores2.apellido_paterno = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }
  if (!Values.id_grupo || Values.id_grupo == "" || Values.id_grupo == "0") {
    Errores.id_grupo = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.id_puesto || Values.id_puesto == "" || Values.id_puesto == "0") {
    Errores.id_puesto = true;
    setErrores(Errores);
    error = true;
  }
  if (Values.empresas_opcion.length === 0) {
    Errores.empresas_opcion = true;
    setErrores(Errores);
    error = true;
  }
  Values.id_grupo = String(Values.id_grupo);
  Values.id_puesto = String(Values.id_puesto);

  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    } else {
      Values.img = "";
    }

    if (
      Values.id == "0" ||
      Values.id == 0 ||
      (Values.id == "" && Values.img != "")
    ) {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_EMPLEADO,
        img64: Values.img,
      };
      Values.img = "";
    }

    let data = [];
    const body = Values;
    console.log("Datos", body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "administradores/managers/Insertar"
        : "administradores/managers/Editar";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `administradores/managers/Eliminar?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `administradores/managers/Desactivar?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const enviarAcceso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `administradores/managers/EnviarAcceso?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
