import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const View = ({
  setShowDilogDetalle,
  setPedidoSeleccionado,
  id,
}) => {
  return (
    <>
       <Tooltip title="Ver detalle" placement="top" className="me-2">
       <IconButton
         onClick={(event) => {
          event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDilogDetalle(true);
          }}
        >
          <RemoveRedEyeIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Tooltip>

    </>
  );
};

export default View;
