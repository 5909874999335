import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";
import { json } from "react-router-dom";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (
  iTake,
  iSkip,
  fechaInicio,
  fechaFin,
  Sucursal
) => {

  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
    Sucursal: Sucursal
  };

  const url = `cortes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getInfoCorte= async (Sucursal) => {
   console.log("id"+Sucursal)
   
    let data = [];
    let queryParamsObj = {
      Sucursal: Sucursal,
    };

    const url = `cortes/ConsultarInfo?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data);
        if (data.length == 0) {
          resolve({
            err: [],
            mensaje: "No se encontró información para corte",
          });
        } else {
          data = JSON.parse(res.data.respuesta.data)[0];
         
          let d = {
            values: {
              fTotalTarjeta: Number(data.TOTAL_TARJETA),
              fTotalEfectivo: Number(data.TOTAL_EFECTIVO),
              fTotalMonedero: Number(data.TOTAL_MONEDERO),
              fTotalTransferencia: Number(data.TRANSFERENCIA),
              fTotalDeposito: Number(data.DEPOSITO),
              fTotalPagoEnTienda: Number(data.PAGO_EN_TIENDA),
              fTotalCompras: Number(data.TOTAL_COMPRAS),
              fTotal: Number(data.TOTAL),
              observaciones: data.OBSERVACIONES,
              fTotalTarjetaEntregado: 0,
              fTotalEfectivoEntregado: 0,
              fTotalMonederoEntregado: 0,
              fTotalTransferenciaEntregado: 0,
              fTotalDepositoEntregado: 0,
              fTotalPagoEnTiendaEntregado: 0,
            },
            detalle: JSON.parse(res.data.respuesta.venta),
          };
          resolve(d);
         
        }
      } else {
        reject({
          mensaje: "Error al conectar a internet, revisa tu conexion a internet",
        });
      }
    });
  };



  export const GuardarCorte = async (
    Values,
    Errores,
    setErrores,
    detalle,
    id_sucursal
  ) => {
    let error = false;

    //console.log(JSON.stringify(Values.faltante))


    if(Values.fTotalTarjeta !== 0){
      if(Values.fTotalTarjetaEntregado === 0){
        Errores.fTotalTarjetaEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }



    if(Values.fTotalEfectivo !== 0){
      if(Values.fTotalEfectivoEntregado === 0){
        Errores.fTotalEfectivoEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }


    
    if(Values.fTotalMonedero !== 0){
      if(Values.fTotalMonederoEntregado === 0){
        Errores.fTotalMonederoEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }



    if(Values.fTotalTransferencia !== 0){
      if(Values.fTotalTransferenciaEntregado === 0){
        Errores.fTotalTransferenciaEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }

    if(Values.fTotalDeposito !== 0){
      if(Values.fTotalDepositoEntregado === 0){
        Errores.fTotalDepositoEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }

    if(Values.fTotalPagoEnTienda !== 0){
      if(Values.fTotalPagoEnTiendaEntregado === 0){
        Errores.fTotalPagoEnTiendaEntregado = true;
        setErrores(Errores);
        error = true;
      }
    }


    if(Values.faltante !== 0 || Values.faltante > 0){
      if (!Values.observaciones || Values.observaciones === "" || Values.observaciones === null) {
        Errores.observaciones = true;
        setErrores(Errores);
        error = true;
      }

    }
    
  


    Values.fTotalTarjeta = Number(Values.fTotalTarjeta);
    Values.fTotalEfectivo = Number(Values.fTotalEfectivo);
    Values.fTotalMonedero = Number(Values.fTotalMonedero);
    Values.fTotalTransferencia = Number(Values.fTotalTransferencia);
    Values.fTotalDeposito = Number(Values.fTotalDeposito);
    Values.fTotalPagoEnTienda = Number(Values.fTotalPagoEnTienda);

    Values.fTotalTarjetaEntregado = Number(Values.fTotalTarjetaEntregado);
    Values.fTotalEfectivoEntregado = Number(Values.fTotalEfectivoEntregado);
    Values.fTotalMonederoEntregado = Number(Values.fTotalMonederoEntregado);
    Values.fTotalTransferenciaEntregado = Number(Values.fTotalTransferenciaEntregado);
    Values.fTotalDepositoEntregado = Number(Values.fTotalDepositoEntregado);
    Values.fTotalPagoEnTiendaEntregado = Number(Values.fTotalPagoEnTiendaEntregado);

    Values.fTotalCompras = Number(Values.fTotalCompras);
    Values.fTotal = Number(Values.fTotal);
    Values.faltante = Number(Values.faltante);
  
    Values.productos = detalle;
    
    if (!error) {
      const body = {
        fTotalTarjeta: Values.fTotalTarjeta,
        fTotalEfectivo: Values.fTotalEfectivo,
        fTotalMonedero: Values.fTotalMonedero,
        fTotalTransferencia: Values.fTotalTransferencia,
        fTotalDeposito: Values.fTotalDeposito,
        fTotalPagoEnTienda: Values.fTotalPagoEnTienda,

        fTotalTarjetaEntregado: Values.fTotalTarjetaEntregado,
        fTotalEfectivoEntregado: Values.fTotalEfectivoEntregado,
        fTotalMonederoEntregado: Values.fTotalMonederoEntregado,
        fTotalTransferenciaEntregado: Values.fTotalTransferenciaEntregado,
        fTotalDepositoEntregado: Values.fTotalDepositoEntregado,
        fTotalPagoEnTiendaEntregado: Values.fTotalPagoEnTiendaEntregado,
        
        fTotalCompras: Values.fTotalCompras,
        fTotal: Values.fTotal,
        observaciones: Values.observaciones,
        detalle: [...detalle],
        id_sucursal: id_sucursal
      };
       
      let data = [];
      const url = "cortes/Insertar";
      const res = await postdData(url, body);
  
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  };


  export const getDetalleCorte= async (Sucursal, Folio) => {

     let data = [];
     let queryParamsObj = {
       Sucursal: Sucursal,
       Folio: Folio
     };
 
     const url = `cortes/ConsultarCorte?${stringify(queryParamsObj)}`;
     const res = await getData(url);
     return new Promise((resolve, reject) => {
       if (!res.error) {
         data = JSON.parse(res.data.respuesta.data);
         if (data.length == 0) {
           resolve({
             err: [],
             mensaje: "No se encontró información para corte",
           });
         } else {
           data = JSON.parse(res.data.respuesta.data)[0];

           let d = {
             values: {
               fTotalTarjeta: Number(data.TARJETA),
               fTotalEfectivo: Number(data.EFECTIVO),
               fTotalMonedero: Number(data.MONEDERO),
               fTotalTransferencia: Number(data.TRANSFERENCIA),
               fTotalDeposito: Number(data.DEPOSITO),
               fTotalPagoEnTienda: Number(data.PAGO_EN_TIENDA),
               TARJETA_ENTREGADO: Number(data.TARJETA_ENTREGADO),
               EFECTIVO_ENTREGADO: Number(data.EFECTIVO_ENTREGADO),
               MONEDERO_ENTREGADO: Number(data.MONEDERO_ENTREGADO),
               TRANSFERENCIA_ENTREGADO: Number(data.TRANSFERENCIA_ENTREGADO),
               DEPOSITO_ENTREGADO: Number(data.DEPOSITO_ENTREGADO),
               PAGO_EN_TIENDA_ENTREGADO: Number(data.PAGO_EN_TIENDA_ENTREGADO),
               fTotalCompras: Number(data.NO_VENTAS),
               fTotal: Number(data.TOTAL),
               FOLIO : Number(data.FOLIO_CORTE),
               observaciones: data.OBSERVACIONES,
               IMG_EMPRESA: String(data.IMG_EMPRESA),
               NOMBRE_EMPRESA : String(data.NOMBRE_EMPRESA),
               FECHA: String(data.FECHA),
               FECHA_INICIO: String(data.FECHA_INICIO),
               FECHA_FIN: String(data.FECHA_FIN),
               HORA_INICIO: String(data.HORA_INICIO),
               HORA_FIN: String(data.HORA_FIN),
               USUARIO: String(data.USUARIO)
             },
              
           };
           console.log(d);
           resolve(d);
          
         }
       } else {
         reject({
           mensaje: "Error al conectar a internet, revisa tu conexión a internet",
         });
       }
     });
   };



   export const getFolio= async () => {

    let data = [];


    const url = `cortes/ConsultarFolio`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data);
        if (data.length == 0) {
          resolve({
            err: [],
            mensaje: "No se encontró información para corte",
          });
        } else {
          data = JSON.parse(res.data.respuesta.data)[0];

          let d = {
            values: {
              FOLIO_CORTE: Number(data.FOLIO_CORTE)
            },

          };
          resolve(d);

 
         
        }
      } else {
        reject({
          mensaje: "Error al conectar a internet, revisa tu conexión a internet",
        });
      }
    });
  };