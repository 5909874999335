import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  DialogContent,
  Button,
  TextField,
  Card,
  Avatar,
  Grid,
  Typography,
  Snackbar,
  Box,
  Tabs,
  Tab,
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Efectivo from "./../../../assets/Efectivo.svg";
import Monedero from "./../../../assets/Monedero.svg";
import Otros from "./../../../assets/Otro.svg";
import Tarjeta from "./../../../assets/Tarjeta.svg";
import Pago5Deposito from "./../../../assets/Pago5Deposito.svg";
import Pago6Tiendas from "./../../../assets/Pago6Tiendas.svg";


import Cita from "./../../../assets/Cita.svg";
import Carrito from "./Carrito";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "../../../includes/Switch";
import { LoadingButton } from "@mui/lab";
///import { pagarReservacion } from "../Funciones/Reservaciones";
import { styled } from "@mui/material/styles";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";
import { Label } from "@mui/icons-material";
import ModalSelectProd from "../../Catalogos/Productos/Componentes/Dialogos/ModalSelectProd";
import TablaProdAcoreon from "./TablaProdAcoreon";

const pagarReservacion = () => {
  console.log("reservacion pagada");
};

const MySwal = withReactContent(Swal);
export default function ModalPagoVenta({
  setShowModalPago = () => {},
  totalCarrito = 0,
  Values = {},
  setValues = () => {},
  handlInputChange = () => {},
  Errores = {},
  Guardar = () => {},
  IsGuardando,
  descuento = { descuento: 0, tipo: 0, max: 0 },
  costoEnvio = 0,
  productosCarrito = [],
  setProductosCarrito,
  IVA,
}) {
  const efectivoRef = useRef(null);
  const tarjetaRef = useRef(null);
  const monederoRef = useRef(null);
  const transferenciaRef = useRef(null);
  const depositoRef = useRef(null);
  const pago_En_TiendaRef = useRef(null);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [tab, setTab] = React.useState(0);
 
  const [IsLoading, setIsLoading] = useState(false);

 
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [formasPago, setFormasPago] = useState({
    efectivo: { active: false, monto: 0 },
    tarjeta: { active: false, monto: 0 },
    monedero: { active: false, monto: 0 },
    otros: { active: false, monto: 0 },
    deposito: { active: false, monto: 0 },
    pago_En_Tienda: { active: false, monto: 0 },
  });

  let cambio = 0;


  let Total = 0;
  {
    productosCarrito.length > 0
      ? productosCarrito.map((row) => {
          Total += parseFloat(row.PRECIO) * row.CANTIDAD;
          row.DESCUENTO = 0;
          row.TIPO_DESCUENTO = 0;
        })
      : (Total = 0);
  }

  const Valor_IVA = 16; // IVA del producto
  const IVA_FRACCION = Valor_IVA / 100 + 1;
  const RestaIVA = Total / IVA_FRACCION;
  const valorCalculado = IVA === true ? Total : RestaIVA.toFixed(2);
  const TOTAL_IVA = Total - RestaIVA;
  // const valorCalculadoIVA = IVA ? TOTAL_IVA : TOTAL_IVA;
  const SubtotalIVA = IVA == false ? Total : RestaIVA;
  const SUMRES = IVA ? "+" : "-";

  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
      ? (valorCalculado * descuento.descuento) / 100 > descuento.max
        ? descuento.max
        : (valorCalculado * descuento.descuento) / 100
      : descuento.descuento;

  // alert("calculado:" +valorCalculado)

  let efectivo =
    formasPago.efectivo.monto == "" || formasPago.efectivo.monto == undefined
      ? 0
      : parseFloat(formasPago.efectivo.monto);
  let tarjeta =
    formasPago.tarjeta.monto == "" || formasPago.tarjeta.monto == undefined
      ? 0
      : parseFloat(formasPago.tarjeta.monto);
  let monedero =
    formasPago.monedero.monto == "" || formasPago.monedero.monto == undefined
      ? 0
      : parseFloat(formasPago.monedero.monto);
  let otros =
    formasPago.otros.monto == "" || formasPago.otros.monto == undefined
      ? 0
      : parseFloat(formasPago.otros.monto);

      let deposito =
      formasPago.deposito.monto == "" || formasPago.deposito.monto == undefined
        ? 0
        : parseFloat(formasPago.deposito.monto);
        
        let pago_En_Tienda =
    formasPago.pago_En_Tienda.monto == "" || formasPago.pago_En_Tienda.monto == undefined
      ? 0
      : parseFloat(formasPago.pago_En_Tienda.monto);

  let TotalPagado = efectivo + tarjeta + monedero + otros + deposito + pago_En_Tienda;

  if (formasPago.efectivo.monto != 0) {
    cambio = TotalPagado - (valorCalculado - totalDescuento + costoEnvio);
  }
  //alert(TotalPagado +" - "+ ("TOTAL: "+valorCalculado + " TOTAL:DESCUENTO:" +totalDescuento + " ENVIO:" + costoEnvio + " Carrito:"+ JSON.stringify(productosCarrito) + " IVA: " + IVA));

  const resetFormasPago = () => {
    setFormasPago({
      efectivo: { active: false, monto: 0 },
      tarjeta: { active: false, monto: 0 },
      monedero: { active: false, monto: 0 },
      otros: { active: false, monto: 0 },
      deposito: { active: false, monto: 0 },
      pago_En_Tienda: { active: false, monto: 0 },
    });
    const val = {
      tarjeta: 0,
      transferencia: 0,
      efectivo: 0,
      monedero: 0,
      deposito: 0,
      pago_En_Tienda: 0
    };
    handlInputChange({
      target: {
        name: "metodos_pago",
        value: val,
      },
    });
  };

  const setForma = (tipo, active, monto) => {
    let Value = { active: active, monto: monto };
    if (Value.active == true && monto == 0) {
      Value.monto = "";
    }
    let NewValue = {
      ...formasPago,
      [tipo]: Value,
    };
    setFormasPago(NewValue);
    const val = {
      tarjeta: Number(tipo == "tarjeta" ? monto : formasPago.tarjeta.monto),
      transferencia: Number(tipo == "otros" ? monto : formasPago.otros.monto),
      efectivo: Number(tipo == "efectivo" ? monto : formasPago.efectivo.monto),
      monedero: Number(tipo == "monedero" ? monto : formasPago.monedero.monto),
      deposito: Number(tipo == "deposito" ? monto : formasPago.deposito.monto),
      pago_En_Tienda: Number(
        tipo == "pago_En_Tienda" ? monto : formasPago.pago_En_Tienda.monto
      ),
    };
    
    handlInputChange({
      target: {
        name: "metodos_pago",
        value: val,
      },
    });
  };

  useEffect(() => {
    if (Values.pagado) {
      setFormasPago({
        efectivo: {
          active: Values.metodos_pago.efectivo > 0 ? true : false,
          monto: Values.metodos_pago.efectivo,
        },
        tarjeta: {
          active: Values.metodos_pago.tarjeta > 0 ? true : false,
          monto: Values.metodos_pago.tarjeta,
        },
        monedero: {
          active: Values.metodos_pago.monedero > 0 ? true : false,
          monto: Values.metodos_pago.monedero,
        },
        otros: {
          active: Values.metodos_pago.transferencia > 0 ? true : false,
          monto: Values.metodos_pago.transferencia,
        },
        deposito: {
          active: Values.metodos_pago.deposito > 0 ? true : false,
          monto: Values.metodos_pago.deposito,
        },
        pago_En_Tienda: {
          active: Values.metodos_pago.pago_En_Tienda > 0 ? true : false,
          monto: Values.metodos_pago.pago_En_Tienda,
        },
      });
    }
  }, []);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="sm" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setShowModalPago(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Pagar pedido</h6>
        </DialogTitle>

        <DialogContent>
          <Box className=" mb-2">
            <Tabs
              TabIndicatorProps={{
                bgcolor: "#3abe88",
                style: {
                  backgroundColor: "#3abe88",
                },
              }}
              value={tab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="#212529"
              indicatorColor=""
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Formas de pago" className="font-AvenirBold" />
              {/*<Tab label="Personalizable" className="font-AvenirBold" />*/}
            </Tabs>
          </Box>

          <div className="row " style={{ minHeight: "45vh" }}>
            <div className="col-12">
              {tab == 0 ? (
                <>
                  <div style={{ maxWidth: "500_" }}>
                    <h6
                      className="font-AvenirBold mb-3"
                      style={{ color: "black" }}
                    >
                      Formas de pago
                    </h6>

                    <div className="mt-0 d-flex justify-content-between mt-3">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.efectivo.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma(
                              "efectivo",
                              !formasPago.efectivo.active,
                              0
                            );
                            if (efectivoRef.current) {
                              setTimeout(() => {
                                efectivoRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Efectivo}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Efectivo
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="efectivo_campo"
                          inputRef={efectivoRef}
                          label="Efectivo"
                          fullWidth
                          variant="outlined"
                          size="small"
                          disabled={
                            Values.pagado ? !formasPago.efectivo.active : true
                          }
                          value={formasPago.efectivo.monto}
                          onChange={(event) => {
                            setForma(
                              "efectivo",
                              true,
                              event.target.value.toDecimal()
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.tarjeta.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma("tarjeta", !formasPago.tarjeta.active, 0);
                            if (tarjetaRef.current) {
                              setTimeout(() => {
                                tarjetaRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Tarjeta}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Tarjeta de Cred / Deb
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="tarjeta_campo"
                          inputRef={tarjetaRef}
                          label="Tarjeta"
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={
                            Values.pagado ? !formasPago.tarjeta.active : true
                          }
                          value={formasPago.tarjeta.monto}
                          onChange={(event) => {
                            setForma(
                              "tarjeta",
                              true,
                              event.target.value.toDecimal()
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.monedero.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma(
                              "monedero",
                              !formasPago.monedero.active,
                              0
                            );
                            if (monederoRef.current) {
                              setTimeout(() => {
                                monederoRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Monedero}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Monedero electronico
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="monedero_campo"
                          inputRef={monederoRef}
                          label="Monedero"
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={
                            Values.pagado ? !formasPago.monedero.active : true
                          }
                          value={formasPago.monedero.monto}
                          onChange={(event) => {
                            setForma(
                              "monedero",
                              true,
                              event.target.value.toDecimal()
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.otros.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma("otros", !formasPago.otros.active, 0);
                            if (transferenciaRef.current) {
                              setTimeout(() => {
                                transferenciaRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Otros}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Transferencia
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="transferencia_campo"
                          inputRef={transferenciaRef}
                          label="Transferencia"
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={
                            Values.pagado ? !formasPago.otros.active : true
                          }
                          value={formasPago.otros.monto}
                          onChange={(event) => {
                            setForma(
                              "otros",
                              true,
                              event.target.value.toDecimal()
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* Deposito */}
                    <div className="mt-3 d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.deposito.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma(
                              "deposito",
                              !formasPago.deposito.active,
                              0
                            );
                            if (depositoRef.current) {
                              setTimeout(() => {
                                depositoRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Pago5Deposito}
                                className="img-fluid"
                                style={{ maxWidth: "40px", width:"33px", height: "26px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Depósito
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="deposito_campo"
                          inputRef={depositoRef}
                          label="Depósito"
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={
                            Values.pagado ? !formasPago.deposito.active : true
                          }
                          value={formasPago.deposito.monto}
                          onChange={(event) => {
                            setForma(
                              "deposito",
                              true,
                              event.target.value.toDecimal()
                              
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* Pagar en tienda */}
                    <div className="mt-3 d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <Card
                          sx={{ width: "100%" }}
                          className={
                            "card-descuento" +
                            (formasPago.pago_En_Tienda.active
                              ? " card-descuento-active "
                              : "")
                          }
                          onClick={() => {
                            setForma(
                              "pago_En_Tienda",
                              !formasPago.pago_En_Tienda.active,
                              0
                            );
                            if (pago_En_TiendaRef.current) {
                              setTimeout(() => {
                                pago_En_TiendaRef.current.focus();
                              }, 0);
                            }
                          }}
                        >
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Pago6Tiendas}
                                className="img-fluid"
                                style={{ maxWidth: "40px" , width:"33px", height: "26px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Pago en tienda
                              </h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="ms-3" style={{ width: "150px" }}>
                        <TextField
                          id="pago_En_Tienda_campo"
                          inputRef={pago_En_TiendaRef}
                          label="Pago en tienda"
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={
                            Values.pagado
                              ? !formasPago.pago_En_Tienda.active
                              : true
                          }
                          value={formasPago.pago_En_Tienda.monto}
                          onChange={(event) => {
                            setForma(
                              "pago_En_Tienda",
                              true,
                              event.target.value.toDecimal()
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-end">
                      <div className="d-flex align-items-center flex-column m-3">
                        <Grid
                          container
                          direction={"row"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                        >
                          <label>Pagado</label>
                          <FormGroup className="ps-4">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Values.pagado}
                                  name="pagado"
                                  error={Errores.pagado}
                                  onChange={(checked) => {
                                    if (checked === false) {
                                      resetFormasPago();
                                    }

                                    handlInputChange({
                                      target: {
                                        name: "pagado",
                                        value: checked,
                                      },
                                    });
                                  }}
                                  tituloActivo="Pagado"
                                  tituloInactivo="No Pagado"
                                  on
                                />
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          container
                          direction={"row"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          className="pt-1"
                        >
                          <label>Pedido procesado</label>
                          <FormGroup className="ps-4">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Values.is_pedido_procesado}
                                  name="is_pedido_procesado"
                                  error={Errores.is_pedido_procesado}
                                  onChange={(checked) => {
                                    handlInputChange({
                                      target: {
                                        name: "is_pedido_procesado",
                                        value: checked,
                                      },
                                    });
                                  }}
                                  tituloActivo="Procesado"
                                  tituloInactivo="No Procesado"
                                  on
                                />
                              }
                            />
                          </FormGroup>
                        </Grid>
                      </div>
                      {/*<div className="d-flex align-items-center flex-column m-3">
                        <label>Personalizado</label>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Values.personalizable}
                                name="personalizable"
                                error={Errores.personalizable}
                                onChange={(checked) => {
                                  if (checked === false) {
                                    resetFormasPago();
                                  }

                                  handlInputChange({
                                    target: {
                                      name: "personalizable",
                                      value: checked,
                                    },
                                  });
                                }}
                                tituloActivo="Si"
                                tituloInactivo="No"
                                on
                              />
                            }
                          />
                        </FormGroup>
                      </div>*/}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {Values.personalizable ? (
                    <>
                      <div className="mt-0 d-flex justify-content-between mt-3">
                        <TablaProdAcoreon
                          IsLoading={IsLoading}
                          productosCarrito={productosCarrito}
                          setProductosCarrito={setProductosCarrito}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div className="col-12 mb-2 text-end mt-3">
                <h4>
                  <span className="font-AvenirMedium mb-3">
                    Total a pagar:{" "}
                  </span>
                  <span className="font-AvenirBold">
                    {formatMoneda(valorCalculado - totalDescuento + costoEnvio)}
                  </span>
                </h4>
                {cambio > 0 && (
                  <h4 className="text-success">
                    <span className="font-AvenirMedium mb-3">Cambio: </span>
                    <span className="font-AvenirBold">
                      ${cambio.toFixed(2)}
                    </span>
                  </h4>
                )}
              </div>

              <div className="d-flex justify-content-end mt-5">
                <Button
                  onClick={() => setShowModalPago(false)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4 mx-2 me-sm-0  "
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  onClick={() => Guardar()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 ms-3"
                  variant="contained"
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Guardando..." : " Crear pedido "}
                  </span>
                </LoadingButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
