import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepSucursales from "./Componentes/RepSucursales";
import Sucursal from "./Componentes/Sucursal";

const SucursalesRuta = () => {
  console.log("renderizando router");
  return (
    <>
      <Routes>
        <Route index element={<RepSucursales />} />
        <Route path="/add" element={<Sucursal/>} /> 
        <Route path="/edit/:id" element={<Sucursal/>} /> 
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default SucursalesRuta;

