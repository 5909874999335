import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepBitacora from "./Componentes/RepBitacora";

const routerBitacora = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepBitacora />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerBitacora;
