import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  getInfoDireccion,
  getInfoDireccionByIdClienteAndDir,
  guardar,
} from "../Funciones/Direcciones";
import Estado from "./Estado";
import Municipio from "./Municipio";

import validator from "validator";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loading from "../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
const MySwal = withReactContent(Swal);

const ModalDireccion = ({
  SetshowModalDireccion = () => {},
  SetshowModalDirecciones = () => {},
  id,
  id_cliente = 0,
  getDireciones = () => {},
  handlInputChanges = () => {},
  isNuevoRegistro = false,
  callBackNuevaDireccion = () => {},
  valuesEditNuevoReg = {},
}) => {
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const [Values, setValues] = useState({
    id: id ? id : 0,
    id_cliente: id_cliente ? id_cliente : 0,
    nombre: "",
    telefono: "",
    cp: "",
    calle: "",
    numeroext: "",
    numeroint: "",
    colonia: "",
    estado: "0",
    municipio: "0",
    entrecalle1: "",
    entrecalle2: "",
    tipodomicilio: "Casa",
    indicaciones: "",
  });
  const [errores, setErrores] = useState({
    nombre: false,
    telefono: false,
    cp: false,
    calle: false,
    numeroext: false,
    numeroint: false,
    colonia: false,
    estado: false,
    municipio: false,
    entrecalle1: false,
    entrecalle2: false,
    tipodomicilio: false,
    indicaciones: false,
  });

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "estado") {
      NewValue = { ...NewValue, municipio: 0 };
    }
    if (Name == "telefono") {
      const re = /^[0-9\b]+$/; // Expresión regular que solo permite números
      if (target.value != "") {
        if (re.test(target.value) && target.value.length <= 10) {
          NewValue = { ...NewValue, [Name]: target.value };
        } else {
          return;
        }
      }
    }
    if (Name == "cp") {
      const re = /^[0-9\b]+$/; // Expresión regular que solo permite números
      if (target.value != "") {
        if (re.test(target.value) && target.value.length <= 5) {
          NewValue = { ...NewValue, [Name]: target.value };
        } else {
          return;
        }
      }
    }
    if (isNuevoRegistro) {
      if (Name == "estado") {
        NewValue = { ...NewValue, estado_desc: target.desc };
      }
      if (Name == "municipio") {
        NewValue = { ...NewValue, municipio_desc: target.desc };
      }
    }
    setValues(NewValue);
    setErrores({
      ...errores,
      [Name]: false,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validaciones = () => {
    let error = false;

    if (!Values.nombre || Values.nombre == "") {
      errores.nombre = true;
      setErrores(errores);
      error = true;
    }

    if (!Values.telefono || Values.telefono == "") {
      errores.telefono = true;
      setErrores(errores);
      error = true;
    } else {
      if (!validator.isNumeric(Values.telefono)) {
        errores.telefono = true;
        setErrores(errores);
        error = true;
      } else if (Values.telefono.length != 10) {
        errores.telefono = true;
        setErrores(errores);
        error = true;
      }
    }

    if (!Values.cp || Values.cp == "") {
      errores.cp = true;
      setErrores(errores);
      error = true;
    } else {
      if (!validator.isNumeric(Values.cp)) {
        errores.cp = true;
        setErrores(errores);
        error = true;
      } else if (Values.cp.length != 5) {
        errores.cp = true;
        setErrores(errores);
        error = true;
      }
    }

    if (!Values.calle || Values.calle == "") {
      errores.calle = true;
      setErrores(errores);
      error = true;
    }
    if (!Values.numeroext || Values.numeroext == "") {
      errores.numeroext = true;
      setErrores(errores);
      error = true;
    }

    if (!Values.colonia || Values.colonia == "") {
      errores.colonia = true;
      setErrores(errores);
      error = true;
    }

    if (!Values.estado || Values.estado == "" || Values.estado == "0") {
      errores.estado = true;
      setErrores(errores);
      error = true;
    }

    if (
      !Values.municipio ||
      Values.municipio == "" ||
      Values.municipio == "0"
    ) {
      errores.municipio = true;
      setErrores(errores);
      error = true;
    }

    if (!Values.tipodomicilio || Values.tipodomicilio == "") {
      errores.tipodomicilio = true;
      setErrores(errores);
      error = true;
    }
    return error;
  };

  const GuardarDireccion = () => {
    setIsGuardando(true);
    if (isNuevoRegistro) {
      if (validaciones()) {
        setMensaje("Completa los campos requeridos");
        setOpen(true);
        setIsGuardando(false);
      } else {
        callBackNuevaDireccion(Values);
        SetshowModalDireccion(false);
      }
    } else {
      guardar(Values, errores, setErrores, id_cliente)
        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#C9A396",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              setIsGuardando(false);
              SetshowModalDirecciones(true);
              SetshowModalDireccion(false);

              handlInputChanges({
                target: {
                  value: " ",
                  name: "direccion",
                  id: data.respuesta ? Number(data.respuesta) : 0,
                },
              });
              getDireciones();
            });
          } else {
            setMensaje(data.mensaje);
            setOpen(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        });
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (id != undefined && id != 0) {
      getInfoDireccionByIdClienteAndDir(id_cliente, id)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#c9a396",
              denyButtonColor: "#979797",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              SetshowModalDireccion(false);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#c9a396",
            denyButtonColor: "#979797",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            SetshowModalDireccion(false);
          });
        });
    } else {
      if (isNuevoRegistro && valuesEditNuevoReg.hasOwnProperty("index")) {
        setValues(valuesEditNuevoReg);
      }
      setIsLoading(false);
    }
  }, []);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="xs" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalDireccion(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Agregar nueva dirección</h6>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <Row>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre y apellidos"
                  variant="outlined"
                  value={Values.nombre}
                  error={errores.nombre}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Telefono contacto"
                  variant="outlined"
                  value={Values.telefono}
                  error={errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="calle"
                  label="Calle"
                  variant="outlined"
                  value={Values.calle}
                  error={errores.calle}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="numeroext"
                  label="Número exterior"
                  variant="outlined"
                  value={Values.numeroext}
                  error={errores.numeroext}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="numeroint"
                  label="Número int"
                  variant="outlined"
                  value={Values.numeroint}
                  error={errores.numeroint}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="cp"
                  label="Código postal"
                  variant="outlined"
                  value={Values.cp}
                  error={errores.cp}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-3">
                <Estado
                  Name="estado"
                  Value={Values.estado}
                  Error={errores.estado}
                  handlInputChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <Municipio
                  Name="municipio"
                  Estado={Values.estado}
                  Value={Values.municipio}
                  Error={errores.municipio}
                  init={Values.id == 0 ? true : false}
                  handlInputChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="colonia"
                  label="Colonia"
                  variant="outlined"
                  value={Values.colonia}
                  error={errores.colonia}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="entrecalle1"
                  label="Entre calle:"
                  variant="outlined"
                  value={Values.entrecalle1}
                  error={errores.entrecalle1}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  name="entrecalle2"
                  label="y calle:"
                  variant="outlined"
                  value={Values.entrecalle2}
                  error={errores.entrecalle2}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} className="p-3">
                <FormControl>
                  <FormLabel id="tipodomicilio">
                    ¿Es tu trabajo o tu casa?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="tipodomicilio"
                    name="tipodomicilio"
                    value={Values.tipodomicilio}
                    onChange={handlInputChange}
                  >
                    <FormControlLabel
                      value="Casa"
                      control={<Radio />}
                      label="Casa"
                    />
                    <FormControlLabel
                      value="Trabajo"
                      control={<Radio />}
                      label="Trabajo"
                    />
                    <FormControlLabel
                      value="Otro"
                      control={<Radio />}
                      label="Otro"
                    />
                  </RadioGroup>
                </FormControl>
                <FormHelperText
                  id="component-error-text"
                  error={errores.tipodomicilio}
                >
                  {errores.tipodomicilio
                    ? "Selecciona un tipo de domicilio"
                    : ""}
                </FormHelperText>
              </Col>

              <Col sm={12} className="p-3">
                <TextField
                  fullWidth
                  multiline={true}
                  maxRows={3}
                  minRows={3}
                  name="indicaciones"
                  label="Indicaciones adicionales de esta dirección"
                  variant="outlined"
                  value={Values.indicaciones}
                  error={errores.indicaciones}
                  onChange={handlInputChange}
                />
              </Col>
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <Col sm={12} className="p-3">
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarDireccion()}
              className="btn shadow-none w-100 btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 font-AvenirMedium "}
              >
                {IsGuardando ? "Guardando..." : "Guardar dirección"}
              </span>
            </LoadingButton>
          </Col>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModalDireccion;
