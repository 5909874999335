import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { BasicTimePicker } from "../../../../../Includes/TimePicker";
import HelperError from "../../../../../Includes/HelperError";
import { Col, Row } from "react-bootstrap";
import { GetDate } from '../../../../../Lib/Funciones Generales/ObtenerFecha';
import AccEditar from "../../../../assets/AccEditar.svg";
import AccEliminar from "../../../../assets/AccEliminar.svg";
import AddIcon from "@mui/icons-material/Add";

import Swal from "sweetalert2";
import { eliminarHorarioEnvio } from "../Funciones/HorarioEnvio";
import { getTiempoFromMinutosToHoras } from "../../../../../Lib/Funciones Generales/Generales";

const FECHA_ACTUAL = GetDate();
const MINUTOS = 1000 * 60;

const TablaHorariosEnvios = ({ dia, Horarios, setHorarios, clickAgregarHorario, clickEditarHorario }) => {


    const eliminarHorarioDeTabla = (IndexHorario) => {
        const newHorarios = Horarios[dia.numeroSQL].lista.filter((horario, Index) => Index != IndexHorario);
        Horarios[dia.numeroSQL].lista = newHorarios;
        console.log("ELIMINADO: ", Horarios, IndexHorario)
        setHorarios({ ...Horarios })
    };

    const eliminarHorario = (ID, IndexHorario) => {
        new Swal({
            title: "¿Estás seguro de eliminar este horario?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm.isConfirmed) {
                if (ID != undefined) {
                    if (ID == 0) {
                        // Tadavia esta en memoria ram
                        eliminarHorarioDeTabla(IndexHorario);
                    } else {
                        // Esta guardado en la base de datos
                        eliminarHorarioEnvio(ID)
                            .then((resp) => {
                                new Swal({
                                    title: "Exito!",
                                    text: "Eliminado correctamente",
                                    icon: "success",
                                }).then(function () {
                                    eliminarHorarioDeTabla(IndexHorario);
                                });
                            })
                            .catch((resp) => {
                                alert("Error al eliminar");
                            });
                    }
                }
            }
        });
    };


    return (
        <Row className="p-0">
            <Col sm={12} className='px-1'>
                <TableContainer
                    component={Paper}
                    style={{ border: "0px", boxShadow: "none" }}
                >
                    <Table aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    INICIO DE LA JORNADA
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    FIN DE LA JORNADA
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    MAX. PEDIDOS
                                </TableCell>
                                <TableCell className="font-Avenir font-AvenirBold" align="left">
                                    TIEMPO
                                </TableCell>
                                <TableCell
                                    className="font-Avenir font-AvenirBold"
                                    align="right"
                                >
                                    ACCIONES
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Horarios[dia.numeroSQL].lista.length != 0 ? (
                                Horarios[dia.numeroSQL].lista.map((horario, IndexHorario) => {
                                    const ErrorHorario =
                                        horario.ERROR_HORARIO || horario.error ? true : false;
                                    const ErrorInicio =
                                        horario.ERROR_INICIO || horario.error ? true : false;
                                    const ErrorFin =
                                        horario.ERROR_FIN || horario.error ? true : false;

                                    let totalTiempoHrs = 0;
                                    const fin = new Date(FECHA_ACTUAL + " " + horario.FIN + ":00");
                                    const inicio = new Date(FECHA_ACTUAL + " " + horario.INICIO + ":00");
                                    totalTiempoHrs += (fin - inicio) / MINUTOS ;

                                    

                                    return (
                                        <TableRow key={dia.numeroSQL + '_' + IndexHorario}>

                                            <TableCell component="th" scope="row" align="left">
                                                <BasicTimePicker
                                                    label="Inicio"
                                                    hora={horario.INICIO}
                                                    readOnly={true}
                                                // setTime={(hora) => {
                                                //     setTime(IndexHorario, hora, "INICIO");
                                                // }}
                                                />
                                                {
                                                    <HelperError
                                                        Error={ErrorInicio}
                                                        Mensaje={horario.MENSAJE_INICIO}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left">
                                                <BasicTimePicker
                                                    label="Fin"
                                                    hora={horario.FIN}
                                                    readOnly={true}
                                                // setTime={(hora) => {
                                                //     setTime(IndexHorario, hora, "FIN");
                                                // }}
                                                />
                                                {
                                                    <HelperError
                                                        Error={ErrorFin}
                                                        Mensaje={horario.MENSAJE_FIN}
                                                    />
                                                }
                                            </TableCell>

                                            <TableCell component="th" scope="row" align="left">
                                                <TextField
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    size="small"
                                                    name="MAX_PEDIDOS"
                                                    variant="outlined"
                                                    value={horario.MAX_PEDIDOS}
                                                    style={{ width: "90px", height: "5%" }}
                                                // onChange={({ target }) => {
                                                //     handleChange({
                                                //         name: target.name,
                                                //         value: target.value,
                                                //         numeroSQL: dia.numeroSQL,
                                                //         index: IndexHorario,
                                                //     });
                                                // }}
                                                // error={horario.ERROR_MAX_PEDIDOS}
                                                // helperText={horario.ERROR_MAX_PEDIDOS ? "No valido" : ""}
                                                // className={
                                                //     horario.ERROR && !horario.ERROR_MAX_PEDIDOS
                                                //         ? "mb-4"
                                                //         : ""
                                                // }
                                                />
                                            </TableCell>

                                            <TableCell component="th" scope="row" align="left">
                                                {
                                                    getTiempoFromMinutosToHoras(totalTiempoHrs)
                                                }
                                            </TableCell>

                                            <TableCell align="right">

                                                <Tooltip title="Editar" placement="top">
                                                    <IconButton onClick={() => {
                                                        clickEditarHorario(horario, IndexHorario)
                                                    }}
                                                        className={horario.error ? "mb-4" : ""}>
                                                        <img src={AccEditar} />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Eliminar" placement="top">
                                                    <IconButton
                                                        className={horario.error ? "mb-4" : ""}
                                                        onClick={() =>
                                                            eliminarHorario(horario.ID, IndexHorario)
                                                        }
                                                    >
                                                        <img src={AccEliminar} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell component="th" scope="row" colSpan={5}>
                                        Sin horarios
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow style={{ border: "0px" }}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    colSpan={5}
                                    align="right"
                                    style={{ border: "0px" }}
                                >
                                    <Button
                                        style={{ color: "#191919" }}
                                        onClick={() => {
                                            clickAgregarHorario(dia)
                                        }}
                                        className="btn  btn-exp-PDF font-AvenirMedium"
                                        variant="text"
                                    >
                                        <AddIcon className="me-2" /> Agregar horario
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Col>
        </Row>
    )



}

export default TablaHorariosEnvios