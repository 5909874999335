import React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Avatar,
  Snackbar,
  Stack,
  TextField,
  Tabs,
  Box,
  Tab,
  TextareaAutosize,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import Tabla from "./Tabla";
import { Col, Row } from "react-bootstrap";
import ExportarDanger from "./../../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../../assets/ExportarSucess.svg";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
import SelectAlmacenes from "../../Almacenes/Componentes/SelectAlmacenes";
import Select from "../../../../../Includes/Select";
import { getLista } from "../Funciones/bitacora";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import SelectOrdenamientoProductos from "../../../../includes/SelectOrdenamientoProductos";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";

function convertirFecha(fechaString) {
  var fecha = new Date(fechaString);
  var anio = fecha.getFullYear();
  var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
  var dia = ("0" + fecha.getDate()).slice(-2);

  var fechaFormateada = anio + "-" + mes + "-" + dia;

  return fechaFormateada;
}

export default function RepBitacora() {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [SucursalSelecciona, setSucursalSelecciona] = useState("0");
  const [AlmacenSelecciona, setAlmacenSelecciona] = useState("0");
  const [tipo, setTipo] = useState(0);
  const [conExistencia, setConExistencia] = useState(0);

  const [modulo, setModulo] = useState(0);

  const [ValueFechaInicio, setValueFechaInicio] = useState(GetDate(-15));
  const [ValueFechaFin, setValueFechaFin] = useState(GetDate());
  const [selectSucursal, setSelectSucursal] = useState(0);
  const [selectAlmacen, setSelectAlmacen] = useState(0);
  const [tipoMovimiento, setTipoMoviento] = useState(0);

  const UPDATE_TABLE = () => {
    setIsLoading(true);
    getLista(
      0,
      0,
      ValueSelect,
      ValueBusqueda,
      convertirFecha(ValueFechaInicio),
      convertirFecha(ValueFechaFin),
      selectSucursal,
      selectAlmacen,
      tipoMovimiento
    )
      .then((data) => {
        //console.log(data);
        let list = JSON.parse(data.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((e) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    UPDATE_TABLE();
  }, [ValueBusqueda, ValueSelect]);

  const handleChangesSucu = (event) => {
    if (event.target.value != 0) {
      setSucursalSelecciona(event.target.value);
    } else {
      setSucursalSelecciona("0");
    }
  };

  const handleChangesAlma = (event) => {
    if (event.target.value != 0) {
      setAlmacenSelecciona(event.target.value);
    } else {
      setAlmacenSelecciona("0");
    }
  };
  useEffect(() => {
    document.title = "Bitacora";
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Bitacora de inventarios</h4>
        </div>
      </div>
      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} className="p-2 pb-0">
            <h6>Selecciona los filtros</h6>
          </Col>

          <div className="row p-3">
            <div class="p-2" style={{ width: "200px" }}>
              <BasicDatePicker
                label={"Inicio"}
                fecha={ValueFechaInicio}
                setFecha={setValueFechaInicio}
                format="DD/MM/YYYY"
                size="small"
              />
            </div>

            <div class="p-2" style={{ width: "200px" }}>
              <BasicDatePicker
                label={"Fin"}
                fecha={ValueFechaFin}
                setFecha={setValueFechaFin}
                format="DD/MM/YYYY"
                size="small"
              />
            </div>
            <div className="p-2" style={{ width: "200px" }}>
              <SelectSucursales
                size="small"
                Value={selectSucursal}
                handlInputChange={({ target }) => {
                  setSelectSucursal(target.value);
                }}
              />
            </div>
            <div className="p-2" style={{ width: "200px" }}>
              <SelectAlmacenes
                size="small"
                Sucursal={selectSucursal}
                handlInputChange={({ target }) => {
                  setSelectAlmacen(target.value);
                }}
              />
            </div>
            <div class="p-2" style={{ width: "200px" }}>
              <Select
                titulo="Tipo de movimiento"
                size="small"
                ValueSelect={tipoMovimiento}
                setValueSelect={setTipoMoviento}
                opciones={[
                  { value: 0, label: "Todos" },
                  { value: 1, label: "Ajuste" },
                  { value: 2, label: "Ingreso" },
                  { value: 3, label: "Salida" },
                  { value: 4, label: "Traspaso" },
                ]}
              />
            </div>

            <div class="p-2 mx-3" style={{ width: "200px" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
                variant="contained"
                onClick={UPDATE_TABLE}
              >
                Filtrar
              </Button>
            </div>

            <div className="col mx-5"></div>

            <div className="col mx-auto" style={{ width: "200px" }}>
              <SelectOrdenamiento
                size="small"
                currencies={[
                  {
                    value: "1",
                    label: "Más recientes",
                  },
                  {
                    value: "2",
                    label: "Más antiguos",
                  },
                ]}
                ValueSelect={ValueSelect}
                setValueSelect={setValueSelect}
              />
            </div>
          </div>
          <Col sm={12}>
            <Tabla
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
