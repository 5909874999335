import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepMermas from "./Componentes/RepMermas";

const routerMermas = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepMermas />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerMermas;
