import React, { useState, useEffect } from "react";
import Ticket from "./Ticket";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDetalleCorte, getFolio } from "../Funciones/CorteCaja";
import Loading from "../../../../../Includes/Loading";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { printTicket } from "../../../../../Lib/Funciones Generales/print";
import { getLocalStorage } from "../../../../../Context/Storage";

const ModalTicket = ({
  SetshowModalTicket = () => {},
  SetshowModalCorte = () => {},
  id,
  sucursal
}) => {
  //console.log("sucursal"+ sucursal)
  const [IsLoading, setIsLoading] = useState(true);

  const [config, setConfig] = useState({
    id: 0,
    encabezado: "Encabezado del ticket",
    pie: "Gracias por ser parte de la magia",
    linea_1: "Linea extra para el ticket",
    incrementos_horarios: "",
    si_cobro: false,
    ancho: 80,
    fuente: "11",
  });

  const [sucursalSelector, setSucursalSelector] = useState(
    getLocalStorage("SUCURSAL_SELECTOR") == null ||
      getLocalStorage("SUCURSAL_SELECTOR") == undefined ||
      getLocalStorage("SUCURSAL_SELECTOR") == ""
      ? 0
      : getLocalStorage("SUCURSAL_SELECTOR")
  );


  const [Values, setValues] = useState({
    fTotalTarjeta: 0,
    fTotalEfectivo: 0,
    fTotalMonedero: 0,
    fTotalTransferencia: 0,
    fTotalDeposito: 0,
    fTotalPagoEnTienda: 0,
    fTotalCompras: 0,
    observaciones: ""
  });

  const [otrosDatos, setOtrosDatos] = useState([]);
  const [tipo, setTipo] = useState(0);
  const [idRow, setRow] = useState(0);

  let FOLIO = 0;
  let SUCURSAL = 0;


  async function Folio(){
    getFolio()
    .then((resp) => {
  
      FOLIO = resp.values.FOLIO_CORTE;
   
    })
    .catch((resp) => {
       console.log(resp);
      setIsLoading(false);
    });
  }




  useEffect(() => {

    setIsLoading(true);
    Folio();



      const timer = setTimeout(() => {
   
        let ID = id !== undefined ? id : FOLIO;

        let ID_SUCURSAL = sucursal !== undefined ? sucursal : sucursalSelector;
   
      getDetalleCorte(ID_SUCURSAL, ID)
        .then((resp) => {
          console.log("OK");
          //console.log("datos_GENERAL: "+JSON.stringify(resp.values))
          setValues(resp.values)

          setIsLoading(false);
        })
        .catch((resp) => {
           console.log(resp);
          setIsLoading(false);
        });


      }, 2000);
      return () => clearTimeout(timer);



  }, []);

  

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} PaperProps={{ elevation: 0 }}>
        <IconButton
          aria-label="Close"
          className="m-0"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalTicket(false);
            SetshowModalCorte(false);
          }}
        >
          <CloseIcon style={{ width: "60px", height: "60px" }} />
        </IconButton>
        <DialogTitle style={{ backgroundColor: "#f9f9f9f9" }}>
          <h6 className="fw-bolder f-12 mt-2">Ticket corte</h6>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#f9f9f9f9" }}>
          {IsLoading ? (
            <div className="col-12  ">
              <Loading />
            </div>
          ) : (
            <>
                <center>
                 {<Ticket
                    tipo={tipo}
                    config={config}
                    Values={Values}
                    otrosDatos={otrosDatos}
                    ButtonImprimir={
                      <div className="ms-3 sticky-top">
                        <Button
                          onClick={printTicket}
                          className="  btn-accion  w-100"
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            background: "#65748B",
                          }}
                        >
                          {" "}
                          <LocalPrintshopOutlinedIcon
                            className="me-2"
                            style={{ color: "white" }}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Typography
                            style={{
                              color: "white",
                            }}
                          >
                            Imprimir
                          </Typography>
                        </Button>
                
                      </div>
                    }
                  />}
                </center>
              
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default ModalTicket;
