import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepProductosDescuentos from "./Componentes/RepProductosDescuentos";
import ProductosDescuento from "./Componentes/ProductosDescuento";

const routerProductosDescuentos = () => {
  return (
    <Routes>
      <Route index element={<RepProductosDescuentos />} />
      <Route path="/add" element={<ProductosDescuento />} />
      <Route path="/edit/:id" element={<ProductosDescuento />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default routerProductosDescuentos;
