import { useState, useRef, useEffect } from 'react'
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";


import ReactCrop from 'react-image-crop'
import { base64Resize, blobToBase64, canvasPreview, centerAspectCrop } from '../Funciones/CanvasPreview'
import { useDebounceEffect } from '../Funciones/useDebounceEffect'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import 'react-image-crop/dist/ReactCrop.css'

// const PrettoSlider = styled(Slider)({
//     color: '#fe8d27 ',
//     height: 8,
//     '& .MuiSlider-track': {
//         border: 'none',
//     },
//     '& .MuiSlider-thumb': {
//         height: 24,
//         width: 24,
//         backgroundColor: '#fff',
//         border: '2px solid currentColor',
//         '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
//             boxShadow: 'inherit',
//         },
//         '&:before': {
//             display: 'none',
//         },
//     },
//     '& .MuiSlider-valueLabel': {
//         lineHeight: 1.2,
//         fontSize: 12,
//         background: 'unset',
//         padding: 0,
//         width: 32,
//         height: 32,
//         // borderRadius: '50% 50% 50% 0',
//         backgroundColor: '#fe8d27',
//         transformOrigin: 'bottom left',
//         transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
//         '&:before': { display: 'none' },
//         '&.MuiSlider-valueLabelOpen': {
//             transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
//         },
//         '& > *': {
//             transform: 'rotate(45deg)',
//         },
//     },
// });



const ModalRecortarImagen = ({
    img,
    imgWidth,
    imgHeight,
    handleClose,
    aspectoAlto,
    aspectoAncho,
}) => {

    const scale = 1, rotate = 0, aspect = aspectoAncho / aspectoAlto
    const previewCanvasRef = useRef(null), imgRef = useRef(null)

    const [imgSrc] = useState(img)
    const [widthCrop, setWidthCrop] = useState(70)
    const [completedCrop, setCompletedCrop] = useState()
    const [crop, setCrop] = useState()

    function onImageLoad() {
        if (aspect) {
            setCrop(centerAspectCrop(imgWidth, imgHeight, aspect, widthCrop))
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale,
                rotate,
            )
        }
    }

    function onDownloadCropClick() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            blobToBase64(blob).then(async img64 => {

                // img64 = await base64Resize(img64, 900, 900)
                console.log(img64)
                handleClose(true, img64)                


            });
        })
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    useEffect(() => {
        onImageLoad()
    }, [widthCrop])

    return (
        <Dialog fullWidth maxWidth="sm" open={true}>
            <IconButton
                aria-label="Close"
                className="m-2"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                    handleClose(false)
                }}
            >
                <CloseIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>
            {
                <DialogTitle>
                    <h5 className="fw-bolder f-12 mt-2 w-100 text-center">
                        Recortar imagen
                    </h5>
                </DialogTitle>
            }
            <DialogContent >
                <Row className="m-0">
                    {/* <Col sm={12}>
                        <PrettoSlider
                            onChange={({ target }) => setWidthCrop(target.value)}
                            min={1}
                            max={100}
                            valueLabelDisplay="auto"
                        />
                    </Col> */}
                    <Col sm={12}>
                        <div className="App">
                            {!!imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                    ruleOfThirds >
                                    <img
                                        ref={imgRef}
                                        src={imgSrc}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                            {completedCrop && (
                                <div className='d-none'>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col sm={12} className='d-flex justify-content-end'>


                        <LoadingButton
                            onClick={onDownloadCropClick}
                            loading={false}
                            disabled={false}
                            loadingPosition="start"
                            className="btn btn-Crexendo font-AvenirMedium py-2 px-4 mt-3 me-2"
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            Guardar
                        </LoadingButton>

                        <Button
                            onClick={() => handleClose(false)}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4 mt-3 ms-2"
                            variant="outlined"
                        >
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            </DialogContent>
        </Dialog>
    )
}

export default ModalRecortarImagen