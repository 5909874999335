import React from "react";
import { getOpcionesCM } from "../Funciones/Clientes";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function SelectClienteCM({
  Value,
  Error,
  handlInputChange,
  Name = "id_cliente",
  Opciones = [],
  size = "medium",
}) {
  const opcionInicial = { ID: 0, DESCRIPCCION: "Cliente" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Categorias, setCategorias] = useState({
    cargado: false,
    lista: [opcionInicial],
  });
  const cargarOps = () => {
    getOpcionesCM()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        if (list.length != 0) {
          setCategorias({ cargado: true, lista: [...Opciones, ...list] });
        } else {
          setCategorias({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCCION: "Sin clientes disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setCategorias({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCCION: "Sin clientes disponibles" }],
        });
      });
  };
  useEffect(() => {
    cargarOps();
  }, []);
  useEffect(() => {
    if (Value == "" || Value == "0" || Value == 0) {
      setCategorias({
        cargado: false,
        lista: [],
      });
      cargarOps();
    }
  }, [Value]);
  const getOpcion = () => {
    const opcion = Categorias.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Categorias.cargado ? (
    <Autocomplete
      size={size}
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          //console.log(value);
          handlInputChange({
            target: {
              name: Name,
              value: String(value.ID),
              nombre: value.DESCRIPCCION,
            },
          });
        } else {
          handlInputChange({ target: { name: Name, value: "", nombre: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Categorias.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un cliente" : ""}
            label="Cliente"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Cliente"} />
  );
}
