import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import AccEditarImagen from "./../../../assets/EditarImagen.svg";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { CargarImagenEdit } from "../Funciones/CargarImagen";
import { IMG } from "../../Funciones Generales/Constantes";
import { styled } from "@mui/material/styles";
import Modal from "../../../Includes/Modal";
import ModalRecortarImagenMultiple from "./ModalRecortarImagenMultiple";
import { getImgDimensions } from "../Funciones/CanvasPreview";
import Swal from "sweetalert2";

const extenciones = ["webp", "png", "jpg", "jpeg"]
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);


const isValidoAzureStorage = (tipo) => {
  let validarTipo = false;
  Object.keys(IMG).map((tipoImagen) => {
    if (IMG[tipoImagen] == tipo) {
      validarTipo = true;
    }
  });
  return validarTipo
}

const getRandomId = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const ModalCargarMultImagen = ({
  id = 0,
  tipo = "",
  Titulo = "Cargar imagen",
  mostrar = false,
  fnCerrar = () => { },
  fnDevolverImagen = (src) => { },
  getTotalImgs = () => { },
  maximoImagenes,
  ConfigRecortar = {
    requerida: false,
    aspectoAlto: 4,
    aspectoAncho: 3
  }
}) => {
  const [error, setError] = useState({
    mostrar: false,
    mensaje: "",
    severity: "error",
  });
  const [isLoading, setLoading] = useState(false)
  const [arrImgs, setArrImgs] = useState([]);
  const [showDialogRecortar, setShowDialogoRecortar] = useState(false)



  const EnviarImagenApi = (data, index, numImagenEnviar, totalImages, arrURLS) => {

    if (index <= numImagenEnviar) {

      const principal = totalImages == 0 ? (index == 1 ? true : false) : false;
      let img_64 = "";
      if (data[index - 1].IMG) {
        img_64 = data[index - 1].IMG.split("base64,")[1];
      } else {
        img_64 = data[index - 1].split("base64,")[1];
      }

      console.log("ENVIANDO: ", index, 'de', numImagenEnviar, ' => principal: ', principal)

      CargarImagenEdit(id, tipo, img_64, principal)
        .then((resp) => {
          EnviarImagenApi(data, index + 1, numImagenEnviar, totalImages, [
            ...arrURLS,
            {
              ID: resp.respuesta.id,
              IMG: resp.respuesta.url
            }
          ])
        })
        .catch((resp) => {
          setError({
            mostrar: true,
            mensaje: resp.mensaje,
            severity: "error",
          });
          EnviarImagenApi(data, index + 1, numImagenEnviar, totalImages, arrURLS)
        })
    } else {

      // Creo que aqui se acaba el proceso y devuelve las imagenes 
      // Esto pasa cuando las manda a cargar al api
      fnDevolverImagen(arrURLS);
      fnCerrar();

    }


  }







  useEffect(() => {

    if (!isValidoAzureStorage(tipo)) {
      setError({
        mostrar: true,
        mensaje: "Falta asignar el tipo de la imagen",
        severity: "error",
      });
      return;
    }

    if (isLoading) {

      if (!ConfigRecortar.requerida) {
        let arrURLS = [];
        if (id && id != 0) {
          EnviarImagenApi(arrImgs, 1, arrImgs.length, getTotalImgs(), [])
        } else {
          const arrayImagenes = arrImgs.map(x => x.IMG)
          fnDevolverImagen(arrayImagenes);
          fnCerrar();
        }
      } else {
        setLoading(false)
        setShowDialogoRecortar(true)
      }



    }


  }, [arrImgs])



  const onDrop = useCallback((files) => {

    if (files) {
      setLoading(true)
      if (files.length > 0 && files.length <= maximoImagenes - getTotalImgs()) {
        let newArr = arrImgs.filter((item) => item);
        let error = false;
        files.map(async (item, i) => {

          if (item.size < 2000000) {

            const data = await toBase64(item)
            const size = await getImgDimensions(window.URL.createObjectURL(item))
            const dataImg64 = data.split("base64,");
            const dataImgType = dataImg64[0].split("/")[1].replace(";", "").trim()
            if (!error) {
              error = extenciones.includes(dataImgType) ? false : true
            }
            newArr.push({ ID: getRandomId(1, 100000000), IMG: data, SIZE: size });
            if (i == (files.length - 1)) {
              if (!error) {
                setArrImgs([...newArr]);
              } else {
                setError({
                  mostrar: true,
                  mensaje: `Los archivos permitidos son (${extenciones.toString().replaceAll(",", " ,")})`,
                  severity: "error",
                });
                setLoading(false)
              }
            }

          } else {
            setLoading(false)
            new Swal({
              title: "Lo sentimos",
              text: `${files.length == 1 ? 'El archivo seleccionado no puede' : 'Los archivos seleccionados no pueden'} pesar más de 2MB. Intente con otro archivo.`,
              icon: "warning",
              showDenyButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "#3ABE88",
              denyButtonColor: "#65748B",
              reverseButtons: true,
            }).then(function (isConfirm) {

            });
          }



        });

      } else {
        setLoading(false)
        setError({
          mostrar: true,
          mensaje: `Maximo de imagenes superado (${maximoImagenes})`,
          severity: "error",
        });
      }

    }
  }, []);



  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: {
      "image/webp": [],
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 10,
  });



  return (
    <>


      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledSnackbar
        style={{ zIndex: 2000000 }}
        direction="right"
        open={error.mostrar}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, mostrar: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setError({ ...error, mostrar: false })}
          severity={error.severity}
          sx={{ width: "100%" }}
        >
          {error.mensaje}
        </Alert>
      </StyledSnackbar>



      <Modal
        Titulo={Titulo}
        mostrar={mostrar}
        fnCerrar={fnCerrar}
        maxWidth="md"
        textoGuardar=""
        textoCancelar=""
      >
        <div
          {...getRootProps()}
          style={{ border: "1px dotted lightgray" }}
          className="p-3 mt-0 mb-4 mx-4"
        >
          <input {...getInputProps()} />
          {
            <Row className="p-4 w-100 h-100">
              <Col sm={6} md={4} className="d-flex justify-content-center">
                <img
                  src={AccEditarImagen}
                  className="img-fluid"
                />
              </Col>
              <Col sm={6} md={8}>
                <div className="w-100 h-100 d-flex">
                  <div className="m-auto">
                    <strong> Selecciona la imagen </strong>
                    <p className="text-mutted mb-0">
                      Arrastra la imagen o{" "}
                      <strong style={{ color: "#3ABE88" }}> selecciona</strong>{" "}
                      <br />
                      desde tu ordenador
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          }
        </div>
        {fileRejections && fileRejections.length != 0 && (
          <div className="alert alert-danger py-4 mx-4" role="alert">
            <strong> Error al cargar: </strong>{" "}
            {fileRejections[0].errors[0].message == "Too many files"
              ? "Demasiados archivos"
              : "Solo se aceptan archivos JPG / JPGE / PNG / WEBP /"}
          </div>
        )}
      </Modal>

      {
        showDialogRecortar && arrImgs.length != 0 && (
          <ModalRecortarImagenMultiple
            arrayImagenes={arrImgs}
            aspectoAlto={ConfigRecortar.aspectoAlto}
            aspectoAncho={ConfigRecortar.aspectoAncho}
            handleClose={(recortado, arrImgsRecortadas) => {
              setShowDialogoRecortar(false)
              if (recortado) {
                if (id && id != 0) {
                  setLoading(true)
                  EnviarImagenApi(arrImgsRecortadas, 1, arrImgsRecortadas.length, getTotalImgs(), [])
                }
                else {
                  fnDevolverImagen(arrImgsRecortadas)
                  fnCerrar()
                }
              }


            }}
          />
        )
      }


    </>
  );
};

export default ModalCargarMultImagen;
