import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { BasicDatePickers } from "../../../includes/BasicDatePickers";
import { GetDate } from "./Componentes/ObtenerFecha";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "../../../includes/Switch";
import { getOpcionesProd } from "../Productos/Funciones/Productos";
import BACKEND_URL, { KEY } from "../../../../Context/backend";
//import BACKEND_URL, { KEY } from "../../../../Context/backend";

const MySwal = withReactContent(Swal);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, producto, theme) {
  return {
    fontWeight:
      producto.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function ReporteVentasProd() {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  const [StatusCancelado, setStatusCancelado] = useState(false);
  const [ProductosSelecciona, setProductosSelecciona] = useState([0]);
  const [Productos, setProductos] = useState([]);
  const opcionesProductos = [...Productos];
  const theme = useTheme();

  useEffect(() => {
    getOpcionesProd(1)
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setProductos(opciones);
      })
      .catch((err) => console.error(err));
  }, []);
  console.log("ListaPRo", Productos);

  const handleChangeSelectProd = (event) => {
    const {
      target: { value },
    } = event;
    setProductosSelecciona(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangesCancelado = (event) => {
    setStatusCancelado(event.target.value);
  };

  const handleChangeFechaIn = (event) => {
    const startDate = new Date(event);
    const endDate = new Date(fechaFin);
    startDate.setDate(startDate.getDate());
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };
  const handleChangeFechaFIn = (event) => {
    const startDate = new Date(fechaInicio);
    const endDate = new Date(event);
    endDate.setDate(endDate.getDate());
    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };
  const generarReportePDF = () => {
    const startDate = new Date(fechaInicio);
    const endDate = new Date(fechaFin);
    const daysDifference = Math.floor(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    if (daysDifference >= 31) {
      MySwal.fire({
        title: "Advertencia",
        html: "El rango máximio de consulta es de un mes, intenta con un rango menor",
        icon: "warning",
        confirmButtonColor: "#FD8C27",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      });
      return;
    }
    window.open(
      `../pdf/reporteventas/${fechaInicio}/${fechaFin}/${StatusCancelado}/${ProductosSelecciona}`,
      "_blank"
    );
  };
  useEffect(() => {
    document.title = `Ventas`;
  }, []);
  console.log("Array Prod", ProductosSelecciona);
  return (
    <>
      <Card elevation={3} style={{ marginTop: "150px" }}>
        <CardContent className="p-3">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Reporte de ventas
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          <Row>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePickers
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de inicio"
                fecha={fechaInicio}
                setFecha={handleChangeFechaIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePickers
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de fin"
                fecha={fechaFin}
                setFecha={handleChangeFechaFIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <div>
                <FormControl fullWidth sx={{ width: 520 }}>
                  <InputLabel id="demo-multiple-sucursal-label">
                    Productos
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-sucursal-label"
                    id="demo-multiple-sucursal"
                    multiple
                    value={ProductosSelecciona}
                    onChange={(e) => {
                      if (e.target.value.includes(-1)) {
                        handleChangeSelectProd(e);
                      } else {
                        setProductosSelecciona(e.target.value);
                        console.log(e.target.value);
                      }
                    }}
                    input={<OutlinedInput label="Productos" />}
                  >
                    {opcionesProductos.map((producto) => (
                      <MenuItem
                        key={producto.ID}
                        value={producto.ID}
                        style={getStyles(
                          producto.NOMBRE,
                          opcionesProductos,
                          theme
                        )}
                      >
                        {producto.NOMBRE}{" "}
                        {ProductosSelecciona.includes(producto.ID) && ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Switch
                  checked={StatusCancelado}
                  onChange={(checked) => {
                    handleChangesCancelado({
                      target: {
                        name: "cancelado",
                        value: checked,
                      },
                    });
                  }}
                  name="cancelado"
                  tituloActivo="Si"
                  tituloInactivo="No"
                />
                <label>Cancelado</label>
              </Grid>
            </Col>
          </Row>
          <Stack
            className="py-4"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{ float: "right" }}
          >
            <Button
              onClick={() =>
                generarReportePDF(StatusCancelado, ProductosSelecciona)
              }
              disabled={!fechaFin}
              className="font-AvenirMedium  py-2 px-4 "
              variant="contained"
              color="error"
            >
              Generar Reporte PDF
            </Button>
            <a
              target={"_blank"}
              href={
                BACKEND_URL +
                `ventas/ReporteVentasProdExcel?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&Cancelado=${StatusCancelado}&Productos=${ProductosSelecciona}&KEY=${KEY}`
              }
            >
              <Button
                disabled={!fechaFin}
                className="font-AvenirMedium  py-2 px-4 "
                variant="contained"
                color="success"
              >
                Generar Reporte EXCEL
              </Button>
            </a>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default ReporteVentasProd;
