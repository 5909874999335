import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  DialogContent,
  Button,
  TextField,
  Card,
  Avatar,
  Grid,
  Typography,
  Snackbar,
  Box,
  Tabs,
  Tab,
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import TablaModalDetalleBit from "./TablaModalDetalleBit";
import Loading from "../../../../../Includes/Loading";
import { getRel } from "../Funciones/bitacora";
import { Col, Row } from "react-bootstrap";

const MySwal = withReactContent(Swal);

export default function ModalInfoBitacora({
  openModal = false,
  setOpenModal = () => {},
  id = 0,
  setId = () => {},
  row = {},
}) {
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [Lista, setLista] = useState([]);
  const [catalogoTipo, setCatalogoTipo] = useState({
    PEDIDOS: "Pedidos",
    AJUSTE_MERCANCIA: "Ajuste de mercancia",
    INGRESO_MERCANCIA: "Ingreso de mercancia",
    ORDEN_COMPRA: "Orden de compra",
    TRASPASO_MERCANCIA: "Traspaso de mercancia",
  });
  const handleClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    getRel(id)
      .then((data) => {
        let list = JSON.parse(data.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((e) => {
        setLista([]);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Detalle de movimiento</h6>
        </DialogTitle>

        <DialogContent>
          <Row className="p-3">
            <table
              style={{
                borderSpacing: "60px",
                tableLayout: "auto",
                width: "auto",
              }}
              //className="table table-responsive"
            >
              <tr>
                <td style={{ fontWeight: "bold" }}>USUARIO:</td>
                <td className="px-4 py-1">
                  {row.NOMBRE_USUARIO + " " + row.APELLIDOS_USUARIO}
                </td>
                <td style={{ fontWeight: "bold" }}>MODULO:</td>
                <td className="px-4 py-1">{catalogoTipo[row.TIPO]}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}> PRODUCTOS AFECTADOS:</td>
                <td className="px-4 py-1">{row.CANTIDAD_PRODUCTOS}</td>
                <td style={{ fontWeight: "bold" }}>MOVIMIENTO:</td>
                <td className="px-4 py-1">{row.ACCION}</td>
              </tr>
              <br />
              <tr>
                <td style={{ fontWeight: "bold" }}>DESCRIPCION:</td>
                <td colSpan={3} className="px-4 py-1">
                  <Typography>{row.DESCRIPCION}</Typography>
                </td>
              </tr>
            </table>
          </Row>

          <Row className="p-3">
            <TablaModalDetalleBit
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            />
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
}
