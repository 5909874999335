import React from "react";
import {
  Grid,
  Stack,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  T,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function ChartLine({
  titulo = "",
  values = [],
  Labels = [],
  Color = "#26A0FC",
  Height = "",
}) {
  const ops = {
    options: {
      chart: {
        id: `Pedidos_Últimos_6_meses_${"INICIO"}_-_${"FIN"}`,
        zoom: {
          enabled: false,
        },
        height: 350,
      },
      xaxis: {
        categories: Labels,
      },
      markers: {
        size: 6,
      },
      colors: [Color],
    },

    series: [
      {
        name: "Total",
        data: values,
      },
    ],
  };

  return (
    <>
      <Card className="shadow-sm" elevation={3} style={{ height: Height }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          <div lassName="p-3 m-3">
            <Row className="p-1 m-2">
              <Col>
                <Chart
                  options={ops.options}
                  series={ops.series}
                  type="line"
                  //width={480}
                  height={300}
                />
              </Col>
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
