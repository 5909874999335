import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carrito from "./Carrito";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import { Alert, styled, Snackbar, Tab, Tabs, Stack } from "@mui/material";
import {
  getClienteMostrador,
  getUpdatedPrecio,
  guardar,
} from "../Funciones/PuntoDeVente";
import { Link, useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalSelectDireccionCliente from "../../../../Lib/Direcciones/Componentes/ModalSelectDireccionCliente";
import ModalDireccion from "../../../../Lib/Direcciones/Componentes/ModalDireccion";
import { getDireccionPrincipal } from "../../../../Lib/Direcciones/Funciones/Direcciones";
import { getPrecioZona } from "../../Catalogos/Zonas/Funciones/Zonas";
import TabsDatosEntrega from "./Tabs/TabsDatosEntrega";
import TabsDedicatoria from "./Tabs/TabsDedicatoria";
import TabsPago from "./Tabs/TabsPago";
import { useContext } from "react";
import { IconContext } from "../../../includes/Menu";
import ModalPagoVenta from "./ModalPagoVenta";
import "dayjs/locale/es-mx";
import { setLocalStorage, getLocalStorage } from "../../../../Context/Storage";
import ButtonsTabs from "./Tabs/ButtonsTabs";
import BuscarProductos from "./BuscarProductos";
import SucursalSelector from "./Tabs/SucursalSelector";
import Switch from "../../../../Includes/Switch";
import ModalTicket from "../../Pedidos/Compomentes/Dialogos/ModalTicket";
import ModalCorte from "./ModalCorte";
import ModalTicket_ from "../../Catalogos/CorteCaja/Componentes/ModalTicket";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const options = ["Cambiar de sucursal", "Corte de caja"];

const ITEM_HEIGHT = 48;

const MySwal = withReactContent(Swal);

export default function PruebaContenedores() {
  const context = useContext(IconContext);

  //#region Variables de estado PV_PRODS_COMPO
  const { id } = useParams();
  const [categoria, setCategoria] = React.useState(0);
  const [productosCarrito, setProductosCarrito] = useState(
    getLocalStorage("PV_PRODS") == ""
      ? []
      : JSON.parse(getLocalStorage("PV_PRODS"))
  );
  const [ventaLibre, setVentaLibre] = useState(
    getLocalStorage("VENTA_LIBRE") == ""
      ? false
      : Boolean(JSON.parse(getLocalStorage("VENTA_LIBRE")))
  );
  const [comisionBancaria, setComisionBancaria] = useState(
    getLocalStorage("COMISION_BANCARIA") == ""
      ? false
      : Boolean(JSON.parse(getLocalStorage("COMISION_BANCARIA")))
  );
  const [IDbeforeVenta, setIDbeforeVenta] = useState("0");
  const [showDiloglImprimir, setShowDiloglImprimir] = useState(false);
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
  const [showModalMetodosPago, setShowModalMetodosPago] = useState(false);
  const [openNuevaDireccion, setOpenNuevaDireccion] = useState(false);
  const [precioEnvio, setPreioEnvio] = useState(0);
  const [showModalCorte, SetshowModalCorte] = useState(false);
  const [showModalTicket, SetshowModalTicket] = useState(false);
  const [checked, setChecked] = useState(true);

  const [idRow, setIdRow] = useState(0);

  const [tab, setTab] = React.useState("0");
  const [descuento, setDescuento] = useState({
    id: 0,
    descuento: 0,
    tipo: 0,
    max: 0,
    categoria: "",
  });
  const [expanded, setExpanded] = React.useState(
    getLocalStorage("PV") == ""
      ? "panel1"
      : JSON.parse(getLocalStorage("PV")).tipo_envio == 1
      ? "panel1"
      : "panel2"
  );
  const [Fecha, setFecha] = useState(
    getLocalStorage("PV") == ""
      ? GetDate()
      : JSON.parse(getLocalStorage("PV")).fecha_entrega
  );
  const [horarios, setHorarios] = useState({
    id: 0,
    inicio: "",
    fin: "",
  });

  useEffect(() => {
    // alert("horarios ha cambiado");
  }, [horarios]);

  const [isRentable, setIsRentable] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Incluye_IVA, setIncluye_IVA] = useState(false);
  const [Values, setValues] = useState(
    getLocalStorage("PV") == ""
      ? {
          id: id ?? "0",
          id_cliente: "0",
          nombre: "",
          id_sucursal: "0",
          id_direccion: 0,
          fecha_entrega: ConvertDate(GetDate()),
          observaciones: "",
          direccion: "",
          tipo_envio: 1,
          descuento: 0,
          tipo_descuento: 0,
          descuento_max: 0,
          subtotal: 0,
          total: 0,
          pagado: false,
          personalizable: false,
          Incluye_iva: false,
          productos: [],
          //productoscompo: [],
          codigo: "",
          id_zona: 0,
          ubicacion: "0 0",
          lat: 0,
          long: 0,
          horario_entrega: {
            inicio: "00:00:00",
            fin: "00:00:00",
          },
          metodos_pago: {
            tarjeta: 0,
            transferencia: 0,
            efectivo: 0,
            monedero: 0,
          },
          costo_envio: 0,
          metodo_pago: "",
          dedicatoria: "",
          envia: "",
          recibe: "",
          id_sucursal_venta: 0,
          id_descuento: 0,
          categoria_descuento: "",
          is_pedido_procesado: false,
        }
      : JSON.parse(getLocalStorage("PV"))
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ChangeOption = (event) => {
    setAnchorEl(null);
  };

  const [Errores, setErrores] = useState({
    id_cliente: false,
    id_sucursal: false,
    fecha_entrega: false,
    observaciones: false,
    direccion: false,
    tipo_envio: false,
    horarios_entrega: false,
    is_pedido_procesado: false,
  });
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleInputChange = ({ target }) => {
    let refreshHorario=false;
    let Value = target.value;
    const Name = target.name;

    if (Name == "id_cliente") {
      Values.nombre = target.nombre;
      Values.id_direccion = 0;
      Values.direccion = "";
    }
    if (Name == "id_sucursal") {
      Values.direccion = target.dir;
      Value = String(Value);
      Errores.direccion = false; 
      Values.direccion = "";
      Values.id_direccion = 0;
    }
   
    if (Name == "tipo_envio" && Value != Values.tipo_envio) {

    //  console.log(Value+" != "+Values.tipo_envio)
      if (Value == 1) {
        Values.id_sucursal = "0";  

      } else { 
        Values.id_sucursal = "0";
        Values.direccion = "";
        Values.id_direccion = 0;
      }
      refreshHorario=true;
    }
    if (Name == "direccion") {
      Values.id_direccion = target.id;
      Values.lat = target.lat;
      Values.long = target.long;
    }
    //Values[Name] = Value;
    //setValues(Values);
    setValues({ ...Values, [Name]: Value });
    //console.log(Values);
    setErrores({ ...Errores, [Name]: false });

  if(refreshHorario )
  {
    horarios.id=0;
    horarios.inicio="";
    horarios.fin=""; 
    setHorarios(...horarios);
  }


  };
  const handleInputChangeIVA = ({ target }) => {
    let Value = target.value;
    if (Value) {
      setIncluye_IVA(true);
    } else {
      setIncluye_IVA(false);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openModalValidations = () => {
    if (Values.id_cliente == "" || Values.id_cliente == "0") {
      Errores.id_cliente = true;
      setErrores(Errores);
      setMensaje("Selecciona un cliente para ver direcciones");
      setOpenAlert(true);
    } else {
      SetshowModalDireccion(true);
    }
  };

  // console.log(horarios)

  
  const Guardar = () => {
    setIsGuardando(true);
    guardar(
      Values,
      Errores,
      setErrores,
      productosCarrito,
      isRentable,
      descuento,
      precioEnvio,
      Incluye_IVA,
      ventaLibre,
      comisionBancaria
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Imprimir ticket",
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            timer: 10000,
          }).then((result) => {
            setShowModalMetodosPago(false);
            setTab(0);
            setProductosCarrito([]);
            setValues({
              id: id ?? "0",
              id_cliente: "0",
              id_sucursal: "0",
              id_direccion: 0,
              fecha_entrega: ConvertDate(GetDate()),
              observaciones: "",
              direccion: "",
              tipo_envio: 1,
              descuento: 0,
              tipo_descuento: 0,
              descuento_max: 0,
              subtotal: 0,
              total: 0,
              pagado: false,
              personalizable: false,
              Incluye_iva: false,
              productos: [],
              codigo: "",
              id_zona: 0,
              ubicacion: "0 0",
              lat: 0,
              long: 0,
              horario_entrega: {
                inicio: "12:34:00",
                fin: "15:10:00",
              },
              metodos_pago: {
                tarjeta: 0,
                transferencia: 0,
                efectivo: 0,
                monedero: 0,
              },
              costo_envio: 0,
              metodo_pago: " ",
              dedicatoria: "",
              envia: "",
              recibe: "",
              id_sucursal_venta: sucursalSelector,
              id_descuento: 0,
              categoria_descuento: "",
              is_pedido_procesado: false,
            });
            setDescuento({
              id: 0,
              descuento: 0,
              tipo: 0,
              max: 0,
              categoria: "",
            });
            setFecha(GetDate());
            setIsRentable(false);
            setCategoria(0);
            setExpanded("panel1");
            setPreioEnvio(0);
            setLocalStorage("PV", "");
            setLocalStorage("PV_PRODS", "");
            setProductosCarrito([]);
            if (result.isConfirmed) {
              setIDbeforeVenta(data.respuesta.id);
              setShowDiloglImprimir(true);
            }
          });
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setIsGuardando(false);
      });
  };

  const LimpiarDatos = () => {
    new Swal({
      title: "¿Estás seguro de eliminar los datos?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        setValues({
          id: id ?? "0",
          id_cliente: "0",
          id_sucursal: "0",
          id_direccion: 0,
          fecha_entrega: ConvertDate(GetDate()),
          observaciones: "",
          direccion: "",
          tipo_envio: 1,
          descuento: 0,
          tipo_descuento: 0,
          descuento_max: 0,
          subtotal: 0,
          total: 0,
          pagado: false,
          personalizable: false,
          Incluye_iva: false,
          productos: [],
          codigo: "",
          id_zona: 0,
          ubicacion: "0 0",
          lat: 0,
          long: 0,
          horario_entrega: {
            inicio: "12:34:00",
            fin: "15:10:00",
          },
          metodos_pago: {
            tarjeta: 0,
            transferencia: 0,
            efectivo: 0,
            monedero: 0,
          },
          costo_envio: 0,
          metodo_pago: "",
          dedicatoria: "",
          envia: "",
          recibe: "",
          id_sucursal_venta: sucursalSelector,
          id_descuento: 0,
          categoria_descuento: "",
          is_pedido_procesado: false,
        });
        setDescuento({ id: 0, descuento: 0, tipo: 0, max: 0, categoria: "" });
        setFecha(GetDate());
        setIsRentable(false);
        setCategoria(0);
        setExpanded("panel1");
        setPreioEnvio(0);
        setLocalStorage("PV", "");
        setLocalStorage("PV_PRODS", "");
        setProductosCarrito([]);
      }
    });
  };

  const cambioSucursal = () => {
    new Swal({
      title: "¿Estás seguro de cambiar de sucursal?",
      text: "",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        setSucursalSelector(0);
        setSucursalName("");
      }
    });
  };

  useEffect(() => {
    setProductosCarrito((productos) =>
      productos.map((p) => getUpdatedPrecio(p, ventaLibre, comisionBancaria))
    );
  }, [comisionBancaria, ventaLibre]);

  useEffect(() => {
    localStorage.setItem("COMISION_BANCARIA", comisionBancaria);
  }, [comisionBancaria]);

  useEffect(() => {
    localStorage.setItem("VENTA_LIBRE", ventaLibre);
  }, [ventaLibre]);

  useEffect(() => {
    document.title = "Punto de venta";
    setValues({ ...Values, fecha_entrega: ConvertDate(Fecha) });
    Errores.fecha_entrega = false;
    setErrores(Errores);
  }, [Fecha]);

  useEffect(() => {
    Values.horario_entrega.inicio = horarios.inicio;
    Values.horario_entrega.fin = horarios.fin;
    setValues(Values);
    Errores.horarios_entrega = false;
    setErrores(Errores);
  }, [horarios]);

  useEffect(() => {
    let sumaPrecios = 0;
    let rent = false;
    productosCarrito.map((item) => {
      //console.log(item);
      sumaPrecios =
        sumaPrecios + parseFloat(item.PRECIO) * parseFloat(item.CANTIDAD);
      if (item.RENTABLE == true) {
        //console.log(item);
        rent = true;
      }
    });
    setIsRentable(rent);
    setValues({ ...Values, total: sumaPrecios });
    setLocalStorage("PV_PRODS", JSON.stringify(productosCarrito));
    // console.log({ productosCarrito });
  }, [productosCarrito]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Aquí guardamos el valor de Incluye_IVA en el almacenamiento local
      localStorage.setItem("Incluye_IVA", String(Incluye_IVA));
    };

    const handleLoad = () => {
      // Aquí recuperamos el valor de Incluye_IVA del almacenamiento local
      const storedValue = localStorage.getItem("Incluye_IVA");
      if (storedValue) {
        setIncluye_IVA(storedValue === "true");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    if (
      Values.id_direccion != 0 &&
      Values.id_direccion != "" &&
      Values.id_direccion != "0"
    ) {
      getPrecioZona(Values.lat, Values.long)
        .then((data) => {
          if (data.length > 0) {
            setPreioEnvio(data[0].PRECIO_DE_ENVIO);
            setValues({
              ...Values,
              ubicacion: `${Values.long} ${Values.lat}`,
              id_zona: data[0].ID,
            });
          } else {
            setPreioEnvio(0);
            setValues({ ...Values, id_zona: 0 });
          }
        })
        .catch((data) => {
          console.log();
        });
    } else {
      setPreioEnvio(0);
    }
  }, [Values.id_direccion, Values.direccion]);

  useEffect(() => {
    if (
      Values.tipo_envio == 1 &&
      Values.id_cliente != "" &&
      Values.id_cliente != "0" &&
      Values.id_direccion == 0
    ) {
      getDireccionPrincipal(Values.id_cliente)
        .then((data) => {
          if (data.length == 0) {
            setValues({ ...Values, direccion: "", id_direccion: 0 });
          } else {
            setValues({
              ...Values,
              direccion:
                "Calle " +
                data.CALLE +
                " " +
                data.NUMERO_EXT +
                " - " +
                data.COLONIA +
                ", " +
                data.NOMBRE_MUNICIPIO +
                ", " +
                data.NOMBRE_ESTADO +
                "/" +
                data.NOMBRE_CONTACTO +
                "/" +
                data.TELEFONO,
              id_direccion: data.ID,
              lat: data.LAT,
              long: data.LONG,
            });

            if (Errores.direccion) setErrores({ ...Errores, direccion: false });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
  }, [Values.id_cliente]);

  useEffect(() => {
    setLocalStorage("PV", JSON.stringify(Values));
  }, [Values]);

  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  //#region CAMBIO DE SUCURSAL
  const [sucursalSelector, setSucursalSelector] = useState(
    getLocalStorage("SUCURSAL_SELECTOR") == null ||
      getLocalStorage("SUCURSAL_SELECTOR") == undefined ||
      getLocalStorage("SUCURSAL_SELECTOR") == ""
      ? 0
      : getLocalStorage("SUCURSAL_SELECTOR")
  );
  const [sucursalName, setSucursalName] = useState(
    getLocalStorage("SUCURSAL") == null ||
      getLocalStorage("SUCURSAL") == undefined ||
      getLocalStorage("SUCURSAL") == ""
      ? 0
      : getLocalStorage("SUCURSAL")
  );

  useEffect(() => {
    setLocalStorage("SUCURSAL_SELECTOR", sucursalSelector);
    setLocalStorage("SUCURSAL", sucursalName);
    // Obtener el cliente del mostrador de la sucursal selecciona
    console.log({ sucursalSelector, sucursalName });
    getClienteMostrador(sucursalSelector)
      .then((resp) => {
        setValues({
          ...Values,
          id_cliente: resp.id_cliente,
          nombre: resp.nombre,
          id_sucursal_venta: sucursalSelector,
        });
      })
      .catch((resp) => {
        MySwal.fire({
          title: "Error",
          html: resp.mensaje,
          icon: "error",
          confirmButtonColor: "#3ABE88",
          showConfirmButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
        }).then(() => {});
      });
  }, [sucursalSelector]);
  //#endregion
  return (
    <>
      <>
        <div
          style={{
            height: "calc(100vh - 5rem)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <StyledSnackbar
            direction="right"
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {mensaje}
            </Alert>
          </StyledSnackbar>

          {sucursalSelector == 0 ? (
            <>
              <SucursalSelector
                sucursalSelector={sucursalSelector}
                setSucursalSelector={setSucursalSelector}
                setSucursalName={setSucursalName}
              />
            </>
          ) : (
            <Row className="h-100">
              <Col
                xs={12}
                md={6}
                className="d-flex flex-column justify-content-between"
              >
                <div className="d-flex flex-column">
                  <div
                    class="flex-grow-1   "
                    style={{
                      maxHeight: "calc(100vh - 5rem)",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <BuscarProductos
                      productosCarrito={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                      IVA={Incluye_IVA}
                      sucursalSelector={sucursalSelector}
                      comisionBancaria={comisionBancaria}
                      ventaLibre={ventaLibre}
                      setDescuento={setDescuento}
                    />
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex flex-column justify-content-between bg-white"
                style={
                  {
                    //background: "green"
                  }
                }
              >
                <div class="containerZic">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="text-white fw-bolder"
                      onClick={() => {}}
                      style={{ background: "", width: "140px" }}
                    >
                      Nuevo pedido
                    </h5>
                    <div class="d-flex flex-row justify-content-end align-items-start mb-3 flex-wrap">
                      <h5
                        className="text-white fw-bolder mt-2 mx-3"
                        style={{ background: "", width: "" }}
                      >
                        {sucursalName}
                      </h5>
                      <IconButton
                        style={{ color: "#FFF" }}
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={ChangeOption}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          key={1}
                          onClick={() => {
                            cambioSucursal();
                          }}
                        >
                          Cambiar de sucursal
                        </MenuItem>

                        <MenuItem
                          key={1}
                          onClick={() => {
                            SetshowModalCorte(true);
                          }}
                        >
                          Corte de caja
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
                <div
                  className="flex-grow-1"
                  style={
                    {
                      //background: "orange",
                    }
                  }
                >
                  <TabContext value={tab}>
                    <Row className="">
                      <Col xs={12}>
                        <TabList
                          TabIndicatorProps={{
                            bgcolor: "#fd8c27",
                            style: {
                              backgroundColor: "#fd8c27",
                            },
                          }}
                          value={tab}
                          onChange={handleChangeTab}
                          variant="scrollable"
                          scrollButtons="auto"
                          textColor="#212529"
                          indicatorColor=""
                          aria-label="scrollable auto tabs example"
                        >
                          <Tab
                            value="0"
                            label="Productos"
                            className="font-AvenirBold"
                          />
                          <Tab
                            value="1"
                            label="Envío"
                            className="font-AvenirBold"
                          />
                          <Tab
                            value="2"
                            label="Dedicatoria"
                            className="font-AvenirBold"
                          />
                          <Tab
                            value="3"
                            label="Pagar"
                            className="font-AvenirBold"
                          />
                        </TabList>
                      </Col>
                      <Col xs={12}>
                        <TabPanel
                          value="0"
                          style={{ padding: 0 }}
                          className="p-0"
                        >
                          <div className="w-100 text-end pe-3">
                            <label htmlFor="">Venta libre</label>
                            <Switch
                              checked={ventaLibre}
                              name="VENTA_LIBRE"
                              error={Incluye_IVA}
                              onChange={(checked) => {
                                setVentaLibre(checked);
                              }}
                              tituloActivo="Si"
                              tituloInactivo="No"
                            />
                          </div>
                          <Carrito
                            //isVista
                            comisionBancaria={comisionBancaria}
                            productosCarrito={productosCarrito}
                            setProductosCarrito={setProductosCarrito}
                            descuento={descuento}
                            setDescuento={setDescuento}
                            cambio={0}
                            setIsRentable={setIsRentable}
                            isRentable={isRentable}
                            precioEnvio={precioEnvio}
                            tipoEnvio={Values.tipo_envio}
                            IVA={Incluye_IVA}
                            Values={Values}
                            ventaLibre={ventaLibre}
                          />
                          <div className="w-100 text-end pe-3">
                            <Link
                              className=" font-AvenirMedium text-end "
                              variant="text"
                              href="#"
                              underline="none"
                              style={{ color: "#fd8c27", textAlign: "end" }}
                              onClick={() => {
                                LimpiarDatos();
                                //console.log("datos limpios");
                              }}
                            >
                              Limpiar Carrito
                            </Link>
                          </div>
                          <div className="w-100 text-end pe-3">
                            <label htmlFor="">Incluye IVA</label>
                            <Switch
                              checked={Incluye_IVA}
                              name="INCLUYE_IVA"
                              error={Incluye_IVA}
                              disabled={ventaLibre}
                              onChange={(checked) => {
                                handleInputChangeIVA({
                                  target: {
                                    name: "INCLUYE_IVA",
                                    value: checked,
                                  },
                                });
                              }}
                              tituloActivo="Si"
                              tituloInactivo="No"
                              on
                            />
                          </div>
                          <div className="w-100 text-end pe-3">
                            <label htmlFor="">Comisión bancaria</label>
                            <Switch
                              checked={comisionBancaria}
                              disabled={ventaLibre}
                              name="COMISION_BANCARIA"
                              error={comisionBancaria}
                              onChange={(checked) => {
                                setComisionBancaria(checked);
                              }}
                              tituloActivo="Si"
                              tituloInactivo="No"
                            />
                          </div>
                        </TabPanel>
                        <TabPanel
                          value="1"
                          style={{ padding: 0 }}
                          className="p-0"
                        >
                          <TabsDatosEntrega
                            Values={Values}
                            handleInputChange={handleInputChange}
                            openModalValidations={openModalValidations}
                            expanded={expanded}
                            handleChange={handleChange}
                            Fecha={Fecha}
                            setFecha={setFecha}
                            Errores={Errores}
                            horarios={horarios}
                            setHorarios={setHorarios}
                            idSucursal={sucursalSelector}
                            checked={checked}
                            setChecked={setChecked}
                          />
                        </TabPanel>
                        <TabPanel
                          value="2"
                          style={{ padding: 0 }}
                          className="p-0"
                        >
                          <TabsDedicatoria
                            Values={Values}
                            handleInputChange={handleInputChange}
                          />
                        </TabPanel>
                        <TabPanel
                          value="3"
                          style={{ padding: 0 }}
                          className="p-0"
                        >
                          <TabsPago
                            ventaLibre={ventaLibre}
                            horarios={horarios}
                            Values={Values}
                            totalCarrito={(
                              Number(Values.total) + Number(precioEnvio)
                            ).toFixed(2)}
                            setShowModalMetodosPago={setShowModalMetodosPago}
                            IsGuardando={IsGuardando}
                            Guardar={Guardar}
                            id={id}
                            productosCarrito={productosCarrito}
                            precioEnvio={precioEnvio}
                            productos={productosCarrito}
                            setProductosCarrito={setProductosCarrito}
                            setDescuento={setDescuento}
                            descuento={descuento}
                            mensaje={mensaje}
                            setMensaje={setMensaje}
                            setOpenAlert={setOpenAlert}
                            handleInputChange={handleInputChange}
                            IVA={Incluye_IVA}
                          />
                        </TabPanel>
                      </Col>
                    </Row>
                  </TabContext>
                </div>
                <div
                  style={
                    {
                      //background: "blue",
                    }
                  }
                >
                  <Row>
                    <Stack
                      className="p-3"
                      spacing={2}
                      direction={{ xs: "column", sm: "row" }}
                      style={{}}
                    >
                      <ButtonsTabs
                        setShowModalMetodosPago={setShowModalMetodosPago}
                        tab={tab}
                        setTab={setTab}
                      />
                    </Stack>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
          {showModalDireccion ? (
            <ModalSelectDireccionCliente
              SetshowModalDireccion={SetshowModalDireccion}
              setOpenNuevaDireccion={setOpenNuevaDireccion}
              //getDireciones={getDireciones}
              id_cliente={Values.id_cliente}
              id={Values.id_direccion}
              handlInputChange={handleInputChange}
            />
          ) : (
            ""
          )}
          {openNuevaDireccion ? (
            <ModalDireccion
              SetshowModalDireccion={setOpenNuevaDireccion}
              SetshowModalDirecciones={SetshowModalDireccion}
              handlInputChanges={handleInputChange}
              id_cliente={Values.id_cliente}
            />
          ) : (
            ""
          )}
          {showModalMetodosPago ? (
            <ModalPagoVenta
              setShowModalPago={setShowModalMetodosPago}
              Values={Values}
              setValues={setValues}
              Errores={Errores}
              handlInputChange={handleInputChange}
              totalCarrito={Values.total.toFixed(2)}
              Guardar={Guardar}
              IsGuardando={IsGuardando}
              descuento={descuento}
              costoEnvio={precioEnvio}
              productosCarrito={productosCarrito}
              setProductosCarrito={setProductosCarrito}
              IVA={Incluye_IVA}
            />
          ) : (
            ""
          )}
          {showDiloglImprimir && (
            <ModalTicket
              pedidoSeleccionado={IDbeforeVenta}
              setShowDiloglImprimir={setShowDiloglImprimir}
              ticketFromVenta
            />
          )}

          {showModalCorte ? (
            <ModalCorte
              SetshowModalCorte={SetshowModalCorte}
              SetshowModalTicket={SetshowModalTicket}
            />
          ) : (
            ""
          )}

          {showModalTicket ? (
            <ModalTicket_
              SetshowModalTicket={SetshowModalTicket}
              SetshowModalCorte={SetshowModalCorte}
            />
          ) : (
            ""
          )}
        </div>
      </>
    </>
  );
}
