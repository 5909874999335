import React from "react";
import { useEffect, useState } from "react";
import ModalSelectProd from "../../Productos/Componentes/Dialogos/ModalSelectProd";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ExportarDanger from "./../../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../../assets/ExportarSucess.svg";
import Tabla from "./Tabla";
import { getLista } from "../Funciones/AjusteMercancia";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import SelectOrdenamientoProductos from "../../../../includes/SelectOrdenamientoProductos";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";

export default function RepAjusteMercancia() {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueSelect2, setValueSelect2] = useState("123");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [ValueFechaInicio, setValueFechaInicio] = useState(GetDate(-7));
  const [ValueFechaFin, setValueFechaFin] = useState(GetDate());

  useEffect(() => {
    getListaIngreso();
  }, [ValueBusqueda, ValueSelect, ValueSelect2]);

  const fechaCompletaInicio = new Date(ValueFechaInicio);
  const offsetMinutosInicio = fechaCompletaInicio.getTimezoneOffset();
  fechaCompletaInicio.setMinutes(
    fechaCompletaInicio.getMinutes() + offsetMinutosInicio
  );
  const diaInicio = fechaCompletaInicio.getDate();
  const mesInicio = fechaCompletaInicio.getMonth() + 1;
  const anioInicio = fechaCompletaInicio.getFullYear();
  const fechaInicio = `${mesInicio < 10 ? "0" : ""}${mesInicio}/${
    diaInicio < 10 ? "0" : ""
  }${diaInicio}/${anioInicio}`;
  //console.log(fechaInicio);

  const fechaCompletaFin = new Date(ValueFechaFin);
  const offsetMinutosFin = fechaCompletaFin.getTimezoneOffset();
  fechaCompletaFin.setMinutes(fechaCompletaFin.getMinutes() + offsetMinutosFin);
  const diaFin = fechaCompletaFin.getDate();
  const mesFin = fechaCompletaFin.getMonth() + 1;
  const anioFin = fechaCompletaFin.getFullYear();
  const fechaFin = `${mesFin < 10 ? "0" : ""}${mesFin}/${
    diaFin < 10 ? "0" : ""
  }${diaFin}/${anioFin}`;

  const getListaIngreso = () => {
    setIsLoading(true);
    getLista(
      0,
      0,
      ValueSelect,
      ValueSelect2,
      ValueBusqueda,
      fechaInicio,
      fechaFin
    )
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Ajuste Mercancía`;
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Ajuste de mercancía</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            {/* <Button
              className="btn btn-exp-Excel font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarSucess} />
              Exportar Excel
            </Button>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button> */}
            <Link to="add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
              >
                Crear nueva
              </Button>
            </Link>
          </Stack>
        </div>
      </div>
      <Card variant="outlined" className="p-0 shadow">
        <div className="row p-3">
          <div class="p-2" style={{ width: "200px" }}>
            <BasicDatePicker
              label={"Inicio"}
              fecha={ValueFechaInicio}
              setFecha={setValueFechaInicio}
              format="DD/MM/YYYY"
              size="small"
            />
          </div>
          <div class="p-2" style={{ width: "200px" }}>
            <BasicDatePicker
              label={"Fin"}
              fecha={ValueFechaFin}
              setFecha={setValueFechaFin}
              format="DD/MM/YYYY"
              size="small"
            />
          </div>

          <div class="p-2" style={{ width: "200px" }}>
            <SelectOrdenamientoProductos
              size="small"
              ValueSelect={ValueSelect2}
              setValueSelect={setValueSelect2}
              currencies={[
                {
                  value: "1",
                  label: "Pendiente",
                },
                {
                  value: "2",
                  label: "Procesado",
                },
                {
                  value: "3",
                  label: "Cancelado",
                },
                {
                  value: "123",
                  label: "Todas",
                },
              ]}
            />
          </div>

          <div class="p-2 mx-3" style={{ width: "150px" }}>
            <Button
              className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
              variant="contained"
              onClick={getListaIngreso}
            >
              Filtrar
            </Button>
          </div>

          <div className="col mx-5"></div>

          <div className="col mx-auto" style={{ width: "200px" }}>
            <SelectOrdenamiento
              currencies={[
                {
                  value: "1",
                  label: "Más recientes",
                },
                {
                  value: "2",
                  label: "Más antiguos",
                },
                {
                  value: "3",
                  label: "Folio descendente",
                },
                {
                  value: "4",
                  label: "Folio ascendente",
                },
              ]}
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
              size="small"
            />
          </div>
        </div>

        <Row>
          <Col sm={12}>
            <Tabla
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              updateTable={getListaIngreso}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
