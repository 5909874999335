import { useState } from "react";
import { IMG } from "../../../../Lib/Funciones Generales/Constantes"
import ModalCargarImagen from "../../../../Lib/Imagen/Componentes/ModalCargarImagen"
import { Backdrop, Button, CircularProgress, Dialog } from "@mui/material";
import Modal from "../../../../Includes/Modal";

export const ModalPedidoTerminado = ({ id, showModal, handleClose, handleLoad }) => {
    const [loading, setLoading] = useState(false);
    const [uploadImage, setUploadImage] = useState(false);
    const fnDevolverImagen = async (img) => {
        setLoading(true);
        handleLoad(img).then(() => {
            console.log("object");
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error)
        })
    }
    return <>
    <Backdrop sx={{ color: "#fff", zIndex: Number.MAX_SAFE_INTEGER }}  open={loading && !uploadImage}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <Modal maxWidth="sm" Titulo="Pedido terminado" textoGuardar="" mostrar={true} fnCerrar={handleClose}>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <Button onClick={() => setUploadImage(true)} className="btn btn-Crexendo font-AvenirMedium py-2 px-4 mb-3" variant="contained">
                    Seleccionar imagen
                </Button>
                <Button onClick={()=>fnDevolverImagen("")} className="font-AvenirMedium py-2 px-4" variant="text" style={{color:"#fe8d27", textDecoration:"unset", textTransform:"unset"}}>
                    Continuar sin imagen
                </Button>
            </div>
        </Modal>
        {uploadImage &&(
            <ModalCargarImagen
                tipo={IMG.TIPO_PEDIDO}
                Titulo="Cargar imagen de pedido"
                mostrar={showModal}
                fnCerrar={() => { setUploadImage(false) }}
                fnDevolverImagen={fnDevolverImagen}
                ConfigRecortar={{
                    requerida: true,
                    aspectoAlto: 16,
                    aspectoAncho: 9,
                }}
                loadingParent={loading}
            />
        )}

    </>
}