import React from "react";
import {
  Grid,
  Stack,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  T,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function ChartDonut({
  titulo = "",
  widthChart = 350,
  values = [],
  Labels = [],
  colorsArr = [
    "#FF9E3D",
    "#7FE5F3",
    "#005BD6",
    "#96DFAF",
    "#EDDC86",
    "#FF0000",
    "#FF0080",
    "#000000",
  ],
  Height = "",
}) {
  let indexColor = 0;
  const ops = {
    series: values,
    options: {
      chart: {
        type: "donut",
        height: 550,
        width: "100%",
        offsetX: 2,
      },
      labels: Labels,
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
        position: "left",
        offsetY: 100,
      },
      fill: {
        colors: colorsArr,
      },
      markers: {
        colors: colorsArr,
      },
      colors: colorsArr,
    },
  };

  return (
    <>
      <Card className="shadow-sm" elevation={3} style={{ height: Height }}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">{titulo}</h6>
          </div>
          <hr
            style={{
              backgroundColor: "#DFDEE0",
              height: "1px",
              opacity: "1",
            }}
            className="m-0 p-0"
          />
          <div lassName="p-3 m-3">
            <Row
              className="p-3 m-2"
              style={{
                background: "",
                //height: "400px"
                height: "80%",
              }}
            >
              <Col>
                <Row>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chart
                      options={ops.options}
                      series={ops.series}
                      type="donut"
                      width={widthChart}
                    />
                  </Grid>
                </Row>
                <Row
                  className="ms-3 mt-4"
                  style={{
                    overflowY: "scroll",
                    //height: "150px",
                    height: "30%",
                    overflow: "auto",
                  }}
                >
                  {Labels.map((item, i) => {
                    //console.log(item);
                    //indexColor = i + 1 == colorsArr.length ? 0 : i;
                    const list = (
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ marginBottom: "5px" }}
                      >
                        <RadioButtonUncheckedIcon
                          fontSize="12px"
                          style={{
                            color: colorsArr[indexColor],
                            marginRight: "4px",
                          }}
                        />
                        <Typography>{item}</Typography>
                      </Grid>
                    );
                    if (i + 1 == colorsArr.length) {
                      indexColor = 0;
                    } else {
                      indexColor += 1;
                    }
                    //console.log(indexColor);
                    return list;
                  })}
                </Row>
              </Col>
            </Row>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
