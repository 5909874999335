import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";

const Buscador = ({ handleChangeBuscador, placeholder, size = "medium" }) => {
    return (
        <FormControl fullWidth variant="outlined" size={size}>
            <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
            <OutlinedInput
                id="Buscar"
                placeholder={placeholder}
                onChange={handleChangeBuscador}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                label="Buscar"
            />
        </FormControl>
    )
}

export default Buscador