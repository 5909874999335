import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";
import { json } from "react-router-dom";

export const guardar = async (
  Values = {},
  Errores = {},
  setErrores = () => {},
  productosCarrito = [],
  id
) => {
  let error = false;

  if (
    !Values.id_sucursal_origen ||
    Values.id_sucursal_origen == "" ||
    Values.id_sucursal_origen == "0" ||
    Values.id_sucursal_origen == 0
  ) {
    Values.id_sucursal_origen = 0; //Puede ir en cero la sucursal
  }

  if (
    !Values.id_almacen_origen ||
    Values.id_almacen_origen == "" ||
    Values.id_almacen_origen == "0" ||
    Values.id_almacen_origen == 0
  ) {
    Errores.id_almacen_origen = true;
    setErrores(Errores);
    error = true;
  }
  if (
    !Values.id_sucursal_destino ||
    Values.id_sucursal_destino == "" ||
    Values.id_sucursal_destino == "0" ||
    Values.id_sucursal_destino <= 0
  ) {
    Values.id_sucursal_destino = 0; //Puede ir en cero la sucursal
  }
  if (
    !Values.id_almacen_destino ||
    Values.id_almacen_destino == "" ||
    Values.id_almacen_destino == "0" ||
    Values.id_almacen_destino <= 0
  ) {
    Errores.id_almacen_destino = true;
    setErrores(Errores);
    error = true;
  }

  if (productosCarrito.length <= 0 && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Ingresa al menos un producto " });
    });
  }

  Values.productos = productosCarrito;
  Values.id = Number(Values.id);
  Values.id_almacen_destino = Number(Values.id_almacen_destino);
  Values.id_almacen_origen = Number(Values.id_almacen_origen);

  if (!error) {
    const body = Values;
    //console.log(body);
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "traspasomercancia/Insertar"
        : "traspasomercancia/Editar";
    const res = await postdData(url, body);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
    //console.log("Datos", res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getLista = async (
  iTake,
  iSkip,
  Order,
  query,
  fechaInicio,
  fechaFin
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
  };
  const url = `traspasomercancia/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoTraspasoMerca = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `traspasomercancia/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data?.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este ingreso de mercancia",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];

        let d = {
          values: {
            id: (data.ID),
            id_sucursal_origen: data.ID_SUCURSAL_ORIGEN,
            id_almacen_origen: (data.ID_ALMACEN_ORIGEN),
            id_sucursal_destino: (data.ID_SUCURSAL_DESTINO),
            id_almacen_destino: (data.ID_ALMACEN_DESTINO),
            id_repartidor: (data.ID_REPARTIDOR),
            observaciones: String(data.OBSERVACIONES),
            productos: [],
          },
          productos: JSON.parse(res.data.respuesta.productos),
        };
        resolve(d);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const CancelarTraspaso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `traspasomercancia/Cancelar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const ProcesarTraspaso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `traspasomercancia/TraspasoProcesado?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
