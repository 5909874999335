import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getDescuentos = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `descuentos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getDescuentosVigentes = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {};

  const url = `descuentos/ListarVigentes?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarDescuento = async (Values, Errores, setErrores) => {
  let error = false;
  console.log(Values);
  if (
    !Values.cantidad ||
    Values.cantidad == "" ||
    Values.caducidad === 0 ||
    Values.cantidad === "0"
  ) {
    Errores.cantidad = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.descripcion || Values.descripcion == "") {
    Errores.descripcion = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.tipo_de_descuento || Values.tipo_de_descuento == "") {
    Errores.tipo_de_descuento = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.fecha_de_caducidad || Values.fecha_de_caducidad == "") {
    Errores.fecha_de_caducidad = true;
    setErrores(Errores);
    error = true;
  } else {
    const hoy = GetDate();

    if (Values.fecha_de_caducidad < hoy) {
      Errores.fecha_de_caducidad = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (
    !Values.descuento_max ||
    Values.descuento_max == "" ||
    Values.descuento_max === 0 ||
    Values.descuento_max === "0"
  ) {
    Errores.descuento_max = true;
    setErrores(Errores);
    error = true;
  }

  Values.cantidad = parseFloat(Values.cantidad);
  Values.descuento_max = parseFloat(Values.descuento_max);

  console.log(Errores);

  if (!error) {
    let data = [];

    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "descuentos/Insertar"
        : "descuentos/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarDescuento = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `descuentos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoDescuento = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `descuentos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta descuento",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Value = {
          id: String(data.ID),
          cantidad: String(data.CANTIDAD),
          descripcion: String(data.DESCRIPCION),
          tipo_de_descuento: String(data.TIPO_DE_DESCUENTO),
          fecha_de_caducidad: String(data.FECHA_DE_CADUCIDAD),
          descuento_max: String(data.DESCUENTO_MAX),
          caducidad: String(data.CADUCIDAD),
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `descuentos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `descuentos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
