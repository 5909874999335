import React, { useState, useEffect } from "react";
import {
  DialogContent,
  Button,
  TextField,
  Card,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Box,
  Stack,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import TabReceptor from "./TabsFacturacion/TabReceptor";
import {
  getDatosFacturacion,
  timbrarCFDICompra,
} from "../../Funciones/Pedidos";
import TabConceptos from "./TabsFacturacion/TabConceptos";
import TabDatosCFDI from "./TabsFacturacion/TabDatosCFDI";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

export default function ModalFacturacion({
  pedidoSeleccionado,
  getListaPedidos,
  setShowDialogFacturacion = () => {},
}) {
  const [IsGuardando, setIsGuardando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [tab, setTab] = useState(0);
  const [IsLoading, setIsLoading] = useState(true);
  const [Values, setValues] = useState({
    emisor: {},
    receptor: {},
    conceptos: [],
  });
  const [ivaTotal, setIvaTotal] = useState(0);
  const [totalProds, setTotalProds] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  /*id cliente 
rsus datos fiscales (rfc y todo eso )
y todo lo que no este    deshabilitado en el formulario de datos del cfdi*/
  const [ValuesSend, setValuesSend] = useState({
    id_cliente: "",
    rfc: "",
    razon_social: "",
    regimen_fiscal: "",
    cp: "",
    fecha_emision: "0",
    serie: "",
    forma_pago: "01",
    metodo_pago: "PUE",
    uso_cfdi: "G03",
    observaciones: "",
  });
  const [Errores, setErrores] = useState({
    id_cliente: false,
    rfc: false,
    razon_social: false,
    regimen_fiscal: false,
    cp: false,
    fecha_emision: false,
    serie: false,
    forma_pago: false,
    metodo_pago: false,
    uso_cfdi: false,
  });

  const handleInputChange = ({ target }) => {
    let Value = target.value;
    const Name = target.name;

    setValuesSend({ ...ValuesSend, [Name]: Value });
    setErrores({ ...Errores, [Name]: false });
  };

  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const guardar = () => {
    setIsGuardando(true);
    timbrarCFDICompra(ValuesSend, Errores, setErrores, pedidoSeleccionado)
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 10000,
          }).then((result) => {
            setShowDialogFacturacion(false);
            getListaPedidos();
          });
        } else {
          MySwal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            setIsGuardando(false);
          });
        }
      })
      .catch((data) => {
        console.log(data);
        MySwal.fire({
          title: "Error",
          html: data.mensaje,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          setIsGuardando(false);
        });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getDatosFacturacion(pedidoSeleccionado)
      .then((data) => {
        //console.log("facturacion", data);
        if (data.conceptos.length == 0) {
          MySwal.fire({
            title: "Advertencia",
            html: "No se encontraron productos para esta venta",
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 10000,
          }).then((result) => {
            setShowDialogFacturacion(false);
            //console.log(Values);
          });
        }
        setValues(data);
        setValuesSend({
          ...ValuesSend,
          id_cliente: data.receptor.ID_USUARIO,
          rfc: data.receptor.RFC,
          razon_social: data.receptor.RAZON_SOCIAL,
          regimen_fiscal: data.receptor.REGIMEN_FISCAL,
          cp: data.receptor.CP,
        });
        let t = 0;
        let p = 0;
        let s = 0;
        if (data.conceptos.length > 0) {
          data.conceptos.map((item) => {
            t = t + item.TOTAL_IVA * item.CANTIDAD;
            p = p + item.PRECIO * item.CANTIDAD;
            s = s + item.PRECIO_SIN_IVA * item.CANTIDAD;
          });
        }
        setIvaTotal(t);
        setTotalProds(p);
        setSubTotal(s);
        console.log(ivaTotal, totalProds, subTotal);
        console.log(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Dialog fullWidth maxWidth="lg" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setShowDialogFacturacion(false);
          }}
        >
          <CloseIcon style={{ width: "40px", height: "40px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h6 className="fw-bolder f-12 mt-2">Datos de facturación</h6>
          </DialogTitle>
        }

        <DialogContent>
          <Box className=" mb-2">
            <Tabs
              TabIndicatorProps={{
                bgcolor: "#3abe88",
                style: {
                  backgroundColor: "#3abe88",
                },
              }}
              value={tab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="#212529"
              indicatorColor=""
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Receptor" className="font-AvenirBold" />
              <Tab label="Conceptos" className="font-AvenirBold" />
              <Tab label="Datos de CFDI" className="font-AvenirBold" />
            </Tabs>
          </Box>
          <div className="row" style={{ minHeight: "45vh" }}>
            {tab == 0 ? (
              <TabReceptor
                data={Values}
                IsLoading={IsLoading}
                ValuesSend={ValuesSend}
                handleInputChange={handleInputChange}
                Errores={Errores}
              />
            ) : tab == 1 ? (
              <TabConceptos
                data={Values}
                ivaTotal={ivaTotal}
                totalProds={totalProds}
                subTotal={subTotal}
              />
            ) : (
              <TabDatosCFDI
                id_pedido={pedidoSeleccionado}
                ValuesSend={ValuesSend}
                handleInputChange={handleInputChange}
                Errores={Errores}
              />
            )}
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-between flex-wrap">
            <div>
              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <Button
                  onClick={() => {
                    const nextTab = tab - 1 < 0 ? 0 : tab - 1;
                    setTab(nextTab);
                  }}
                  className="btn btn-exp-Excel font-AvenirMedium py-2 px-4  "
                  variant="text"
                >
                  <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
                  Anterior
                </Button>

                <Button
                  onClick={() => {
                    const nextTab = tab + 1 > 3 ? 3 : tab + 1;
                    setTab(nextTab);
                  }}
                  className="btn btn-exp-Excel font-AvenirMedium py-2 px-4  "
                  variant="text"
                >
                  Siguiente
                  <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
                </Button>
              </Stack>
            </div>
            <div>
              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <Button
                  onClick={() => {
                    setShowDialogFacturacion(false);
                    //(-1)
                  }}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Cancelar
                </Button>

                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => guardar()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Timbrando..." : "Facturar pedido"}
                  </span>
                </LoadingButton>
              </Stack>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
