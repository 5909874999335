import React, { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import RepPedidos from "./Compomentes/RepPedidos";

const RutasPedidos = () => {
  const notistackRef = useRef();
  return (
    <>
      <Routes>
        <Route index element={<RepPedidos />} />
        {/* <Route path="/notificaciones" element={<Notificaciones />} /> */}
        {/* <Route path="/add" element={<Cupon />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default RutasPedidos;