import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../../../Context/backend";
import RFCValidator from "../../../../../Lib/Funciones Generales/ValidarRFC";

export const getSizeCaducidades = (caducidades) => {
  let size = 0;
  caducidades.map(x => {
    size += parseFloat(x.cantidad_ingresada)
  })

  return size;
}

export const getLista = async (iTake, iSkip, Order, query, tipoProd) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    cat: 0,
    idSucursal: 0,
    TIPO: tipoProd ? tipoProd : 0,
  };
  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const getInfoProducto = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `productos/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(JSON.parse(res.data.respuesta));
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este producto",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let imgs = JSON.parse(res.data.respuesta.imagenes);
        let composicion = JSON.parse(res.data.respuesta.composicion);
        let colores = JSON.parse(res.data.respuesta.colores);
        let tamanos = JSON.parse(res.data.respuesta.tamanos);
        

        let Values = {
          data: {
            id: String(data.ID),
            codigo: String(data.CODIGO),
            id_categoria: String(data.ID_CATEGORIA),
            desc_corta: String(data.DESC_CORTA),
            desc_larga: String(data.DESC_LARGA),
            precio: String(data.PRECIO),
            //p_venta: String(data.PRECIO_VENTA),
            p_compra: String(data.PRECIO_COMPRA),
            id_proveedor: String(data.ID_PROVEEDOR),
            //materia prima
            isMateriaPrima: Boolean(data.IS_MATERIAPRIMA),
            //inventariable
            isInventariable: Boolean(data.IS_INVENTARIABLE),
            stock_max: String(data.STOCK_MAX),
            stock_min: String(data.STOCK_MIN),
            id_sucursal: String(data.ID_SUCURSAL),
            id_almacen: String(data.ID_ALMACEN),
            //rentable
            isRentable: Boolean(data.IS_RENTABLE),
            //Perecedero
            isPerecedero: Boolean(data.IS_PERECEDERO),
            //Es ecommerce
            is_ecommerce: Boolean(data.IS_ECOMMERCE),
            //p_dia: String(data.PRECIO_DIA),
            p_horaExtra: String(data.PRECIO_HORAEXTRA),
            p_penalizacion: String(data.PRECIO_PENALIZACION),
            //imagenes
            galeriaImg: imgs,
            //sat
            isFacturable: Boolean(data.IS_FACTURABLE),
            unidad_medida: String(data.UNIDAD_MEDIDA),
            clave_unidad_medida_sat: String(data.CLAVE_UNIDAD_MEDIDA_SAT),
            clave_codigo_prod_sat: String(data.CLAVE_CODIGO_PROD_SAT),
            moneda: String(data.MONEDA),
            iva: String(data.IVA),
            img: String(data.IMG),
            //composicion
            composicion: composicion,
            //is_productoTerminado
            is_productoTerminado: Boolean(data.IS_PRODUCTO_TERMINADO),

            //adicionales
            colores,
            tamanos
          },
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getSucursalesByProd = async (ID) => {
  let data = [];
  let queryParamsObj = {
    ID_PRODUCTO: ID,
  };
  const url = `productos/getSucurslesRelacion?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `productos/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `productos/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Guardar = async (
  Values,
  Errores,
  setErrores,
  productosCarrito,
  sucursales
) => {
  let error = false;
  if (!Values.codigo || Values.codigo == "") {
    error = true;
    Errores.codigo = true;
    setErrores(Errores);
  }

  if (!Values.id_categoria || Values.id_categoria == "") {
    Values.id_categoria = 0;
  }

  if (!Values.isMateriaPrima) {
    if (Values.id_categoria == 0) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "La categoría es requerida" });
      });
    }
  }

  if (!Values.desc_corta || Values.desc_corta == "") {
    error = true;
    Errores.desc_corta = true;
    setErrores(Errores);
  }

  if (!Values.p_compra || Values.p_compra == "") {
    error = true;
    Errores.p_compra = true;
    setErrores(Errores);
  }
  if (!Values.id_proveedor || Values.id_proveedor == "") {
    error = true;
    Errores.id_proveedor = true;
    setErrores(Errores);
  }

  if (!Values.isMateriaPrima) {
    if (!validator.isNumeric(Values.precio)) {
      error = true;
      Errores.precio = true;
      setErrores(Errores);
    }
  }

  if (!Values.isMateriaPrima) {
    if (Values.isRentable) {
      if (!validator.isNumeric(Values.p_horaExtra)) {
        error = true;
        Errores.p_horaExtra = true;
        setErrores(Errores);
      }
      if (!validator.isNumeric(Values.p_penalizacion)) {
        error = true;
        Errores.p_penalizacion = true;
        setErrores(Errores);
      }
    }
  }

  if (Values.isInventariable) {
    //console.log("isinventariable");
    if (!validator.isNumeric(Values.stock_min)) {
      error = true;
      Errores.stock_min = true;
      setErrores(Errores);
    }
    if (!validator.isNumeric(Values.stock_max)) {
      error = true;
      Errores.stock_max = true;
      setErrores(Errores);
    }

    if (!Values.isMateriaPrima) {
      // if (
      //   !Values.id_sucursal ||
      //   Values.id_sucursal == "" ||
      //   Values.id_sucursal == "0"
      // ) {
      //   error = true;
      //   Errores.id_sucursal = true;
      //   setErrores(Errores);
      // }
      // if (
      //   !Values.id_sucursal ||
      //   Values.id_sucursal == "" ||
      //   Values.id_sucursal == "0"
      // ) {
      //   error = true;
      //   Errores.id_sucursal = true;
      //   setErrores(Errores);
      // }
      // if (
      //   !Values.id_almacen ||
      //   Values.id_almacen == "" ||
      //   Values.id_almacen == "0"
      // ) {
      //   error = true;
      //   Errores.id_almacen = true;
      //   setErrores(Errores);
      // }
    }
  }
  if (!Values.unidad_medida || Values.unidad_medida == "") {
    error = true;
    Errores.unidad_medida = true;
    setErrores(Errores);
  }

  if (Values.isFacturable) {
    if (
      !Values.clave_unidad_medida_sat ||
      Values.clave_unidad_medida_sat == ""
    ) {
      error = true;
      Errores.clave_unidad_medida_sat = true;
      setErrores(Errores);
    }
    if (!Values.clave_codigo_prod_sat || Values.clave_codigo_prod_sat == "") {
      error = true;
      Errores.clave_codigo_prod_sat = true;
      setErrores(Errores);
    }
    if (!Values.moneda || Values.moneda == "") {
      error = true;
      Errores.moneda = true;
      setErrores(Errores);
    }
    if (!Values.iva || Values.iva == "") {
      error = true;
      Errores.iva = true;
      setErrores(Errores);
    }
  }

  const relacion = sucursales
    .filter((objeto) => objeto.ACTIVO === 1)
    .map((objeto) => objeto.ID);

  if (!error) {
    let imgs = [];
    try {
      Values.galeriaImg?.forEach((element) => {
        if (element.IMG?.includes("base64")) {
          const newElement = JSON.parse(JSON.stringify(element));
          newElement.IMG = element.IMG?.split("base64,")[1];
          const d = {};
          d.ID = newElement.ID;
          d.IMG = newElement.IMG;
          d.PRINCIPAL = element.PRINCIPAL;
          imgs.push(d);
        } else {
          const d = {};
          d.ID = element.ID;
          d.IMG = element.IMG;
          d.PRINCIPAL = element.PRINCIPAL;
          imgs.push(d);
        }
        ///console.log("ok");
      });
      Values.galeriaImg = imgs;
    } catch (e) {
      console.log(e);
    }

    let data = [];
    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "productos/Insertar"
        : "productos/Editar";
    const body = {
      id: Values.id,
      codigo: Values.codigo,
      iD_CATEGORIA: Values.id_categoria,
      desC_CORTA: Values.desc_corta,
      desC_LARGA: Values.desc_larga,
      precio: Values.isMateriaPrima ? "0" : Values.precio,
      p_COMPRA: Values.p_compra,
      iD_PROVEEDOR: Values.id_proveedor,
      iS_MATERIA_PRIMA: Values.isMateriaPrima,
      isinventariable: Values.isInventariable,
      stocK_MAX: Values.isInventariable ? Values.stock_max : "",
      stocK_MIN: Values.isInventariable ? Values.stock_min : "",
      ID_SUCURSAL: Values.isInventariable ? Values.id_sucursal : 0,
      ID_ALMACEN: Values.isInventariable ? Values.id_almacen : 0,
      isrentable: Values.isMateriaPrima ? false : Values.isRentable,
      p_HORAEXTRA: Values.isMateriaPrima
        ? ""
        : Values.isRentable
          ? Values.p_horaExtra
          : "",
      p_PENALIZACION: Values.isMateriaPrima
        ? ""
        : Values.isRentable
          ? Values.p_penalizacion
          : "",
      isfacturable: Values.isFacturable,
      unidaD_MEDIDA: Values.unidad_medida,
      clavE_UNIDAD_MEDIDA_SAT: Values.isFacturable
        ? String(Values.clave_unidad_medida_sat)
        : "0",
      clavE_CODIGO_PROD_SAT: Values.isFacturable
        ? String(Values.clave_codigo_prod_sat)
        : "0",
      moneda: Values.isFacturable ? Values.moneda : "",
      iva: Values.isFacturable ? Values.iva : "",
      IS_PERECEDERO: Values.isPerecedero,
      is_ecommerce: Values.is_ecommerce,
      galeriaImg: Values.galeriaImg,
      composicion: [...productosCarrito],
      iS_PRODUCTOTERMINDO: Values.is_productoTerminado,
      relacion: relacion,
      colores: Values.colores,
      tamanos: Values.tamanos
    };
    //console.log(body);
    const res = /* {
      error: false,
      data: { codigo: "201", mensaje: "okey" },
    }; */ await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
export const getListaByCategoria = async (
  cat,
  query,
  suc = 0,
  ecommerceedith = 0
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: 0,
    iSkip: 0,
    Order: 1,
    query: query,
    cat: cat,
    idSucursal: suc,
    ecommerceedith: ecommerceedith,
  };

  const url = `productos/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      
      resolve(data);
    } else {
      reject(res);
    }
  });
};
export const getListaProdProve = async (
  id,
  //onlyProductosTerminados = false,
  onlyInvent = false,
  onlyMateriaPrima = false,
  existencias = false,
  id_almacen = 0,
  id_sucursal = 0,
  ValueBusqueda
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    id_proveedor: id,
    onlyInventariables: onlyInvent,
    onlyMateriaPrima: onlyMateriaPrima,
    existencias: existencias,
    //onlyProductosTerminados: onlyProductosTerminados,
    id_almacen: id_almacen,
    id_sucursal: id_sucursal,
    query: ValueBusqueda,
  };
  const url = `productos/buscarProductos?${stringify(queryParamsObj)}`;

  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const ActualizarImgPrincipal = async (id, url) => {
  let data = [];
  let queryParamsObj = { id, url };
  const urlAPI = `productos/ActualizarImgPrincipal?${stringify(
    queryParamsObj
  )}`;
  const res = await postUrl(urlAPI);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      //console.log(data);
      resolve(data);
    } else {
      //res.mensaje = "asdas";
      reject(res);
    }
  });
};

export const getOpcionesProd = async (query = 0) => {
  let data = [];
  let queryParamsObj = {
    Opc: query,
  };
  const url = `productos/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoUnidadMedida = async () => {
  let data = [];
  const url = `sat/ConsultarUnidadMedidaInterna`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};




export const EliminarColor = async (id_producto, id) => {
  if (id_producto != undefined && id != undefined) {
    let data = [];
    let queryParamsObj = {
      id_producto: id_producto,
      id: id,
    };
    const url = `productos/EliminarColor?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};


export const EliminarTamano = async (id_producto, id) => {
  if (id_producto != undefined && id != undefined) {
    let data = [];
    let queryParamsObj = {
      id_producto: id_producto,
      id: id,
    };
    const url = `productos/EliminarTamano?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};