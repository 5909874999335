import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../../../Context/backend";
import RFCValidator from "../../../../../Lib/Funciones Generales/ValidarRFC";

export const getLista = async (
  iTake,
  iSkip,
  Order,
  query,
  fechaInicio,
  fechaFin,
  sucursal,
  almacen,
  movimiento
) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    FechaInicio: fechaInicio,
    FechaFin: fechaFin,
    Suc: sucursal ? sucursal : 0,
    Alm: almacen ? almacen : 0,
    tipo: movimiento,
  };

  //console.log(queryParamsObj);
  const url = `bitacora/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(res);
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getRel = async (id) => {
  //onsole.log(query)

  let data = [];
  let queryParamsObj = {
    ID_BITACORA: id,
  };

  //console.log(queryParamsObj);
  const url = `bitacora/getrel?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(res);
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
