import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepFacturas from "./Componentes/RepFacturas";
import PDFFactura from "./Componentes/PDFFactura";
import NeuvaFactura from "./Componentes/NuevaFactura";

const routerFacturas = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepFacturas />} />
        <Route path="/add" element={<NeuvaFactura />} />
        {/* <Route path="/pdf/:id?" element={<PDFFactura />} /> 
        <Route path="/pdf/:uuid?" element={<PDFFactura />} />  */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerFacturas;
