import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import OrdenCompra from "./Componentes/OrdenCompra";
import RepOrdenCompra from "./Componentes/RepOrdenCompra";




const routerOrdencompra = () => {
    return (
        <>
            <Routes>
                <Route index element={<RepOrdenCompra />} />
                <Route path="/add" element={<OrdenCompra />} />
                <Route path="/edit/:id" element={<OrdenCompra />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </>
    );
}

export default routerOrdencompra