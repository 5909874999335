
import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CambiarEstatus = ({
  setShowDiloglEstatutus,
  setPedidoSeleccionado,
  id,
  isDiv = true,
}) => {

  const View = () => {
    return (
      <Tooltip title="Cambiar estatus" placement="top" className="me-2">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDiloglEstatutus(true);
          }}
        >
          <CheckCircleIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Tooltip>
    )
  }

  const ButtonView = (data) => {
    if (isDiv) {
      return (
        <div className="w-100">
          {data}
        </div>
      )
    }
    return data;
  }

  return ButtonView(View())
};

export default CambiarEstatus;
