import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../../../Context/backend";
import validator from "validator";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import RFCValidator from "../../../../../Lib/Funciones Generales/ValidarRFC";
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async () => {
  let data = [];
  const url = `configEmpresas/Listar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoEmpresa = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `configEmpresas/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          direccion: String(data.DIRECCION),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          regimen_fiscal: String(data.REGIMEN),
          cp: String(data.CP),
          url_pagina: String(data.URL_PAGINA),
          cer_pas: String(data.CER_PASS),
          cer_key: String(data.CER_KEY),
          cer_cer: String(data.CER_CER),
          img: String(data.IMG) == "" ? imgDefault : String(data.IMG),
          comision_fija: String(data.COMISION_FIJA),
          porcentaje_comision: String(data.PORCENTAJE_COMISION),
          correos_notificaciones: String(data.CORREOS_NOTIFICACIONES),
          link_encuesta_satisfaccion: String(data.LINK_ENCUESTA_SATISFACCION),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getComisiones = async () => {
  let data = [];
  const url = `configEmpresas/getComisiones`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;

    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.direccion || Values.direccion == "") {
    let Errores2 = Errores;

    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.razon_social || Values.razon_social == "") {
    let Errores2 = Errores;

    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.correos_notificaciones || Values.correos_notificaciones == "") {
    Errores.correos_notificaciones = true;
    Errores.correos_notificaciones = "Introduce por lo menos un correo";
    setErrores(Errores);
    error = true;
  } else {
    let correos = Values.correos_notificaciones.split(";");
    let errorCorreos = false;
    correos.forEach((c) => {
      if (errorCorreos === true) return;
      if (!validator.isEmail(c.trim())) {
        Errores.correos_notificaciones = `"${c}" no es un correo válido`;
        setErrores(Errores);
        error = true;
        errorCorreos = true;
      }
    })
  }

  // if(!Values.link_encuesta_satisfaccion || Values.link_encuesta_satisfaccion == ""){
  //   Errores.link_encuesta_satisfaccion = true;
  //   Errores.link_encuesta_satisfaccion = "Introduce la encuesta de satisfacción";
  //   setErrores(Errores);
  //   error = true;
  // }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    let Errores2 = Errores;
    Errores.cp = true;
    setErrores(Errores2);
    error = true;
  }

  if (
    !Values.comision_fija ||
    Values.comision_fija == "" ||
    Number(Values.comision_fija) < 0
  ) {
    let Errores2 = Errores;
    Errores.comision_fija = true;
    setErrores(Errores2);
    error = true;
  }
  if (
    !Values.porcentaje_comision ||
    Values.porcentaje_comision == "" ||
    Number(Values.porcentaje_comision) < 0
  ) {
    let Errores2 = Errores;
    Errores.porcentaje_comision = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    let Errores2 = Errores;
    Errores2.rfc = true;
    setErrores(Errores2);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    let Errores2 = Errores;
    Errores2.regimen_fiscal = true;
    setErrores(Errores2);
    error = true;
  }

  Values.regimen_fiscal = String(Values.regimen_fiscal);

  if (!error) {
    if (Values.img.includes("base64")) {
      Values.img = Values.img.split("base64,")[1];
    } else {
      if (Values.img.includes("https")) {
      } else {
        Values.img = "";
      }
    }

    if (
      Values.id == "0" ||
      Values.id == 0 ||
      (Values.id == "" && Values.img != "")
    ) {
      Values.img_nueva = {
        id: "",
        tipo: IMG.TIPO_EMPRESA,
        img64: Values.img,
      };
      Values.img = "";
    }

    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "configEmpresas/Editar"
        : "configEmpresas/Editar";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getOpcionesEmpresas = async () => {
  let data = [];
  let queryParamsObj = {};
  const url = `configEmpresas/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const CargarArchivo = async (Values) => {
  let data = [];
  const body = Values
  const url = "configEmpresas/upload";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const CambiarEmpresa = async (key) => {
  if (key != undefined) {
    let data = [];
    let queryParamsObj = {
      key: key,
    };
    const url = `configEmpresas/cambiarEmpresa?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
