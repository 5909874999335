import React, { useEffect, useState } from "react";
import logo from "../assets/Logoblanco.svg"; 
const StartLoad = ({ isLoged }) => {
  // const [logueado, setLogueado] = useState(true);

  return (
    <div className="d-flex justify-content-center  ling-items-center" style={{height:"100vh",   backgroundColor: "#101727"}}>
      {<img src={logo} alt="asd" className="img-fluid" style={{maxWidth:"150px"}} />} 
    </div>
  );
};

export default StartLoad;
