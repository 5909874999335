import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepZonas from "./Componentes/RepZonas";
import Zona from "./Componentes/Zonas";

const routerZonas = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepZonas />} />
        <Route path="/add" element={<Zona />} />
        <Route path="/edit/:id" element={<Zona />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerZonas;
