import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";

export const crearDia = (DIA = 0, ID_SUCURSAL = 0, ACTIVO = 0) => {
  return {
    ID: 0,
    DIA: DIA,
    ACTIVO: ACTIVO,
    ID_SUCURSAL: ID_SUCURSAL,
    INICIO: "00:00",
    FIN: "00:00",
    MAX_PEDIDOS: '',
  }
}


export const validarHorario = async (Values, Errores, fecha_actual, Horarios = null, validaRepetidos = false) => {
  let respuesta = null
  let inicio = new Date(fecha_actual + " " + Values.INICIO)
  let fin = new Date(fecha_actual + " " + Values.FIN)
  if (inicio.getTime() > fin.getTime()) {
    Errores.INICIO = { mostrar: true, mensaje: "La hora de inicio es mayor al fin." }
    Errores.FIN = { mostrar: false, mensaje: "" }
    Errores.MAX_PEDIDOS = { mostrar: false, mensaje: "" }
    respuesta = { mensaje: "Completa todos los campos requeridos." };
  }

  if (Values.INICIO.substring(0, 5) == Values.FIN.substring(0, 5)) {
    Errores.INICIO = { mostrar: true, mensaje: "La hora es igual" }
    Errores.FIN = { mostrar: true, mensaje: "La hora es igual" }
    Errores.MAX_PEDIDOS = { mostrar: false, mensaje: "" }
    respuesta = { mensaje: "Completa todos los campos requeridos." };
  }

  if (parseInt(Values.MAX_PEDIDOS) == 0 || isNaN(parseInt(Values.MAX_PEDIDOS))) {
    Errores.MAX_PEDIDOS = { mostrar: true, mensaje: "El campo de pedidos es requerido" }
    respuesta = { mensaje: "Completa todos los campos requeridos." };
  }

  // INICIO BETWEEN '05:05:00' AND '08:00:00'
  // OR FIN BETWEEN '05:05:00' AND '08:00:00'

  console.log(validaRepetidos, Horarios)
  if (validaRepetidos && Horarios) {
    if (Array.isArray(Horarios)) {
      Horarios.map(horario => {
        let inicioHorario = new Date(fecha_actual + " " + horario.INICIO)
        let finHorario = new Date(fecha_actual + " " + horario.FIN)
        if ((inicioHorario.getTime() >= inicio.getTime() && inicioHorario.getTime() <= fin.getTime()) ||
          (finHorario.getTime() >= inicio.getTime() && finHorario.getTime() <= fin.getTime())) {
          respuesta = { mensaje: "El horario de " + Values.INICIO + " a " + Values.FIN + " no esta disponible." };
        }
      })
    }
    // console.log("Validando repetidos: ", Values)
  }

  return respuesta;
};

///////////////////
/* FUNCIONES GET */
///////////////////
export const getHorariosEnvios = async (id_sucursal = 0) => {
  let data = [];
  let queryParamsObj = { id_sucursal };

  console.log("SUCURSAL: ", id_sucursal)

  const url = `horarios/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};



export const guardarHorarioEnvio = async (Values, Errores, setErrores, fecha_actual) => {
  let error = false;

  // console.log(Values)
  // console.log("FECHA ACTUAL", fecha_actual)

  const validacion = await validarHorario(Values, Errores, fecha_actual)
  if (validacion) {
    return new Promise((resolve, reject) => {
      resolve(validacion);
    })
  }


  if (!error) {
    let data = [];

    let queryParamsObj = {};
    const url =
      Values.ID == undefined || Values.ID == "" || Values.ID == 0
        ? "horarios/Insertar"
        : "horarios/Editar";

    const body = {
      ...Values,
      MAX_PEDIDOS: parseInt(Values.MAX_PEDIDOS),
      INICIO: Values.INICIO.substring(0, 5) + ':00',
      FIN: Values.FIN.substring(0, 5) + ':00'
    };
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarHorarioEnvio = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `horarios/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoHorarioEnvio = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `horarios/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información del horario",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        resolve(data);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id_sucursal, dia, checked) => {
  if (id_sucursal != undefined && dia != undefined) {
    let data = [];
    let queryParamsObj = {
      id_sucursal: id_sucursal,
      dia: dia,
      activo: checked,
    };
    const url = `horarios/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpcionesHorarios = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `horarios/getOpcionesHorarios?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
