import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Perfil from "./Componentes/Perfil";
import UpdatePassword from "./Componentes/UpdatePassword";

const RouterPerfil = () => {
  console.log("renderizando router");
  return (
    <>
      <Routes>
        <Route path="/" element={<Perfil />} />
        <Route path="/update" element={<UpdatePassword />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default RouterPerfil;
