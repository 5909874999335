import { Paper } from "@mui/material";

import {
  NumerosLetra,
  convertirNumeroATexto,
  numeroEnPalabras,
  recortarHoraAgregarTexto,
} from "./../../../../Lib/Funciones Generales/Generales";
import { useState } from "react";
import { useEffect } from "react";
const Ticket = ({
  Values = [],
  Articulos,
  tipo,
  config,
  ButtonImprimir,
  otrosDatos,
  isReimpresion = false,
}) => {
  let NumArticulos = 0;
  const handleFileInputChange = () => {
    const reader = new FileReader();
    if (Values.length == 0) {
    }
  };

  useEffect(() => {
    handleFileInputChange();
  }, [Values]);

  if (Values.length == 0) {
    return "";
  }

  return (
    <>
      {" "}
      <div className="contectTiket pe-1 p-2  d-flex justify-content-center">
        <Paper className="shadow " style={{ width: config.ancho + "mm" }}>
          <div
            id="divTiket"
            className="ticket  p-1"
            style={{ width: config.ancho + "mm" }}
          >
            <div
              style={{
                fontSize: config.fuente + "px",
                fontWeight: "500",
                paddingLeft: "2mm",
                paddingRight: "2mm",
                paddingTop: "50px",
                paddingBottom: "40px",
              }}
            >
              <center>
                <img
                  src={Values[0].IMG_EMPRESA}
                  style={{ maxWidth: "170px", margin: "auto" }}
                />
              </center>
              <br />
              <p style={{ textAlign: "center", margin: "0px" }}>
                {Values[0].NOMBRE_EMPRESA.toUpperCase()}
              </p>
              <br />
              {isReimpresion && (
                <>
                  <br />
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    Reimpresión
                  </p>
                  <br />
                </>
              )}
              <p
                style={{
                  textAlign: "center",
                  margin: "0px",
                  fontSize: parseInt(config.fuente) + 6 + "px",
                }}
              >
                PEDIDO: {Values[0].FOLIO.toString().padStart(5, "0")}
              </p>
              <div className="border-dotted">
                -------------------------------------------------------------------------------------------------------
              </div>

              {config.encabezado != "" ? (
                <>
                  {/*
                
                 <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.encabezado.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div> 
                
                */}
                </>
              ) : (
                ""
              )}
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <b style={{ fontSize: config.fuente + "px" }}>CANT.</b>
                  </td>
                  <td>
                    <b style={{ fontSize: config.fuente + "px" }}>CONCEPTO</b>
                  </td>
                  <td align="right">
                    <b style={{ fontSize: config.fuente + "px" }}>CÓDIGO</b>
                  </td>
                </tr>

                {Articulos.map((row) => {
                  NumArticulos += row.CANTIDAD;
                  return (
                    <tr>
                      <td valign="top">{row.CANTIDAD}</td>
                      <td valign="top">
                        {row.DESC_CORTA}
                        {row.COLOR != null || row.TAMANIO != null ? (
                          <>
                            <div
                              style={{ fontSize: "10px", marginLeft: "3px" }}
                            >
                              {row.COLOR != null ? " " + " -" + row.COLOR : ""},{" "}
                              {row.TAMANIO != null ? row.TAMANIO : ""}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td valign="top" align="right">
                        {row.CODIGO}
                      </td>
                    </tr>
                  );
                })}
              </table>
              <br />
              <p style={{ textAlign: "left", margin: "0px" }}>
                {NumArticulos} ARTÍCULOS
              </p>
              <br />

              {tipo == 2 && (
                <>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td align="right">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          SUBTOTAL
                        </b>
                      </td>
                      <td align="right">
                        ${parseFloat(Values[0].SUBTOTAL).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td align="right">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          DESCUENTOS
                        </b>
                      </td>
                      <td align="right">
                        ${parseFloat(Values[0].DESCUENTO).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td align="right">
                        <b style={{ fontSize: config.fuente + "px" }}>TOTAL</b>
                      </td>
                      <td align="right">
                        ${parseFloat(Values[0].TOTAL).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {NumerosLetra(Values[0].TOTAL).toUpperCase()}
                  </p>
                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  <br />
                  <table style={{ width: "100%" }}>
                    {Values[0].EFECTIVO != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            EFECTIVO
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].EFECTIVO).toFixed(2)}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>CAMBIO</b>
                      </td>
                      <td align="right">
                        ${parseFloat(Values[0].CAMBIO).toFixed(2)}
                      </td>
                    </tr>

                    {Values[0].TARJETA != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            TARJETA CRE./DEB.
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].TARJETA).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {Values[0].MONEDERO != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            MONEDERO
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].MONEDERO).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {Values[0].TRANSFERENCIA != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            TRANSFERENCIA
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].TRANSFERENCIA).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {Values[0].DEPOSITO != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            DEPOSITO
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].DEPOSITO).toFixed(2)}
                        </td>
                      </tr>
                    )}
                    {Values[0].PAGO_EN_TIENDA != "0" && (
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            PAGO EN TIENDA
                          </b>
                        </td>
                        <td align="right">
                          ${parseFloat(Values[0].PAGO_EN_TIENDA).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </table>
                </>
              )}

              <br />
              <p style={{ textAlign: "left", margin: "0px" }}>
                INFORMACIÓN DEL PEDIDO
              </p>
              <br />
              <table style={{ width: "100%" }}>
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>FECHA</b>
                  </td>
                  <td align="right">{Values[0].FECHA_DE_ENTREGA}</td>
                </tr>

                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>HORARIO</b>
                  </td>
                  <td align="right">
                    {Values[0].ENTREGA_INICIO + " - " + Values[0].ENTREGA_FIN}
                  </td>
                </tr>
                <tr>
                  <td align="left" valign="top">
                    <b style={{ fontSize: config.fuente + "px" }}>
                      {Values[0].TIPO_ENVIO == "1" ? "DIRECCIÓN" : "SUCURSAL"}
                    </b>
                  </td>
                  <td align="right" valign="top">
                    {Values[0].TIPO_ENVIO == "1"
                      ? Values[0].D_CALLE +
                        " " +
                        Values[0].D_NUMERO_EXT +
                        (Values[0].D_NUMERO_INT == ""
                          ? ""
                          : " Int. " + Values[0].D_NUMERO_INT) +
                        " CP " +
                        Values[0].D_CP +
                        (Values[0].D_COLONIA.trim() == ""
                          ? ""
                          : " Col. " + Values[0].D_COLONIA) +
                        +(Values[0].D_CALLE1 == ""
                          ? ""
                          : " Entre " + Values[0].D_CALLE1) +
                        (Values[0].D_CALLE2 == ""
                          ? ""
                          : " y " + Values[0].D_CALLE2)
                      : Values[0].SUCURSAL}
                  </td>
                </tr>
                
                  <>
                    <tr>
                      <td align="left" valign="top">
                        <b style={{ fontSize: config.fuente + "px" }}>ZONA</b>
                      </td>
                      <td align="right" valign="top">
                        {Values[0].ZONA_CORTA} - {Values[0].ZONA_LARGA}
                      </td>
                    </tr>

                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          DISTANCIA
                        </b>
                      </td>
                      <td align="right">{otrosDatos.distancia}</td>
                    </tr>
                  </>
               
                {tipo == 1 && (
                  <>
                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          FLORISTA
                        </b>
                      </td>
                      <td align="right">{Values[0].ENCARGADO}</td>
                    </tr>
                  </>
                )}
                {tipo == 2 && (
                  <>
                    <tr>
                      <td align="left" valign="top">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          REPARTIDOR
                        </b>
                      </td>
                      <td align="right" valign="top">
                        {Values[0].REPARTIDOR}
                      </td>
                    </tr>
                    {Values[0].ENVIA !== "" ? (
                      <tr>
                        <td align="left" valign="top">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            ENVIA
                          </b>
                        </td>
                        <td align="right" valign="top">
                          {Values[0].ENVIA}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {Values[0].RECIBE !== "" ? (
                      <tr>
                        <td align="left" valign="top">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            RECIBE
                          </b>
                        </td>
                        <td align="right" valign="top">
                          {Values[0].RECIBE}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {Values[0].D_TELEFONO !== "" ? (
                      <tr>
                        <td align="left" valign="top">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            TELEFONO
                          </b>
                        </td>
                        <td align="right" valign="top">
                          {Values[0].D_TELEFONO}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {Values[0].D_INDICACIONES !== "" ? (
                      <tr>
                        <td align="left" valign="top">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            INDICACIONES
                          </b>
                        </td>
                        <td
                          align="right"
                          valign="top"
                          style={{ fontSize: "11px" }}
                        >
                          {Values[0].D_INDICACIONES}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td align="center" colSpan={2}>
                        <img
                          id="img_qr"
                          src={
                            "https://appbordoapi.azurewebsites.net/api/qr/get?data=" +
                            encodeURIComponent(
                              "https://www.google.com/maps/search/?api=1&query=" +
                                String(Values[0].LAT) +
                                "," +
                                String(Values[0].LNG)
                            )
                          }
                          width={"80%"}
                        ></img>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" colSpan={2}>
                        Escanea para ir a google maps
                      </td>
                    </tr>
                  </>
                )}
              </table>

              {tipo == 1 && (
                <>
                  <br />
                  <p style={{ textAlign: "left", margin: "0px" }}>
                    DATOS DEL CLIENTE
                  </p>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>NOMBRE</b>
                      </td>
                      <td align="right">{Values[0].CLIENTE}</td>
                    </tr>
                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          TELÉFONO
                        </b>
                      </td>
                      <td align="right">{Values[0].TELEFONO}</td>
                    </tr>
                  </table>
                </>
              )}

              {tipo == 1 && (
                <>
                  <br />
                  <p style={{ textAlign: "left", margin: "0px" }}>
                    DEDICATORIA
                  </p>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          QUIÉN RNVÍA
                        </b>
                      </td>
                      <td align="right">
                        {Values[0].ENVIA == "" ? "Anónimo" : Values[0].ENVIA}
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          QUIÉN RECIBE
                        </b>
                      </td>
                      <td align="right">{Values[0].RECIBE}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} align="left">
                        <b style={{ fontSize: config.fuente + "px" }}>
                          DEDICATORIA:
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} align="left">
                        {Values[0].DEDICATORIA}
                      </td>
                    </tr>
                  </table>
                </>
              )}

              <br />
              {config.pie != "" ? (
                <>
                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.pie.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                </>
              ) : (
                ""
              )}

              {/* config.linea_1 != "" ? (
                <>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.linea_1.toUpperCase()}
                  </p>
                </>
              ) : (
                ""
              )*/}
            </div>
          </div>
        </Paper>
        {ButtonImprimir}
      </div>
    </>
  );
};

export default Ticket;
