import ListAltIcon from "@mui/icons-material/ListAlt";
import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ArticleIcon from '@mui/icons-material/Article';
import Facturar from "../../../../../assets/Facturar.svg";


const DatosFacturacion = ({
  setShowDialogFacturacion,
  setPedidoSeleccionado,
  id,
}) => {
  return (
    <>
      <Tooltip title="Facturar pedido" placement="top" className="me-2">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDialogFacturacion(true);
          }}
        >
          <img src={Facturar} width={22} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DatosFacturacion;
