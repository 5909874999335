import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const Print = ({ setShowDiloglImprimir, setPedidoSeleccionado, id }) => {
  return (
    <>
      <Tooltip title="Imprimir ticket" placement="top" className="me-2">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            setPedidoSeleccionado(id);
            setShowDiloglImprimir(true);
          }}
        >
          <PrintIcon style={{ fontSize: "2rem" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Print;
