import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DetalleCompra from "../DetalleCompra";

const ModalDetalleCompra = ({
    pedidoSeleccionado,
    setShowDiloglImprimir,
    setPedidoSeleccionado,
    setShowDilogDetalle,
    setShowDialogComprobante,
    setShowDialogFormaPago,
    setShowDialogCargarComprobante,
    setComprobanteSeleccionado
}) => {
    return (
        <Dialog open={true} fullWidth={true} maxWidth={"lg"}>
            <div className="position-absolute" style={{ right: 0 }}>
                <IconButton
                    onClick={() => {
                        setComprobanteSeleccionado(-1)
                        setPedidoSeleccionado(0);
                        setShowDilogDetalle(false);
                    }}
                >
                    <CloseIcon style={{ width: "60px", height: "60px" }} />
                </IconButton>
            </div>

            <DialogContent className="p-0"> 
                <DetalleCompra
                    setComprobanteSeleccionado={setComprobanteSeleccionado}
                    setShowDialogFormaPago={setShowDialogFormaPago}
                    pedidoSeleccionado={pedidoSeleccionado}
                    setShowDialogComprobante={setShowDialogComprobante}
                    setShowDialogCargarComprobante={setShowDialogCargarComprobante}
                    setShowDiloglImprimir={setShowDiloglImprimir}
                />

            </DialogContent>
        </Dialog>
    )
}

export default ModalDetalleCompra