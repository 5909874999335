import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import CorteCaja from "./Componentes/CorteCaja";
import RepCorteCaja from "./Componentes/RepCorteCaja";




const routerCorteCaja= () => {
    return (
        <>
            <Routes>
                <Route index element={<RepCorteCaja />} />
                <Route path="/add" element={<CorteCaja />} />
                <Route path="/edit/:id" element={<CorteCaja />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </>
    );
}

export default routerCorteCaja