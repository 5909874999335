import { Dialog, DialogContent, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Card, Overlay, Row } from "react-bootstrap";
import CardPedido from "./CardPedido";
import Loading from "../../../../Includes/Loading";

const Cuadricula = ({
  Lista
  , setPedidoSeleccionado
  , setShowDialogAsignarFlorista
  , setShowDilogDetalle
  , setShowDiloglEstatutus
  , setShowDiloglImprimir
  , setShowDialogAsignarRepartidor
  , setRepartidorSeleccionado
  , IsLoading
  , estado
  , setShowDialogoModalEditar
  , folio
  , setFolio
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const folio = event.target.elements["folio"].value;
    if(folio == "") return;
    setFolio(folio)
  }
  return (<>
    {IsLoading ? (
      <div className="col-12  ">
        <Loading />
      </div>
    ) : (
      <>
        <div className="w-100">
          {estado == 6 &&
            <form onSubmit={handleSubmit} className="d-flex justify-content-center mb-3">
              <TextField
                label="Folio"
                className="mx-auto"
                placeholder="Presiona enter para buscar"
                name="folio"
                size="small"
                defaultValue={folio} 
                helperText="Presiona enter para buscar"
              />
            </form>
          }
        </div>
        
        <Row >
          {Lista &&
            Array.isArray(Lista) &&
            Lista.map((row, index) => (
              <CardPedido
                key={"card_pedido_" + row.ID}
                row={row}
                index={index}
                setPedidoSeleccionado={setPedidoSeleccionado}
                setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
                setShowDilogDetalle={setShowDilogDetalle}
                setShowDiloglEstatutus={setShowDiloglEstatutus}
                setShowDiloglImprimir={setShowDiloglImprimir}
                setShowDialogAsignarRepartidor={setShowDialogAsignarRepartidor}
                setRepartidorSeleccionado={setRepartidorSeleccionado}
                setShowDialogoModalEditar={setShowDialogoModalEditar}

              />
            ))}
        </Row>
      </>
    )
    }
  </>
  );
};

export default Cuadricula;
