import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Categoria from "./Componentes/Categoria";
import RepCategorias from "./Componentes/RepCategorias";

const routerCategorias = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepCategorias />} />
        <Route path="/add" element={<Categoria />} />
        <Route path="/edit/:id" element={<Categoria />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerCategorias;
