import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";
import { json } from "react-router-dom";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (
  iTake,
  iSkip,
  Order,
  query,
  fechaInicio,
  fechaFin
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
  };
  //console.log("alof: " +queryParamsObj);
  const url = `requisicionmercancia/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getListaDetalleOrden = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    ID_ORDENES_DE_COMPRA: id,
  };
  const url = `requisicionmercancia/ListarDetalleOrden?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoOrdenRequisicion = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `requisicionmercancia/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta requisición",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let d = {
          values: {
            id: String(data.ID),
            id_sucursal: Number(data.ID_SUCURSAL),
            id_almacen: Number(data.ID_ALMACEN),
            id_proveedor: Number(data.ID_PROVEEDOR),
            observaciones: String(data.OBSERVACIONES),
          },
          productos: JSON.parse(res.data.respuesta.productos),
        };
        resolve(d);
        //console.log("Datosss", Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

///////////////////
/* FUNCIONES POST */
///////////////////

export const CancelarRequisicion = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `requisicionmercancia/Cancelar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const GuardarRequisicion_ = async (
  Values,
  Errores,
  setErrores,
  productosCarrito,
  id
) => {
  let error = false;

  if (
    !Values.id_sucursal ||
    Values.id_sucursal == "" ||
    Values.id_sucursal == "0"
  ) {
    Values.id_sucursal = 0; //Puede ir en cero la sucursal
  }
  if (
    !Values.id_almacen ||
    Values.id_almacen == "" ||
    Values.id_almacen == "0"
  ) {
    Errores.id_almacen = true;
    setErrores(Errores);
    error = true;
  }

  /*if (!Values.observaciones || Values.observaciones == "") {
    let Errores2 = Errores;
    Errores2.observaciones = true;
    setErrores(Errores2);
    error = true;
  }*/

  if (productosCarrito.length == 0) {
    // error = true;
  }

  Values.productos = productosCarrito;

  let Total = 0;
  let Cantidad = 0;
  productosCarrito.map((row) => {
    Total += row.PRECIO * row.CANTIDAD;
    Cantidad += row.CANTIDAD;
  });

  Values.id_sucursal = String(Values.id_sucursal);
  Values.id_almacen = String(Values.id_almacen);
  Values.id_proveedor = String(Values.id_proveedor);
  Values.moneda = String(Values.moneda);

  if (!error) {
    const body = {
      id: Values.id,
      id_sucursal: Values.id_sucursal,
      id_almacen: Values.id_almacen,
      id_proveedor: Values.id_proveedor,
      observaciones: Values.observaciones,
      relacion: [...productosCarrito],
    };
    // console.log(body);
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "requisicionmercancia/Insertar"
        : "requisicionmercancia/Editar?ID=" + id;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const ProcesarRequisicion = async (id, observaciones, fecha) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      observaciones: observaciones,
      fecha: fecha,
    };
    const url = `requisicionmercancia/ProcesarRequisicion?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const EnviarCorreo = async (
  ID,
  Values,
  correosArray,
  Errores,
  setErrores
) => {
  let error = false;

  correosArray.forEach((correo) => {
    if (!validator.isEmail(correo.trim())) {
      error = true;
      setErrores(Errores);
    }
  });

  if (!error) {
    const body = correosArray;
    const url = `requisicionmercancia/EnviarCorreo?ID_REQUISICION=${ID}&comentarios=${Values.comentarios}`;
    const headers = {
      "Content-Type": "application/json",
    };
    let data = [];
    const res = await postdData(url, body, headers);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getCorreoProveedor = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    ID_ORDEN_COMPRA: id,
  };
  const url = `requisicionmercancia/getCorreoProveedor?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  //console.log(res);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
