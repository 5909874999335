import React from "react";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function NetxTab({ tab = 0, setTab = () => {} }) {
  let TAB = parseInt(tab)
  return (
    <>
      <Button
        onClick={() => {
          const nextTab = TAB + 1 > 3 ? 3 : TAB + 1;
          setTab(String(nextTab));
        }} 
        style={{
          background: "#65748B",
        }}
        className="btn  text-white  font-AvenirMedium  py-2 px-4  "
        variant="contained"

      >
        Siguiente
        <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
      </Button>
    </>
  );
}
