import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TablaDetalleComposicion from "../../Catalogos/Productos/Componentes/TablaDetalleComposicion";
import ModalSelectProd from "../../Catalogos/Productos/Componentes/Dialogos/ModalSelectProd";
import TablaDetalleComposicionPV from "./TablaDetalleComposicionPV";

export default function TablaProdAcoreon({
  IsLoading,
  productosCarrito = [],
  setProductosCarrito = () => {},
}) {
  console.log(productosCarrito);
  return (
    <>
      <Table>
        <TableBody>
          {productosCarrito.map((row, index) => (
            <CustomAccordion
              key={row.ID}
              COMPOSICION={row.COMPOSICION}
              index={index}
              ID={row.ID}
              row={row}
              productosCarrito={productosCarrito}
              setProductosCarrito={setProductosCarrito}
            />
          ))}
          {productosCarrito.length > 0 && (
            <TableRow style={{ height: 53 * productosCarrito.length }}>
              <TableCell colSpan={3} />
            </TableRow>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          ></div>
        </TableBody>
      </Table>
    </>
  );
}
const CustomAccordion = ({
  COMPOSICION,
  index,
  row,
  ID,
  productosCarrito,
  setProductosCarrito,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [openModalSelectProd, setOpenModalSelectProd] = useState(false);

  console.log(productosCarrito);
  console.log(index);
  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...COMPOSICION];
    const itemIndex = items.findIndex(
      (item) => item.ID_PRODUCTO === producto.ID
    );
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD =
        Number(productoExistente.CANTIDAD) + Number(cantidad);
    } else {
      const productoNuevo = {
        ID_PRODUCTO: parseInt(producto.ID),
        DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
        PRECIO: parseFloat(producto.PRECIO_COMPRA),
        CANTIDAD: cantidad,
      };
      items.push(productoNuevo);
    }

    productosCarrito[index] = {
      ...productosCarrito[index],
      COMPOSICION: items,
    };
    setProductosCarrito([...productosCarrito]);
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  const handleOpen = () => {
    setOpenModalSelectProd(true);
  };
  console.log("Composicion", COMPOSICION);

  return (
    <TableRow key={row.ID}>
      <TableCell component="th" scope="row">
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          className="w-100"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <div className="d-flex justify-content-center">
              <label className="font-AvenirMedium p-2">
                {row.DESCRIPCCION} - Cantidad ({row.CANTIDAD})
              </label>
            </div>
          </AccordionSummary>
          <AccordionDetails className="w-100">
            <div className="d-flex flex-column justify-content-end w-100">
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleOpen}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 ms-3 mb-3"
                >
                  Agregar composicion
                </Button>
              </div>
              {openModalSelectProd && (
                <ModalSelectProd
                  handleProductoSeleccionado={handleProductoSeleccionado}
                  requiredTotal={false}
                  onlyMateriaPrima
                  onClose={() => {
                    setOpenModalSelectProd(false);
                  }}
                />
              )}
              <TablaDetalleComposicionPV
                ID={row.ID}
                index={index}
                IsLoading={IsLoading}
                COMPOSICION={COMPOSICION}
                productosCarrito={productosCarrito}
                setProductosCarrito={setProductosCarrito}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
};
