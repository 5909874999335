import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export const TextFieldPrecio = ({ row, comisionBancaria, ventaLibre, show, IVA, IVA_FRACCION, name, initialValue, onSubmit }) => {
    const [value, setValue] = useState(Number(!IVA ? (initialValue / IVA_FRACCION).toFixed(2) : initialValue).toFixed(2));
    const [previousCorrect, setPreviousCorrect] = useState(Number(!IVA ? (initialValue / IVA_FRACCION).toFixed(2) : initialValue).toFixed(2));
    let precio = row.PRECIO;
    if (!ventaLibre && !comisionBancaria) precio = row.PRECIO_ORIGINAL
    if (!ventaLibre && comisionBancaria) precio = row.PRECIO_PUBLICO
    if (ventaLibre && comisionBancaria) precio = row.PRECIO_PUBLICO
    const [enterPressed, setEnterPressed] = useState(false);
    const [blured, setBlurred] = useState(false);
    const [clicked, setClicked] = useState(false);
    const handleEnter = (event) => {
        if (event.key === "Enter" && value != "" && value > 0) {
            setEnterPressed(true);
            event.target.blur()
            return;
        }
        setEnterPressed(false);
    }

    const handleBlur = (event) => {
        setBlurred(true)
    }

    const handleChange = ({ target: { value } }) => {
        //regex para validar si es un numero flotante valido
        if (/^(?=\.\d|\d)(?:\d+)?(?:\.?\d*)?(?:[eE][+-]?\d+)?$/.test(value) || value === "") {
            setValue(value)
        };
    }

    useEffect(() => {
        if (ventaLibre) {
            setValue(Number(!IVA ? (precio / IVA_FRACCION).toFixed(2) : precio).toFixed(2))
        }
        setPreviousCorrect(Number(!IVA ? (precio / IVA_FRACCION).toFixed(2) : precio).toFixed(2))
    }, [ventaLibre])

    useEffect(() => {
        if (enterPressed && blured) {
            onSubmit(Number(!IVA ? (value * IVA_FRACCION) : value).toFixed(2))
            setPreviousCorrect(value)
            setEnterPressed(false);
        }
        if (!enterPressed && blured) {
            setValue(Number(previousCorrect).toFixed(2));
            setBlurred(false);
            setClicked(false)
        }
    }, [enterPressed, blured, value])

    const handleClick = () => {
        setClicked(true)
    }
    return <TextField
        label="Precio"
        name={name}
        style={{ display: show ? null : "none" }}
        onChange={handleChange}
        onKeyDown={handleEnter}
        onBlur={handleBlur}
        onClick={handleClick}
        helperText={clicked ? "Presiona enter para guardar el precio" : ""}
        InputProps={{
            startAdornment: "$",
            endAdornment: "MXN"
        }}
        value={value}
    />
}