import { stringify } from "query-string";
import { getData } from "../../../../../Context/backend";

//Peticiones GET
export const getListaSucuMerca = async (
  SucursalSelecciona,
  AlmacenSelecciona,
  tipo,
  conExistencia,
  busqueda = "",
  CategoriaSelecciona
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    ID_SUCURSAL: SucursalSelecciona,
    ID_ALMACEN: AlmacenSelecciona,
    tipo: tipo,
    existencia: conExistencia,
    BUSQ: busqueda,
    ID_CATEGORIA: CategoriaSelecciona,
  };
  const url = `ordencompra/ListarMercaSucuAlma?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
