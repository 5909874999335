import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepAjusteMercancia from "./Componentes/RepAjusteMercancia";
import AjusteMercancia from "./Componentes/AjusteMercancia";

const routerAjusteMercancia = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepAjusteMercancia />} />
        <Route path="/add" element={<AjusteMercancia />} />
        <Route path="/edit/:id" element={<AjusteMercancia />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerAjusteMercancia;
