import React, { useState, useEffect } from "react";
import { Avatar, TextField, Stack, Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import AccEditar from "../../../../assets/AccEditar.svg";
import AccEliminar from "../../../../assets/AccEliminar.svg";
import ModalDireccion from "../../../../../Lib/Direcciones/Componentes/ModalDireccion";
import {
  Eliminar,
  getLista,
} from "../../../../../Lib/Direcciones/Funciones/Direcciones";

const MySwal = withReactContent(Swal);

export default function TabDirecciones({
  Values = {},
  setValues = () => {},
  img_url = "",
  handleOpenModal = () => {},
  Errores = {},
  handlInputChange = () => {},
}) {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalDireccion, setShowModalDireccion] = useState(false);
  const [Lista, setLista] = useState([]);
  const [idDireccion, setIdDireccion] = useState(0);
  const [valuesEditNuevoReg, setValuesEditNuevoReg] = useState({});

  const handleClose = () => {
    setOpen(false);
  };
  const EliminarRegistro = (id, index) => {
    new Swal({
      title: "¿Estás seguro de eliminar esta dirección?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                getDireciones();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        } else {
          let filteredArr = Lista.filter((item, i) => {
            if (i != index) {
              return item;
            }
          });
          setLista(filteredArr);
        }
      }
    });
  };
  const callBackNewRegistro = (val) => {
    //console.log(val);
    //console.log(!val.hasOwnProperty("index"));
    if (val && !val.hasOwnProperty("index")) {
      const newElement = {
        NOMBRE_CONTACTO: val?.nombre,
        CALLE: val?.calle,
        NUMERO_EXT: val?.numeroext,
        NUMERO_INT: val?.numeroint,
        COLONIA: val?.colonia,
        CP: val?.cp,
        ID_ESTADO: val?.estado,
        ESTADO: val?.estado_desc,
        ID_MUNICIPIO: val?.municipio,
        MUNICIPIO: val?.municipio_desc,
        TIPODOMICILIO: val?.tipodomicilio,
        CALLE1: val?.entrecalle1,
        CALLE2: val?.entrecalle2,
        INDICACIONES: val?.indicaciones,
        TELEFONO: val?.telefono,
      };
      let nreArr = [];
      Lista.map((item) => {
        nreArr.push(item);
      });
      nreArr.push(newElement);

      setLista(nreArr);
    }
    if (val.hasOwnProperty("index")) {
      let nreArr = [];
      Lista.map((item, i) => {
        //console.log("indexArr: ", i);
        //console.log("itemArr: ", item);
        if (i == val.index) {
          const newElement = {
            NOMBRE_CONTACTO: val?.nombre,
            CALLE: val?.calle,
            NUMERO_EXT: val?.numeroext,
            NUMERO_INT: val?.numeroint,
            COLONIA: val?.colonia,
            CP: val?.cp,
            ID_ESTADO: val?.estado,
            ESTADO: val?.estado_desc,
            ID_MUNICIPIO: val?.municipio,
            MUNICIPIO: val?.municipio_desc,
            TIPODOMICILIO: val?.tipodomicilio,
            CALLE1: val?.entrecalle1,
            CALLE2: val?.entrecalle2,
            INDICACIONES: val?.indicaciones,
            TELEFONO: val?.telefono,
          };
          nreArr.push(newElement);
        } else {
          nreArr.push(item);
        }
      });
      setLista(nreArr);
    }
  };

  useEffect(() => {
    //console.log("Cambio la lista");
    const newValues = { ...Values, direcciones: Lista };
    //Values.direcciones = Lista;
    setValues(newValues);
  }, [Lista]);

  useEffect(() => {
    //console.log(Values.id);
    if (Values.id != "0") {
      getDireciones();
    } else {
      setLista(Values.direcciones);
    }
  }, []);

  const getDireciones = () => {
    setIsLoading(true);

    getLista(Values.id)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Col xs={12}>
        <div className="col-12 text-end">
          <Button
            onClick={() => {
              setValuesEditNuevoReg({});
              setShowModalDireccion(true);
            }}
            className="btn shadow-none  btn-Crexendo font-AvenirMedium  py-2 px-4 "
            variant="contained"
            style={{ max: "400px" }}
          >
            <span className={"px-2 font-AvenirMedium"}>
              <AddIcon className="me-2" />
              Agregar
            </span>
          </Button>
        </div>
        {showModalDireccion ? (
          <ModalDireccion
            id={idDireccion}
            SetshowModalDireccion={setShowModalDireccion}
            callBackNuevaDireccion={callBackNewRegistro}
            getDireciones={getDireciones}
            id_cliente={Values.id}
            valuesEditNuevoReg={valuesEditNuevoReg}
            isNuevoRegistro={Values.id == 0 || Values.id == "0" ? true : false}
          />
        ) : (
          <></>
        )}
        <Row className="p-3">
          {Lista.map((row, ind) => (
            <div
              className="pb-0 px-3 px-md-4 pt-3  mt-3"
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <div className="row">
                <div className="col-12">
                  <h6 className="font-AvenirBold">{row.NOMBRE_CONTACTO}</h6>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Calle</p>
                    <p className="font-AvenirLight my-1">{row.CALLE}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1"> Número exterior</p>
                    <p className="font-AvenirLight my-1">{row.NUMERO_EXT}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Número interior</p>
                    <p className="font-AvenirLight my-1">{row.NUMERO_INT}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Colonia</p>
                    <p className="font-AvenirLight my-1">{row.COLONIA}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Código postal</p>
                    <p className="font-AvenirLight my-1">{row.CP}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Estado</p>
                    <p className="font-AvenirLight my-1">{row.ESTADO}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Municipio</p>
                    <p className="font-AvenirLight my-1">{row.MUNICIPIO}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Tipo de domicilio</p>
                    <p className="font-AvenirLight my-1">{row.TIPODOMICILIO}</p>
                  </small>
                </div>

                <div className="col-6 col-md-4 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Entre calles</p>
                    <p className="font-AvenirLight my-1">{row.CALLE1}</p>
                    <p className="font-AvenirLight my-1">{row.CALLE2}</p>
                  </small>
                </div>

                <div className="col-12 col-lg-3  mt-3 mt-md-1">
                  <small>
                    <p className="font-AvenirBold my-1">Indicaciones</p>
                    <p className="font-AvenirLight my-1">{row.INDICACIONES}</p>
                  </small>
                </div>

                <div className="col-12 text-end">
                  <Stack
                    className="w-100 mt-2"
                    spacing={4}
                    direction="row"
                    justifyContent="end"
                  >
                    {
                      <Button
                        className="btn-accion font-AvenirBold"
                        variant="text"
                        onClick={() => {
                          setIdDireccion(row.ID ? row.ID : 0);
                          setValuesEditNuevoReg({
                            id: idDireccion,
                            id_cliente: Values.id,
                            nombre: row.NOMBRE_CONTACTO,
                            telefono: row.TELEFONO,
                            cp: row.CP,
                            calle: row.CALLE,
                            numeroext: row.NUMERO_EXT,
                            numeroint: row.NUMERO_INT,
                            colonia: row.COLONIA,
                            estado: row.ID_ESTADO,
                            estado_desc: row.ESTADO,
                            municipio: row.ID_MUNICIPIO,
                            municipio_desc: row.MUNICIPIO,
                            entrecalle1: row.CALLE1,
                            entrecalle2: row.CALLE2,
                            tipodomicilio: row.TIPODOMICILIO,
                            indicaciones: row.INDICACIONES,
                            index: ind,
                          });
                          setShowModalDireccion(true);
                        }}
                      >
                        <img
                          src={AccEditar}
                          className="me-2 mb-1"
                          style={{ width: "15px" }}
                        />{" "}
                        Editar
                      </Button>
                    }

                    <Button
                      className="btn-accion font-AvenirBold"
                      variant="text"
                      onClick={() => {
                        EliminarRegistro(row.ID, ind);
                      }}
                    >
                      <img src={AccEliminar} className="me-2" /> Eliminar
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          ))}
        </Row>
      </Col>
    </>
  );
}
