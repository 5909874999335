import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Col, Row } from "react-bootstrap";
import TablaEmpresas from "./Tabla";
import { getLista } from "../Funciones/Empresas";

const Empresas = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaEmpresas();
  }, []);

  const getListaEmpresas = () => {
    setIsLoading(true);
    getLista(0, 0)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Empresas`;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Empresas</h4>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-1">
        </Row>
        <Row>
          <Col sm={12}>
            <TablaEmpresas
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TablaEmpresas>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Empresas;
