import React, { useState, useEffect } from "react";
import "../../../../../Lib/Funciones Generales/Prototipos"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  DialogActions,
  TableFooter,
  Typography,
} from "@mui/material";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import Switch from "../../../../includes/Switch";

export default function ModalProcesarODC({
  onClose = () => { },
  titulo = "Productos para procesar",
  id = 0,
  getProductos = async () => { },
  save = () => { },
}) {
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [desactivado, setDesactivado] = useState(true);
  const [IsIngresar, setIsIngresar] = useState(false);
  useEffect(() => {
    getProductos(id)
      .then((data) => { 
        setProductosDisponibles(data.productos);
        setDesactivado(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  let total = 0;
  {
    if (productosDisponibles.length > 0) {
      for (let row of productosDisponibles) {
        if (!isNaN(row.PRECIO) && !isNaN(parseInt(row.CANTIDAD))) {
          total += parseFloat(row.PRECIO) * parseInt(row.CANTIDAD);
        }
      }
    } else {
      total = 0;
    }
  }

  const addItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      //items.push({...newItem, CANTIDAD: 1});
      items.push(newItem);
    }
    setProductosDisponibles(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items[itemIndex].CANTIDAD = 0;
      }
    } else {
      // items.push(newItem);
    }
    setProductosDisponibles(items);
  };

  const isFloat = (n) => {
    return !isNaN(n) && parseFloat(n) === Number(n);
  };


  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    // alert("cantidad")

    // validar que sea entero  Value
    const items = [...productosDisponibles];
 

    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO == ID)
      : -1;
    if (itemIndex !== -1) { 
      if (Value === '' || isFloat(Value)) {
        items[itemIndex].CANTIDAD = Value.toString(); 
        }   

    } else {
      alert("producto no encontrado");
    }
    setProductosDisponibles(items);
  };

  const handlInputChangeCosto = ({ target }) => {
    // alert("COSTO")
    let ID = target.id;
    let Value = target.value; 
    const items = [...productosDisponibles]; 
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO == ID)
      : -1;
    if (itemIndex !== -1) {
      let precio = String(Value).toDecimal();
      items[itemIndex].PRECIO = precio.toString();
    } else {
      alert("producto no encontrado");
    }
    setProductosDisponibles(items);
  };
  const handleInputChangeIngresar = ({ target }) => {
    let Value = target.value;
    const Name = target.name;
    console.log("Nombre", Name, "Valor", Value);
    if (Value == true) {
      setIsIngresar(true);
    } else {
      setIsIngresar(false);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">{titulo}</h6>
        </DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Descripcion
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Costo
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="right"
                  >
                    Cantidad
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productosDisponibles
                  ? productosDisponibles.map((Productos) => (
                    <>
                      <TableRow key={Productos.ID}>
                        <TableCell component="th" scope="row" className="">
                          {firstLeterMayus(Productos.DESCRIPCION_PRODUCTO)}
                        </TableCell>
                        <TableCell component="th" scope="row" className="">
                          {/* {formatMoneda(Productos.PRECIO)} */}
                          <div
                            className="d-flex justify-content-center flex-column"
                            style={{ maxWidth: "150px" }}
                          >
                            <TextField
                              size="small"
                              fullWidth
                              id={Productos.ID_PRODUCTO}
                              label="Costo"
                              variant="outlined"
                              name={Productos.PRECIO}
                              value={
                                isNaN(Productos.PRECIO)
                                  ? ""
                                  : Productos.PRECIO
                              }
                              onChange={handlInputChangeCosto}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" className="">
                          {/* {Productos.CANTIDAD} */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              className="mx-2"
                              onClick={() => addItem(Productos)}
                            >
                              <img src={Mas} style={{ width: "15px" }} />
                            </IconButton>
                            <div
                              className="d-flex justify-content-center flex-column"
                              style={{ maxWidth: "150px" }}
                            >
                              <TextField
                                size="small"
                                fullWidth
                                id={Productos.ID_PRODUCTO}
                                label="Cantidad"
                                variant="outlined"
                                name={Productos.ID_PRODUCTO}
                                value={
                                  isNaN(Productos.CANTIDAD)
                                    ? ""
                                    : Productos.CANTIDAD
                                }
                                //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                                onChange={handlInputChange}
                              />
                            </div>
                            <IconButton
                              aria-label="delete"
                              className="mx-2"
                              onClick={() => disminuirItem(Productos)}
                            >
                              <img src={Menos} style={{ width: "15px" }} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                  : null}

              </TableBody>
            </Table>
            <TableFooter>
              <Typography
                className="font-Avenir font-AvenirBold ms-2"
                align="right"
              >
                <div style={{ display: "flex" }}>
                  <p>Total: {formatMoneda(total)}</p>
                </div>
              </Typography>
            </TableFooter>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <div className="w-100 text-end pe-3">
            <div className="row">
              <div className="col-12 col-md-8 mb-3 text-start text-muted">
                <h6>
                  * Una vez capturado el costo, ya no
                  sera posible modificar la orden de compra<br />
                  * Una vez capturado el costo se podra ingresar a inventario
                </h6>
              </div>
              <div className="col-12 col-md-4 mb-3">
                <label htmlFor="">Ingresar a inventario</label>
                <Switch
                  checked={IsIngresar}
                  name="IsIngresar"
                  onChange={(checked) => {
                    handleInputChangeIngresar({
                      target: {
                        name: "IsIngresar",
                        value: checked,
                      },
                    });
                  }}
                  tituloActivo="Si"
                  tituloInactivo="No"
                  on
                />
              </div>
            </div>
            <Button
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4  mb-3"
              variant="contained"
              disabled={desactivado}
              onClick={() => {
                save(id, productosDisponibles, IsIngresar);
                //onClose();
              }}
            >
              Guardar costos
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
