import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import Loading from "../../../../../Includes/Loading";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { getCuponesByCuponera } from "../Funciones/Cupones";

const MySwal = withReactContent(Swal);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function ModalDetallesCuponera({
  setShowModalReserva,
  id,
  vigencia,
}) {
  const [IsLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Lista, setLista] = useState([]);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getCuponesByCuponera(id)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={true}>
        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => setShowModalReserva(false)}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12">
            Detalles de cuponera con vigencia el {vigencia}
          </h6>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    CODIGO
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    TIPO DESCUENTO
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    VALOR
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    APLICADO
                  </TableCell>

                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    FECHA APLICACION
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    MAXIMO DE USOS
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    USOS
                  </TableCell>
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  >
                    TOTAL DESCUENTO
                  </TableCell>
                </TableRow>
              </TableHead>
              {IsLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={8}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {(rowsPerPage > 0
                    ? Lista.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : Lista
                  ).map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell component="th" scope="row" align="center">
                        {row.CODIGO}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {row.TIPO_DESCUENTO == "1" ? "Porcentaje" : "Neto"}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {row.VALOR}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {row.APLICADO ? "SI" : "NO"}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.FECHA_APLICACION
                          ? `${row.FECHA_APLICACION}`
                          : "Sin fecha"}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.MAXIMO_USOS}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.USOS}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.TOTAL_DESCUENTO}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      15,
                      30,
                      100,
                      { label: "Todos", value: -1 },
                    ]}
                    count={Lista.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "Filas por pagína",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={"Registros por página"}
                    labelDisplayedRows={({ from, to, count, page }) => {
                      return `${from} - ${to} de ${count}`;
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
