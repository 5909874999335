import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "../../../../assets/ExportarDanger.svg";
import ExportarSucess from "../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableEmpleados from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import Buscador from "../../../../includes/SelectOrdenamiento";
import { getPuestos } from "../Funciones/Puestos";
import { generarHorarios } from "../../../../../Lib/Funciones Generales/Generales";
import BACKEND_URL, { KEY } from "../../../../../Context/backend";

const RepPuestos = () => {
  console.log("Renderizando...");

  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaEmpleados();
  }, [ValueBusqueda, ValueSelect]);

  const getListaEmpleados = () => {
    setIsLoading(true);
    getPuestos(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };
  
  const generarReportePDF = () => {
    window.open(`../pdf/puestos/${ValueSelect}`, "_blank");
  };

  useEffect(() => {
    document.title = `Puestos`;
    //const horarios = generarHorarios("09:36:00", "15:47:00", 30);
    //console.log("horarios generados: ", horarios);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Puestos</h4>
        </div>
        <div className="p-2">
          <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
            <a
              href={
                BACKEND_URL +
                `puestos/ReportePuestosExcel?KEY=${KEY}&Order=${ValueSelect}`
              }
              target={"_blank"}
            >
              <Button
                className="btn btn-exp-Excel font-AvenirMedium"
                variant="text"
              >
                <img className="me-2" src={ExportarSucess} />
                Exportar Excel
              </Button>
            </a>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
              onClick={() => generarReportePDF(ValueSelect)}
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button>
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium px-4 "
                variant="contained"
              >
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar puestos por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableEmpleados
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            ></TableEmpleados>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RepPuestos;
