import { Route, Routes } from "react-router-dom";
import Error404 from "../../../Includes/Error404";
import PuntoDeVenta from "./Componentes/PuntoDeVenta";
import PruebaContenedores from "./Componentes/PruebaContenedores";

const routerPuntoDeVenta = ({ open, setOpen = () => {} }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<PuntoDeVenta setOpen={setOpen} />} />
        <Route path="/add" element={<>agregar</>} />
        <Route path="/edit/:id" element={<>editar</>} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerPuntoDeVenta;
