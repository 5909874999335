import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Loading from "../../../../../Includes/Loading";
import CloseIcon from "@mui/icons-material/Close";
import TablaProds from "./TablaProds";
import { getInfo } from "../Funciones/ProductosDescuentos";

export default function ModalDetalleDescuentosProds({
  setShowModal = () => {},
  id,
  vigencia,
}) {
  const [IsLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({
    descuento: 0,
    tipo: 0,
  });

  const [Lista, setLista] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getInfo(id)
      .then((data) => {
        //console.log(data);
        setValues(data.general);
        setLista(data.relacion);
        setIsLoading(false);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={true}>
        <IconButton
          aria-label="delete"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => setShowModal(false)}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12">
            Detalles de productos con descuendo de vigencia el {vigencia}
          </h6>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Loading />
          ) : (
            <TablaProds
              Lista={Lista}
              IsVista
              descuento={values.descuento}
              tipo={values.tipo}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
