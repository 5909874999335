 import {  Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Empleado from "./Componentes/Repartidor";
import RepEmpleadoss from "./Componentes/RepRepartidor";
 
 const routerRepartidores =()=> {
  return (
    <> 
     <Routes>     
        <Route index element={<RepEmpleadoss/>} /> 
        <Route path="/add" element={<Empleado/>} /> 
        <Route path="/edit/:id" element={<Empleado />} /> 
        <Route path="*" element={<Error404 />} />
    </Routes>
    </> 
  );
}

export default routerRepartidores