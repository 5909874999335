import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import validator from "validator";
import { stringify } from "query-string";
import { getData, postUrl, postdData } from "../../../../../Context/backend";
import RFCValidator from "../../../../../Lib/Funciones Generales/ValidarRFC";

export const getLista = async (iTake, iSkip, Order, query, tipoFuente) => {
  //onsole.log(query)
  if (Order != "1" && query != "") {
    Order = "1";
  }
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    fuente: tipoFuente,
    query: query,
  };
  console.log(queryParamsObj);
  const url = `usuarios/Listar?${stringify(queryParamsObj)}`;
  console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoCliente = async (id, imgDefault) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `usuarios/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          apellidos: String(data.APELLIDO),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          img: String(data.IMG),
          fuente: String(data.FUENTE),
          rfc: String(data.RFC),
          razon_social: String(data.RAZON_SOCIAL),
          cp: data.CP == "0" ? "" : String(data.CP),
          regimen_fiscal: String(data.REGIMEN_FISCAL),
          requiere_factura: data.REQUIERE_FACTURA,
          cfdi: String(data.USO_CFDI),
          Mostrador: Boolean(data.IS_MOSTRADOR),
          fechanacimiento: data.FECHANACIMIENTO == null ? null : String(data.FECHANACIMIENTO),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardarDatosFactura = async (Values, Errores, setErrores) => {
  let error = false;

  let Errores2 = Errores;
  if (!Values.razon_social || Values.razon_social == "") {
    Errores2.razon_social = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    Errores2.cp = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.cfdi || Values.cfdi == "") {
    Errores2.cfdi = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    Errores2.rfc = true;
    setErrores(Errores2);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    Errores2.regimen_fiscal = true;
    setErrores(Errores2);
    error = true;
  }

  Values.regimen_fiscal = String(Values.regimen_fiscal);
  let data = [];
  const body = Values;
  console.log(body);
  if (!error) {
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? `usuarios/EditarFactura}`
        : "usuarios/EditarFactura";

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const guardar = async (
  nombre = "",
  tipo = "",
  id_rs = "",
  img = "",
  Values = {},
  Errores = {},
  setErrores = () => {}
) => {
  try {
    let error = false;
    /* REQUERIDOS */
    if (!Values.nombre || Values.nombre == "") {
      //console.log(Values);
      Errores.nombre = true;
      setErrores(Errores);
      error = true;
    }

    if (!Values.apellidos || Values.apellidos == "") {
      let Errores2 = Errores;

      Errores2.apellidos = true;
      setErrores(Errores2);
      error = true;
    }

    if(Values.correo === null || Values.correo === undefined) Values.correo = "";
    if (Values.correo !== "" && !validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.telefono || Values.telefono == "") {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else {
      if (!validator.isNumeric(Values.telefono)) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      } else if (Values.telefono.length != 10) {
        Errores.telefono = true;
        setErrores(Errores);
        error = true;
      }
    }

    if (!Values.fuente || Values.fuente == "") {
      Errores.fuente = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.fechanacimiento || Values.fechanacimiento == "") {
      Errores.fechanacimiento = true;
      setErrores(Errores);
      error = true;
    }

    if (Values.requiere_factura) {
      if (!Values.razon_social || Values.razon_social == "") {
        Errores.razon_social = true;
        setErrores(Errores);
        error = true;
      }

      if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
        Errores.cp = true;
        setErrores(Errores);
        error = true;
      }

      if (!Values.rfc || Values.rfc == "") {
        Errores.rfc = true;
        setErrores(Errores);
        error = true;
      } else {
        if (!RFCValidator(Values.rfc)) {
          Errores.rfc = true;
          setErrores(Errores);
          error = true;
        }
      }

      if (
        !Values.regimen_fiscal ||
        Values.regimen_fiscal == "" ||
        Values.regimen_fiscal == "0"
      ) {
        Errores.regimen_fiscal = true;
        setErrores(Errores);
        error = true;
      }

      Values.regimen_fiscal = String(Values.regimen_fiscal);
    }

    if (Values.direcciones?.length > 0) {
      const dir = Values.direcciones.map((item) => {
        return {
          id:
            Values.id == undefined || Values.id == "" || Values.id == 0
              ? 0
              : item.ID,
          iD_CLIENTE: Number(Values.id),
          nombre: item.NOMBRE_CONTACTO,
          telefono: Number(item.TELEFONO),
          cp: item.CP,
          calle: item.CALLE,
          numeroext: item.NUMERO_EXT,
          numeroint: item.NUMERO_INT,
          colonia: item.COLONIA,
          estado: item.ID_ESTADO,
          municipio: item.ID_MUNICIPIO,
          entrecallE1: item.CALLE1,
          entrecallE2: item.CALLE2,
          tipodomicilio: item.TIPODOMICILIO,
          indicaciones: item.INDICACIONES,
        };
      });
      Values.direcciones = dir;
    }

    if (!error) {
      let im = "";
      if (Values.img?.includes("base64")) {
        im = Values.img?.split("base64,")[1];
        Values.img = im;
      } else {
        Values.img = im;
      }
      ///console.log(im);
      let data = [];
      let queryParamsObj = {
        isByUser: false,
      };
      const body = {
        id:
          Values.id == undefined || Values.id == "" || Values.id == 0
            ? 0
            : Number(Values.id),
        nombre: nombre,
        iD_RS: id_rs,
        tipo: tipo,
        img: Values.img,
        apellidos: Values.apellidos ? Values.apellidos : "",
        correo: Values.correo ? Values.correo : "",
        telefono: Values.telefono ? Values.telefono : "",
        fuente: Values.fuente,
        is_mostrador: Values.Mostrador,
        direcciones: Values.direcciones,
        requiere_factura: Values.requiere_factura,
        rfc: Values.rfc,
        razon_social: Values.razon_social,
        cp: Values.cp,
        regimen_fiscal: Values.regimen_fiscal,
        fechanacimiento: Values.fechanacimiento,
      };
      console.log(body);
      const url =
        Values.id == undefined || Values.id == "" || Values.id == 0
          ? `usuarios/Insertar?${stringify(queryParamsObj)}`
          : "usuarios/Editar";

      console.log(url);
      console.log(body);
      const res = await postdData(url, body);
      //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };

      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          //console.log(data);
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `usuarios/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `usuarios/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `usuarios/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarasd = async (
  nombre = "",
  tipo = "",
  id_rs = "",
  img = "",
  Values = {},
  Errores = {},
  setErrores = () => {}
) => {
  let error = false;

  /* REQUERIDOS */

  if (!error) {
    let data = [];
    const body = {
      id: 0,
      nombre: nombre,
      iD_RS: id_rs,
      tipo: tipo,
      img: img,
      apellidos: Values.apellido ? Values.apellido : "",
      correo: Values.correo ? Values.correo : "",
      telefono: Values.telefono ? Values.telefono : "",
      contrasenia: Values.contrasena ? Values.contrasena : "",
      confirmarcontrasenia: Values.confirmcontrasena
        ? Values.confirmcontrasena
        : "",
    };
    //console.log(body);
    const url = "usuarios/Insertar";

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const getOpcionesCM = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
    IS_MOSTRADOR: true,
  };
  const url = `usuarios/getOpcionesClienteMostrador?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
