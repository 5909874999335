import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { json, useNavigate } from "react-router-dom";
import { guardar, getInfoAdministrador } from "../Funciones/Administradores";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalCargarImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "../../../../assets/EditarImagen.svg";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import "../../../../../Lib/Funciones Generales/Prototipos";
import SelectPuestos from "../../Puestos/Componentes/SelectPuestos";
import SelectGrupoAcceso from "../../../Configuraciones/gruposacceso/Componentes/SelectGrupoAcceso";
// //import Switch from "../../../../includes/Switch";
import Switch from "../../../../includes/Switch";
import { getOpcionesEmpresas } from "../../../Configuraciones/Empresas/Funciones/Empresas";

//getOpcionesEmpresas
const MySwal = withReactContent(Swal);

const Administrador = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  //Definicion de variables para el formulario
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Empresas, setEmpresas] = useState([]);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    id_grupo: "0",
    id_puesto: "0",
    empresas_opcion: {
      emp1: false,
      emp2: false,
    },
    empresa: "0",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
    supervisor: false,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    id_grupo: false,
    id_puesto: false,
    empresa: false,
    correo: false,
    telefono: false,
  });

  console.log("Empresas", Values.empresas_opcion);

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    console.log("Name", Name, "", "Value", Value);

    if (Name.startsWith("empresa_opcion")) {
      const index = parseInt(Name.replace("empresa_opcion_", ""));
      const empresas_opcion = [...Values.empresas_opcion];
      empresas_opcion[index] = !empresas_opcion[index];
      const empresas_seleccionadas = empresas_opcion
        .map((seleccionada, index) =>
          seleccionada ? Empresas[index].ID : null
        )
        .filter((seleccionada) => seleccionada !== null);

      setValues((prevValues) => ({
        ...prevValues,
        empresas_opcion,
        empresas_seleccionadas,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [Name]: Value,
      }));
    }

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoAdministrador(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarAdministrador = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  useEffect(() => {
    getOpcionesEmpresas()
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setEmpresas(opciones);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Usuarios del sistema
      </Button>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar suarios del sistema" : "Nuevo usuarios del sistema"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <div className="d-flex align-items-center" style={{ position: 'absolute', right: 30, marginTop: 10 }}>
                <h6 className="m-0 me-2">Supervisor</h6>
                <Switch
                  checked={Values.supervisor}
                  onChange={(checked, id) => {
                    setValues({...Values, supervisor: checked})
                  }}
                  id={Values.id}
                  tituloActivo="Activar usuario supervisor"
                  tituloInactivo="Desactivar usuario supervisor"
                />
              </div>
              <Row className="p-3">
                <Col
                  sm={12}
                  md={12}
                  lg={4}
                  className="d-flex flex-wrap align-items-center justify-content-center"
                >



                  <Avatar
                    className="shadow"
                    sx={{ width: 120, height: 120 }}
                    src={img_url}
                  />
                  <strong
                    className="w-100 text-center px-0 pt-3"
                    style={{ color: "#3ABE88", cursor: "pointer" }}
                    onClick={handleOpenModal}
                  >
                    Editar foto
                  </strong>
                </Col>
                <Col sm={12} md={4} className="p-3 mt-5">
                  <TextField
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    variant="outlined"
                    value={Values.nombre}
                    error={Errores.nombre}
                    helperText={Errores.nombre ? "Nombre no valido" : ""}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3 mt-5">
                  <TextField
                    fullWidth
                    name="apellido_paterno"
                    label="Apellido paterno"
                    variant="outlined"
                    value={Values.apellido_paterno}
                    error={Errores.apellido_paterno}
                    helperText={
                      Errores.apellido_paterno ? "Apellido paterno no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="apellido_materno"
                    label="Apellido materno"
                    variant="outlined"
                    value={Values.apellido_materno}
                    error={Errores.apellido_materno}
                    helperText={
                      Errores.apellido_materno ? "Apellido materno no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="correo"
                    label="Correo electrónico"
                    variant="outlined"
                    helperText={Errores.correo ? "Correo incorrecto" : ""}
                    value={Values.correo}
                    error={Errores.correo}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={4} className="p-3">
                  <TextField
                    fullWidth
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    helperText={Errores.telefono ? "Teléfono inválido" : ""}
                    value={Values.telefono}
                    error={Errores.telefono}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <SelectGrupoAcceso
                    Value={Values.id_grupo}
                    Error={Errores.id_grupo}
                    handlInputChange={handlInputChange}
                    Name="id_grupo"
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  <SelectPuestos
                    Value={Values.id_puesto}
                    Error={Errores.id_puesto}
                    handlInputChange={handlInputChange}
                    Name="id_puesto"
                  />
                </Col>
                <Col sm={12} md={4} className="p-3">
                  {Empresas ? (
                    Empresas.map((empresa, index) => (
                      <div key={index} className="mb-3">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <h6>{empresa.NOMBRE}</h6>
                          </div>
                          <div className="col-6">
                            <Switch
                              className="algin-self-center"
                              error={Errores[`empresa_opcion_${index}`]}
                              onChange={(checked) => {
                                if (index == 0) {
                                  Values.empresas_opcion.emp1 =
                                    !Values.empresas_opcion.emp1;
                                }
                                if (index == 1) {
                                  Values.empresas_opcion.emp2 =
                                    !Values.empresas_opcion.emp2;
                                }
                                setValues({ ...Values });
                              }}
                              checked={
                                index == 0
                                  ? Values.empresas_opcion.emp1
                                  : Values.empresas_opcion.emp2
                              }
                              tituloActivo="Si"
                              tituloInactivo="No"
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No hay empresas disponibles</p>
                  )}
                </Col>

                <Col sm={12}></Col>
                <Col sm={12}></Col>

                <Col sm={12} md={4} className="p-3">
                  {Values.id == 0 ? (
                    <>
                      <Alert severity="info">
                        <AlertTitle>Mensaje</AlertTitle>
                        Se enviará por correo una contraseña temporal
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarAdministrador()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_PERFIL}
          Titulo="Cargar imagen de empleado"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
          ConfigRecortar={{
            requerida: true,
            aspectoAlto: 4,
            aspectoAncho: 3,
          }}
        />
      )}
    </>
  );
};

export default Administrador;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
