import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getHorarios } from "../Funciones/Horarios";
import dayjs from "dayjs";

const Horarios = ({
  Value,
  Error,
  horario,
  setHorario = () => {},
  Name = "Horario",
 //  fecha,
  sucursal,
  isEditar,
  Values
}) => {
  const opcionInicial = { ID: 0, RANGO: "Horario de entrega" };
  const [ValorFiltrado, setFiltro] = useState();
  const [horarios, setHorarios] = useState({
    cargado: true,
    lista: [opcionInicial],
  });
  const [fecha, setFecha] = useState(dayjs(Values.fecha_entrega).format("YYYY-MM-DD"));
 



  const getHorariosBack = ()=>{

    setFecha(dayjs(Values.fecha_entrega).format("YYYY-MM-DD"))
    setHorarios({ cargado: true, lista: [opcionInicial] });
    getHorarios(sucursal, fecha)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //--AAAA/MM/DD
        //console.log("ListaDeHorarios: ", list);
        if (list.length != 0) {
          setHorarios({ cargado: false, lista: list });
        } else {
          setHorarios({
            cargado: false,
            lista: [{ ID: 0, RANGO: "Sin horarios disponibles" }],
          });
        }
      })
      .catch((resp) => {
        alert("Ha ocurrido un error al obtener horarios")
        console.log(resp)
        setHorarios({
          cargado: false,
          lista: [{ ID: 0, RANGO: "Sin horarios disponibles" }],
        });
      });
  }



  useEffect(() => {
    if(fecha!=Values.fecha_entrega)
    { 
     // alert("fecha ha cambiado"+ fecha+" "+Values.fecha_entrega) 
      getHorariosBack()
    } 
  }, [Values.fecha_entrega]);

 

  useEffect(() => { 
    if(fecha!=Values.fecha_entrega)
      { 
    horarios.cargado=false;
    setHorarios({ ...horarios }); 
    setTimeout(() => {
      horarios.cargado=true;
    setHorarios({ ...horarios });
    }, 1500);
  }
     
  }, [horario]);

  useEffect(() => {

   //console.log(Values.id_sucursal +" - " +Values.id_sucursal  +" - " +Values.tipo_envio )

    if((Values.id_sucursal==0 || Values.id_sucursal=="") && Values.tipo_envio == 2)
    {
      setHorarios({
        cargado: false,
        lista: [{ ID: 0, RANGO: "Selecciona una sucursal" }],
      });
    }
   else{
    getHorariosBack()  
   }
  }, [Values.id_sucursal, Values.tipo_envio]);
  
  
  const getOpcion = () => {
    console.log("obteniendo info "+ horario.inicio )
    const opcion = isEditar
      ? horarios.lista.find((x) => x.INICIO == horario.inicio)
      : horarios.lista.find((x) => x.ID == horario.id);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return !horarios.cargado ? (
    <Autocomplete
      size="small"
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
     //   alert("aa")
        if (value) {
          
          horario.id = value.ID;
          horario.inicio = value.INICIO;
          horario.fin = value.FIN;
          setHorario(
            JSON.parse(
              JSON.stringify({
                id: value.ID,
                inicio: value.INICIO,
                fin: value.FIN,
              })
            )
          );
        } else {
          setHorario({ id: 0, inicio: "00:00", fin: "00:00" });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={horarios.lista}
      getOptionLabel={(option) => {
        return option.RANGO;
      }}
      renderInput={(params) => {
        return (
          <TextField
          disabled={(Values.id_sucursal==0 || Values.id_sucursal=="") && Values.tipo_envio == 2}
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un horario" : ""}
            label="horario"
          />
        );
      }}
    />
  ) : (
    <TextField
      size="small"
      fullWidth
      disabled={true}
      value={"Horarios disponibles"}
    />
  );
};

export default Horarios;
