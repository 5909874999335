import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";


import { BasicDatePicker } from "../../../../includes/DatePicker";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import SelectTipo from "../../../../includes/SelectTipo";
import { getBusqueda } from "../Funciones/IngresoMercancia";
import TableBusqueda from "./TablaBusqueda";


const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);


const ModalBuscar = ({ SetshowModalBuscar,
  handleProductoSeleccionado = (producto, cantidad = 1) => { },
}) => {

  // console.log("MODAL INGRESO")

  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [ValueFechaInicio, setValueFechaInicio] = useState(GetDate(-7));
  const [ValueFechaFin, setValueFechaFin] = useState(GetDate());
  const [ValueSelect, setValueSelect] = useState("123");
  const [Lista, setLista] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [Folio, setFolio] = useState('');


  const fechaCompletaInicio = new Date(ValueFechaInicio);
  const offsetMinutosInicio = fechaCompletaInicio.getTimezoneOffset();
  fechaCompletaInicio.setMinutes(
    fechaCompletaInicio.getMinutes() + offsetMinutosInicio
  );
  const diaInicio = fechaCompletaInicio.getDate();
  const mesInicio = fechaCompletaInicio.getMonth() + 1;
  const anioInicio = fechaCompletaInicio.getFullYear();
  const fechaInicio = `${mesInicio < 10 ? "0" : ""}${mesInicio}/${diaInicio < 10 ? "0" : ""
    }${diaInicio}/${anioInicio}`;
  //console.log(fechaInicio);

  const fechaCompletaFin = new Date(ValueFechaFin);
  const offsetMinutosFin = fechaCompletaFin.getTimezoneOffset();
  fechaCompletaFin.setMinutes(fechaCompletaFin.getMinutes() + offsetMinutosFin);
  const diaFin = fechaCompletaFin.getDate();
  const mesFin = fechaCompletaFin.getMonth() + 1;
  const anioFin = fechaCompletaFin.getFullYear();
  const fechaFin = `${mesFin < 10 ? "0" : ""}${mesFin}/${diaFin < 10 ? "0" : ""
    }${diaFin}/${anioFin}`;


  useEffect(() => {
    getListaBusqueda();
  }, [ValueSelect]);



  const handleChange = (event) => {
    //console.log("Folio: " +event.target.value)
    setFolio(event.target.value)
  };




  const getListaBusqueda = () => {
    setIsLoading(true);
    getBusqueda(ValueSelect, fechaInicio, fechaFin, Folio)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log("Data: "+ JSON.stringify(resp.data));

        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };


  return (

    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        PaperProps={{ elevation: 0 }}
      >
        <IconButton
          aria-label="Close"
          className="m-3 mt-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalBuscar(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Buscar </h6>
        </DialogTitle>
        <DialogContent>


          <Row className="mt-0 ">

            <Col xs={12}>
              <div class="d-flex flex-row align-items-start mb-3 flex-wrap">
                <div class="p-2" style={{ width: "170px" }}>
                  <BasicDatePicker
                    label={"Inicio"}
                    fecha={ValueFechaInicio}
                    setFecha={setValueFechaInicio}
                    format="DD/MM/YYYY"
                    size="small"
                  />
                </div>
                <div class="p-2" style={{ width: "170px" }}>
                  <BasicDatePicker
                    label={"Fin"}
                    fecha={ValueFechaFin}
                    setFecha={setValueFechaFin}
                    format="DD/MM/YYYY"
                    size="small"
                  />
                </div>
                <div class="p-2" style={{ width: "170px" }}>
                  <SelectTipo
                    ValueSelect={ValueSelect}
                    setValueSelect={setValueSelect}
                    size="small"
                  />
                </div>

                <div class="p-2">
                  <TextField
                    name="folio"
                    label="Folio"
                    size="small"
                    value={Folio}
                    variant="outlined"
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 99999,
                      type: 'number',
                    }}
                    onChange={handleChange}
                  />
                </div>

                <div class="p-2 mx-4">
                  <Button
                    className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
                    variant="contained"
                    onClick={getListaBusqueda}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </Col>



          </Row>

          <Row>
            <Col sm={12}>
              <TableBusqueda
                Lista={Lista ?? []}
                IsLoading={IsLoading}
                setLista={setLista}
                updateTable={getListaBusqueda}

                handleProductoSeleccionado={
                  handleProductoSeleccionado
                }
              />
            </Col>
          </Row>




          <Col sm={12} className="pt-3 ">
            <Col sm={12} className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  SetshowModalBuscar(false)
                }}
                loadingPosition="start"
                className="btn btn-cancelar font-AvenirMedium py-2 px-4 me-2 "
                variant="outlined"
              >
                <span>Cancelar</span>
              </Button>

            </Col>
          </Col>



        </DialogContent>
      </Dialog>


    </>
  )
}

export default ModalBuscar;