import React, { useState, useEffect } from "react";
import { Alert, Avatar, Fade, FormHelperText, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Tooltip, Grid, MenuItem, Menu } from "@mui/material";
import { Link } from "react-router-dom";
import { CambiarAlmacen, Desactivar, Eliminar } from "../Funciones/Almacenes";
import withReactContent from "sweetalert2-react-content";
import MoreVert from "@mui/icons-material/MoreVert";
import Swal from "sweetalert2";
import styled from "@emotion/styled";
import Switch from "../../../../../Includes/Switch";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Loading from "../../../../../Includes/Loading";
import { firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
import AccEditar from "./../../../../assets/AccEditar.svg";
import AccEliminar from "./../../../../assets/AccEliminar.svg";
// import { set } from "date-fns/esm";

const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

export default function TableAlmacenes({
  Lista,
  IsLoading,
  setLista,
  getListaAlmacen,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EnablePrincipal = (ID_ALMACEN, ID_SUCURSAL) => {
    console.log("Almacen", ID_ALMACEN);
    console.log("Sucursal", ID_SUCURSAL);
    new Swal({
      title: "¿Estás seguro de cambiar este almacen a principal?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (ID_ALMACEN != undefined && ID_SUCURSAL != undefined) {
          CambiarAlmacen(ID_ALMACEN, ID_SUCURSAL)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Cambiado correctamente",
                icon: "success",
              }).then(function () {
                Actualizar();
              });
            })
            .catch((resp) => {
              alert("Error al cambiar el almacen principal");
            });
        }
      }
    });
  };
  //Actualizar contenido
  const Actualizar = () => {
    getListaAlmacen();
  };

  const EliminarRegistro = (id) => {
    console.log("id: ", id);
    new Swal({
      title: "¿Estás seguro de eliminar este almacen?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                eliminarAlmacenDeTabla(id);
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  // se elimina de la tabla en memoria para no consultar nuevamente toda la lista
  const eliminarAlmacenDeTabla = (id) => {
    const filteredLibraries = Lista.filter((item) => {
      return item.ID !== id;
    });
    setLista(filteredLibraries);
  };

  const onChange = (checked, id) => {
    let text = checked ? "Activar" : "Desactivar";
    new Swal({
      title: `¿Estás seguro de ${text} este almacen?`,
      text: "",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        setIdRow(id);
        setStateRow(checked);
        ActivarOrDesactivar(id, checked);
      }
    });
  };
  const ActivarOrDesactivar = (id, checked) => {
    if (id != undefined) {
      Desactivar(id, checked)
        .then((resp) => {
          const filteredLibraries = Lista.filter((item) => {
            if (item.ID == id) {
              item.ACTIVO = checked;
            }
            return item;
          });
          setLista(filteredLibraries);
          setSeverity("success");
          setMensaje("Cambios guardados correctamente");
          setOpen(true);
        })
        .catch((resp) => {
          console.log(resp);
          setSeverity("error");
          setMensaje("Error al guardar los cambios");
          setOpen(true);
        });
    }
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        // top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem>
            <Switch
              checked={stateRow}
              onChange={onChange}
              id={idRow}
              tituloActivo="Desactivar almacen "
              tituloInactivo="Activar almacen"
              on
            />
            {stateRow ? "Desactivar" : "Activar"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"./edit/" + idRow}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              EliminarRegistro(idRow);
            }}
          >
            <IconButton>
              <img src={AccEliminar} />
            </IconButton>
            Eliminar
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                SUCURSAL
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="left">
                ALMACÉN
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESCRIPCIÓN
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={5}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.FECHA}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.NOMBRE_SUCURSAL}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {firstLeterMayus(row.NOMBRE)}
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {row.IS_PRINCIPAL == "1" && (
                        <FormHelperText error="true">Principal </FormHelperText>
                      )}
                      {row.IS_PRINCIPAL == "1" && row.IS_ECOMMERCE == "1" && (
                        <FormHelperText
                          error="true"
                          style={{ marginLeft: "3px", marginRight: "3px" }}
                        >
                          -
                        </FormHelperText>
                      )}
                      {row.IS_ECOMMERCE == "1" && (
                        <FormHelperText error="true">E-commerce</FormHelperText>
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell component="th" scope="row" className="">
                    {row.DESCRIPCION}
                  </TableCell>
                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      <Switch
                        checked={row.ACTIVO}
                        onChange={onChange}
                        id={row.ID}
                        tituloActivo="Desactivar almacen"
                        tituloInactivo="Activar almacen"
                        on
                        disabled={
                          Boolean(row.IS_PRINCIPAL) || Boolean(row.IS_ECOMMERCE)
                        }
                      />
                      {/*row.IS_PRINCIPAL == "1" ? (
                        <Tooltip title="Principal" placement="top">
                          <IconButton style={{ opacity: 1 }}>
                            <StarIcon style={{ color: "#ffc107" }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="No es principal" placement="top">
                          <IconButton
                            onClick={() =>
                              EnablePrincipal(row.ID, row.ID_SUCURSAL)
                            }
                          >
                            <StarBorderIcon style={{ color: "#ffc107" }} />
                          </IconButton>
                        </Tooltip>
                      )*/}
                      <Link
                        to={"./edit/" + row.ID}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="Editar" placement="top">
                          <IconButton>
                            <img src={AccEditar} />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Tooltip title="Eliminar" placement="top">
                        <IconButton onClick={() => EliminarRegistro(row.ID)}>
                          <img src={AccEliminar} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
