import { Card, Dialog, DialogContent, IconButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import { useEffect } from "react";
import { getInfoVenta } from "../../Funciones/Pedidos";
import { useState } from "react";
import Loading from "../../../../../Includes/Loading";

const ModalEstatusCompra = ({
  setShowDiloglEstatutus,
  CambiarEstatusPedido,
  pedidoSeleccionado,
  setPedidoSeleccionado,
  handleTerminado
}) => {
  const [Values, setValues] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    let array = [];
    if (pedidoSeleccionado != "0") {
      setIsLoading(true);
      getInfoVenta(pedidoSeleccionado)
        .then((resp) => {
          setValues(resp.Values);
          setIsLoading(false);
        })
        .catch((resp) => {
          // console.log(resp);
          setIsLoading(false);
        });
    }
  }, []);
  

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"sm"}>
      
      <div className="position-absolute" style={{ right: 0 }}>
        <IconButton
          onClick={() => {
            setPedidoSeleccionado(0);
            setShowDiloglEstatutus(false);
          }}
        >
          <CloseIcon style={{ width: "60px", height: "60px" }} />
        </IconButton>
      </div>
      <div className="w-100 mt-5">
        <h4 className="text-center fw-bolder">Cambiar estatus del pedido</h4>
      </div>

      <DialogContent
        className="p-5 pt-0 d-flex flex-wrap justify-content-center"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        {IsLoading ? (
          <div className="col-12  ">
            <Loading />
          </div>
        ) : (
          <>
            <Card
              onClick={() => CambiarEstatusPedido(1)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 1
                  ? { border: "4px solid #fd8c27" }
                  : {}),
              }}
            >
              <LocalShippingOutlinedIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-info"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>RECIBIDO </strong>
              </p>
            </Card>

            <Card
              onClick={() => CambiarEstatusPedido(2)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 2
                  ? { border: "4px solid #fd8c27" }
                  : {}),
              }}
            >
              <AccessTimeIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-primary"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>EN PROCESO</strong>
              </p>
            </Card>

            <Card
              onClick={() => handleTerminado(pedidoSeleccionado)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 3
                  ? { border: "4px solid #fd8c27" }
                  : {}),
              }}
            >
              <CheckCircleOutlineIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-success"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>TERMINADO</strong>
              </p>
            </Card>

            <Card
              onClick={() => CambiarEstatusPedido(4)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 4
                  ? { border: "4px solid #CA7823" }
                  : {}),
              }}
            >
              <DeliveryDiningOutlinedIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-warning"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>EN RUTA</strong>
              </p>
            </Card>

            <Card
              onClick={() => CambiarEstatusPedido(5)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 5
                  ? { border: "4px solid #CA7823" }
                  : {}),
              }}
            >
              <MarkunreadMailboxOutlinedIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-success"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>ENTREGADO</strong>
              </p>
            </Card>

            <Card
              onClick={() => CambiarEstatusPedido(0)}
              className={"shadow d-flex flex-wrap mt-4 m-3"}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: "#FFF",
                width: "10rem",
                height: "10rem",
                ...(Values[0].ID_ESTATUS == 0
                  ? { border: "4px solid #fd8c27" }
                  : {}),
              }}
            >
              <HighlightOffIcon
                style={{ fontSize: "5rem" }}
                className={"mx-auto mt-auto text-danger"}
              />
              <p className="w-100 text-center mb-auto mt-0">
                <strong>CANCELADO</strong>
              </p>
            </Card>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalEstatusCompra;
