import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Requisicion from "./Componentes/Requisicion";
import RepRequisicion from "./Componentes/RepRequisicion";




const routerRequisicion = () => {
    return (
        <>
            <Routes>
                <Route index element={<RepRequisicion />} />
                <Route path="/add" element={<Requisicion />} />
                <Route path="/edit/:id" element={<Requisicion />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </>
    );
}

export default routerRequisicion