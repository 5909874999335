import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import RegimenFiscal from "../../../../../../Lib/SAT/Componentes/RegimenFiscal";
import Loading from "../../../../../../Includes/Loading";

export default function TabReceptor({
  data,
  IsLoading,
  handleInputChange = () => {},
  ValuesSend = {},
  Errores = {},
}) {
  return (
    <>
      {IsLoading ? (
        <Loading />
      ) : (
        <Card elevation={3} className="mb-4">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">EMISOR</h6>
            </div>
            <hr
              style={{
                backgroundColor: "#DFDEE0",
                height: "1px",
                opacity: "1",
              }}
              className="m-0 p-0"
            />
            <Row className="px-4">
              <Col xs={12} className="p-1">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                    Razón Social:
                  </Typography>
                  <Typography>{data.emisor?.RAZON_SOCIAL}</Typography>
                </Grid>
              </Col>
              <Col xs={12} className="p-1">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                    Régimen Fiscal:{" "}
                  </Typography>
                  <Typography>{data.emisor?.DESCRIPCION}</Typography>
                </Grid>
              </Col>
              <Col xs={12} className="p-1">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                    RFC:{" "}
                  </Typography>
                  <Typography>{data.emisor?.RFC}</Typography>
                </Grid>
              </Col>
            </Row>
          </CardContent>
        </Card>
      )}
      {IsLoading ? (
        <Loading />
      ) : (
        <Card elevation={3} className="mb-4">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">RECEPTOR</h6>
            </div>
            <hr
              style={{
                backgroundColor: "#DFDEE0",
                height: "1px",
                opacity: "1",
              }}
              className="m-0 p-0"
            />
            <Row className="px-4">
              <Col xs={6} className="p-3">
                <TextField
                  fullWidth
                  name="rfc"
                  label="RFC"
                  variant="outlined"
                  helperText={Errores.rfc ? "RFC invalido" : ""}
                  value={ValuesSend.rfc}
                  error={Errores.rfc}
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={6} className="p-3">
                <TextField
                  fullWidth
                  name="razon_social"
                  label="Razón social"
                  variant="outlined"
                  helperText={
                    Errores.razon_social ? "Razón social invalida" : ""
                  }
                  value={ValuesSend.razon_social}
                  error={Errores.razon_social}
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={6} className="p-3">
                <RegimenFiscal
                  Value={ValuesSend.regimen_fiscal}
                  Error={Errores.regimen_fiscal}
                  handlInputChange={handleInputChange}
                />
              </Col>
              <Col xs={6} className="p-3">
                <TextField
                  fullWidth
                  name="cp"
                  inputProps={{ maxLength: 5 }}
                  label="Código postal"
                  variant="outlined"
                  helperText={Errores.cp ? "Código postal invalido" : ""}
                  value={ValuesSend.cp}
                  error={Errores.cp}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </CardContent>
        </Card>
      )}
    </>
  );
}
