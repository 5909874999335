import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../Context/backend";
import validator from "validator";
import RFCValidator from "../../../../Lib/Funciones Generales/ValidarRFC";
import { obtenerMetodoPagoMasAlto } from "../../../../Lib/Funciones Generales/Generales";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (
  iTake,
  iSkip,
  Order,
  query = "",
  FechaInicio = "",
  FechaFin = "",
  folioBusqueda = "",
  Estatus = -1
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake,
    iSkip,
    Order,
    query,
    FechaInicio,
    FechaFin,
    folioBusqueda,
    Estatus,
  };
  const url = `pedidos/managers/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoPago = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = { id };
  const url = `pedidos/managers/ConsultarInfoPago?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getComprobantes = async (id_venta) => {
  let data = [];
  let queryParamsObj = { id_venta };
  const url = `pedidos/managers/ListarComprobantes?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const comprobantes = JSON.parse(res.data.respuesta.comprobantes);
      resolve({
        data: comprobantes,
        estatus_pedido: res.data.respuesta.estatus_pedido,
      });
    } else {
      reject([]);
    }
  });
};

export const getDistanciaVentaASucursal = async (ID_VENTA) => {
  let data = [];
  let queryParamsObj = { ID: ID_VENTA };
  const url = `ventas/getDistanciaVentaASucursal?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.metros);
      resolve(data);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getListaDetalle = async (id) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = { id };
  const url = `pedidos/managers/ListarDetalle?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoVenta = async (id) => {
  let data = [];
  let detalle = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `pedidos/managers/ListarDetalleCompleto?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data)
      detalle = data.PRODUCTOS;
      data = data.DETALLE;
      const jsonObj = JSON.parse(res.data.respuesta.data);
      // console.log(jsonObj.DISTANCIA);
      const distancia = jsonObj.DISTANCIA;

      resolve({ Values: data, detalle: detalle, DISTANCIA: distancia });
    } else {
      reject({ Values: data, detalle: detalle, DISTANCIA: "" });
    }
  });
};

export const getInfoComprobante = async (id) => {
  let queryParamsObj = {
    id: id,
  };
  const url = `pedidos/managers/ComprobantePago?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      const data = JSON.parse(res.data.respuesta.data);
      resolve(data[0]);
    } else {
      reject({});
    }
  });
};

export const eliminarComprobante = async (id, id_venta, is_cliente = false) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = { id, id_venta, is_cliente };
    const url = `pedidos/managers/EliminarComprobante?${stringify(
      queryParamsObj
    )}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getFloristas = async (id) => {
  let data = [];
  let florista = 0;
  let queryParamsObj = {
    id: id,
  };
  const url = `administradores/managers/ListarAdminsFloristas?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      florista = res.data.respuesta.id_florista;
      resolve({ data: data, florista: florista });
    } else {
      reject([]);
    }
  });
};

export const cambiarEstatusPedido = async (id, id_estatus) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id,
      id_estatus,
    };
    const url = `pedidos/managers/CambiarEstatus?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const tomarPedido = async (id, id_florista, password) => {

  password = password ?? "";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = { id, id_florista, password };
    const url = `pedidos/managers/TomarPedido?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const asignarRepartidorPedido = async (id, id_repartidor) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id,
      id_repartidor,
    };
    const url = `pedidos/managers/AsignarRepartidor?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const guardarInfoPago = async (Values, Metodos) => {
  let data = [];
  console.log("MEDODS: ", Metodos);
  let metodos_pago = {};
  try {
    metodos_pago = {
      efectivo: parseFloat(Metodos.efectivo.monto.toString().toDecimal()),
      transferencia: parseFloat(Metodos.otros.monto.toString().toDecimal()),
      monedero: parseFloat(Metodos.monedero.monto.toString().toDecimal()),
      tarjeta: parseFloat(Metodos.tarjeta.monto.toString().toDecimal()),
      deposito: parseFloat(Metodos.deposito.monto.toString().toDecimal()),
      pago_En_Tienda: parseFloat(
        Metodos.pago_En_Tienda.monto.toString().toDecimal()
      ),
    };
    console.log(metodos_pago);
    metodos_pago.efectivo =
      metodos_pago.efectivo == null ||
        metodos_pago.efectivo == NaN ||
        metodos_pago.efectivo == ""
        ? 0
        : metodos_pago.efectivo;

    metodos_pago.transferencia =
      metodos_pago.transferencia == null ||
        metodos_pago.transferencia == NaN ||
        metodos_pago.transferencia == ""
        ? 0
        : metodos_pago.transferencia;

    metodos_pago.monedero =
      metodos_pago.monedero == null ||
        metodos_pago.monedero == NaN ||
        metodos_pago.monedero == ""
        ? 0
        : metodos_pago.monedero;

    metodos_pago.tarjeta =
      metodos_pago.tarjeta == null ||
        metodos_pago.tarjeta == NaN ||
        metodos_pago.tarjeta == ""
        ? 0
        : metodos_pago.tarjeta;

    metodos_pago.deposito =
      metodos_pago.deposito == null ||
        metodos_pago.deposito == NaN ||
        metodos_pago.deposito == ""
        ? 0
        : metodos_pago.deposito;

    metodos_pago.pago_En_Tienda =
      metodos_pago.pago_En_Tienda == null ||
        metodos_pago.pago_En_Tienda == NaN ||
        metodos_pago.pago_En_Tienda == ""
        ? 0
        : metodos_pago.pago_En_Tienda;


    console.log(metodos_pago);


  } catch (error) {
    console.log(error);
  }

  const metodoP = obtenerMetodoPagoMasAlto(metodos_pago);
  //console.log("METODOS DE PAGO MAS ALTO: ", metodoP);
  console.log(metodoP);
  switch (metodoP) {
    case "transferencia":
      Values.metodo_pago = "TRANSFERENCIA";
      break;
    case "monedero":
      Values.metodo_pago = "MONEDERO";
      break;
    case "tarjeta":
      Values.metodo_pago = "TARJETA";
      break;
    case "efectivo":
      Values.metodo_pago = "EFECTIVO";
      break;
    case "deposito":
      Values.metodo_pago = "DEPOSITO";
      break;
    case "pago_En_Tienda":
      Values.metodo_pago = "PAGO EN TIENDA";
      break;
    default:
      Values.metodo_pago = "";
      break;
  }

  let body = { ...Values, metodos_pago };
  const url = `pedidos/managers/PagarDesdeMonitor`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarInfoComprobante = async (Values) => {
  let data = [];
  let body = { ...Values };
  const url =
    Values.id == 0
      ? `pedidos/managers/AgregarComprobante`
      : `pedidos/managers/EditarComprobante`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getRepartidores = async (id) => {
  let data = [];
  let repartidor = 0;
  let queryParamsObj = {
    id: id,
  };
  const url = `administradores/managers/ListarAdminsRepartidores?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      repartidor = res.data.respuesta.id_repartidor;
      resolve({ data: data, repartidor: repartidor });
    } else {
      reject([]);
    }
  });
};

export const getColoresEstatus = (ID_ESTATUS) => {
  let colorSstatus = "";
  let backgrorundStatus = "";
  let backgrorundStatusStyle = {};
  let colores = {
    recibido: "info",
    enProceso: "warning",
    terminado: "success",
    cancelado: "danger",

    recibidoV2: "#0dcaf0",
    enProcesoV2: "#ffc107 ",
    terminadoV2: "#e0ffd2",
    canceladoV2: "#dc3545",

    recibidoV3: "#00badf",
    enProcesoV3: "#fff1d2 ",
    terminadoV3: "#198754",
    canceladoV3: "#dc3545",
  };
  switch (ID_ESTATUS) {
    case 1:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-info";
      colorSstatus = "#FFF";
      backgrorundStatusStyle = { backgroundColor: colores.recibidoV3 };
      break;
    case 2:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-warning";
      backgrorundStatusStyle = { backgroundColor: colores.enProcesoV3 };
      break;
    case 3:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-success";
      backgrorundStatusStyle = { backgroundColor: colores.terminadoV2 };
      break;
    case 4:
      backgrorundStatusStyle = { backgroundColor: colores.enProcesoV3 };
      break;
    case 5:
      backgrorundStatusStyle = { backgroundColor: colores.terminadoV2 };
      break;
    case 0:
      // colorSstatus = "#FFF"; backgrorundStatus = "bg-danger";
      colorSstatus = "#FFF";
      backgrorundStatusStyle = { backgroundColor: colores.canceladoV3 };
      break;
  }

  return { colorSstatus, backgrorundStatus, backgrorundStatusStyle, colores };
};

export const getDatosFacturacion = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `pedidos/managers/getDatosFacturacion?${stringify(
    queryParamsObj
  )}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    //console.log(res);
    if (!res.error) {
      //data = JSON.parse(res.data.respuesta);
      const em = JSON.parse(res.data.respuesta.emisor);
      const rec = JSON.parse(res.data.respuesta.receptor);
      const concep = JSON.parse(res.data.respuesta.conceptos);

      let values = {
        emisor: em.length > 0 ? em[0] : {},
        receptor: rec.length > 0 ? rec[0] : {},
        conceptos: concep,
      };
      resolve(values);
    } else {
      reject([]);
    }
  });
};

export const timbrarCFDICompra = async (Values, Errores, setErrores, id) => {
  let error = false;

  if (!Values.uso_cfdi || Values.uso_cfdi == "") {
    Errores.uso_cfdi = true;
    error = true;
  }

  if (!Values.metodo_pago || Values.metodo_pago == "") {
    Errores.metodo_pago = true;
    error = true;
  }
  if (!Values.forma_pago || Values.forma_pago == "") {
    Errores.forma_pago = true;
    error = true;
  }
  if (!Values.fecha_emision || Values.fecha_emision == "") {
    Errores.fecha_emision = true;
    error = true;
  }

  if (!Values.cp || Values.cp == "" || Values.cp.length > 5) {
    Errores.cp = true;
    setErrores(Errores);
    error = true;
  }
  if (
    !Values.regimen_fiscal ||
    Values.regimen_fiscal == "" ||
    Values.regimen_fiscal == "0"
  ) {
    Errores.regimen_fiscal = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.razon_social || Values.razon_social == "") {
    Errores.razon_social = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.rfc || Values.rfc == "") {
    Errores.rfc = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!RFCValidator(Values.rfc)) {
      Errores.rfc = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.id_cliente || Values.id_cliente <= 0 || Values.id_cliente == "") {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.serie || Values.serie == "" || Values.serie == undefined) {
    Errores.serie = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    Values.id = id;
    Values.regimen_fiscal = String(Values.regimen_fiscal);
    // Values.fecha_emision = parseInt( Values.fecha_emision);
    Values.auto_factura = false;
    const body = Values;
    const url = `Facturacion/facturarpedido`;

    //console.log(url);
    //console.log(body);
    const res = await postdData(url, body);
    //const res = { error: false, data: { codigo: "200", mensaje: "okey" } };

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const CambiarStatusTicket = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = { ID: id };
    const url = `pedidos/managers/reimpresion?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const CambiarTerminado = async (id, img) => {
  if (id != undefined) {
    let data = [];
    const url = `pedidos/managers/MarcarTerminado`;
    if (img.includes("base64")) {
      img = img.split("base64,")[1];
    } else {
      img = "";
    }
    let body = { ID: id, img };
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}