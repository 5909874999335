import * as React from "react";
import { MenuItem, TextField } from "@mui/material";

const Select = ({
  ValueSelect,
  setValueSelect,
  opciones = [ ],
  titulo="Selecciona",
  size="medium"
}) => {
  const handlInputChange = ({ target }) => {
    setValueSelect(target.value);
  }; 
  return (
    <TextField
    size={size}
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handlInputChange}
      label={titulo}
      value={ValueSelect}
      className="mt-3 mt-sm-3 mt-md-0"
    >
      {opciones.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
