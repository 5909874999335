import { getLocalStorageJWT, clearStorageJWT } from "./../Context/Storage";
import React, { useState, useEffect } from "react";

export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://florcreativaapi.azurewebsites.net/api/"
    : "http://localhost:7053/api/";

//const BACKEND_URL = "https://tymtrackingapi.azurewebsites.net/api/"
//const BACKEND_URL = "http://localhost:7053/api/";
export const KEY = "27a7f6f3-f9fe-421f-ab72-fdcbf751ff97";

export function postdData(url, body) {
  let bearer_token = getLocalStorageJWT();

  return fetch(BACKEND_URL + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status != 200) {
        console.log(response);
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      //console.log(response);
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
          data: response.resp,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: true,
        mensaje: "Error al conectar con los servidores (503)",
      };
    });
}

export function postUrl(url) {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status != 200) {
        console.log(response);
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return {
        error: true,
        mensaje: "Error al conectar con los servidores (503)",
      };
    });
}

export const getData = (url) => {
  let bearer_token = getLocalStorageJWT();
  return (
    fetch(BACKEND_URL + url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    })
      /*
  .then(function(response) { 
    console.log(response);
    response.json()
    console.log(response);
    if (response.status === 401) {
      clearStorageJWT() 
    }  
  })
]*/
      .then((response) => {
        if (response.status == 401) {
          clearStorageJWT();
        }
        if (response.status != 200) {
          console.log(response);
          return {
            resp: {
              codigo: String(response.status),
              mensaje: "Error: " + response.statusText,
            },
          };
        }
        //console.log(response.status);
        return response.json();
      })
      .then((response) => {
        if (response.resp.codigo == "200") {
          return { error: false, data: response.resp };
        } else {
          if (response.resp.codigo == "403" || response.resp.codigo == "401") {
            clearStorageJWT();
          } else {
            return {
              error: true,
              mensaje:
                response.resp.mensaje + " (" + response.resp.codigo + ")",
            };
          }
        }
      })
      .catch((error) => {
        //alert('response.status: ', error.status); // 👉️ 200
        console.log(error);
        return {
          error: true,
          mensaje: "Error al conectar con los servidores (503)",
        };
      })
  );
};
export default BACKEND_URL;
