import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha"

export const CrearFechaCaducidad = (cantidad_ingresada, fecha_caducidad = null) => {
    return {
        cantidad_ingresada,
        fecha_caducidad: !fecha_caducidad ? GetDate() : fecha_caducidad,
        errores: {
            cantidad_ingresada: false,
            fecha_caducidad: false
        }
    }
}

export const EliminarFechaCaducidad = (IndexProducto, IndexCaducidad, Productos, setProductos) => {
    Productos[IndexProducto].Caducidades = Productos[IndexProducto].Caducidades.filter((item, index) => {
        if (index != IndexCaducidad) {
            return item;
        }
    })
    setProductos([...Productos])
}