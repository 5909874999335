import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import Puesto from "./Componentes/Puesto";
import RepPuestos from "./Componentes/RepPuestos";

const routerPuestos = () => {
  console.log("renderizando router");
  return (
    <>
      <Routes>
        <Route index element={<RepPuestos />} />
        <Route path="/add" element={<Puesto />} />
        <Route path="/edit/:id" element={<Puesto />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerPuestos;
