import React, { useState } from "react";
import { TextField, Alert, Snackbar, AlertTitle } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { CargarArchivo } from "../Funciones/Empresas";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Certificados = ({ id, Errores, RFC, cer_string, key_string }) => {
  const [error, setError] = useState({
    mostrar: false,
    mensaje: "",
    severity: "error",
  });
  const navigate = useNavigate();
  const [selectedKeyFileName, setSelectedKeyFileName] = useState("");
  const [selectedCerFileName, setSelectedCerFileName] = useState("");
  const [IsGuardando, setIsGuardando] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [Values, setValues] = useState({
    id: id,
    cer_string: "",
    key_string: "",
    cer_pass: "",
    rfc: RFC,
    pruebas: process.env.NODE_ENV === "production" ? 0 : 1,
  });

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    setValues(NewValue);
    setError({
      ...Errores,
      [Name]: false,
    });
  };

  const getFileExtension = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1];
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      const extension = getFileExtension(file.name);

      let validarTipo = false;
      if (id === 1) {
        validarTipo = true;
      } else {
        validarTipo = true;
      }
      if (validarTipo) {
        if (extension === "cer") {
          setValues({ ...Values, cer_string: base64String });
          setSelectedCerFileName(file.name);
        }

        if (extension === "key") {
          setValues({ ...Values, key_string: base64String });
          setSelectedKeyFileName(file.name);
        }
      } else {
        setError({
          mostrar: true,
          mensaje: "Falta asignar el tipo del archivo",
          severity: "error",
        });
      }
    };
  };

  const guardar = () => {
    setIsGuardando(true);
    CargarArchivo(Values)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setError({
            mostrar: true,
            mensaje: data.mensaje,
            severity: "success",
          });
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setError({
          mostrar: true,
          mensaje: data.mensaje,
          severity: "success",
        });
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={error.mostrar}
        autoHideDuration={6000}
        onClose={() => setError({ ...error, mostrar: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setError({ ...error, mostrar: false })}
          severity={error.severity}
        >
          {error.mensaje}
        </Alert>
      </StyledSnackbar>
      <Row>
        {cer_string === "" && key_string === "" ? (
          <Col sm={12} md={6} className="p-3">
            <Alert severity="error">
              <AlertTitle>No se encontraron certificados</AlertTitle>
              No se encontraron los certificados ni las claves
            </Alert>
          </Col>
        ) : cer_string === "" ? (
          <Col sm={12} md={6} className="p-3">
            <Alert severity="error">
              <AlertTitle>No se encontró el certificado</AlertTitle>
              No se encontró el certificado, pero se encontró la clave
            </Alert>
          </Col>
        ) : key_string === "" ? (
          <Col sm={12} md={6} className="p-3">
            <Alert severity="error">
              <AlertTitle>No se encontró la clave</AlertTitle>
              Se encontró el certificado, pero no se encontró la clave
            </Alert>
          </Col>
        ) : (
          <Col sm={12} md={6} className="p-3">
            <Alert>
              <AlertTitle>Cargados</AlertTitle>
              Los certificados ya se encuentran cargados
            </Alert>
          </Col>
        )}

        <Col></Col>
        <Col sm={12} md={6} className="p-3">
          <p>Selecciona el archivo .cer</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              value={selectedCerFileName || ""}
              readOnly
              style={{
                marginRight: "10px",
                marginTop: "-10px",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                flex: 1,
                outline: "none",
              }}
            />
            <div style={{ position: "relative" }}>
              <label
                htmlFor="fileCer"
                style={{
                  background: "#f1f1f1",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
              >
                Seleccionar archivo
              </label>
              <input
                type="file"
                accept=".cer"
                onChange={handleFileUpload}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                id="fileCer"
              />
            </div>
          </div>
        </Col>

        <Col sm={12} md={6} className="p-3">
          <p>Selecciona el archivo .key</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              value={selectedKeyFileName || ""}
              readOnly
              style={{
                marginRight: "10px",
                marginTop: "-10px",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                flex: 1,
                outline: "none",
              }}
            />
            <div style={{ position: "relative" }}>
              <label
                htmlFor="fileKey"
                style={{
                  background: "#f1f1f1",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
              >
                Seleccionar archivo
              </label>
              <input
                type="file"
                accept=".key"
                onChange={handleFileUpload}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                id="fileKey"
              />
            </div>
          </div>
        </Col>

        <Col sm={12} md={6} className="p-3">
          <div className="d-flex flex-column">
            <div>Ingresa la contraseña de los certificados</div>
            <TextField
              fullWidth
              name="cer_pass"
              label="Contraseña"
              variant="outlined"
              value={Values.cer_pass}
              error={Errores.cer_pass}
              onChange={handlInputChange}
              style={{ marginTop: "10px" }}
            />
          </div>
        </Col>

        <Col sm={12} md={6} className="p-3 d-flex align-items-end">
          <LoadingButton
            loading={IsGuardando}
            loadingPosition="start"
            disabled={IsLoading}
            onClick={() => guardar()}
            className="btn btn-Crexendo font-AvenirMedium py-2 px-4 p-3"
            variant="contained"
            xs={{ with: "100$" }}
          >
            <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando
                ? "Guardando..."
                : id
                ? "Cargar certificados"
                : "Crear"}
            </span>
          </LoadingButton>
        </Col>
      </Row>
    </>
  );
};

export default Certificados;
