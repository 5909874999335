import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepEmpresas from "./Componentes/RepEmpresas";
import Empresas from "./Componentes/Empresas";
const routerEmpresas = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepEmpresas />} />
        <Route path="/add" element={<Empresas/>} />
        <Route path="/edit/:id" element={<Empresas/>} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerEmpresas;
