import {
  Alert,
  Button,
  Card,
  CardContent,
  Avatar,
  Snackbar,
  Stack,
  TextField,
  Tabs,
  Box,
  Tab,
  TextareaAutosize,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  guardar,
  getInfoCliente,
  guardarDatosFactura,
} from "../Funciones/Clientes";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import RegimenFiscal from "../../../../../Lib/SAT/Componentes/RegimenFiscal";
import Switch from "../../../../includes/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccEditarImagen from "./../../../../assets/EditarImagen.svg";
import ModalCargarImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import "../../../../../Lib/Funciones Generales/Prototipos";
import SelectFuente from "./SelectFuente";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TabGenerales from "./TabGenerales";
import TabDirecciones from "./TabDirecciones";
import TabFacturacion from "./TabFacturacion";
import ButtonTabs from "../../AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
import {
  ConvertDate,
  GetDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";

const MySwal = withReactContent(Swal);

const Cliente = () => {
  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tab, setTab] = React.useState(0);
  // const [Mostrador, setMostrador] = useState(false);

  // const ClienteMostrador = ({ target }) => {
  //   setMostrador(target.value);
  // };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellidos: "",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
    fuente: "",
    Mostrador: false,
    direcciones: [],
    rfc: "",
    razon_social: "",
    cp: "",
    regimen_fiscal: "",
    requiere_factura: false,
    cfdi: "",
    fechanacimiento: "",
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellidos: false,
    correo: false,
    telefono: false,
    fuente: false,
    rfc: false,
    razon_social: false,
    cp: false,
    regimen_fiscal: false,
    requiere_factura: false,
    cfdi: false,
    fechanacimiento: false,
  });
  const [fecha, setFecha] = useState(GetDate());

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    console.log(target);
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setValues({ ...Values, fechanacimiento: ConvertDate(fecha) });
  }, [fecha]);

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCliente(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);

    //if(tab === 0){
    guardar(
      Values.nombre,
      "Registro",
      "",
      Values.img,
      Values,
      Errores,
      setErrores
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
    //}

    /*if (tab === 2){
      guardarDatosFactura(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
    }*/
  };

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Clientes
      </Button>
      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Direcciones" className="font-AvenirBold" />
          <Tab label="Facturación" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar cliente" : "Nuevo cliente"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <TabGenerales
                    Values={Values}
                    Errores={Errores}
                    handleOpenModal={handleOpenModal}
                    img_url={img_url}
                    tab={tab}
                    handlInputChange={handlInputChange}
                    setValues={setValues}
                    setFecha={setFecha}
                  />
                ) : tab == 1 ? (
                  <TabDirecciones
                    Values={Values}
                    setValues={setValues}
                    Errores={Errores}
                    handleOpenModal={handleOpenModal}
                    img_url={img_url}
                    tab={tab}
                  />
                ) : (
                  <TabFacturacion
                    Values={Values}
                    Errores={Errores}
                    handlInputChange={handlInputChange}
                    tab={tab}
                  />
                )}
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCliente()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>

            <ButtonTabs tab={tab} setTab={setTab} tabsTotales={3} />
          </Stack>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_USUARIOS}
          Titulo="Cargar imagen del cliente"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
          ConfigRecortar={{
            requerida: true,
            aspectoAlto: 1,
            aspectoAncho: 1,
          }}
        />
      )}
    </>
  );
};

export default Cliente;
