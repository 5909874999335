import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { GetDate } from '../Lib/Funciones Generales/ObtenerFecha';

import 'dayjs/locale/es-mx';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

export const BasicTimePicker = ({
    hora,
    setTime,
    label,
    format = "HH:mm",
    size = "small",
    readOnly = false
}) => {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-MX">
            <TimePicker
                readOnly={readOnly}
                ampm={false}
                orientation="landscape"
                label={label}
                value={dayjs(GetDate() + "T" + hora)}
                inputFormat={format}
                onChange={setTime}
                renderInput={(params) => (
                    <TextField
                        {...params} size={size}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                )}
            />
        </LocalizationProvider>
    );
}