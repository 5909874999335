import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepInsertarMercancia from "./Componentes/RepInsertarMercancia";
import IngresoMercancia from "./Componentes/IngresoMercancia";

const routerIngresoMercancia = () => {
  return (
    <>
      <Routes>
        <Route index element={<RepInsertarMercancia />} />
        <Route path="/add" element={<IngresoMercancia />} />
        <Route path="/edit/:id" element={<IngresoMercancia />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default routerIngresoMercancia;
