import React from "react";
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function BackTab({
  tab = 0,
  setTab = () => {},
  btnClass = "btn btn-exp-Excel",
  style = {},
}) {
  return (
    <>
      <Button
        onClick={() => {
          const nextTab = tab - 1;
          setTab(nextTab);
        }}
        className={`${btnClass} font-AvenirMedium py-2 px-4 ms-auto`}
        variant="text"
        style={style}
      >
        <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
        Anterior
      </Button>
    </>
  );
}
