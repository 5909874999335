import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../Includes/Loading";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getLocalStorage } from "../../../../Context/Storage";
import ModalTicket from "../../Catalogos/CorteCaja/Componentes/ModalTicket";
import {
  getInfoCorte,
  GuardarCorte,
} from "../../Catalogos/CorteCaja/Funciones/CorteCaja";
import { formatMoneda } from "../../../../Lib/Funciones Generales/Generales";

import Efectivo from "../../../assets/Efectivo.svg";
import Monedero from "../../../assets/Monedero.svg";
import Otros from "../../../assets/Otro.svg";
import Tarjeta from "../../../assets/Tarjeta.svg";

const MySwal = withReactContent(Swal);

const ModalCorte = ({
  SetshowModalCorte = () => {},
  SetshowModalTicket = (idRow) => {},
}) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [detalle, setDetalle] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [openMSG, setOpenMSG] = useState(false);
  const navigate = useNavigate();
  const [idRow, setIdRow] = useState(0);

  const [faltante, setFaltante] = useState(0);
  const [colorText, setcolorText] = useState("");

  const [Values, setValues] = useState({
    fTotalTarjeta: 0,
    fTotalEfectivo: 0,
    fTotalMonedero: 0,
    fTotalTransferencia: 0,
    fTotalDeposito: 0,
    fTotalPagoEnTienda: 0,
    fTotalCompras: 0,
    observaciones: "",
    fTotalTarjetaEntregado: 0,
    fTotalEfectivoEntregado: 0,
    fTotalMonederoEntregado: 0,
    fTotalTransferenciaEntregado: 0,
    fTotalDepositoEntregado: 0,
    fTotalPagoEnTiendaEntregado: 0,
  });

  const [Errores, setErrores] = useState({
    fTotalTarjeta: false,
    fTotalEfectivo: false,
    fTotalMonedero: false,
    fTotalTransferencia: false,
    fTotalDeposito: false,
    fTotalPagoEnTienda: false,
    fTotalCompras: false,
    observaciones: false,
    fTotalTarjetaEntregado: false,
    fTotalEfectivoEntregado: false,
    fTotalMonederoEntregado: false,
    fTotalTransferenciaEntregado: false,
    fTotalDepositoEntregado: false,
    fTotalPagoEnTiendaEntregado: false,
  });

  const [sucursalSelector, setSucursalSelector] = useState(
    getLocalStorage("SUCURSAL_SELECTOR") == null ||
      getLocalStorage("SUCURSAL_SELECTOR") == undefined ||
      getLocalStorage("SUCURSAL_SELECTOR") == ""
      ? 0
      : getLocalStorage("SUCURSAL_SELECTOR")
  );

  console.log("ID_SUCURSAL:" + sucursalSelector);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const GuardarCorteCaja = () => {
    setIsGuardando(true);
    GuardarCorte(Values, Errores, setErrores, detalle, sucursalSelector)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            //SetshowModalCorte(false);
            setIdRow(data.respuesta);

            SetshowModalTicket(true);
          });
        } else {
          setMensaje(data.mensaje);
          setOpenMSG(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpenMSG(true);
        setIsGuardando(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      getInfoCorte(sucursalSelector)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setIsLoading(false);
            setValues(resp.values);
            setDetalle(resp.detalle);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    }, 1300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("2");
      Calcular();
      if (Values.fTotalCompras === 0) {
        MySwal.fire({
          title: "Error",
          html: "No hay ventas suficientes para crear corte",
          icon: "warning",
          confirmButtonColor: "#3ABE88",
          showConfirmButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
        }).then(() => {
          SetshowModalCorte(false);
        });
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [Values]);

  function Calcular() {
    //console.log("calculando...")
    let TOTAL = Values.fTotal;
    let TOTAL_SUMA =
      Number(Values.fTotalTarjetaEntregado) +
      Number(Values.fTotalEfectivoEntregado) +
      Number(Values.fTotalMonederoEntregado) +
      Number(Values.fTotalTransferenciaEntregado) +
      Number(Values.fTotalDepositoEntregado) +
      Number(Values.fTotalPagoEnTiendaEntregado);
    //console.log("SUMA..." +TOTAL_SUMA)
    setFaltante(TOTAL - TOTAL_SUMA);
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true} PaperProps={{ elevation: 0 }}>
        <IconButton
          aria-label="Close"
          className="m-0"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalCorte(false);
          }}
        >
          <CloseIcon style={{ width: "60px", height: "60px" }} />
        </IconButton>
        <DialogTitle style={{ backgroundColor: "#f9f9f9f9" }}>
          <h6 className="fw-bolder f-12 mt-2">Crear corte</h6>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#f9f9f9f9" }}>
          {IsLoading ? (
            <div className="col-12  ">
              <Loading />
            </div>
          ) : (
            <>
              <Card elevation={3} className="mb-4">
                <CardContent className="p-0">
                  <hr
                    style={{
                      backgroundColor: "#DFDEE0",
                      height: "1px",
                      opacity: "1",
                    }}
                    className="m-0 p-0"
                  />
                  {IsLoading ? (
                    <Row className="px-3">
                      <Col sm={12} className="p-3">
                        <Loading />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row className="p-3">
                        <Col sm={12} md={6} className="p-2 font-AvenirBold">
                          {" "}
                          <h5>Formas de pago</h5>{" "}
                        </Col>
                        <Col sm={12} md={3} className="p-2 font-AvenirBold">
                          {" "}
                          <h5>Venta</h5>{" "}
                        </Col>
                        <Col sm={12} md={3} className="p-2 font-AvenirBold">
                          {" "}
                          <h5>Entrega</h5>{" "}
                        </Col>

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Tarjeta}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Tarjeta de Cred / Deb
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalTarjeta"
                            label="Tarjeta"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalTarjeta
                                ? "Total de tarjeta inválido"
                                : ""
                            }
                            value={Values.fTotalTarjeta.toFixed(2)}
                            error={Errores.fTotalTarjeta}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalTarjetaEntregado"
                            label="Tarjeta"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalTarjetaEntregado
                                ? "Total de tarjeta requerido"
                                : ""
                            }
                            value={Values.fTotalTarjetaEntregado}
                            error={Errores.fTotalTarjetaEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Efectivo}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Efectivo
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalEfectivo"
                            label="Efectivo"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalEfectivo
                                ? "Total de efectivo inválido"
                                : ""
                            }
                            value={Values.fTotalEfectivo.toFixed(2)}
                            error={Errores.fTotalEfectivo}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalEfectivoEntregado"
                            label="Efectivo"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalEfectivoEntregado
                                ? "Total de efectivo requerido"
                                : ""
                            }
                            value={Values.fTotalEfectivoEntregado}
                            error={Errores.fTotalEfectivoEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Monedero}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Monedero electrónico
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalMonedero"
                            label="Monedero"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalMonedero
                                ? "Total de monedero inválido"
                                : ""
                            }
                            value={Values.fTotalMonedero.toFixed(2)}
                            error={Errores.fTotalMonedero}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalMonederoEntregado"
                            label="Monedero"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalMonederoEntregado
                                ? "Total de monedero requerido"
                                : ""
                            }
                            value={Values.fTotalMonederoEntregado}
                            error={Errores.fTotalMonederoEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Otros}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Transferencia
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalTransferencia"
                            label="Transferencia"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalTransferencia
                                ? "Total de transferencia inválido"
                                : ""
                            }
                            value={Values.fTotalTransferencia.toFixed(2)}
                            error={Errores.fTotalTransferencia}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalTransferenciaEntregado"
                            label="Transferencia"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalTransferenciaEntregado
                                ? "Total de transferencia requerido"
                                : ""
                            }
                            value={Values.fTotalTransferenciaEntregado}
                            error={Errores.fTotalTransferenciaEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        {/* depsito */}

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Efectivo}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Depósito
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalDeposito"
                            label="Depósito"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalDeposito
                                ? "Total de depósito inválido"
                                : ""
                            }
                            value={Values.fTotalDeposito.toFixed(2)}
                            error={Errores.fTotalDeposito}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalDepositoEntregado"
                            label="Depósito"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalDepositoEntregado
                                ? "Total de depósito requerido"
                                : ""
                            }
                            value={Values.fTotalDepositoEntregado}
                            error={Errores.fTotalDepositoEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        {/* pago en tienda */}

                        <Col sm={12} md={6} className="p-2">
                          <div className="d-flex p-2 align-content-center align-items-center">
                            <div className="pe-2">
                              <img
                                src={Efectivo}
                                className="img-fluid"
                                style={{ maxWidth: "40px" }}
                              />
                            </div>
                            <div className="">
                              <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                                Pago en Tienda
                              </h6>
                            </div>
                          </div>
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            multiline
                            name="fTotalPagoEnTienda"
                            label="Pago en Tienda"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            helperText={
                              Errores.fTotalPagoEnTienda
                                ? "Total de pago en tienda inválido"
                                : ""
                            }
                            value={Values.fTotalPagoEnTienda.toFixed(2)}
                            error={Errores.fTotalPagoEnTienda}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Col>

                        <Col sm={12} md={3} className="p-2">
                          <TextField
                            fullWidth
                            name="fTotalPagoEnTiendaEntregado"
                            label="Pago en Tienda"
                            variant="outlined"
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 99999,
                              type: "number",
                            }}
                            helperText={
                              Errores.fTotalPagoEnTiendaEntregado
                                ? "Total de pago en tienda requerido"
                                : ""
                            }
                            value={Values.fTotalPagoEnTiendaEntregado}
                            error={Errores.fTotalPagoEnTiendaEntregado}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} md={9} className="py-4 mx-1">
                          <TextField
                            fullWidth
                            multiline
                            name="observaciones"
                            label="Observaciones"
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            helperText={
                              Errores.observaciones
                                ? "Observaciones requeridas"
                                : ""
                            }
                            value={Values.observaciones}
                            error={Errores.observaciones}
                            onChange={handlInputChange}
                          />
                        </Col>

                        <Col sm={12} className="p-2 font-AvenirBold">
                          <div className="col-12 mb-2 text-end mt-3">
                            <h5>
                              <span className="font-AvenirMedium mb-3">
                                Total compras:{" "}
                              </span>
                              <span className="font-AvenirBold">
                                {Values.fTotalCompras}
                              </span>
                            </h5>

                            <h5>
                              <span className="font-AvenirMedium mb-3">
                                Total:{" "}
                              </span>
                              <span className="font-AvenirBold">
                                ${Values.fTotal}
                              </span>
                            </h5>

                            <h5>
                              <span className="font-AvenirMedium mb-3">
                                Faltante:{" "}
                              </span>
                              <span
                                className={
                                  "font-AvenirBold" +
                                  (faltante === 0
                                    ? " text-success"
                                    : " text-danger")
                                }
                              >
                                ${faltante.toFixed(2)}
                              </span>
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Stack
                    className="p-3"
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    style={{}}
                  >
                    <LoadingButton
                      loading={IsGuardando}
                      loadingPosition="start"
                      disabled={IsLoading}
                      onClick={() => GuardarCorteCaja()}
                      className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                      variant="contained"
                      xs={{ with: "100$" }}
                    >
                      <span className={IsGuardando ? "px-4" : "px-2"}>
                        {IsGuardando
                          ? "Guardando..."
                          : idRow
                          ? "Guardar"
                          : "Crear"}
                      </span>
                    </LoadingButton>
                    <Button
                      onClick={() => SetshowModalCorte(false)}
                      className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                      variant="outlined"
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCorte;
