import { postdData } from "../../../Context/backend";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const EliminarImagen = async (ID, TIPO, IMG64, SUC = false, ID_ENTIDAD="", PRINCIPAL=false) => {
  let data = [];
  const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;
  const body = {
    id: String(ID),
    tipo: String(TIPO),
    img64: String(IMG64),
    PRUEBAS,
    ID_ENTIDAD: ID_ENTIDAD ? ID_ENTIDAD.toString() : "",
    PRINCIPAL
  };

  const url = `img/delete?galery=${SUC}`;
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const CargarImagen = async (ID, TIPO, IMG64) => {
  let data = [];
  const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;
  const body = { ID, TIPO, IMG64, PRUEBAS };
  const url = "img/upload";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const CargarImagenEdit = async (ID, TIPO, IMG64, PRINCIPAL=false) => {
  let data = [];
  const PRUEBAS = process.env.NODE_ENV === "production" ? 0 : 1;
  const body = { ID, TIPO, IMG64, PRUEBAS, PRINCIPAL};
  const url = "img/uploadEdit";
  const res = await postdData(url, body);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
