import React, { useState, useEffect } from "react";
import Ticket from "../Ticket";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CambiarStatusTicket, getInfoVenta } from "../../Funciones/Pedidos";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { printTicket } from "../../../../../Lib/Funciones Generales/print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Loading from "../../../../../Includes/Loading";

const ModalTicket = ({
  pedidoSeleccionado,
  setPedidoSeleccionado,
  setShowDiloglImprimir,
}) => {
  const [IsLoading, setIsLoading] = useState(true);

  const [config, setConfig] = useState({
    id: 0,
    encabezado: "Encabezado del ticket",
    pie: "Gracias por ser parte de la magia",
    linea_1: "Linea extra para el ticket",
    incrementos_horarios: "",
    si_cobro: false,
    ancho: 80,
    fuente: "11",
  });

  const [Values, setValues] = useState([]);
  const [otrosDatos, setOtrosDatos] = useState([]);

  const [isReimpresion, setIsReimpresion] = useState(false);

  const [productosCarrito, setProductosCarrito] = useState([]);
  const [tipo, setTipo] = useState(0);

  const cambiarReimpresion = () => {
    CambiarStatusTicket(pedidoSeleccionado)
      .then((data) => {
        //console.log(data);
      })
      .catch((data) => {
        //console.log(data);
      });
  };

  useEffect(() => {
    let array = [];
    if (pedidoSeleccionado != "0") {
      setIsLoading(true);
      getInfoVenta(pedidoSeleccionado)
        .then((resp) => {
          array = [];
          resp.detalle.map((row) => {
            array.push({
              ID: row.ID,
              DESC_CORTA: row.PRODUCTO,
              CANTIDAD: row.CANTIDAD,
              PRECIO: row.PRECIO,
              CODIGO: row.CODIGO,
              COLOR: row.COLOR,
              TAMANIO: row.TAMANIO,
            });
          });
          setValues(resp.Values);
          setProductosCarrito(array);
          // alert( resp.DISTANCIA)
          setOtrosDatos({ distancia: resp.DISTANCIA });
          ///console.log("reimpresion", resp.Values[0].IS_REIMPRESION);
          setIsReimpresion(resp.Values[0].IS_REIMPRESION);
          setIsLoading(false);
        })
        .catch((resp) => {
          // console.log(resp);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={true} PaperProps={{ elevation: 0 }}>
        <IconButton
          aria-label="Close"
          className="m-0"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            // setPedidoSeleccionado(0);
            setShowDiloglImprimir(false);
          }}
        >
          <CloseIcon style={{ width: "60px", height: "60px" }} />
        </IconButton>
        <DialogTitle style={{ backgroundColor: "#f9f9f9f9" }}>
          <h6 className="fw-bolder f-12 mt-2">Ticket pedido</h6>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#f9f9f9f9" }}>
          {IsLoading ? (
            <div className="col-12  ">
              <Loading />
            </div>
          ) : (
            <>
              {tipo == 0 ? (
                <div
                  className="d-flex  justify-content-center flex-column mx-auto"
                  style={{ maxWidth: "400px" }}
                >
                  <Button
                    disabled={Values[0].ID_USUARIO_ENCARGADO == 0}
                    onClick={() => {
                      setTipo(1);
                    }}
                    className="m-1 m-md-4 ms-0 btn-accion"
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      background: "#65748B",
                    }}
                  >
                    {" "}
                    <ReceiptIcon
                      className="me-2"
                      style={{ color: "white" }}
                      sx={{ width: 40, height: 40 }}
                    />
                    <Typography
                      className="w-100"
                      style={{
                        color: "white",
                      }}
                    >
                      Ticket florista{" "}
                      {Values[0].ID_USUARIO_ENCARGADO == 0 && "(No dísp) "}
                    </Typography>
                  </Button>

                  <Button
                    onClick={() => {
                      setTipo(2);
                    }}
                    className="m-1 m-md-4 ms-0 btn-accion"
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      background: "#65748B",
                    }}
                  >
                    {" "}
                    <ReceiptIcon
                      className="me-2"
                      style={{ color: "white" }}
                      sx={{ width: 40, height: 40 }}
                    />
                    <Typography
                      className="w-100"
                      style={{
                        color: "white",
                      }}
                    >
                      Ticket envío
                    </Typography>
                  </Button>
                </div>
              ) : (
                <center>
                  <Ticket
                    tipo={tipo}
                    config={config}
                    Values={Values}
                    Articulos={productosCarrito}
                    otrosDatos={otrosDatos}
                    isReimpresion={isReimpresion}
                    ButtonImprimir={
                      <div className="ms-3 sticky-top">
                        <Button
                          onClick={() => {
                            const ok = printTicket();
                            if (ok == true && isReimpresion == false) {
                              cambiarReimpresion();
                            }
                          }}
                          className="  btn-accion  w-100"
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            background: "#65748B",
                          }}
                        >
                          {" "}
                          <LocalPrintshopOutlinedIcon
                            className="me-2"
                            style={{ color: "white" }}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Typography
                            style={{
                              color: "white",
                            }}
                          >
                            Imprimir
                          </Typography>
                        </Button>
                        <Button
                          onClick={() => {
                            setTipo(0);
                          }}
                          className=" mt-3 btn-accion w-100"
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            background: "#65748B",
                          }}
                        >
                          {" "}
                          <ArrowBackIcon
                            className="me-2"
                            style={{ color: "white" }}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Typography
                            style={{
                              color: "white",
                            }}
                          >
                            Regresar
                          </Typography>
                        </Button>
                      </div>
                    }
                  />
                </center>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default ModalTicket;
