import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `repartidores/managers/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpciones = async (query = "", top = 10) => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `repartidores/managers/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoRepartidor = async (id) => {
  let data = [];
  let queryParamsObj = {
    id: id,
  };
  const url = `repartidores/managers/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este empleado",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          apellido_paterno: String(data.APELLIDO_PATERNO),
          apellido_materno: String(data.APELLIDO_MATERNO),
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO),
          fecha_de_ingreso: String(data.FECHA_DE_INGRESO),
          codigo_de_empleado: String(data.CODIGO_DE_EMPLEADO),
          domicilio: String(data.DOMICILIO),
          placas: String(data.PLACAS),
          id_sucursal: data.ID_SUCURSAL,
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }

  if (!Values.apellido_paterno || Values.apellido_paterno == "") {
    Errores.apellido_paterno = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.apellido_materno || Values.apellido_materno == "") {
    Errores.apellido_materno = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.domicilio || Values.domicilio == "") {
    Errores.domicilio = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.placas || Values.placas == "") {
    Errores.placas = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.codigo_de_empleado || Values.codigo_de_empleado == "") {
    Errores.codigo_de_empleado = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.fecha_de_ingreso || Values.fecha_de_ingreso == "") {
    Errores.fecha_ingreso = true;
    setErrores(Errores);
    error = true;
  }

  if (!error) {
    let data = [];
    const body = Values;
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "repartidores/managers/Insertar"
        : "repartidores/managers/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const enviarAcceso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `repartidores/managers/EnviarAcceso?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `repartidores/managers/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `repartidores/managers/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
