import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import { cancelarFactura, enviarFactura } from "../../Funciones/Facturas";
import { Col, Row } from "react-bootstrap";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const ModalCancelarFactura = ({
  facturaSeleccionada,
  correoEnvio,
  setCorreoEnvio,
  setFacturaSeleccionada,
  setShowModalCancelarFactura,
  getListaFacturas,
}) => {
  const [Values, setValues] = useState({
    id: facturaSeleccionada.ID,
    UUID_SUSTITUCION: "",
    UUID: facturaSeleccionada.UUID,
    observaciones: "",
    motivo: "02",
  });
  const [Errores, setErrores] = useState({
    id: false,
    UUID_SUSTITUCION: false,
    UUID: false,
    observaciones: false,
    motivo: false,
  });

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [IsGuardando, setIsGuardando] = useState(false);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    setValues((Values) => ({
      ...Values,
      [Name]: Value,
    }));

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const EnviarFactura = () => {
    new Swal({
      title: "¿Estás seguro de cancelar esta factura?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      setIsGuardando(true);
      if (isConfirm.isConfirmed) {
        cancelarFactura(Values, Errores, setErrores)
          .then((data) => {
            setIsGuardando(false);
            if (data.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                setShowModalCancelarFactura(false);
                getListaFacturas();
                setFacturaSeleccionada([]);
              });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            setIsGuardando(false);
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="sm" open={true} PaperProps={{ elevation: 0 }}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            setFacturaSeleccionada(-1);
            setCorreoEnvio("");
            setShowModalCancelarFactura(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">
            Cancelar factura {facturaSeleccionada.SERIE}-
            {facturaSeleccionada.FOLIO}
          </h6>
        </DialogTitle>
        <DialogContent>
          <Row className="">
            <Col sm={12} className="p-3">
              <TextField
                fullWidth
                label="Folio físcal (UUID)"
                variant="outlined"
                disabled={true}
                value={facturaSeleccionada.UUID}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} className="p-3">
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">
                  Motivo de cancelación
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={Values.fecha_emision}
                  error={Errores.fecha_emision}
                  label="Motivo de cancelación"
                  onChange={handlInputChange}
                  fullWidth
                  name="motivo"
                  defaultValue={"02"}
                >
                  <MenuItem value={"01"}> 01 - Errores con relación </MenuItem>
                  <MenuItem value={"02"}> 02 - Errores sin relación </MenuItem>
                  <MenuItem value={"03"}>
                    {" "}
                    03 - No se llevó a cabo la operación
                  </MenuItem>
                  <MenuItem value={"04"}>
                    {" "}
                    04 - Operación nominativa,relacionada en una factura global
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>

            <Col sm={12} className="p-3">
              <TextField
                fullWidth
                name="UUID_SUSTITUCION"
                label="UUID Sustitución"
                variant="outlined"
                value={Values.UUID_SUSTITUCION}
                error={Errores.UUID_SUSTITUCION}
                onChange={handlInputChange}
              />
            </Col>
            <Col sm={12} className="p-3">
              <TextField
                fullWidth
                multiline
                rows={2}
                name="observaciones"
                label="Observaciones"
                variant="outlined"
                value={Values.observaciones}
                error={Errores.observaciones}
                onChange={handlInputChange}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions className="px-4 pb-4">
          <Button
            onClick={() => {
              setFacturaSeleccionada(-1);
              setShowModalCancelarFactura(false);
              setCorreoEnvio("");
            }}
            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
            variant="outlined"
          >
            Cancelar
          </Button>

          <LoadingButton
            loading={IsGuardando}
            loadingPosition="start"
            onClick={() => EnviarFactura()}
            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
            variant="contained"
            xs={{ with: "100$" }}
          >
            <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando ? "Cancelando..." : "Cancelar factura"}
            </span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCancelarFactura;
