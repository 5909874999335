import { FormGroup, FormControlLabel, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Switch from "../../../../includes/Switch";
import RegimenFiscal from "../../../../../Lib/SAT/Componentes/RegimenFiscal";
import UsoCDFI from "../../../../../Lib/SAT/Componentes/UsoCFDI";

const TabFacturacion = ({ Values, Errores, handlInputChange }) => {
  return (
    <>
      <Row>
        <Col sm={12} className="p-3 ">
          <FormGroup className="align-items-end">
            <FormControlLabel
              control={
                <Switch
                  checked={Values.requiere_factura}
                  name="requiere_factura"
                  error={Errores.requiere_factura}
                  onChange={(checked) => {
                    handlInputChange({
                      target: {
                        name: "requiere_factura",
                        value: checked,
                      },
                    });
                  }}
                  tituloActivo="No requiero factura"
                  tituloInactivo="Requiero factura"
                  on
                />
              }
              label="Requiero Factura"
            />
          </FormGroup>
        </Col>
        <Row>
          <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              name="rfc"
              label="RFC"
              variant="outlined"
              helperText={Errores.rfc ? "RFC invalido" : ""}
              value={Values.rfc}
              error={Errores.rfc}
              onChange={handlInputChange}
            />
          </Col>

          <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              name="razon_social"
              label="Razón social"
              variant="outlined"
              helperText={Errores.razon_social ? "Razón social invalida" : ""}
              value={Values.razon_social}
              error={Errores.razon_social}
              onChange={handlInputChange}
            />
          </Col>

          <Col sm={12} md={6} className="p-3">
            <RegimenFiscal
              Value={Values.regimen_fiscal}
              Error={Errores.regimen_fiscal}
              handlInputChange={handlInputChange}
            />
          </Col>

          <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              name="cp"
              inputProps={{ maxLength: 5 }}
              label="Código postal"
              variant="outlined"
              helperText={Errores.cp ? "Código postal invalido" : ""}
              value={Values.cp}
              error={Errores.cp}
              onChange={handlInputChange}
            />
          </Col>
        </Row>
      </Row>
      {/* <code>
          <pre>{JSON.stringify(Values, null, 2)}</pre>
        </code> */}
    </>
  );
};

export default TabFacturacion;
