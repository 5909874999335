import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import ReportesOrdenCompra from "../OrdenCompra/Reportes/ReportesOrdenCompra";
import ReportesRequisicion from "../RequisicionMercancia/Reportes/ReportesRequisicion";
import PDFVentas from "../ReporteVentas/Componentes/PDFVentas";
import PDFVentasProd from "../ReporteVentasProducto/Componentes/PDFVentasProd";
import PDFMercancia from "../ExistenciaMercancia/Componentes/PDFMercancia";
import PDFIngMerca from "../IngresoMercancia/Reporte/PDFIngMerca";
import PDFFactura from "../../Facturacion/Facturas/Componentes/PDFFactura";
import PDFTraspasoMerca from "../TraspasoMercancia/Reportes/PDFTraspasoMerca";
import PDFAjusteMerca from "../AjusteMercancia/Reporte/PDFAjusteMerca";
import PDFReStock from "../ReStock/Componentes/PDFReStock";
import PDFMerma from "../Mermas/Componentes/PDFMermas";
import PDFCategorias from "../Categorias/Componentes/PDFCategorias";
import PDFClientes from "../Clientes/Componentes/PDFClientes";
import PDFProductos from "../Productos/Componentes/PDFProductos";
import PDFAlmacenes from "../Almacenes/Componentes/PDFAlmacenes";
import PDFProveedores from "../Proveedores/Componentes/PDFProveedores";
import PDFCupones from "../Cupones/Componentes/PDFCupones";
import PDFFacturas from "../../Facturacion/Facturas/Componentes/PDFFacturas";
import PDFHorarios from "../HorarioEnvio/Componentes/PDFHorarios";
import PDFProductosDescuento from "../ProductosDescuentos/Componentes/PDFProductosDescuento";
import PDFRepartidores from "../Repartidores/Componentes/PDFRepartidores";
import PDFPuestos from "../Puestos/Componentes/PDFPuestos";
import PDFZonas from "../Zonas/Componentes/PDFZonas";
import PDFDescuentos from "../Descuentos/Componentes/PDFDescuentos";

const ReportesPDF = () => {
  return (
    <Routes>
      <Route path="ordencompra/:id" element={<ReportesOrdenCompra />}></Route>
      <Route
        path="requisicionmercancia/:id"
        element={<ReportesRequisicion />}
      ></Route>
      <Route path="horarios" element={<PDFHorarios />}></Route>
      <Route
        path="productosDescuento"
        element={<PDFProductosDescuento />}
      ></Route>
      <Route
        path="reportepedidos/:fecha1/:fecha2/:status/:id_repartidor/:id_florista/:pagado/:order/:emisor/:tipoFecha"
        element={<PDFVentas />}
      ></Route>
      <Route
        path="reporteventas/:fecha1/:fecha2/:cancelado/:productos"
        element={<PDFVentasProd />}
      ></Route>
      <Route
        path="reportemermas/:sucursal/:almacen/:tipo/:merma/:busqueda"
        element={<PDFMerma />}
      ></Route>
      <Route
        path="exitenciamercancia/:sucursal/:almacen/:tipo/:existencia/:busqueda/:categoria"
        element={<PDFMercancia />}
      ></Route>
      <Route
        path="pdfrestock/:sucursal/:almacen/:tipo/:existencia"
        element={<PDFReStock />}
      ></Route>
      <Route path="ingresomercancia/:id" element={<PDFIngMerca />}></Route>
      <Route path="factura/:id" element={<PDFFactura />}></Route>
      <Route
        path="traspasomercancia/:id"
        element={<PDFTraspasoMerca />}
      ></Route>
      <Route path="ajustemercancia/:id" element={<PDFAjusteMerca />}></Route>
      <Route
        path="clientes/:ordernamiento/:fuente"
        element={<PDFClientes />}
      ></Route>
      <Route
        path="categorias/:ordernamiento"
        element={<PDFCategorias />}
      ></Route>
      <Route
        path="productos/:ordernamiento/:tipo"
        element={<PDFProductos />}
      ></Route>
      <Route path="almacenes/:ordernamiento" element={<PDFAlmacenes />}></Route>
      <Route
        path="proveedores/:ordernamiento"
        element={<PDFProveedores />}
      ></Route>
      <Route path="cupones/:ordernamiento" element={<PDFCupones />}></Route>
      <Route
        path="facturas/:fecha_inicio/:fecha_fin"
        element={<PDFFacturas />}
      ></Route>
      <Route
        path="repartidores/:ordernamiento/"
        element={<PDFRepartidores />}
      ></Route>
      <Route path="puestos/:ordernamiento/" element={<PDFPuestos />}></Route>
      <Route path="zonas/:ordernamiento/" element={<PDFZonas />}></Route>
      <Route
        path="descuentos/:ordernamiento/"
        element={<PDFDescuentos />}
      ></Route>
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default ReportesPDF;
