import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Radio,
  TextField,
} from "@mui/material";
import Menos from "./../../../assets/Menos.svg";
import Mas from "./../../../assets/Mas.svg";
import { color } from "@mui/system";
import { Col } from "react-bootstrap";
import EliminarRojo from "./../../../assets/EliminarRojo.svg";
import { getDaysFromHours } from "../../../../Lib/Funciones Generales/Generales";
import { TextFieldPrecio } from "./TextFieldPrecio";
const getTiempoFromMinutos = (a) => {
  return a;
};


export default function Carrito({
  productosCarrito = [],
  setProductosCarrito = () => { },
  descuento = {},
  setDescuento = () => { },
  cambio,
  isVista = false,
  isRentable = false,
  setIsRentable = () => { },
  precioEnvio = 0,
  tipoEnvio = 0,
  IVA = true,
  Values = {},
  ventaLibre = false,
  comisionBancaria
}) {
  console.log({ productosCarrito });
  const handleCarritoChange = ({ target }) => {
    console.log({ target });
    const Name = String(target.name);
    const Value = String(target.value);
    let newValue = Value.replace(/[^\d.]/g, '');
    setProductosCarrito(c => c.map((p, index) => index == Name ? ({ ...p, PRECIO: newValue }) : p))
  }
  const [Rentable, setRentable] = useState(false);
  //let Rentable = false;
  let totalTiempo = 0;
  let total = 0;
  {
    productosCarrito.length > 0
      ? productosCarrito.map((row) => {
        //console.log(row);
        total += parseFloat(row.PRECIO) * row.CANTIDAD;
        //totalTiempo += row.DURACION * row.CANTIDAD;
        row.DESCUENTO = 0;
        row.TIPO_DESCUENTO = 0;
      })
      : (total = 0);
  }
  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
        ? (total * descuento.descuento) / 100 > descuento.max
          ? descuento.max
          : (total * descuento.descuento) / 100
        : descuento.descuento;

  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };
  const addDay = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      const precioUnitarioDia =
        items[itemIndex].PRECIO /
        getDaysFromHours(items[itemIndex].HORAS_RENTA);
      /*console.log(
        `${items[itemIndex].PRECIO}/${getDaysFromHours(
          items[itemIndex].HORAS_RENTA
        )}`
      );*/
      //console.log(precioUnitarioDia);
      items[itemIndex].HORAS_RENTA += 24;
      items[itemIndex].PRECIO += precioUnitarioDia;
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };
  const disminuirDia = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    //console.log(itemIndex);
    if (itemIndex !== -1) {
      if (items[itemIndex].HORAS_RENTA > 24) {
        const precioUnitarioDia =
          items[itemIndex].PRECIO /
          getDaysFromHours(items[itemIndex].HORAS_RENTA);
        items[itemIndex].HORAS_RENTA -= 24;
        items[itemIndex].PRECIO -= precioUnitarioDia;
      }
      /*if (items[itemIndex].HORAS_RENTA <= 0) {
        items.splice(itemIndex, 1);
      }*/
    } else {
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    if (newItem.RENTABLE) {
      setIsRentable(false);
    }
    setProductosCarrito(items);
  };
  const Valor_IVA = 16; // IVA del producto
  const IVA_FRACCION = Valor_IVA / 100 + 1;
  const RestaIVA = total / IVA_FRACCION;
  const valorCalculado = IVA ? total : RestaIVA.toFixed(2);
  const TOTAL_IVA = IVA ? total - RestaIVA : 0;
  // const valorCalculadoIVA = IVA ? TOTAL_IVA : TOTAL_IVA;
  const SubtotalIVA = IVA == false ? total : RestaIVA;
  return (
    <>
      <div className="p-3">
        <div className="row">
          <div className="col-12 pe-4">
            <table className="w-100">
              {isVista && (
                <tr>
                  <td className="p-1 ps-0">
                    <h6 className="font-AvenirBold">Concepto</h6>
                  </td>
                  <td className="p-1 text-center">
                    <h6 className="font-AvenirBold tex-end">Cantidad</h6>
                  </td>
                  <td className="p-1 text-center">
                    <h6 className="font-AvenirBold tex-end">Dias de renta</h6>
                  </td>
                  <td className="p-2 pe-0 text-end">
                    <h6 className="font-AvenirBold text-end">Precio</h6>
                  </td>
                </tr>
              )}

              {!isVista && (
                <tr>
                  <td className="p-1 ps-0">
                    <h6 className="font-AvenirBold">Concepto</h6>
                  </td>
                  <td className="p-1 text-center">
                    <h6 className="font-AvenirBold tex-end">Cantidad</h6>
                  </td>
                  {isRentable && (
                    <td className="p-1 text-center">
                      <h6 className="font-AvenirBold tex-end">Dias de renta</h6>
                    </td>
                  )}

                  <td className="p-2 pe-0 text-end">
                    <h6 className="font-AvenirBold text-end">Precio</h6>
                  </td>
                </tr>
              )}

              {productosCarrito.map((row, index) => (
                <>
                  <tr>
                    <td className="p-1 ps-0">
                      <div className="flex-grow-1 w-100">
                        <p
                          className="my-0  font-AvenirMedium"
                          style={{ fontSize: "13px", color: "#808c9f" }}
                        >
                          {row.DESCRIPCCION}
                        </p>
                      </div>
                    </td>
                    <td className="p-1 text-center">
                      <div
                        className={
                          !isVista ? "d-flex justify-content-between " : ""
                        }
                      >
                        {!isVista && (
                          <div>
                            <IconButton
                              className="   mx-2"
                              onClick={() => addItem(row)}
                            >
                              <img src={Mas} style={{ width: "15px" }} />
                            </IconButton>
                          </div>
                        )}
                        {isVista ? (
                          <div
                            className={
                              "d-flex justify-content-center flex-column"
                            }
                            style={{ maxWidth: "150px" }}
                          >
                            <p className="my-0">{row.CANTIDAD}</p>
                          </div>
                        ) : (
                          <div
                            className="d-flex justify-content-center flex-column mx-2 "
                            style={{ width: "30px" }}
                          >
                            <p
                              className="my-0 mb-3 algin-self-center font-Avenir mb-1 text-center"
                              style={{ fontSize: "134x", color: "#808c9f" }}
                            >
                              {row.CANTIDAD}
                            </p>
                          </div>
                        )}

                        {!isVista && (
                          <div>
                            <IconButton
                              aria-label="delete"
                              className="   mx-2"
                              onClick={() => disminuirItem(row)}
                            >
                              <img src={Menos} style={{ width: "15px" }} />
                            </IconButton>
                          </div>
                        )}
                        {!isVista && (
                          <div>
                            <IconButton
                              aria-label="delete"
                              className="   mx-1"
                              onClick={() => deleteItem(row)}
                            >
                              <img
                                src={EliminarRojo}
                                style={{ width: "15px" }}
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </td>
                    {row.RENTABLE ? (
                      <>
                        <td className="p-1 text-center">
                          <div
                            className={
                              !isVista ? "d-flex justify-content-between " : ""
                            }
                          >
                            {!isVista && (
                              <div>
                                <IconButton
                                  aria-label="delete"
                                  className="   mx-2"
                                  onClick={() => {
                                    //console.log("dis");
                                    //disminuirItem(row)
                                    disminuirDia(row);
                                  }}
                                >
                                  <img src={Menos} style={{ width: "15px" }} />
                                </IconButton>
                              </div>
                            )}
                            {isVista ? (
                              <div
                                className={
                                  "d-flex justify-content-center flex-column"
                                }
                                style={{ maxWidth: "150px" }}
                              >
                                <p className="my-0">
                                  {getDaysFromHours(row.HORAS_RENTA)}
                                </p>
                              </div>
                            ) : (
                              <div
                                className="d-flex justify-content-center flex-column mx-2 "
                                style={{ width: "30px" }}
                              >
                                <p
                                  className="my-0 mb-3 algin-self-center font-Avenir mb-1 text-center"
                                  style={{ fontSize: "134x", color: "#808c9f" }}
                                >
                                  {getDaysFromHours(row.HORAS_RENTA)}
                                </p>
                              </div>
                            )}

                            {!isVista && (
                              <div>
                                <IconButton
                                  className="   mx-2"
                                  onClick={() => {
                                    //addItem(row)
                                    //console.log("add");
                                    addDay(row);
                                  }}
                                >
                                  <img src={Mas} style={{ width: "15px" }} />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td className="p-2 pe-0 text-end">
                      <div
                        className="d-flex justify-content-center flex-column mx-2 text-end"
                        style={{ minWidth: "90px" }}
                      >
                        <TextFieldPrecio
                          show={ventaLibre}
                          ventaLibre={ventaLibre}
                          comisionBancaria={comisionBancaria}
                          row={row}
                          key={index}
                          initialValue={row.PRECIO}
                          name={index}
                          IVA={IVA}

                          IVA_FRACCION={IVA ? 1 : IVA_FRACCION}
                          onSubmit={(value) => {
                            console.log({ value });
                            handleCarritoChange({
                              target: {
                                name: index,
                                value
                              }
                            })
                          }}
                        />
                        <p
                          className="my-0 algin-self-center  font-AvenirMedium text-nowrap"
                          style={{ fontSize: "13px", color: "#808c9f", display: ventaLibre ? "none" : "block" }}
                        >
                          $
                          {IVA
                            ? row.PRECIO
                            : (row.PRECIO / IVA_FRACCION).toFixed(2)}{" "}
                          MXN
                        </p>
                        {
                          row.DESCUENTOS_CATEGORIA_PUNTO.TIPO_DESCUENTO != 0 && <p className="my-0 algin-self-center font-AvenirMedium text-nowrap"
                            style={{ fontSize: "11px", color: "#808c9f", display: ventaLibre ? "none" : "block" }}>
                            <p className="my-0 algin-self-center font-AvenirMedium text-nowrap d-inline"
                            >
                              DC: {" "}
                            </p>
                            <p
                              className="my-0 algin-self-center font-AvenirMedium text-nowrap text-decoration-line-through d-inline"
                            >
                              $
                              {IVA
                                ? (comisionBancaria ? row.PRECIO_SIN_DESCUENTO_PUBLICO : row.PRECIO_SIN_DESCUENTO)
                                : ((comisionBancaria ? row.PRECIO_SIN_DESCUENTO_PUBLICO : row.PRECIO_SIN_DESCUENTO) / IVA_FRACCION).toFixed(2)}{" "}
                              MXN
                            </p>
                          </p>
                        }
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <Divider style={{ background: "#e2e2e2" }} />
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </div>

          {totalDescuento != 0 && !isVista ? (
            <>
              <div className="col-12 mt-3 text-end">
                <Link
                  className=" font-AvenirMedium font-carrito"
                  variant="text"
                  href="#"
                  underline="none"
                  onClick={() => {
                    setDescuento({
                      id: 0,
                      descuento: 0,
                      tipo: 0,
                      max: 0,
                      categoria: "",
                    });
                  }}
                  style={{ color: "#d14343", textAlign: "end" }}
                >
                  Eliminar descuentos
                </Link>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-12 mt-3 d-flex justify-content-end">
            <table className="w-100">
              <tr>
                <td className="text-end pe-5">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    SUBTOTAL
                  </h6>
                </td>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    ${IVA ? SubtotalIVA.toFixed(2) : valorCalculado} MXN
                  </h6>
                </td>
              </tr>

              <tr>
                <td className="text-end pe-5">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    DESCUENTO
                  </h6>
                </td>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    ${Math.abs(totalDescuento.toFixed(2))} MXN
                  </h6>
                </td>
              </tr>

              <tr>
                <td className="text-end pe-5">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    COSTO DE ENVIO
                  </h6>
                </td>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    <div
                      //style={{ width: "150px" }}
                      className={
                        typeof precioEnvio == "number" && precioEnvio > 0
                          ? ""
                          : //: total > 0
                          Values.id_zona <= 0
                            ? "text-danger"
                            : ""
                      }
                    >
                      <strong>
                        {typeof precioEnvio == "number" && precioEnvio > 0
                          ? precioEnvio.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          }) + " MXN"
                          : Values.id_zona <= 0
                            ? "Zona no disponible"
                            : "$ 0 MXN"}
                      </strong>
                    </div>
                    {/*${precioEnvio} MXN*/}
                  </h6>
                </td>
              </tr>


              <tr>
                <td className="text-end pe-5">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    IVA
                  </h6>
                </td>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    ${" "}
                    {!isVista
                      ? TOTAL_IVA.toFixed(2)
                      : TOTAL_IVA.toFixed(2)
                        ? 0
                        : TOTAL_IVA.toFixed(2) - Number(precioEnvio)}{" "}
                    MXN
                  </h6>
                </td>
                <td className="text-end"></td>
              </tr>
              <tr>
                <td className="text-end pe-5">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    TOTAL
                  </h6>
                </td>
                <td className="text-end">
                  <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                    ${" "}
                    {!isVista
                      ? (valorCalculado - totalDescuento + Number(precioEnvio)).toFixed(2)
                      : valorCalculado - totalDescuento < 0
                        ? 0
                        : (valorCalculado -
                          totalDescuento +
                          Number(precioEnvio)).toFixed(2)}{" "}
                    MXN
                  </h6>
                </td>
                <td className="text-end"></td>
              </tr>
              {cambio > 0 ? (
                <tr>
                  <td className="text-end">
                    <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                      CAMBIO
                    </h6>
                  </td>
                  <td className="text-end">
                    <h6
                      className="font-carrito text-end font-AvenirMedium"
                      style={{ color: "#3abe88" }}
                    >
                      $ {cambio} MXN
                    </h6>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </div>

          <div>
            <div className="row"></div>
          </div>
        </div>
      </div>

      <Col sm={12} className="d-flex justify-content-end d-none">
        <div
          className="d-flex justify-content-center flex-column me-3 "
          style={{ width: "30px" }}
        >
          <p
            className="my-0 algin-self-center font-AvenirBold mb-1 text-center"
            style={{ fontSize: "13px" }}
          >
            Pagado{" "}
          </p>
        </div>
        {/*<Switch
        checked={Values.pagado}
        name="pagado"
        error={Errores.pagado}
        onChange={(checked) => {
          handlInputChange({
            target: {
              name: "pagado",
              value: checked,
            },
          });
        }}
        tituloActivo="Si Pagado"
        tituloInactivo="No pagado"
      />*/}
      </Col>
    </>
  );
}
