import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getInfoSucursal, Guardar } from "../Funciones/Sucursales";
import Loading from "../../../../../Includes/Loading";
import RepHorarioEnvio from "../../HorarioEnvio/Componentes/RepHorarioEnvio";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Mapa from "../../Zonas/Funciones/Mapa";
import SelectClienteCM from "../../Clientes/Componentes/SelectClienteCM";
import ButtonTabs from "../../AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
//import SelectProducto from "./SelectProductoWithRadio";

const MySwal = withReactContent(Swal);

const containerStyle = {
  width: "100%",
  height: "30rem",
};

const Sucursal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, SetValues] = useState({
    id: id ?? "0",
    nombre: "",
    direccion: "",
    num_telefono: "",
    id_cliente: 0,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    direccion: false,
    num_telefono: false,
    id_cliente: false,
  });
  const [Horarios, setHorarios] = useState({
    1: { rango: "", lista: [] },
    2: { rango: "", lista: [] },
    3: { rango: "", lista: [] },
    4: { rango: "", lista: [] },
    5: { rango: "", lista: [] },
    6: { rango: "", lista: [] },
    7: { rango: "", lista: [] },
  });

  /*
   * --- Inicio mapa ---
   */
  const zoomMap = 13;
  const [ListaPuntos, setListaPuntos] = useState([]);

  const obtenerPointByAddress = (lat, lng) => {
    if (lat != 0 && lng != 0) {
      actualizarMarker(0, lat, lng);
      moverCamera(lat, lng);
    }
  };
  const clickMapa = (lat, lng) => {
    actualizarMarker(0, lat, lng);
  };
  const moverMarker = (marker, index) => {
    const { lat, lng } = marker.latLng;
    actualizarMarker(index, lat(), lng());
  };
  const {
    onLoad,
    onUnmount,
    onMapClick,
    actualizarMarker,
    buscarAddress,
    moverCamera,
    isLoaded,
    Zoom,
    Map,
    ZoomInit,
  } = Mapa({
    clickMapa,
    obtenerPointByAddress,
    zoomMap,
    ListaPuntos,
    setListaPuntos,
  });
  /*
   *  --- Fin mapa ----
   */

  //console.log("PUNTOS: ", Values.id)

  const [tab, setTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    console.log("Name: ", Name, " Value: ", Value);
    let NewValue = {
      ...Values,
      [Name]: Value,
    };
    SetValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoSucursal(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            SetValues(resp);
            if (resp.ubicacion.lat != 0 && resp.ubicacion.lng != 0) {
              setListaPuntos([{ ...resp.ubicacion }]);
            }
            setIsLoading(false);
          }
          // SetValues(resp);
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarSucursal = () => {
    setIsGuardando(true);
    Guardar(Values, Errores, setErrores, Horarios, ListaPuntos)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  useEffect(() => {
    document.title = `Sucursal ${id ? id : " "}`;
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Sucursales
      </Button>

      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Horarios" className="font-AvenirBold" />
          <Tab label="Ubicación" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar sucursal" : "Nuevo sucursal"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              {tab == 0 && (
                <Row className="p-3">
                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Nombre no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <TextField
                      fullWidth
                      name="num_telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={
                        Errores.num_telefono ? "Teléfono inválido" : ""
                      }
                      value={Values.num_telefono}
                      error={Errores.num_telefono}
                      onChange={handlInputChange}
                    />
                  </Col>

                  <Col sm={12} md={6} className="p-3">
                    <SelectClienteCM
                      Name="id_cliente"
                      Value={Values.id_cliente}
                      Error={Errores.id_cliente}
                      handlInputChange={handlInputChange}
                    />
                  </Col>
                  <Divider></Divider>
                </Row>
              )}
              {tab == 1 && !IsLoading && (
                <Row className="p-3">
                  <Col sm={12} className="p-0">
                    <RepHorarioEnvio
                      id_sucursal={Values.id == 0 ? -1 : Values.id}
                      is_save={Values.id == 0 ? false : true}
                      setHorarios={setHorarios}
                      Horarios={Horarios}
                    />
                  </Col>
                </Row>
              )}

              {tab == 2 && (
                <Row className="p-3">
                  <Col sm={9} className="p-3">
                    <TextField
                      multiline
                      fullWidth
                      name="direccion"
                      label="Dirección"
                      maxRows={3}
                      inputProps={{ maxLength: 249 }}
                      value={Values.direccion}
                      error={Errores.direccion}
                      placeholder="Dirección"
                      helperText={
                        Errores.direccion ? "Direccion no valida" : ""
                      }
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={3} className="p-3 d-flex">
                    <Button
                      onClick={() => buscarAddress(Values.direccion)}
                      className="btn btn-Crexendo font-AvenirMedium px-4 py-2 my-auto"
                      variant="contained"
                    >
                      <MyLocationIcon className="me-2" /> Localizar
                    </Button>
                  </Col>
                  <Col sm={12} className="p-0">
                    {!IsLoading && isLoaded && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onClick={onMapClick}
                        zoom={Zoom}
                      >
                        {ZoomInit && Map && (
                          <>
                            {ListaPuntos.map((punto, index) => (
                              <Marker
                                onDragEnd={(marker) =>
                                  moverMarker(marker, index)
                                }
                                draggable={true}
                                position={{
                                  lat: punto.lat,
                                  lng: punto.lng,
                                }}
                              />
                            ))}
                          </>
                        )}
                      </GoogleMap>
                    )}
                  </Col>
                </Row>
              )}

              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => GuardarSucursal()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                  </span>
                </LoadingButton>
                <Button
                  onClick={() => navigate(-1)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <ButtonTabs tab={tab} setTab={setTab} tabsTotales={3} />
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Sucursal;
