import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificacionMenu from "../../assets/NotificacionMenu.svg";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import Badge from "@mui/material/Badge";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  NotificacionesGrl,
  getNotificaciones,
  verNotificaciones,
} from "../Components/Catalogos/Notificaciones/Funciones/Notificaciones";
import { SocketReceptor } from "../../Lib/Funciones Generales/Constantes";
import { Avatar } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { truncateText } from "../../Lib/Funciones Generales/Generales";
import { Row, Col } from "react-bootstrap";

const MenuNotificaciones = ({ Values, IsLoading }) => {
  const initNotificaciones = {
    consultar: true,
    nuevas: false,
    total: 0,
    totalVentasNuevas: 0,
  };
  const [notificaciones, setNotificaciones] = useState(initNotificaciones);
  const [NotifiGrl, setNotifiGrl] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getNotificaciones(SocketReceptor.EMPRESA).then((resp) => {
      setNotificaciones(resp.contadores);
    });
  }, []);

  useEffect(() => {
    if (open) {
      if (!notificaciones.consultar) {
        verNotificaciones(SocketReceptor.EMPRESA).then((resp) => {
          console.log(resp);
          if (resp.respuesta == "Ok") {
            setNotificaciones({ ...initNotificaciones });
          }
        });
      }
    }
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await NotificacionesGrl();
        setNotifiGrl(response);
        const data = response.json();
      } catch (error) {
        console.error("Error al llamar a la API:", error);
      }
    };

    const intervalId = setInterval(fetchData, 60000 * 2);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  /*console.log(NotifiGrl);
  console.log(
    "NUMERO DE NOTIFICACIONES:",
    notificaciones.total + NotifiGrl.length
  );*/
  let Numero_Noti = notificaciones.total + NotifiGrl.length;
  return (
    <>
      <Tooltip title="Notificaciones" onClick={handleClick}>
        {Values.pass_default ? (
          <IconButton
            className="mx-0 px-0 me-2"
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-notificaciones" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <>
              <Badge variant="dot" color="error">
                <img src={NotificacionMenu} />
              </Badge>{" "}
            </>
          </IconButton>
        ) : !notificaciones.nuevas ? (
          <IconButton
            className="mx-0 px-0 me-2"
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-notificaciones" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Badge badgeContent={Numero_Noti} color="error">
              <img src={NotificacionMenu} />
            </Badge>{" "}
          </IconButton>
        ) : (
          <IconButton
            className="mx-0 px-0 me-2"
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-notificaciones" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <>
              <img src={NotificacionMenu} />
            </>
          </IconButton>
        )}
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-notificaciones"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Values.pass_default ? (
          <Link
            to="perfil/update"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>
              <ListItemIcon>
                <GppMaybeIcon className="text-danger" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Es necesario cambiar tu contraseña</ListItemText>
            </MenuItem>
          </Link>
        ) : Numero_Noti == 0 ? (
          <MenuItem>Sin notificaciones</MenuItem>
        ) : (
          <>
            {notificaciones.totalVentasNuevas != 0 && (
              <Link
                to="monitor_pedidos/"
                style={{ textDecoration: "none", color: "#191919" }}
              >
                <MenuItem>
                  Tienes {notificaciones.totalVentasNuevas}
                  {notificaciones.totalVentasNuevas == 1
                    ? " nuevo pedido "
                    : " nuevos pedidos"}
                </MenuItem>
              </Link>
            )}
            {NotifiGrl.map((row) => (
              <>
                <Link
                  to={row.url}
                  style={{ textDecoration: "none", color: "#191919" }}
                >
                  <MenuItem>
                    <div className="d-flex justify-content-start">
                      <div className="d-flex justify-content-start">
                        <ListItemIcon className="d-flex align-items-center">
                          {row.prioridad === 1 ? (
                            <Avatar style={{ backgroundColor: "#198754" }}>
                              <ReportProblemIcon />
                            </Avatar>
                          ) : row.prioridad === 2 ? (
                            <Avatar style={{ backgroundColor: "#ffc107" }}>
                              <ReportProblemIcon />
                            </Avatar>
                          ) : row.prioridad === 3 ? (
                            <Avatar style={{ backgroundColor: "#dc3545" }}>
                              <ReportProblemIcon />
                            </Avatar>
                          ) : (
                            ""
                          )}
                        </ListItemIcon>
                      </div>
                      <div className="d-flex flex-column">
                        <label
                          className="d-flex justify-content-start"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          {row.titulo}
                        </label>
                        <Row>
                          <Col>
                            <label
                              className="text-truncate"
                              style={{ fontSize: "12px", maxWidth: "150px" }}
                            >
                              {row.subtitulo}
                            </label>
                          </Col>
                          <Col>
                            <label
                              className="text-truncate"
                              style={{ fontSize: "12px" }}
                            >
                              {row.fecha}
                            </label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </MenuItem>
                </Link>
              </>
            ))}
          </>
        )}
      </Menu>
    </>
  );
};

export default MenuNotificaciones;
