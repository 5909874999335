import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";

//Peticiones POST
export const Guardar = async (
  Values,
  Errores,
  setErrores,
  Horarios,
  Puntos
) => {
  let error = false;

  let _horarios_ = [];
  if (Horarios) {
    _horarios_ = Array.isArray(Horarios["1"].lista)
      ? _horarios_.concat(Horarios["1"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["2"].lista)
      ? _horarios_.concat(Horarios["2"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["3"].lista)
      ? _horarios_.concat(Horarios["3"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["4"].lista)
      ? _horarios_.concat(Horarios["4"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["5"].lista)
      ? _horarios_.concat(Horarios["5"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["5"].lista)
      ? _horarios_.concat(Horarios["5"].lista)
      : [];
    _horarios_ = Array.isArray(Horarios["7"].lista)
      ? _horarios_.concat(Horarios["7"].lista)
      : [];
  }

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }
  if (
    !Values.id_cliente ||
    Values.id_cliente == "" ||
    Values.id_cliente == "0"
  ) {
    error = true;
    Errores.id_cliente = true;
    setErrores(Errores);
  }

  if (!Values.direccion || Values.direccion == "") {
    Errores.direccion = true;
    setErrores(Errores);
    error = true;
  }
  if (!Values.num_telefono || Values.num_telefono == "") {
    Errores.num_telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.num_telefono)) {
      Errores.num_telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.num_telefono.length != 10) {
      Errores.num_telefono = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    let lat = 0,
      lng = 0;
    lat = Array.isArray(Puntos) && Puntos[0] ? Puntos[0].lat : 0;
    lng = Array.isArray(Puntos) && Puntos[0] ? Puntos[0].lng : 0;

    const body = {
      id: Values.id,
      nombre: Values.nombre,
      direccion: Values.direccion,
      num_telefono: Values.num_telefono,
      horarios: _horarios_,
      ubicacion: { lat, lng },
      id_cliente: Values.id_cliente,
    };
    console.log(body);
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "sucursales/Insertar"
        : "sucursales/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `sucursales/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `sucursales/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

//Peticiones GET
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `sucursales/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesSucursal = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `sucursales/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoSucursal = async (id) => {
  //console.log("aqui" + id);
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `sucursales/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(JSON.stringify(data));
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta sucursal",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          fecha: String(data.FECHA),
          nombre: String(data.NOMBRE),
          direccion: String(data.DIRECCION),
          num_telefono: String(data.TELEFONO),
          ubicacion: { lat: data.LAT, lng: data.LNG },
          id_cliente: String(data.ID_CLIENTE),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
