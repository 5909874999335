import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import RepProductos from "./Componentes/RepProductos";
import Producto from "./Componentes/Producto";

export default function Productos() {
  return (
    <>
      <Routes>
        <Route path="/" element={<RepProductos />} />
        <Route path="/add" element={<Producto />} />
        <Route path="/edit/:id" element={<Producto />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}
