import React from "react";
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function BackTab({ tab = "0", setTab = () => {} }) {
  let TAB = parseInt(tab)
  return (
    <>
      <Button
        onClick={() => {
          const nextTab = TAB - 1 < 0 ? 0 : TAB - 1;
          setTab(String(nextTab));
        }}
        style={{
          background: "#65748B",
        }}
        className="btn  text-white  font-AvenirMedium  py-2 px-4  "
        variant="contained"
      >
        <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
        Anterior
      </Button>
    </>
  );
}
