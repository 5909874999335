//#region Importaciones
import {
  TextField,
  FormGroup,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import "../../../../../../Lib/Funciones Generales/Prototipos";
import Switch from "../../../../../includes/Switch";
import SelectCategoria from "../../../Categorias/Componentes/SelectCategorias";
import SelectProveedores from "../../../Proveedores/Componentes/SelectProveedores";
import SelectUnidadMedidaInterna from "../SelectUnidadMedidaInterna";
//#endregion

const MySwal = withReactContent(Swal);
export default function TabGenerales({
  handlInputChange = () => {},
  Values = {},
  Errores = {},
  comisionTotal = 0,
}) {
  return (
    <>
      {
        //#region primeras columnas
      }
      <Col sm={12} md={4} className="p-3">
        <TextField
          fullWidth
          name="codigo"
          label="Codigo"
          variant="outlined"
          value={Values.codigo}
          error={Errores.codigo}
          helperText={Errores.codigo ? "Codigo no valido" : ""}
          onChange={handlInputChange}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <SelectCategoria
          handlInputChange={handlInputChange}
          Value={Values.id_categoria}
          Error={Errores.id_categoria}
        />
      </Col>

      <Col sm={12} md={4} className="p-3">
        <SelectProveedores
          Name="id_proveedor"
          Value={Values.id_proveedor}
          Error={Errores.id_proveedor}
          handlInputChange={handlInputChange}
        />
      </Col>
      {
        //#endregion
      }
      <Col sm={12} md={12} className="">
        <Row>
          <Col sm={12} md={4}>
            <Row className="">
              <Col className="p-3">
                <TextField
                  fullWidth
                  name="desc_corta"
                  label="Descripcción corta"
                  variant="outlined"
                  value={Values.desc_corta}
                  error={Errores.desc_corta}
                  onChange={handlInputChange}
                  inputProps={{ maxLength: 99 }}
                />
              </Col>
            </Row>
            <Row className="">
              <Col className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Costo de compra
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="p_compra"
                    helperText={Errores.precio ? "Costo no valido" : ""}
                    value={Values.p_compra}
                    error={Errores.p_compra}
                    label="Costo de compra"
                    placeholder="Costo de compra"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={8} className="p-3">
            <TextField
              fullWidth
              name="desc_larga"
              label="Descripcción larga"
              variant="outlined"
              value={Values.desc_larga}
              error={Errores.desc_larga}
              onChange={handlInputChange}
              multiline
              rows={5}
            />
          </Col>
          <Col sm={12} md={4} className="p-3">
            <SelectUnidadMedidaInterna
              Name="unidad_medida"
              Value={Values.unidad_medida}
              Error={Errores.unidad_medida}
              handlInputChange={handlInputChange}
            />
          </Col>
        </Row>
        <Row>
          {Values.isMateriaPrima || Values.isRentable ? (
            <></>
          ) : (
            <>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio de venta
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="precio"
                    helperText={Errores.precio ? "Precio no valido" : ""}
                    value={Values.precio}
                    error={Errores.precio}
                    label="Precio de venta"
                    placeholder="Precio"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Comisión bancaria
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    value={comisionTotal?.toFixed(0)}
                    label="Comisión"
                    placeholder="Comisión"
                    disabled
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio público
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    value={Values.p_c_comisiones?.toFixed(0)}
                    label="Precio publico"
                    placeholder="Precio publico"
                    disabled
                  />
                </FormControl>
              </Col>
              {Values.isInventariable && (
                <>
                  {/* <Col sm={12} md={4} className="p-3">
                    <SelectSucursales
                      Name="id_sucursal"
                      Value={Values.id_sucursal}
                      Error={Errores.id_sucursal}
                      handlInputChange={handlInputChange}
                    />
                  </Col> */}
                  {/* <Col sm={12} md={4} className="p-3">
                    <SelectAlmacenes
                      name="id_almacen"
                      Value={Values.id_almacen}
                      Error={Errores.id_almacen}
                      handlInputChange={handlInputChange}
                      Sucursal={null}
                    />
                  </Col> */}
                </>
              )}
            </>
          )}
        </Row>
      </Col>

      {
        //#region switches
      }
      <Divider light />
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">¿Es materia prima?</h6>
          <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
            Si activas esta opcion este producto no estará disponible en el
            e-commers y punto de venta
          </h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.isMateriaPrima ? "SI" : "NO"}
            </p>

            <Switch
              disabled={Values.is_productoTerminado}
              className="algin-self-center"
              checked={Values.isMateriaPrima}
              name="si_cobro"
              error={Errores.isMateriaPrima}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "isMateriaPrima",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
      <Divider light />
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">¿Es inventariable?</h6>
          <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
            Sí activas esta opción podras inventariar este producto
          </h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.isInventariable ? "SI" : "NO"}
            </p>

            <Switch
              className="algin-self-center"
              checked={Values.isInventariable}
              name="si_cobro"
              error={Errores.isInventariable}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "isInventariable",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
      {Values.isInventariable && (
        <>
          <Col sm={12} md={4} className="p-3">
            <TextField
              fullWidth
              name="stock_min"
              label="Stock minimo"
              variant="outlined"
              value={Values.stock_min}
              error={Errores.stock_min}
              onChange={handlInputChange}
            />
          </Col>
          <Col sm={12} md={4} className="p-3">
            <TextField
              fullWidth
              name="stock_max"
              label="Stock maximo"
              variant="outlined"
              value={Values.stock_max}
              error={Errores.stock_max}
              //type="number"
              onChange={handlInputChange}
            />
          </Col>
        </>
      )}
      {Values.isMateriaPrima ? (
        <></>
      ) : (
        <>
          <Divider light />
          <Col sm={12} className="p-3 d-flex justify-content-between">
            <div className="w-100">
              <h6 className="font-AvenirMedium m-0 p-0">¿Es de alquiler?</h6>
              <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
                Sí activas esta opción podras añadir precios de renta
              </h6>
            </div>
            <FormGroup>
              <div className="d-flex justify-content-center">
                <p
                  className="my-0 algin-self-center font-Avenir mt-2 text-center"
                  style={{ fontSize: "13px" }}
                >
                  {Values.isRentable ? "SI" : "NO"}
                </p>

                <Switch
                  className="algin-self-center"
                  checked={Values.isRentable}
                  name="si_cobro"
                  error={Errores.isRentable}
                  onChange={(checked) => {
                    handlInputChange({
                      target: {
                        name: "isRentable",
                        value: checked,
                      },
                    });
                  }}
                />
              </div>
            </FormGroup>
          </Col>
          {Values.isRentable && (
            <>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio renta por dia
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="precio"
                    helperText={Errores.precio ? "Precio no valido" : ""}
                    value={Values.precio}
                    error={Errores.precio}
                    label="Precio renta por dia"
                    placeholder="Precio de rentar por dia"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio horas extra
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="p_horaExtra"
                    helperText={Errores.p_horaExtra ? "Precio no valido" : ""}
                    value={Values.p_horaExtra}
                    error={Errores.p_horaExtra}
                    label="Precio horas extra"
                    placeholder="Precio de horas extra"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Penalizcion por retrazo en entrega
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="p_penalizacion"
                    helperText={
                      Errores.p_penalizacion ? "Precio no valido" : ""
                    }
                    value={Values.p_penalizacion}
                    error={Errores.p_penalizacion}
                    label="Penalizcion por retrazo en entrega"
                    placeholder="Penalizacion"
                    onChange={handlInputChange}
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Comisión
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    value={comisionTotal?.toFixed(0)}
                    label="Comisión"
                    placeholder="Comisión"
                    disabled
                  />
                </FormControl>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Precio publico
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    value={Values.p_c_comisiones?.toFixed(0)}
                    label="Precio publico"
                    placeholder="Precio publico"
                    disabled
                  />
                </FormControl>
              </Col>
            </>
          )}
        </>
      )}
      {
        //#endregion
      }
      <Divider light />
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">¿Es perecedero?</h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.isPerecedero ? "SI" : "NO"}
            </p>

            <Switch
              className="algin-self-center"
              checked={Values.isPerecedero}
              name="si_perecedero"
              error={Errores.isPerecedero}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "isPerecedero",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
      <Divider light />
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">¿Es E-Commerce?</h6>
          <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
            Sí activas esta opción este producto se mostrará en el E-Commerce
          </h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.is_ecommerce ? "SI" : "NO"}
            </p>

            <Switch
              className="algin-self-center"
              checked={Values.is_ecommerce}
              name="is_ecommerce"
              error={Errores.is_ecommerce}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "is_ecommerce",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
      <Divider light />
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">¿Es producto terminado?</h6>
          <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}></h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.is_productoTerminado ? "SI" : "NO"}
            </p>

            <Switch
              disabled={Values.isMateriaPrima}
              className="algin-self-center"
              checked={Values.is_productoTerminado}
              name="is_ecommerce"
              error={Errores.is_productoTerminado}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "is_productoTerminado",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
    </>
  );
}
