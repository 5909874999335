import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
//import { Tooltip } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";
import { EnviarCorreo, getCorreoProveedor } from "../Funciones/Requisicion";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../../../../Includes/Loading";

const MySwal = withReactContent(Swal);

const ModalCorreo = ({
  ID,
  setIdSendCorreo = () => {},
  setOpenModal = () => {},
  openModal = false,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [IsEnviado, setIsEnviado] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    correosArray: [],
    comentarios: "",
    correos: "",
  });
  const [Errores, setErrores] = useState({
    correosArray: false,
    comentarios: false,
    correos: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
    setIdSendCorreo(0);
    setOpenModal(false);
  };

  const handleEmailChange = (event) => {
    setValues({
      ...Values,
      correos: event.target.value,
    });
  };

  const handleSendEmail = () => {
    Values.correosArray = Values.correos
      .split(",")
      .map((correo) => correo.trim());
    EnviarCorreo(ID, Values, Values.correosArray, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsEnviado(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            timer: 10000,
          }).then((result) => {
            // navigate(-1);
            handleClose();
          });
        } else {
          MySwal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "danger",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            timer: 10000,
          }).then((result) => {
            // navigate(-1);
            if (result.isConfirmed) {
            }
          });
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsEnviado(false);
      });
    //handleClose();
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    ///console.log("Name: ", Name, " Value: ", Value);
    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    if (ID != 0 && ID != "0") {
      setIsLoading(true);
      getCorreoProveedor(ID)
        .then((data) => {
          //console.log(data);
          setValues({ ...Values, correos: data });
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {/*<Tooltip title="Enviar correo" placement="top">
        <IconButton onClick={handleClickOpen}>
          <MailIcon style={{ color: "#0f0f0f" }} />
        </IconButton>
  </Tooltip>*/}

      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Enviar Correo
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Loading />
          ) : (
            <Row className="p-3">
              <Col sm={12} md={15} className="p-3">
                <TextField
                  name="correos"
                  label="Correos electrónicos"
                  variant="outlined"
                  helperText={Errores.correos ? "Correo incorrecto" : ""}
                  value={Values.correos}
                  error={Errores.correos}
                  onChange={handleEmailChange}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm={12} md={15} className="p-3">
                <TextField
                  fullWidth
                  multiline
                  name="comentarios"
                  label="Observaciones"
                  variant="outlined"
                  inputProps={{ maxLength: 500 }}
                  value={Values.comentarios}
                  error={Errores.comentarios}
                  onChange={handlInputChange}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
            variant="contained"
            onClick={handleSendEmail}
            disabled={IsLoading}
            //disabled={true}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalCorreo;
