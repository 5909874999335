import { useState, useRef, useEffect, useMemo, createRef } from 'react'
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";


import ReactCrop from 'react-image-crop'
import { base64Resize, blobToBase64, canvasPreview, centerAspectCrop } from '../Funciones/CanvasPreview'
import { useDebounceEffect } from '../Funciones/useDebounceEffect'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import 'react-image-crop/dist/ReactCrop.css'
import ButtonTabs from '../../../Manager/Components/Catalogos/AjusteMercancia/Componentes/ButtonTabs/ButtonTabs';

// const PrettoSlider = styled(Slider)({
//     color: '#fe8d27 ',
//     height: 8,
//     '& .MuiSlider-track': {
//         border: 'none',
//     },
//     '& .MuiSlider-thumb': {
//         height: 24,
//         width: 24,
//         backgroundColor: '#fff',
//         border: '2px solid currentColor',
//         '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
//             boxShadow: 'inherit',
//         },
//         '&:before': {
//             display: 'none',
//         },
//     },
//     '& .MuiSlider-valueLabel': {
//         lineHeight: 1.2,
//         fontSize: 12,
//         background: 'unset',
//         padding: 0,
//         width: 32,
//         height: 32,
//         // borderRadius: '50% 50% 50% 0',
//         backgroundColor: '#fe8d27',
//         transformOrigin: 'bottom left',
//         transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
//         '&:before': { display: 'none' },
//         '&.MuiSlider-valueLabelOpen': {
//             transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
//         },
//         '& > *': {
//             transform: 'rotate(45deg)',
//         },
//     },
// });


const ModalRecortarImagenMultiple = ({
    handleClose,
    arrayImagenes,
    aspectoAlto,
    aspectoAncho,
}) => {



    const [indexImagen, setIndexImagen] = useState(0)
    const [arrayImagenesRecortadas, setArrayImagenesRecortadas] = useState([",", "", "", "", "", "", "", "",])


    // console.log("IMAGENES A RECORTAR: ", arrayImagenes[indexImagen].IMG)
    const scale = 1, rotate = 0, aspect = aspectoAncho / aspectoAlto
    const previewCanvasRef = useMemo(() => arrayImagenes.map(() => createRef()), []);
    const imgRef = useMemo(() => arrayImagenes.map(() => createRef()), []);
    // const [imgSrc, setImg] = useState(arrayImagenes[indexImagen].IMG)
    const [widthCrop, setWidthCrop] = useState([70, 70, 70, 70, 70, 70, 70, 70,])
    const [completedCrop, setCompletedCrop] = useState([null, null, null, null, null, null, null, null,])
    const [crop, setCrop] = useState([null, null, null, null, null, null, null, null,])

    function onImageLoad(index) {
        if (aspect) {
            // if (index == -1) {
            //     setCrop(centerAspectCrop(arrayImagenes[indexImagen].SIZE.width, arrayImagenes[indexImagen].SIZE.height, aspect, widthCrop))
            // } else {
            //     setCrop(centerAspectCrop(arrayImagenes[index].SIZE.width, arrayImagenes[index].SIZE.height, aspect, widthCrop))
            // }
            // console.log("ACTUAL: ", index)
            crop[index] = centerAspectCrop(arrayImagenes[index].SIZE.width, arrayImagenes[index].SIZE.height, aspect, widthCrop[index])
            setCrop([...crop])
            canvasPreview(
                imgRef[index]?.current,
                previewCanvasRef[index]?.current,
                completedCrop[index],
                scale,
                rotate,
            )
        }
    }

    function onDownloadCropClick(index) {

        if (!previewCanvasRef[indexImagen].current) {
            throw new Error('Crop canvas does not exist')
        }
        previewCanvasRef[indexImagen].current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            blobToBase64(blob).then(async img64 => {
                // handleClose(true, img64)

                img64 = await base64Resize(img64, 900, 900);
                console.log(img64)
                arrayImagenesRecortadas[indexImagen] = img64;
                setIndexImagen(index)
                if (typeof (index) != 'number') {
                    handleClose(true, arrayImagenesRecortadas.filter(x => x != ''))
                }


            });
        })


    }


    useDebounceEffect(
        async () => {
            if (
                completedCrop[indexImagen]?.width &&
                completedCrop[indexImagen]?.height &&
                imgRef[indexImagen].current &&
                previewCanvasRef[indexImagen].current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef[indexImagen].current,
                    previewCanvasRef[indexImagen].current,
                    completedCrop[indexImagen],
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop[indexImagen], widthCrop[indexImagen]],
    )

    useEffect(() => {
        if (completedCrop[indexImagen]) {
            onImageLoad(indexImagen)
        }
    }, [widthCrop[indexImagen]])

    // useEffect(() => {
    //     setImg(arrayImagenes[indexImagen].IMG)
    // }, [indexImagen])


    // console.log(arrayImagenesRecortadas)

    return (
        <Dialog fullWidth maxWidth="sm" open={true}>
            <IconButton
                aria-label="Close"
                className="m-2"
                style={{ position: "absolute", right: "0%" }}
                onClick={() => {
                    handleClose(false)
                }}
            >
                <CloseIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>
            {
                <DialogTitle>
                    <h5 className="fw-bolder f-12 mt-2 w-100 text-center">
                        Recortar imagenes
                    </h5>
                </DialogTitle>
            }
            <DialogContent >
                <Row className="m-0">
                    <Col sm={12} className='mt-3'>
                        <h6 className="font-AvenirBold">
                            {`Recortando imagen ${indexImagen + 1} de ${arrayImagenes.length}`}
                        </h6>
                    </Col>
                    <Col sm={12}>
                        <div className="App">

                            {
                                arrayImagenes.map((row, index) => (
                                    index == indexImagen && (
                                        <>
                                            {/* <PrettoSlider
                                                onChange={({ target }) => {
                                                    widthCrop[index] = target.value
                                                    setWidthCrop([...widthCrop])
                                                }}
                                                min={1}
                                                max={100}
                                                valueLabelDisplay="auto"
                                            /> */}

                                            <ReactCrop
                                                crop={crop[index]}
                                                onChange={(_, percentCrop) => {
                                                    crop[index] = percentCrop
                                                    setCrop([...crop])
                                                }}
                                                onComplete={(c) => {
                                                    completedCrop[index] = c;
                                                    setCompletedCrop([...completedCrop])
                                                }}
                                                aspect={aspect}
                                                ruleOfThirds >
                                                <img
                                                    ref={imgRef[index]}
                                                    src={row.IMG}
                                                    onLoad={() => onImageLoad(index)}
                                                />
                                            </ReactCrop>
                                            {completedCrop[index] && (
                                                <div className='d-none'>
                                                    <canvas
                                                        ref={previewCanvasRef[index]}
                                                        style={{
                                                            border: '1px solid black',
                                                            objectFit: 'contain',
                                                            width: completedCrop[index].width,
                                                            height: completedCrop[index].height,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )


                                ))
                            }


                        </div>
                    </Col>
                    <Col sm={12} className='d-flex mt-3'>

                        <ButtonTabs
                            tab={indexImagen}
                            setTab={(tabActual) => {

                                onDownloadCropClick(tabActual);
                                // setImg(arrayImagenes[tabActual].IMG)
                                // onImageLoad(tabActual)
                            }}
                            tabsTotales={arrayImagenes.length} />

                        {
                            indexImagen == (arrayImagenes.length - 1) && (
                                <LoadingButton
                                    onClick={onDownloadCropClick}
                                    loading={false}
                                    disabled={false}
                                    loadingPosition="start"
                                    className="btn btn-Crexendo font-AvenirMedium py-2 px-4 me-2"
                                    variant="contained"
                                    xs={{ with: "100$" }}
                                >
                                    Guardar
                                </LoadingButton>

                            )
                        }

                        {/* <Button
                            onClick={() => handleClose(false)}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4 mt-3 ms-auto"
                            variant="outlined"
                        >
                            Cancelar
                        </Button> */}
                    </Col>
                </Row>
            </DialogContent>
        </Dialog>
    )

}

export default ModalRecortarImagenMultiple