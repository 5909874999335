import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";

//Peticiones POST
export const Guardar = async (Values, Errores, setErrores) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.folio_inicial || Values.folio_inicial == "") {
    Errores.folio_inicial = true;
    setErrores(Errores);
    error = true;
  }

  if (Values.is_ecommerce == false) {
    if (
      !Values.id_sucursal ||
      Values.id_sucursal == "" ||
      Values.id_sucursal == "0"
    ) {
      Errores.id_sucursal = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!error) {
    const body = {
      id: Values.id,
      nombre: Values.nombre,
      folio_inicial: parseInt(Values.folio_inicial),
      id_sucursal: Values.id_sucursal,
      is_ecommerce: Values.is_ecommerce,
    };
    let data = [];
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "series/Insertar"
        : "series/Editar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        //console.log(data);
        resolve(data);
      } else {
        //res.mensaje = "asdas";
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Desactivar = async (id, checked) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    const url = `series/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `series/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

//Peticiones GET
export const getLista = async (iTake, iSkip, Order, query) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };
  const url = `series/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getOpcionesSeries = async (id_pedido) => {
  let data = [];
  let queryParamsObj = { id_pedido: id_pedido };
  const url = `series/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoSerie = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `series/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta serie",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let Values = {
          id: String(data.ID),
          fecha: String(data.FECHA),
          nombre: String(data.NOMBRE),
          folio_inicial: data.FOLIO_INICIAL,
          is_ecommerce: Boolean(data.IS_ECOMMERCE),
          id_sucursal: String(data.ID_SUCURSAL),
        };
        resolve(Values);
        console.log(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
