import { stringify } from "query-string";
import validator from "validator";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import { CrearFechaCaducidad } from "../../Productos/Funciones/ProductosCaducidad";

export const getLista = async (
  iTake,
  iSkip,
  Order,
  OrderStatus,
  query,
  fechaInicio,
  fechaFin
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    OrderStatus: OrderStatus,
    query: query,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
  };
  const url = `ingresoMercancia/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoIngresoMerca = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `ingresoMercancia/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data?.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este ingreso de mercancia",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        let productos = JSON.parse(res.data.respuesta.productos)
        let productos_perecederos = JSON.parse(res.data.respuesta.productos_perecederos)

        try {
          productos = productos.map(item => {
            // Modificaciones para agregar la caducidad de un producto perecedero
            let Caducidades = []
            item.OpenCaducidades = false
            item.ErrorCaducidades = false
            item.MensajeCaducidades = ''

            const ProductosPerecederos = productos_perecederos.filter(x => x.ID_PRODUCTO == item.ID_PRODUCTO)
            ProductosPerecederos.map((itemPerecedero) => {
              Caducidades.push(CrearFechaCaducidad(itemPerecedero.CANTIDAD_INGRESADA, itemPerecedero.FECHA_CADUCIDAD))
            })

            item.Caducidades = Caducidades
            console.log(item)
            return { ...item };
          })
        } catch (error) {
          console.log("ERROR: ", error)
        }


        let d = {
          values: {
            id: String(data.ID),
            id_sucursal: String(data.ID_SUCURSAL),
            id_almacen: String(data.ID_ALMACEN),
            fecha_compra: String(data.FECHA_COMPRA),
            serie: String(data.SERIE),
            folio: String(data.FOLIO_FACTURA),
            forma_pago: String(data.FORMA_PAGO_SAT),
            observaciones: String(data.OBSERVACIONES),
            productos: productos,
          },
          productos: productos,
        };
        resolve(d);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardar = async (
  Values,
  Errores,
  setErrores,
  productosCarrito
) => {
  let error = false;
  if (
    !Values.id_sucursal ||
    Values.id_sucursal == "" ||
    Values.id_sucursal <= 0
  ) {
    Values.id_sucursal = 0; //Puede ir en cero la sucursal
  }

  if (!Values.id_almacen || Values.id_almacen == "" || Values.id_almacen <= 0) {
    Errores.id_almacen = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.fecha_compra || Values.fecha_compra == "") {
    Errores.fecha_compra = true;
    setErrores(Errores);
    error = true;
  }

  if (!error && productosCarrito.length == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "ingresa al menos un producto" });
    });
  }

  Values.productos = [...productosCarrito];
  Values.id = Number(Values.id);
  Values.id_almacen = Number(Values.id_almacen);


  // Validar que el desglose de las caducidades sean correctas.
  let errorCaducidades = false;
  let productos_caducidades = [];
  if (Array.isArray(Values.productos)) {
    Values.productos = Values.productos.map(item => {

      if (item.IS_PERECEDERO) {
        let cantidadCaducidad = 0;
        item.Caducidades = item.Caducidades.map(itemPerecedero => {
          productos_caducidades.push({
            ...itemPerecedero,
            id_relacion: Values.id,
            id_sucursal: Values.id_sucursal,
            id_almacen: Values.id_almacen,
            id_producto: item.ID_PRODUCTO,
          })
          cantidadCaducidad += parseFloat(itemPerecedero.cantidad_ingresada)
          return itemPerecedero
        })
        if (item.CANTIDAD != cantidadCaducidad) {
          item.OpenCaducidades = true
        }
        if (!errorCaducidades) {
          if (parseFloat(item.CANTIDAD) != cantidadCaducidad) {
            errorCaducidades = true
          }
        }
      }

      // item.Caducidades = []

      return item;
    })
  }

  // console.log("A ENVIAR: ", Values.productos)
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: " El desglose no coincide con la cantidad de los productos" });
  // });

  if (errorCaducidades) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: " El desglose no coincide con la cantidad de los productos" });
    });
  }

  // console.log("PRODUCTOS CARRITO FINAL: ", Values.productos)
  // return new Promise((resolve, reject) => {
  //   resolve({ mensaje: "Completa todos los campos requeridos" });
  // });

  //Valores para orden o requisición
  //Values.tipo = idTipo;
  //Values.id_general = idGeneral;

  //console.log("val:" + JSON.stringify(Values.productos))

  if (!error) {
    let data = [];
    const body = { ...Values, productos_caducidades };
    //const url = "ingresoMercancia/Insertar";
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "ingresoMercancia/Insertar"
        : "ingresoMercancia/Editar";

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const ProcesarMercancia = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `ingresoMercancia/IngresoProcesado?${stringify(
      queryParamsObj
    )}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const CancelarIngreso = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `ingresoMercancia/Cancelar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getBusqueda = async (query, fechaInicio, fechaFin, Folio) => {
  let data = [];
  let queryParamsObj = {
    query: query,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
    Folio: Folio,
  };
  const url = `ingresoMercancia/ListarBusqueda?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getProductos = async (
  tipo,
  ID
) => {

  let data = [];
  let queryParamsObj = {
    tipo: tipo,
    ID: ID
  };
  const url = `ingresoMercancia/ListarProductos?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      //console.log(JSON.stringify(data))
      resolve(data);
    } else {
      reject(res);
    }
  });
};