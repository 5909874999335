import { Paper } from "@mui/material";
import { convertirNumeroATexto } from "./../../../../../Lib/Funciones Generales/Generales";
import { useEffect } from "react";

const Ticket = ({ Values = [], tipo, config, ButtonImprimir , otrosDatos}) => {

  //console.log("Values: "+ JSON.stringify(Values));
 
   const IMG = Values.IMG_EMPRESA;

   const handleFileInputChange = () => { 
      const reader = new FileReader();
      if (Values.length === 0) { 
      
    }
   
  }
 
  useEffect(()=>{
    handleFileInputChange();

  },[Values]);


  if (Values.length === 0) {
    return "";
  }

  
 
  return (
    <>
      {" "}
      <div className="contectTiket pe-1 p-2  d-flex justify-content-center">
        <Paper className="shadow " style={{ width: config.ancho + "mm" }}>
          <div
            id="divTiket"
            className="ticket  p-1"
            style={{ width: config.ancho + "mm" }}
          >
            <div
              style={{
                fontSize: config.fuente + "px",
                fontWeight: "500",
                paddingLeft: "2mm",
                paddingRight: "2mm",
                paddingTop: "50px",
                paddingBottom: "40px",
              }}
            >
              <center>
                <img
                  src={IMG}
                  style={{ maxWidth: "170px", margin: "auto" }}
                />
              </center>
              <br />
              <p style={{ textAlign: "center", margin: "0px" }}>
                {Values.NOMBRE_EMPRESA.toUpperCase()}
              </p>
              <br />
              <p
                style={{
                  textAlign: "center",
                  margin: "0px",
                  fontSize: parseInt(config.fuente) + 6 + "px",
                }}
              >
                FOLIO: 000{Values.FOLIO}
              </p>
     

              {/*config.encabezado != "" ? (
                <>
                                 
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.encabezado.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>                 
                
                </>

              ) : (
                ""
              )*/}
   
               <>
              
                <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                </div>

                    <p style={{ textAlign: "center", margin: "0px" }}>
                      DETALLE DE ENTREGA
                    </p>

                <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                </div>

                  <br />
           
                  <table style={{ width: "100%" }}>


                         <tr>
                          <td align="left">
                            <b style={{ fontSize: config.fuente + "px" }}>
                              FORMAS PAGO
                            </b>
                          </td>
                          <td align="center">
                          <b style={{ fontSize: config.fuente + "px" }}>
                              VENTA
                            </b>
                          </td>

                          <td align="right">
                          <b style={{ fontSize: config.fuente + "px" }}>
                              ENTREGA
                            </b>
                          </td>
                        </tr>

                        </table>

                        <div className="border-dotted">
                        -------------------------------------------------------------------------------------------------------
                        </div>

             

                 

                        <table style={{ width: "100%" }}>
                        <tr>
                          <td align="left">
                            <b style={{ fontSize: config.fuente + "px" }}>
                              EFECTIVO
                            </b>
                          </td>
                          <td align="right">
                            ${Values.fTotalEfectivo.toFixed(2)}
                          </td>

                          <td align="right">
                            ${Values.EFECTIVO_ENTREGADO.toFixed(2)}
                          </td>
                        </tr>
                      

   
                        <tr align="right">
                          <td align="left">
                            <b style={{ fontSize: config.fuente + "px" }}>
                              TARJETA CRE./DEB.
                            </b>
                          </td>
                          <td align="right">
                            ${Values.fTotalTarjeta.toFixed(2)}
                          </td>

                          <td align="right">
                            ${Values.TARJETA_ENTREGADO.toFixed(2)}
                          </td>
                      </tr>

                      

                    
                        <tr>
                          <td align="left">
                            <b style={{ fontSize: config.fuente + "px" }}>
                              MONEDERO
                            </b>
                          </td>
                          <td align="right">
                            ${Values.fTotalMonedero.toFixed(2)}
                          </td>
                          <td align="right">
                            ${Values.MONEDERO_ENTREGADO.toFixed(2)}
                          </td>
                      </tr>
                     


                 
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            TRANSFERENCIA
                          </b>
                        </td>
                        <td align="right">
                          ${Values.fTotalTransferencia.toFixed(2)}
                        </td>
                        <td align="right">
                          ${Values.TRANSFERENCIA_ENTREGADO.toFixed(2)}
                        </td>
                    </tr>  

                    <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            DEPOSITO
                          </b>
                        </td>
                        <td align="right">
                          ${Values.fTotalDeposito.toFixed(2)}
                        </td>
                        <td align="right">
                          ${Values.DEPOSITO_ENTREGADO.toFixed(2)}
                        </td>
                    </tr>  

                    <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            PAGO EN TIENDA
                          </b>
                        </td>
                        <td align="right">
                          ${Values.fTotalPagoEnTienda.toFixed(2)}
                        </td>
                        <td align="right">
                          ${Values.PAGO_EN_TIENDA_ENTREGADO.toFixed(2)}
                        </td>
                    </tr>  
                    

                   <br />

                    {
                      Values.fTotal !== 0 &&
                      <tr>
                        <td align="left">
                          <b style={{ fontSize: config.fuente + "px" }}>
                            TOTAL
                          </b>
                        </td>

                        <td align="right">
                          
                        </td>
                          <td align="right">
                              <b style={{ fontSize: config.fuente + "px" }}>
                                 ${Values.fTotal.toFixed(2)}
                              </b>
                          </td>
                    </tr>  
                    } 

                    
                  </table>


                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {convertirNumeroATexto(Values.fTotal).toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>

                  <br />
                </>
              
                
            
              
              <table style={{ width: "100%" }}>

              <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>TOTAL COMPRAS</b>
                  </td>
                  <td align="right"> {Values.fTotalCompras} </td>
                </tr>

                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>FECHA INICIO</b>
                  </td>
                  <td align="right"> {Values.FECHA_INICIO +" "+ Values.HORA_INICIO} </td>
                </tr>

                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>FECHA FIN</b>
                  </td>
                  <td align="right"> {Values.FECHA_FIN + " " + Values.HORA_FIN} </td>
                </tr>
                
                <tr>
                  <td align="left">
                    <b style={{ fontSize: config.fuente + "px" }}>USUARIO</b>
                  </td>
                  <td align="right"> {Values.USUARIO} </td>
                </tr>

              </table>


              <br />
              {config.pie != "" ? (
                <>
                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.pie.toUpperCase()}
                  </p>

                  <div className="border-dotted">
                    -------------------------------------------------------------------------------------------------------
                  </div>
                </>
              ) : (
                ""
              )}

              {/* config.linea_1 != "" ? (
                <>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    {config.linea_1.toUpperCase()}
                  </p>
                </>
              ) : (
                ""
              )*/}
            </div>
          </div>
        </Paper>
        {ButtonImprimir}
      </div>
    </>
  );
};

export default Ticket;
