import React from "react";
import SelectAlmacenes from "../../Almacenes/Componentes/SelectAlmacenes";
import {
  TextField,
  FormControl,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Monedas } from "../../../../../Lib/Funciones Generales/Constantes";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import FormasDePago from "../../../../../Lib/SAT/Componentes/FormasDePago";
import { caracteresRestantes } from "../../../../../Lib/Funciones Generales/Generales";
import SelectRepartidor from "../../Repartidores/Componentes/SelectRepartidor";

export default function TabDetalles({
  Values = {},
  handlInputChange = () => {},
  Errores = {},
  idAlmacenPrincipalOrigen,
  setIdAlmacenPrincipalOrigen = () => {},
  idAlmacenPrincipalDestino,
  setIdAlmacenPrincipalDestino = () => {},
}) {
  return (
    <>
      <Col sm={12} md={6} className="p-3 border-end">
        <Typography fontWeight={600} fontSize={20}>
          Origen
        </Typography>

        <SelectSucursales
          className="pt-3 pb-3"
          Value={Values.id_sucursal_origen}
          Error={Errores.id_sucursal_origen}
          handlInputChange={handlInputChange}
          Name="id_sucursal_origen"
        />
        <SelectAlmacenes
          className="pt-0 pb-1"
          Value={Values.id_almacen_origen}
          Error={Errores.id_almacen_origen}
          Sucursal={Values.id_sucursal_origen}
          name="id_almacen_origen"
          handlInputChange={handlInputChange}
          handleAlmacenPrincipal={(e) => {
            setIdAlmacenPrincipalOrigen(e);
          }}
          principal={idAlmacenPrincipalOrigen}
        />
      </Col>

      <Col sm={12} md={6} className="p-3 border-start">
        <Typography fontWeight={600} fontSize={20}>
          Destino
        </Typography>

        <SelectSucursales
          className="pt-3 pb-3"
          Value={Values.id_sucursal_destino}
          Error={Errores.id_sucursal_destino}
          Name="id_sucursal_destino"
          handlInputChange={handlInputChange}
        />
        <SelectAlmacenes
          className="pt-0 pb-1"
          Value={Values.id_almacen_destino}
          Error={Errores.id_almacen_destino}
          Sucursal={Values.id_sucursal_destino}
          handlInputChange={handlInputChange}
          name="id_almacen_destino"
          handleAlmacenPrincipal={(e) => {
            setIdAlmacenPrincipalDestino(e);
          }}
          principal={idAlmacenPrincipalDestino}
        />
      </Col>
      <Col sm={12} md={6} className="p-3">
        <SelectRepartidor
          handlInputChange={handlInputChange}
          Value={Values.id_repartidor}
          Error={Errores.id_repartidor}
          name="id_repartidor"
        />
      </Col>

      <Col sm={12} md={12} className="p-3">
        <TextField
          fullWidth
          multiline
          rows={5}
          value={Values.observaciones}
          label={`Observaciones ${caracteresRestantes(
            Values.observaciones?.length,
            500
          )}`}
          inputProps={{ maxLength: 500 }}
          name="observaciones"
          onChange={handlInputChange}
        />
      </Col>
    </>
  );
}
