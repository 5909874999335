import { centerCrop, makeAspectCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

// export const  base64Resize = (sourceBase64, maxW , maxH, callBack) => {

//     // const _scale = scale;
//     const _scale = 1;
//     var img = document.createElement('img');
//     img.setAttribute("src", sourceBase64);

//     img.onload = () => {
//         var canvas = document.createElement('canvas');
//         canvas.width = img.width * _scale;
//         canvas.height = img.height * _scale;

//         var ctx = canvas.getContext("2d");
//         var cw = canvas.width;
//         var ch = canvas.height;
//         // var maxW = img.width * _scale;
//         // var maxH = img.height * _scale;

//         var iw = img.width;
//         var ih = img.height;
//         var scl = Math.min((maxW / iw), (maxH / ih));
//         var iwScaled = iw * scl;
//         var ihScaled = ih * scl;
//         canvas.width = iwScaled;
//         canvas.height = ihScaled;
//         ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
//         const newBase64 = canvas.toDataURL("image/jpeg", scl);

//         callBack(newBase64);
//     }
// }

export const base64Resize = (base64, width, height) => {
    // Create a canvas element
    const canvas = document.createElement('canvas');

    // Create an image element from the base64 string
    const image = new Image();
    image.src = base64;

    // Return a Promise that resolves when the image has loaded
    return new Promise((resolve, reject) => {
        image.onload = () => {

            if (width < image.width || height < image.height) {
                // Calculate the aspect ratio of the image
                const aspectRatio = image.width / image.height;

                // Calculate the best fit dimensions for the canvas
                if (width / height > aspectRatio) {
                    canvas.width = height * aspectRatio;
                    canvas.height = height;
                } else {
                    canvas.width = width;
                    canvas.height = width / aspectRatio;
                }

                // Draw the image to the canvas
                canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);

                // Resolve the Promise with the resized image as a base64 string
                resolve(canvas.toDataURL());
            } else {
                resolve(base64)
            }




        };

        image.onerror = reject;
    });
};

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const getImgDimensions = dataURL => new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
        resolve({
            height: img.height,
            width: img.width
        })
    }
    img.src = dataURL
})

export async function canvasPreview(
    image,
    canvas,
    crop,
    scale = 1,
    rotate = 0,
) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}


export const centerAspectCrop = (
    mediaWidth,
    mediaHeight,
    aspect,
    widthCrop = 80
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: widthCrop,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}