import React, { useState, useEffect } from "react";
import { Alert, Button, TextField, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { TableHead } from "@mui/material";
import Loading from "../../../../../Includes/Loading";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import EliminarRojo from "../../../../assets/EliminarRojo.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getSizeCaducidades } from "../../Productos/Funciones/Productos";
import { CrearFechaCaducidad, EliminarFechaCaducidad } from "../../Productos/Funciones/ProductosCaducidad";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import AccEliminar from "../../../../assets/AccEliminar.svg";

export default function TableDetalleOrden({
  Lista,
  IsLoading,
  productosCarrito,
  setProductosCarrito,
  IsShowProductoPerecedero = false
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState();

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - productosCarrito.length)
      : 0;

  let total = 0;
  {
    if (productosCarrito.length > 0) {
      for (let row of productosCarrito) {
        if (!isNaN(row.PRECIO) && !isNaN(parseInt(row.CANTIDAD))) {
          total += parseFloat(row.PRECIO) * parseInt(row.CANTIDAD);
        }
      }
    } else {
      total = 0;
    }
  }

  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD = Number(items[itemIndex].CANTIDAD) + 1;
    } else {
      //items.push({...newItem, CANTIDAD: 1});
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD = (
        Number(items[itemIndex].CANTIDAD) - 1
      ).toFixed(2);
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
      // items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setProductosCarrito(items);
  };

  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    // validar que sea entero  Value
    const items = [...productosCarrito];
    //console.log(ID);
    //console.log(items);

    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO == ID)
      : -1;
    if (itemIndex !== -1) {
      //let cantidad = parseInt(Value);
      //items[itemIndex].CANTIDAD = cantidad.toString();
      let cantidad = Value.toDecimal();
      items[itemIndex].CANTIDAD = !/^\d*\.?\d{0,2}$/.test(cantidad)
        ? parseFloat(cantidad).toFixed(2)
        : cantidad;
    } else {
      alert("producto no encontrado");
    }
    setProductosCarrito(items);
  };


  const handleInputChangePerecedero = (target, IndexProducto, IndexCaducidad) => {
    let Value = target.value
    let Name = target.name
    if (Name == 'cantidad_ingresada') {
      Value = Value.toDecimal()
    }
    productosCarrito[IndexProducto].ErrorCaducidades = false
    productosCarrito[IndexProducto].MensajeCaducidades = ''
    productosCarrito[IndexProducto].Caducidades[IndexCaducidad][Name] = Value
    productosCarrito[IndexProducto].Caducidades[IndexCaducidad].errores[Name] = false
    setProductosCarrito([...productosCarrito])
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {
                IsShowProductoPerecedero && (
                  <TableCell className="font-Avenir font-AvenirBold" align="left"></TableCell>
                )
              }
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESCRIPCION
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                PRODUCTO TERMINADO
              </TableCell>
              {
                IsShowProductoPerecedero && (
                  <TableCell className="font-Avenir font-AvenirBold" align="left">
                    PRODUCTO PERECEDERO
                  </TableCell>
                )
              }
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                CANTIDAD
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={5}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? productosCarrito.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : productosCarrito
              ).map((row, IndexProducto) => {

                const sizeCaducidades = Array.isArray(row.Caducidades)
                  ? getSizeCaducidades(row.Caducidades)
                  : 0;

                return (
                  <>
                    <TableRow key={row.ID_PRODUCTO}>

                      {
                        IsShowProductoPerecedero && (
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              productosCarrito[IndexProducto].OpenCaducidades = !productosCarrito[IndexProducto].OpenCaducidades
                              setProductosCarrito([...productosCarrito])
                            }}
                          >

                            {
                              row.IS_PERECEDERO && (
                                (row.OpenCaducidades ||
                                  (
                                    productosCarrito[IndexProducto].CANTIDAD != sizeCaducidades &&
                                    productosCarrito[IndexProducto].IS_PERECEDERO
                                  )
                                ) ? <KeyboardArrowUpIcon />
                                  : <KeyboardArrowDownIcon />
                              )
                            }
                          </TableCell>
                        )
                      }

                      <TableCell component="th" scope="row" >
                        {firstLeterMayus(row.DESCRIPCION_PRODUCTO)}
                      </TableCell>
                      <TableCell component="th" scope="row" >
                        {row.PRODUCTO_TERMINADO}
                      </TableCell>
                      {
                        IsShowProductoPerecedero && (
                          <TableCell component="th" scope="row" >
                            {row.IS_PERECEDERO ? 'Si' : 'No'}
                          </TableCell>
                        )
                      }
                      <TableCell component="th" scope="row" >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <IconButton className="mx-2" onClick={() => addItem(row)}>
                            <img src={Mas} style={{ width: "15px" }} />
                          </IconButton>
                          <div
                            className="d-flex justify-content-center flex-column"
                            style={{ maxWidth: "150px" }}
                          >
                            <TextField
                              size="small"
                              fullWidth
                              id={row.ID_PRODUCTO}
                              label="Cantidad"
                              variant="outlined"
                              name={row.ID_PRODUCTO}
                              value={isNaN(row.CANTIDAD) ? "" : row.CANTIDAD}
                              //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                              onChange={handlInputChange}
                            />
                          </div>
                          <IconButton
                            aria-label="delete"
                            className="mx-2"
                            onClick={() => disminuirItem(row)}
                          >
                            <img src={Menos} style={{ width: "15px" }} />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            className="   mx-1"
                            onClick={() => deleteItem(row)}
                          >
                            <img src={EliminarRojo} style={{ width: "15px" }} />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>


                    {
                      (
                        row.OpenCaducidades
                        // ||
                        // (
                        //   row.CANTIDAD != sizeCaducidades &&
                        //   row.IS_PERECEDERO
                        // )
                      ) && (
                        <>

                          {/* Area dinamica de las caducidades */}
                          <TableRow >
                            <TableCell ></TableCell>
                            <TableCell
                              colSpan={5}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <h6 className="font-AvenirBold m-0">Fechas de caducidad del producto</h6>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className="btn btn-cancelar font-AvenirMedium py-2 px-4 "
                                  onClick={() => {
                                    productosCarrito[IndexProducto].Caducidades.push(CrearFechaCaducidad(1))
                                    setProductosCarrito([...productosCarrito])
                                  }}
                                >
                                  {/* <AddIcon />  */}
                                  Agregar
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>

                          {
                            Array.isArray(row.Caducidades) && (
                              row.Caducidades.map((Caducidad, IndexCaducidad) => (
                                // <ItemProductoCaducidad

                                //   IndexCaducidad={IndexCaducidad}
                                //   IndexProducto={IndexProducto}
                                //   Productos={productosCarrito}
                                //   setProductos={setProductosCarrito}
                                //   isProductosDescuento={isProductosDescuento}
                                //   onlyInventariables={onlyInventariables}
                                // />


                                <TableRow>
                                  <TableCell colSpan={4}></TableCell>

                                  <TableCell>
                                    <div className="w-100 d-flex align-items-center justify-content-end my-2">
                                      <TextField
                                        className="me-3"
                                        style={{ maxWidth: 100 }}
                                        fullWidth
                                        size="small"
                                        name="cantidad_ingresada"
                                        label="Cantidad"
                                        variant="outlined"
                                        value={row.Caducidades[IndexCaducidad].cantidad_ingresada}
                                        error={row.Caducidades[IndexCaducidad].errores.cantidad_ingresada}
                                        helperText={row.Caducidades[IndexCaducidad].errores.cantidad_ingresada ? "Cantidad no valido" : ""}
                                        onChange={({ target }) => handleInputChangePerecedero(target, IndexProducto, IndexCaducidad)}
                                      />

                                      <BasicDatePicker
                                        className="me-4"
                                        fullWidth
                                        style={{ maxWidth: 170 }}
                                        format="DD/MM/YYYY"
                                        label="Fecha de caducidad"
                                        size="small"
                                        fecha={row.Caducidades[IndexCaducidad].fecha_caducidad}
                                        setFecha={(value) => {
                                          value = ConvertDate(value);
                                          handleInputChangePerecedero({ name: 'fecha_caducidad', value }, IndexProducto, IndexCaducidad)
                                        }}
                                      />
                                      <Tooltip title="Eliminar" placement="top" className="ms-4">
                                        <IconButton onClick={() => EliminarFechaCaducidad(IndexProducto, IndexCaducidad, productosCarrito, setProductosCarrito)}>
                                          <img src={AccEliminar} />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            )
                          }


                          <TableRow >
                            <TableCell
                              style={{ border: 0 }}
                              className="py-2"
                              colSpan={5}
                            >
                              {
                                (
                                  productosCarrito[IndexProducto].CANTIDAD != sizeCaducidades &&
                                  productosCarrito[IndexProducto].IS_PERECEDERO
                                ) && (
                                  <Alert severity="error" sx={{ width: "100%" }}>
                                    El desglose no coincide con la cantidad del producto
                                  </Alert>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    }
                  </>

                )
              }
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              <TableCell></TableCell>
              <TableCell></TableCell>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="right"
                >
                  <div style={{ display: "flex" }}>
                    <p>Total: {formatMoneda(total)}</p>
                  </div>
                </TableCell> */}
              </div>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
