import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  MenuItem,
  Box,
  Tabs,
  Alert,
  styled,
  Tab,
  Snackbar,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import loader from "./../../../../../assets/loader.gif";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import ModalSelectProd from "../../Productos/Componentes/Dialogos/ModalSelectProd";
import TableDetalleOrden from "../../OrdenCompra/Componentes/TablaDetalleOrden";
import TabDetalles from "./TabDetalles";
//import { GetDate } from "../../OrdenCompra/Componentes/RepOrdenCompra";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import {
  getInfoIngresoMerca,
  guardar,
  getProductos,
} from "../Funciones/IngresoMercancia";
import ModalBuscar from "./ModalBuscar";
import { CrearFechaCaducidad } from "../../Productos/Funciones/ProductosCaducidad";

const MySwal = withReactContent(Swal);

export default function IngresoMercancia() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tab, setTab] = React.useState(0);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [Fecha, setFecha] = useState(GetDate);
  const [idAlmacenPrincipal, setIdAlmacenPrinpal] = useState(0);
  const [showModalBuscar, SetshowModalBuscar] = useState(false);
  const [productosConsulta, setProductosConsulta] = useState([]);
  const [idTipo, setIdTipo] = useState(0);
  const [idGeneral, setIdGeneral] = useState(0);

  const [Values, setValues] = useState({
    id: id ?? "0",
    id_sucursal: 0,
    id_almacen: "",
    fecha_compra: "",
    serie: "",
    folio: "",
    forma_pago: "",
    observaciones: "",
    productos: [],
  });
  const [Errores, setErrores] = useState({
    id_sucursal: false,
    id_almacen: false,
    fecha_compra: false,
    serie: false,
    folio: false,
    forma_pago: false,
    observaciones: false,
  });
  //#region Handles
  const handlInputChange = ({ target }) => {
    const Name = target.name;
    let Value = target.value;

    if (Name == "id_almacen") {
      setProductosCarrito([]);
    }

    setValues({
      ...Values,
      [Name]: Value,
    });

    setErrores({ ...Errores, [Name]: false });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  function handleProductoSeleccionado(producto, cantidad = 1) {
    console.log("SELECCIONADNO: ", producto)

    if (producto.TOTAL_PRODUCTOS) {
      console.log(0)
      const items = [...productosCarrito];
      //setIdTipo(producto.ID_TIPO);
      //setIdGeneral(producto.ID_GENERAL);
      //console.log("All: " + JSON.stringify(producto.ID_GENERAL))

      getProductos(producto.ID_TIPO, producto.ID_GENERAL).then((resp) => {



        if (resp?.err?.length === 0) {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
          });
        } else {
          //console.log("primer"+ JSON.stringify(resp.data));...
          let DATA = [];
          let PRODUCTOS = [];
          setProductosConsulta(resp.data);

          DATA = Array.from(JSON.parse(resp.data));
          DATA.map((ROW) => {
            PRODUCTOS = {
              ID_PRODUCTO: ROW.ID,
              DESCRIPCION_PRODUCTO: ROW.DESC_CORTA,
              PRECIO: ROW.PRECIO_COMPRA,
              CANTIDAD: ROW.CANTIDAD,
              PRODUCTO_TERMINADO: ROW.PRODUCTO_TERMINADO,
              IS_PERECEDERO: ROW.IS_PERECEDERO,
              TIPO: producto.ID_TIPO,
              ID_GENERAL: producto.ID_GENERAL,
              Caducidades: [CrearFechaCaducidad(ROW.CANTIDAD)]
            };

            items.push(PRODUCTOS);
          });

          MySwal.fire({
            html: "Añadiendo productos",
            imageUrl: loader,
            imageWidth: 50,
            showConfirmButton: false,
            timer: 3500,
          }).then(() => {
            SetshowModalBuscar(false);
          });
        }
      });

      setProductosCarrito(items);
    } else {
      //console.log(producto)
      //console.log(parseInt(producto.ID))

      console.log(1)
      const items = [...productosCarrito];
      const itemIndex = Array.isArray(items)
        ? items.findIndex((item) => item.ID_PRODUCTO === producto.ID)
        : -1;

      if (itemIndex !== -1) {
        console.log(2)
        const productoExistente = items[itemIndex];
        productoExistente.CANTIDAD =
          Number(productoExistente.CANTIDAD) + Number(cantidad);
      } else {
        console.log(3)
        producto = {
          ID_PRODUCTO: parseInt(producto.ID),
          DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
          PRECIO: parseFloat(producto.PRECIO_COMPRA),
          CANTIDAD: cantidad,
          PRODUCTO_TERMINADO: producto.PRODUCTO_TERMINADO,
          IS_PERECEDERO: producto.IS_PERECEDERO,
          Caducidades: producto.Caducidades
        };
        console.log("NUEVO PRODUCTO: ", producto)
        //console.log("aquí"+ JSON.stringify(producto))
        items.push(producto);
      }
      setProductosCarrito([...items]);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  //#endregion

  //#region Funciones
  const GuardarIngresoMercancia = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, productosCarrito)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  //#endregion

  //#region Handles
  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoIngresoMerca(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            //console.log(resp.values);

            console.log("SETEAR: ", resp.productos)
            setValues(resp.values);
            setProductosCarrito(resp.productos);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    setValues({ ...Values, fecha_compra: ConvertDate(Fecha) });
    Errores.fecha_compra = false;
    setErrores(Errores);
  }, [Fecha]);
  //#endregion

  console.log("CARRITO: ", productosCarrito)

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Ingreso de mercancía
      </Button>
      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos ingreso de mercancia" className="font-AvenirBold" />
          <Tab label="Agregar Productos" className="font-AvenirBold" />
        </Tabs>
      </Box>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar ingreso" : "Nuevo ingreso"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <TabDetalles
                    Values={Values}
                    Errores={Errores}
                    handlInputChange={handlInputChange}
                    Fecha={Fecha}
                    setFecha={setFecha}
                    idAlmacenPrincipal={idAlmacenPrincipal}
                    setIdAlmacenPrinpal={setIdAlmacenPrinpal}
                  />
                ) : (
                  <>
                    <div>
                      <div class="d-flex flex-row justify-content-end align-items-start mb-3 flex-wrap">
                        <div class="d-flex px-2">
                          <Button
                            className="btn btn-Crexendo font-AvenirMedium py-2 px-5 "
                            variant="contained"
                            onClick={() => {
                              SetshowModalBuscar(true);
                            }}
                          >
                            Buscar Ord./Req.
                          </Button>
                        </div>

                        <div class=" d-flex ">
                          <Button
                            className="btn btn-Crexendo font-AvenirMedium py-2 px-4"
                            onClick={() => {
                              setShowModal(!showModal);
                            }}
                          >
                            Agregar productos
                          </Button>
                        </div>
                      </div>

                      {showModal && (
                        <ModalSelectProd
                          IsShowProductoPerecedero={true}
                          onlyInventariables
                          onClose={() => {
                            setShowModal(!showModal);
                          }}
                          handleProductoSeleccionado={handleProductoSeleccionado}
                          titulo="Seleccionar productos inventariables"
                          //id_almacen={Values.id_almacen}
                          //id_sucursal={Values.id_sucursal}
                          //onlyProductosTerminados={
                          //idAlmacenPrincipal == 0 ? true : false
                          //}
                          MostrarBoton={true}
                        />
                      )}
                    </div>
                    <Col sm={12} style={{ paddingTop: "15px" }}>
                      <TableDetalleOrden
                        IsShowProductoPerecedero={true}
                        handlInputChange={handlInputChange}
                        productosCarrito={productosCarrito ?? []}
                        setProductosCarrito={setProductosCarrito}
                        IsLoading={IsLoading}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarIngresoMercancia()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                const nextTab = tab - 1 < 0 ? 0 : tab - 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
              variant="text"
            >
              <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
              Anterior
            </Button>
            {
              tab < 1 && (
                <Button
                  onClick={() => {
                    const nextTab = tab + 1 > 2 ? 2 : tab + 1;
                    setTab(nextTab);
                  }}
                  className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-n2"
                  variant="text"
                >
                  Siguiente
                  <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
                </Button>
              )
            }

          </Stack>
        </CardContent>
      </Card>

      {showModalBuscar && (
        <ModalBuscar
          SetshowModalBuscar={SetshowModalBuscar}
          handleProductoSeleccionado={handleProductoSeleccionado}
        />
      )}
    </>
  );
}
