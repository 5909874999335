import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getEstados } from "../Funciones/Direcciones";

const Estado = ({ Value, Error, handlInputChange, Name = "id_estado" }) => {
  const [ValorFiltrado, setFiltro] = useState();
  const [Estado, setEstado] = useState({
    cargado: false,
    lista: [{ ID: 0, DESCRIPCION: "Estado" }],
  });

  useEffect(() => {
    getEstados()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          setEstado({ cargado: true, lista: list });
        } else {
          setEstado({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCION: "Sin estados disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setEstado({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCION: "Sin estados disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = Estado.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Estado.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        window.ESTADO = option.DESCRIPCION;
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          //console.log(value);
          handlInputChange({
            target: { name: Name, value: value.ID, desc: value.DESCRIPCION },
          });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Estado.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un estado" : ""}
            label="Estado"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Estado"} />
  );
};

export default Estado;
