import React from "react";
import NetxTab from "./NetxTab";
import BackTab from "./BackTab";
import { Button } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

export default function ButtonsTabs({
  tab = 0,
  setShowModalMetodosPago,
  setTab = () => {},
}) {
  return (
    <>
      {tab == "0" ? (
        <>
          {" "}
          <div className="d-flex justify-content-end w-100">
            <NetxTab className="ms-auto" tab={tab} setTab={setTab} />
          </div>
        </>
      ) : tab == "1" ? (
        <>
          <div className="d-flex justify-content-between w-100">
            <BackTab tab={tab} setTab={setTab} />
            <NetxTab tab={tab} setTab={setTab} />
          </div>
        </>
      ) : tab == "2" ? (
        <>
          <div className="d-flex justify-content-between w-100">
            <BackTab tab={tab} setTab={setTab} />
            <NetxTab tab={tab} setTab={setTab} />
          </div>
        </>
      ) : tab == "3" ? (
        <>
          <div className="d-flex justify-content-end w-100">
            <BackTab tab={tab} setTab={setTab} />
            <Button
              className="btn btn-Crexendo font-AvenirMedium   py-2 px-5 ms-4 "
              variant="contained"
              onClick={() => {
                setShowModalMetodosPago(true);
              }}
              //xs={{ with: "100" }}
            >
              <PaidIcon className="me-2" style={{ fontSize: "20px" }} />
              Pagar pedido
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
