import * as React from "react";
import { MenuItem, TextField } from "@mui/material";

const SelectOrdenamiento= ({
  ValueSelect,
  setValueSelect,
  size = "",
  currencies = [
    {
      value: "1",
      label: "Pendiente",
    },
    {
      value: "2",
      label: "Cancelado",
    },
    {
      value: "3",
      label: "Autorizado",
    },
    {
      value: "123",
      label: "Todas",
    },
  ],
}) => {
  const handlInputChange = ({ target }) => {
    setValueSelect(target.value);
  };
  /*const currencies = [
   
  ];*/

  return (
    <TextField
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handlInputChange}
      label="Estatus"
      value={ValueSelect}
      className="mt-0 mt-sm-0 mt-md-0"
      size={size}
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectOrdenamiento;
