import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  FormGroup,
  Divider,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getInfoAlmacen, Guardar } from "../Funciones/Almacenes";
import Loading from "../../../../../Includes/Loading";
import { getOpcionesSucursal } from "../../Sucursales/Funciones/Sucursales";
import Checkbox from "@mui/material/Checkbox";
import Switch from "../../../../../Includes/Switch";

const MySwal = withReactContent(Swal);

const Almacen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Sucursales, setSucursales] = useState([]);
  const [is_ecommerce, setIs_ecommerce] = useState(false);
  const [is_principal, setIs_principal] = useState(false);

  const [Values, SetValues] = useState({
    id: id ?? "0",
    nombre: "",
    descripcion: "",
    id_sucursal: "0",
    is_principal: false,
    is_ecommerce: false,
    activo: true,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion: false,
    id_sucursal: false,
    is_principal: false,
    is_ecommerce: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    //console.log("Name: ", Name, " Value: ", Value);
    SetValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoAlmacen(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            SetValues(resp);
            setIs_ecommerce(resp.is_ecommerce);
            setIs_principal(resp.is_principal);
            setIsLoading(false);
          }
          SetValues(resp);
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarAlmacen = () => {
    setIsGuardando(true);
    Guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  /*useEffect(() => {
    //document.title = `Almacén ${id ? id : " "}`;
    //document.title = `Almacénes`;
  }, []);*/

  useEffect(() => {
    getOpcionesSucursal()
      .then((resp) => {
        const opciones = JSON.parse(resp.data);
        setSucursales(opciones);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Almacenes
      </Button>

      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar almacén" : "Nuevo almacén"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} md={6} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={6} className="p-3">
                <TextField
                  multiline
                  fullWidth
                  name="descripcion"
                  label="Descripción"
                  maxRows={3}
                  inputProps={{ maxLength: 249 }}
                  value={Values.descripcion}
                  error={Errores.descripcion}
                  placeholder="Descripción"
                  helperText={
                    Errores.descripcion ? "Descripción no valida" : ""
                  }
                  onChange={handlInputChange}
                  style={{ width: "100%", height: "80px" }}
                />
              </Col>
              <Col sm={12} md={6} className="p-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sucursales
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="id_sucursal"
                    value={Values.id_sucursal}
                    Error={Errores.id_sucursal}
                    onChange={handlInputChange}
                    input={<OutlinedInput label="Sucursales" />}
                  >
                    {[
                      { ID: 0, NOMBRE: "Almacen sin sucursal" },
                      ...Sucursales,
                    ].map((Sucursal) => (
                      <MenuItem key={Sucursal.ID} value={Sucursal.ID}>
                        {Sucursal.NOMBRE}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Divider light />
              <Col sm={12} className="p-3 d-flex justify-content-between">
                <div className="w-100">
                  <h6 className="font-AvenirMedium m-0 p-0">¿Es principal?</h6>
                  <h6
                    lassName="font-Avenir mt-0 p-0"
                    style={{ fontSize: "13px" }}
                  >
                    Sí activas esta opción este inventario sera el principal
                  </h6>
                </div>
                <FormGroup>
                  <div className="d-flex justify-content-center">
                    <p
                      className="my-0 algin-self-center font-Avenir mt-2 text-center"
                      style={{ fontSize: "13px" }}
                    >
                      {Values.is_principal ? "SI" : "NO"}
                    </p>

                    <Switch
                      className="algin-self-center"
                      checked={Values.is_principal}
                      name="is_principal"
                      error={Errores.is_principal}
                      onChange={(checked) => {
                        handlInputChange({
                          target: {
                            name: "is_principal",
                            value: checked,
                          },
                        });
                      }}
                      disabled={Values.activo ? is_principal : true}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Divider light />
              <Col sm={12} className="p-3 d-flex justify-content-between">
                <div className="w-100">
                  <h6 className="font-AvenirMedium m-0 p-0">¿Es e-commerce?</h6>
                  <h6
                    lassName="font-Avenir mt-0 p-0"
                    style={{ fontSize: "13px" }}
                  >
                    Sí activas esta opción inventario sera el usado para el
                    e-commerce
                  </h6>
                </div>
                <FormGroup>
                  <div className="d-flex justify-content-center">
                    <p
                      className="my-0 algin-self-center font-Avenir mt-2 text-center"
                      style={{ fontSize: "13px" }}
                    >
                      {Values.is_ecommerce ? "SI" : "NO"}
                    </p>

                    <Switch
                      className="algin-self-center"
                      checked={Values.is_ecommerce}
                      name="is_ecommerce"
                      error={Errores.is_ecommerce}
                      onChange={(checked) => {
                        handlInputChange({
                          target: {
                            name: "is_ecommerce",
                            value: checked,
                          },
                        });
                      }}
                      disabled={Values.activo ? is_ecommerce : true}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarAlmacen()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Almacen;
