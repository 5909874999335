import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {
  Button,
  Divider,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../Lib/Funciones Generales/Generales";
import { Col, Row } from "react-bootstrap";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getColoresEstatus,
  getDistanciaVentaASucursal,
} from "../Funciones/Pedidos";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoVenta } from "../Funciones/Pedidos";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Loading from "../../../../Includes/Loading";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import UploadIcon from "@mui/icons-material/Upload";

const DetalleCompra = ({
  pedidoSeleccionado,
  setShowDialogComprobante,
  setShowDiloglImprimir,
  setShowDialogFormaPago,
  setShowDialogCargarComprobante,
  setComprobanteSeleccionado,
}) => {
  const [compra, setCompra] = useState({
    PRODUCTOS: [],
    DETALLE: {},
  });

  const [IsLoading, setIsLoading] = useState(true);

  const ConsultarDetalle = () => {
    setIsLoading(true);

    getInfoVenta(pedidoSeleccionado)
      .then((resp) => {
        console.log(resp);
        setIsLoading(false);
        setCompra({
          ...compra,
          DETALLE: resp.Values[0],
          PRODUCTOS: resp.detalle,
          CONSULTADO: true,
        });

        // Prueba de distancia a la sucursal
        getDistanciaVentaASucursal(pedidoSeleccionado).then((metros) => {
          console.log(
            "TU PEDIDO ESTA A " +
              metros +
              " METROS DE LA SUCURSAL" +
              resp.Values[0].SUCURSAL
          );
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const { colorSstatus, backgrorundStatus, backgrorundStatusStyle, colores } =
    getColoresEstatus(
      compra.DETALLE.ID_ESTATUS ? compra.DETALLE.ID_ESTATUS : -1
    );

  useEffect(() => {
    ConsultarDetalle();
  }, []);
  window.fnConsultarDetalleCompra = ConsultarDetalle;

  return (
    <Box
      sx={{
        backgroundColor: "#f9f9f9",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <h4 className="fw-bolder">
        Detalle de pedido No.
        {compra.DETALLE.FOLIO ? compra.DETALLE.FOLIO : "..."}
      </h4>
      {IsLoading ? (
        <div className="col-12  ">
          <Loading />
        </div>
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between">
            <Row className="row mx-0 w-100">
              <Col sm={12} md={7}>
                <Table size="small" aria-label="purchases" className="mt-3">
                  <TableHead>
                    <TableRow>
                      <TableCell>Producto</TableCell>
                      <TableCell>Precio</TableCell>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Renta</TableCell>
                      <TableCell align="right">Total </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compra.PRODUCTOS && compra.PRODUCTOS.length > 0 ? (
                      compra.PRODUCTOS.map((rowDetalle) => (
                        <TableRow key={rowDetalle.ID}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="detalle-cell"
                          >
                            {firstLeterMayus(rowDetalle.PRODUCTO)}
                            {rowDetalle.COLOR != null ||
                            rowDetalle.TAMANIO != null ? (
                              <>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: "3px",
                                  }}
                                >
                                  {rowDetalle.COLOR != null
                                    ? " " + " -" + rowDetalle.COLOR
                                    : ""}
                                  ,{" "}
                                  {rowDetalle.TAMANIO != null
                                    ? rowDetalle.TAMANIO
                                    : ""}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell className="detalle-cell">
                            {formatMoneda(rowDetalle.PRECIO_VENTA)}
                          </TableCell>
                          <TableCell className="detalle-cell">
                            {rowDetalle.CANTIDAD}
                          </TableCell>
                          <TableCell className="detalle-cell">
                            {rowDetalle.DIAS_RENTA == 0
                              ? ""
                              : rowDetalle.DIAS_RENTA == 1
                              ? " 1 día"
                              : rowDetalle.DIAS_RENTA + " días"}
                          </TableCell>
                          <TableCell align="right">
                            {formatMoneda(
                              rowDetalle.PRECIO_VENTA * rowDetalle.CANTIDAD
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key={Math.random(0)}>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={5}
                          align=""
                        >
                          Sin productos disponibles
                        </TableCell>
                      </TableRow>
                    )}
                    <>
                      <TableRow key={pedidoSeleccionado + "_detalle_subtotal"}>
                        <TableCell
                          align="right"
                          component={"th"}
                          colSpan={4}
                          className="detalle-cell"
                        >
                          <strong>SUBTOTAL</strong>
                        </TableCell>
                        <TableCell align="right" className="detalle-cell">
                          {" "}
                          {formatMoneda(compra.DETALLE.SUBTOTAL)}{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow key={pedidoSeleccionado + "_detalle_envio"}>
                        <TableCell
                          align="right"
                          component={"th"}
                          colSpan={4}
                          className="detalle-cell"
                        >
                          <strong>ENVÍO</strong>
                        </TableCell>
                        <TableCell align="right" className="detalle-cell">
                          {" "}
                          {formatMoneda(compra.DETALLE.COSTO_ENVIO)}{" "}
                        </TableCell>
                      </TableRow>

                      {compra.DETALLE.DESCUENTO != 0 && (
                        <TableRow
                          key={pedidoSeleccionado + "_detalle_descuento"}
                        >
                          <TableCell
                            align="right"
                            component={"th"}
                            colSpan={4}
                            className="detalle-cell"
                          >
                            <strong>DESCUENTO</strong>
                          </TableCell>
                          <TableCell align="right" className="detalle-cell">
                            {" "}
                            {formatMoneda(compra.DETALLE.DESCUENTO)}{" "}
                          </TableCell>
                        </TableRow>
                      )}
   
                      <TableRow
                        key={pedidoSeleccionado + "_detalle_total"}
                        className="detalle-cell"
                      >
                        <TableCell align="right" component={"th"} colSpan={4}>
                          <strong>TOTAL</strong>
                        </TableCell>
                        <TableCell
                          align="right"
                          className="detalle-cell fw-bold"
                        >
                          <h4>{formatMoneda(compra.DETALLE.TOTAL)} </h4>
                        </TableCell>
                      </TableRow>
                      
                      <TableRow
                        key={pedidoSeleccionado + "_detalle_formapago"}
                        className="detalle-cell"
                      >
                        <TableCell align="right" component={"th"} colSpan={4}>
                          <strong>Forma de pago</strong>
                        </TableCell>
                        <TableCell align="right" className="detalle-cell">
                          {" "}
                          {compra.DETALLE.FORMA_DE_PAGO}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={pedidoSeleccionado + "_IVa"}
                        className="detalle-cell"
                      >
                        <TableCell align="right" component={"th"} colSpan={4}>
                          <strong>INCLUYE IVA</strong>
                        </TableCell>
                        <TableCell
                          align="right"
                          className="detalle-cell"
                        >
                          {compra.DETALLE.INCLUYE_IVA?"SI":"NO"} 
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
                <Divider />
                <div className="row mt-2">
                  <div className="col-12 col-md-6">
                    <strong> Cliente</strong>
                    <br />
                    {compra.DETALLE.CLIENTE}
                  </div>
                  <div className="col-12 col-md-6">
                    <strong> Fecha de entrega </strong>
                    <br />
                    {compra.DETALLE.FECHA_DE_ENTREGA}
                    <br />
                    {compra.DETALLE.ENTREGA_INICIO +
                      " - " +
                      compra.DETALLE.ENTREGA_FIN}
                  </div>

                  <div className="col-12 col-md-6">
                    <strong>
                      {" "}
                      Direccion de entrega ({compra.DETALLE.D_TIPODOMICILIO})
                    </strong>
                    <br />
                    {compra.DETALLE.D_CALLE +
                      " " +
                      (compra.DETALLE.D_NUMERO_EXT != 0
                        ? ", NO.EXT. " + compra.DETALLE.D_NUMERO_EXT
                        : "") +
                      " " +
                      (compra.DETALLE.D_NUMERO_INT != 0
                        ? ", NO.INT. " + compra.DETALLE.D_NUMERO_INT
                        : "") +
                      " " +
                      (compra.DETALLE.D_COLONIA != ""
                        ? ", COL. " + compra.DETALLE.D_COLONIA
                        : "") +
                      " " +
                      (compra.DETALLE.D_CP != ""
                        ? ", CP. " + compra.DETALLE.D_CP
                        : "")}

                    {(
                      compra.DETALLE.D_CALLE1 +
                      " " +
                      (compra.DETALLE.D_CALLE2 != 0
                        ? " y " + compra.DETALLE.D_CALLE2
                        : "")
                    ).trim() != "" && (
                      <>
                        <Typography
                          variant="p"
                          gutterBottom
                          component="div"
                          className="w-100"
                        >
                          Entre{" "}
                          {compra.DETALLE.D_CALLE1 +
                            " " +
                            (compra.DETALLE.D_CALLE2 != 0
                              ? " y " + compra.DETALLE.D_CALLE2
                              : "")}
                        </Typography>
                      </>
                    )}
                  </div>

                  <div className="col-12 col-md-6">
                    <strong> Contacto</strong>
                    <br />
                    {compra.DETALLE.D_NOMBRE_CONTACTO}
                    <br />
                    {compra.DETALLE.D_TELEFONO}
                  </div>
                  <div className="col-12 ">
                    <strong> Indicaciones</strong>
                    <br />
                    {compra.DETALLE.D_INDICACIONES}
                  </div>
                </div>

                <h6 className="mt-3 fw-bolder">Dedicatoria</h6>
                <div
                  className="container py-3 shadow"
                  style={{
                    borderRadius: 5,
                    backgroundColor: colores.enProcesoV3,
                  }}
                >
                  <Row className="">
                    <Col sm={12} md={6}>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-50"
                      >
                        <strong> Envia </strong>
                      </Typography>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-50"
                      >
                        {compra.DETALLE.ENVIA}
                      </Typography>
                    </Col>
                    <Col sm={12} md={6}>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-50"
                      >
                        <strong> Recibe </strong>
                      </Typography>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-50"
                      >
                        {compra.DETALLE.RECIBE}
                      </Typography>
                    </Col>
                    <Col sm={12}>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-1000"
                      >
                        <strong> Dedicatoria </strong>
                      </Typography>
                      <Typography
                        variant="p"
                        gutterBottom
                        component="div"
                        className="w-100"
                      >
                        {compra.DETALLE.DEDICATORIA}
                      </Typography>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col sm={12} md={5}>
                <div className="d-flex justify-content-between mb-3">
                  <div
                    className="d-flex align-items-center justify-content-center w-100 me-2"
                    style={{
                      backgroundColor:
                        compra.DETALLE.PAGADO == 1 ? "#E0FFD2" : "#FFF1D2",
                      width: "200px",
                      height: "40px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "15px",
                    }}
                  >
                    {compra.DETALLE.PAGADO == 1 ? (
                      <p
                        style={{ fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <CheckCircleOutlineIcon
                          color="green"
                          className="me-2"
                        />{" "}
                        PAGADO
                      </p>
                    ) : (
                      <p
                        style={{ fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <WarningAmberIcon color="orange" className="me-2" /> NO
                        PAGADO
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      "d-flex align-items-center justify-content-center w-100 ms-2 " +
                      backgrorundStatus
                    }
                    style={{
                      ...backgrorundStatusStyle,
                      width: "200px",
                      height: "40px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "15px",
                    }}
                  >
                    {compra.DETALLE.ID_ESTATUS == 1 ? (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <LocalShippingOutlinedIcon className="me-2" /> RECIBIDO
                      </p>
                    ) : compra.DETALLE.ID_ESTATUS == 2 ? (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <AccessTimeIcon className="me-2" /> PROCESADO
                      </p>
                    ) : compra.DETALLE.ID_ESTATUS == 3 ? (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <CheckCircleOutlineIcon className="me-2" /> TERMINADO
                      </p>
                    ) : compra.DETALLE.ID_ESTATUS == 4 ? (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <CheckCircleOutlineIcon className="me-2" /> EN RUTA
                      </p>
                    ) : compra.DETALLE.ID_ESTATUS == 5 ? (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <CheckCircleOutlineIcon className="me-2" /> ENTREGADO
                      </p>
                    ) : (
                      <p
                        style={{ color: colorSstatus, fontSize: "0.8rem" }}
                        className="d-flex align-items-center"
                      >
                        <HighlightOffIcon className="me-2" /> CANCELADO
                      </p>
                    )}
                  </div>
                </div>

                <Row className="w-100 mx-0">
                  <Col sm={12} md={6} className="ps-0">
                    <Button
                      className="text-dark w-100  p-3 d-flex align-items-center mb-3"
                      style={{
                        background: "#65748B",
                      }}
                      onClick={() => {
                        setShowDialogComprobante(true);
                      }}
                    >
                      <RemoveRedEyeIcon
                        className="me-3"
                        style={{ fontSize: "2rem", color: "#FFF" }}
                      />
                      <strong
                        className="w-100  text-white "
                        style={{ fontSize: "10px" }}
                      >
                        Ver comprobante
                      </strong>
                    </Button>
                  </Col>

                  <Col sm={12} md={6} className="pe-0">
                    <Button
                      className="text-dark w-100 p-3 d-flex align-items-center mb-3"
                      style={{
                        background: "#65748B",
                      }}
                      onClick={() => {
                        setShowDialogCargarComprobante(true);
                        setComprobanteSeleccionado(0);
                      }}
                    >
                      <UploadIcon
                        className="me-3"
                        style={{ fontSize: "2rem", color: "#FFF" }}
                      />
                      <strong
                        className="w-100  text-white "
                        style={{ fontSize: "10px" }}
                      >
                        Cargar comprobante
                      </strong>
                    </Button>
                  </Col>
                </Row>

                <Button
                  className="text-dark w-100 bg-success p-3 d-flex align-items-center mb-3"
                  onClick={() => {
                    setShowDiloglImprimir(true);
                  }}
                >
                  <LocalPrintshopIcon
                    className="me-3"
                    style={{ fontSize: "2rem", color: "#FFF" }}
                  />
                  <strong className="w-100  text-white ">Imprimir</strong>
                </Button>

                {compra.DETALLE.PAGADO == 0 && (
                  <Button
                    className="text-dark w-100 bg-success p-3 d-flex align-items-center mb-3"
                    onClick={() => {
                      setShowDialogFormaPago(true);
                    }}
                  >
                    <MonetizationOnIcon
                      className="me-3"
                      style={{ fontSize: "2rem", color: "#FFF" }}
                    />
                    <strong className="w-100  text-white ">Pagar</strong>
                  </Button>
                )}

                {/* {compra.DETALLE.PAGADO == 0 ? (
                  <Button
                    className="text-dark w-100 bg-success p-3 d-flex align-items-center mb-3"
                    onClick={() => {
                      setShowDialogFormaPago(true);
                    }}
                  >
                    <MonetizationOnIcon
                      className="me-3"
                      style={{ fontSize: "2rem", color: "#FFF" }}
                    />
                    <strong className="w-100  text-white ">Pagar</strong>
                  </Button>
                ) : (
                  <Button
                    className="text-dark w-100 bg-success p-3 d-flex align-items-center mb-3"
                    onClick={() => {
                      setShowDiloglImprimir(true);
                    }}
                  >
                    <LocalPrintshopIcon
                      className="me-3"
                      style={{ fontSize: "2rem", color: "#FFF" }}
                    />
                    <strong className="w-100  text-white ">Imprimir</strong>
                  </Button>
                )} */}

                {compra.DETALLE.ZONA_LARGA != "" &&
                  compra.DETALLE.ZONA_LARGA && (
                    <>
                      <h6 className="mt-4 fw-bolder">Zona</h6>
                      <Typography variant="p" gutterBottom component="div">
                        {compra.DETALLE.ZONA_LARGA}
                      </Typography>
                    </>
                  )}

                <h6 className="mt-4 fw-bolder">Florista</h6>
                <Typography variant="p" gutterBottom component="div">
                  {compra.DETALLE.ENCARGADO}
                </Typography>

                <h6 className="mt-3 fw-bolder">Repartidor</h6>
                <Typography variant="p" gutterBottom component="div">
                  {compra.DETALLE.REPARTIDOR}
                </Typography>

                <h6 className="mt-3 fw-bolder">Tipo de envío</h6>
                <Typography variant="p" gutterBottom component="div">
                  {compra.DETALLE.TIPO_ENVIO == "1"
                    ? "Envío a domicilio"
                    : "Recoger en sucursal"}
                </Typography>

                <h6 className="mt-3 fw-bolder">Observaciones</h6>
                <Typography variant="p" gutterBottom component="div">
                  {compra.DETALLE.OBSERVACIONES}
                </Typography>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Box>
  );
};

export default DetalleCompra;
