import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import { enviarFactura } from "../../Funciones/Facturas";
import { Col, Row } from "react-bootstrap";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);

const ModalEnvioFactura = ({
    facturaSeleccionada
    , correoEnvio
    , setCorreoEnvio
    , setFacturaSeleccionada
    , setShowModalEnviarFactura
}) => {

    const [Values, setValues] = useState({
        id: facturaSeleccionada.ID,
        correos: correoEnvio,
    });
    const [Errores, setErrores] = useState({
        correos: false,
    });

    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [IsGuardando, setIsGuardando] = useState(false)

    const EnviarFactura = () => {
        new Swal({
            title: "¿Estás seguro de enviar esta factura?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            setIsGuardando(true);
            if (isConfirm.isConfirmed) {
                enviarFactura(Values, Errores, setErrores)
                    .then((data) => {
                        setIsGuardando(false)
                        if (data.codigo == "200") {
                            MySwal.fire({
                                title: "Correcto",
                                html: data.mensaje,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                setCorreoEnvio("")
                                setShowModalEnviarFactura(false);
                                setFacturaSeleccionada(-1)
                            });
                        } else {
                            setMensaje(data.mensaje);
                            setOpen(true);
                        }
                    })
                    .catch((data) => {
                        setIsGuardando(false);;
                        setMensaje(data.mensaje);
                        setOpen(true);
                    });
            }
        });
    }

    return (

        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setFacturaSeleccionada(-1);
                        setCorreoEnvio("");
                        setShowModalEnviarFactura(false)
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder f-12 mt-2">Enviar factura por correo</h6>
                </DialogTitle>
                <DialogContent >

                    <Row className="">
                        {/* <Col sm={12} className="p-3 d-flex  flex-wrap align-items-center">
                            <p className="mx-0 my-1">
                                <strong className="m-0">NOTA: </strong> Para enviar otro correo separalos con ;
                            </p>
                            <p className="m-0">
                                <strong> EJEMPLO: </strong> correo_1@gmail.com<strong>;</strong>correo_2@gmail.com
                            </p>

                        </Col> */}
                        <Col sm={12} className="p-3">
                            <TextField
                                fullWidth
                                name="correos"
                                label="Correos"
                                variant="outlined"
                                value={Values.correos}
                                error={Errores.correos}
                                onChange={({ target }) => {
                                    setValues({ ...Values, correos: target.value })
                                    setErrores({ correos: false })
                                }}
                            />
                        </Col>
                    </Row>

                </DialogContent>
                <DialogActions className="px-4 pb-4">

                    <Button
                        onClick={() => {
                            setFacturaSeleccionada(-1);
                            setShowModalEnviarFactura(false)
                            setCorreoEnvio("")
                        }}
                        className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                        variant="outlined"
                    >
                        Cancelar
                    </Button>

                    <LoadingButton
                        loading={IsGuardando}
                        loadingPosition="start"
                        onClick={() => EnviarFactura()}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                        variant="contained"
                        xs={{ with: "100$" }}
                    >
                        <span className={IsGuardando ? "px-4" : "px-2"}>
                            {IsGuardando ? "Enviando..." : "Enviar"}
                        </span>
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalEnvioFactura