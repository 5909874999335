import { stringify } from "query-string";
import validator from "validator";
import { getData, postdData, postUrl } from "../../../../../Context/backend";

export const getLista = async (
  iTake,
  iSkip,
  Order,
  OrderStatus,
  query,
  fechaInicio,
  fechaFin
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    OrderStatus: OrderStatus,
    query: query,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
  };
  const url = `ajustemercancia/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const getInfoAjusteMerca = async (id) => {
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `ajustemercancia/Consultar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      if (data?.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este ajuste de mercancia",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];

        let d = {
          values: {
            id: String(data.ID),
            id_sucursal: String(data.ID_SUCURSAL),
            id_almacen: String(data.ID_ALMACEN),
            observaciones: String(data.OBSERVACIONES),
            productos: [],
          },
          productos: JSON.parse(res.data.respuesta.productos),
        };
        resolve(d);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardar = async (
  Values,
  Errores,
  setErrores,
  productosCarrito
) => {
  let error = false;

  if (
    !Values.id_sucursal ||
    Values.id_sucursal == "" ||
    Values.id_sucursal == 0
  ) {
    Values.id_sucursal = 0; //Puede ir en cero la sucursal
  }
  if (!Values.id_almacen || Values.id_almacen == "" || Values.id_almacen <= 0) {
    Errores.id_almacen = true;
    setErrores(Errores);
    error = true;
  }

  if (!error && productosCarrito.length == 0) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "ingresa al menos un producto" });
    });
  }

  Values.productos = [...productosCarrito];
  Values.id = Number(Values.id);
  Values.id_almacen = Number(Values.id_almacen);

  if (!error) {
    let data = [];
    const body = Values;
    //const url = "ingresoMercancia/Insertar";
    console.log(body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "ajustemercancia/Insertar"
        : "ajustemercancia/Editar";

    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const CancelarAjuste = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `ajustemercancia/Cancelar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const ProcesarAjuste = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `ajustemercancia/IngresoProcesado?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};
